import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, skipWhile, take } from 'rxjs';
import { TabNavigationItem } from 'src/app/shared/components/tab-navigation/models/TabNavigationItem';
import * as fromRoot from 'src/app/store';
import * as fromBookingCreationActions from './state/bookings-create.actions';
import * as fromBookingCreationSelectors from './state/bookings-create.selectors';
import { FeatureFlagService } from 'src/app/services/unleash.service';
import { Booking } from './models';

@UntilDestroy()
@Component({
	selector: 'app-campaign',
	templateUrl: './campaign.component.html',
	styleUrl: './campaign.component.scss',
})
export class CampaignComponent {
	// prettier-ignore
	constructor(private _route: ActivatedRoute, private _store: Store<fromRoot.State>, private _router: Router, private unleash: FeatureFlagService) {
		const uid = this._route.snapshot.params['uid'];
		this._store.dispatch(fromBookingCreationActions.loadBookingInformation({ id: uid }));
	}

	public loading$: Observable<boolean> = this._store
		.select(fromBookingCreationSelectors.selectLoading)
		.pipe(untilDestroyed(this));

	public booking$: Observable<Booking | null> = this._store
		.select(fromBookingCreationSelectors.selectBooking)
		.pipe(untilDestroyed(this));

	public confirmed$: Observable<boolean | null> = this.booking$.pipe(
		map((booking) => booking && booking.bookingStatus == 'Confirmed')
	);

	public bookingStatus$: Observable<string | null> = this.booking$.pipe(
		map((booking) => booking && booking.bookingStatus)
	);

	public mode$: Observable<'create' | 'edit'> = this._route.params.pipe(
		map((params) => {
			if (params['uid']) {
				return 'edit';
			}
			return 'create';
		})
	);

	public navigation$: Observable<TabNavigationItem[]> = combineLatest([this.confirmed$, this._route.params]).pipe(
		map(([confirmed, params]) => {
			const navigationItems: TabNavigationItem[] = [];
			if (confirmed) {
				navigationItems.push({
					name: 'Booking',
					url: `/network/campaign/${params['uid']}`,
				});
				if (this.unleash.isEnabled('client-portal-campaign-media-uploads')) {
					navigationItems.push({
						name: 'Media',
						url: `/network/campaign/${params['uid']}/media`,
					});
					navigationItems.push({
						name: 'Schedule',
						url: `/network/campaign/${params['uid']}/media/schedule`,
					});
				}
				return navigationItems;
			}
			return navigationItems;
		})
	);

	back(): void {
		this._router.navigate(['/network/campaigns']);
	}
}
