import { createAction, props } from '@ngrx/store';
import { Comment, AddCommentPayload, ContentDetails, ContentApprover } from './content.models';

/********************************
 * Retrieve comments from API
 *********************************/
export const loadComments = createAction('[Comment] Load Comments', props<{ uuid: string }>());
export const loadCommentsSuccess = createAction('[Comment] Load Comments Success', props<{ comments: Comment[] }>());
export const loadCommentsFail = createAction('[Comment] Load Comments Fail', props<{ error: any }>());

/********************************
 * Adding comments to API
 *********************************/
export const addComment = createAction('[Comment] Add Comment', props<{ uuid: string; comment: AddCommentPayload }>());
export const addCommentSuccess = createAction('[Comment] Add Comment Success', props<{ comment: AddCommentPayload }>());
export const addCommentFail = createAction('[Comment] Add Comment Fail', props<{ error: any }>());
export const resetAddCommentSuccess = createAction('[Comment] Reset Add Comment Success');

/********************************
 * Retrieve content metadata from API
 *********************************/
export const loadContentDetails = createAction('[Content] Load Content Details', props<{ uuid: string }>());
export const loadContentDetailsSuccess = createAction(
	'[Content] Load Content Details Success',
	props<{ contentDetails: ContentDetails }>()
);
export const loadContentDetailsFail = createAction('[Content] Load Content Details Fail', props<{ error: any }>());

/********************************
 * Retrieve approvers from API
 *********************************/
export const loadContentApproval = createAction('[Content Approval] Load Content Approval', props<{ uuid: string }>());
export const loadContentApprovalSuccess = createAction(
	'[Content Approval] Load Content Approval Success',
	props<{ approvers: ContentApprover[] }>()
);
export const loadContentApprovalFail = createAction(
	'[Content Approval] Load Content Approval Fail',
	props<{ error: any }>()
);

/********************************
 * Approving/Denying content
 *********************************/
export const approveContent = createAction('[Content Approval] Approve Content', props<{ uuid: string }>());
export const approveContentSuccess = createAction('[Content Approval] Approve Content Success');
export const approveContentFail = createAction('[Content Approval] Approve Content Fail', props<{ error: any }>());
export const denyContent = createAction('[Content Approval] Deny Content', props<{ uuid: string; reason: string }>());
export const denyContentSuccess = createAction('[Content Approval] Deny Content Success');
export const denyContentFail = createAction('[Content Approval] Deny Content Fail', props<{ error: any }>());
