export interface TouchpointSummaryItem {
	changePeriod: string;
	header: string;
	image: string;
	powerNumber: number;
	relativeChange: number;
	directionImage: string;
}

export interface TouchpointCard {
	name: string;
	id: number;
	imageUrl: string;
	categoryId: number;
	categoryName: string;
	touchpointInformation: TouchpointInformation[];
	orientation: string;
	bookingsAllowed: boolean;
	metrics: TouchpointMetric[];
	previews: TouchpointCampaign[];
	variants: TouchpointVariant[];
}

export interface TouchpointInformation {
	name: string;
	values: TouchpointInformationValues[];
}

export interface TouchpointInformationValues {
	type: string;
	data: string;
	text: string;
}

export interface TouchpointMetric {
	name: string;
	number: number;
	percentageGrowth: string;
	growthPeriod: string;
}

export interface TouchpointCampaign {
	name: string;
	videoUrl: string;
	numDisplays: number;
	numNpcDisplays: number;
	brand: string;
	default: boolean;
	startDate: Date;
	mediaId: string;
	isResult: boolean;
	isSplash: boolean;
}

export interface TouchpointVariant {
	name: string;
	id: number;
	variantInformation: TouchpointInformation[];
	metrics: TouchpointMetric[];
	previews: TouchpointCampaign[];
}
