<app-apple-card [shadow]="true" [style]="{ width: '75vw', 'max-width': '460px' }">
	<div class="content">
		<div class="title">You cannot reset your password</div>
		<div class="message">
			Passwords cannot be reset for accounts created using SSO. Please sign in with your Google or Microsoft account.
		</div>
		<div class="action">
			<span class="link">Sign in</span>
		</div>
		<div class="logo">
			<img class="img" src="../../../../assets/barrows.png" />
		</div>
	</div>
</app-apple-card>
