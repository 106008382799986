<app-card>
	<app-apple-table
		class="table"
		[ngClass]="{ 'no-data': data.length == 0 }"
		[loading]="loading"
		[columns]="columns"
		[data]="data"
		[minimumRowHeight]="90">
		<app-no-data
			no-data
			image="../../../../../assets/no-data-category.svg"
			title="No Results"
			message="Try updating your filters."></app-no-data>
	</app-apple-table>
</app-card>
