import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { OAuthService } from 'angular-oauth2-oidc';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { filter, take } from 'rxjs';
import * as fromUser from '../../../../store/user/user.actions';

@Component({
	selector: 'apple-auth-callback',
	templateUrl: './apple-auth-callback.component.html',
	styleUrls: ['./apple-auth-callback.component.scss'],
})
export class AppleAuthCallbackComponent implements OnInit {
	constructor(
		private oauth: OAuthService,
		private route: ActivatedRoute,
		private store: Store
	) {}

	async ngOnInit() {
		await this.handleAuthCallback();
	}

	private async handleAuthCallback() {
		const fragment = this.route.snapshot.fragment;
		const params = this.route.snapshot.queryParams;
		const data = new URLSearchParams(fragment || params);
		const state = data.get('state');
		if (state) {
			await this.processState(state);
		} else {
			console.log('Something went wrong, could not get state from redirect....');
		}
	}

	private async processState(state: string) {
		const decodedState = decodeURIComponent(state.split(';')[1]);
		const parameters = new URLSearchParams(decodedState);
		const provider = parameters.get('provider') || '';
		const redirect = parameters.get('redirect_uri') || '';
		const registration = parameters.get('registration') || false;
		const uuid_token = parameters.get('uuid_token') || '';

		if (provider == 'google' || provider == 'microsoft') {
			if (registration) {
				await this.completeRegistrationFlow(provider, uuid_token);
			} else {
				await this.completeSignInFlow(provider, redirect);
			}
		} else {
			console.log('Something went wrong, we only support Microsoft and Google login methods via OAuth....');
		}
	}

	private async completeSignInFlow(provider: 'microsoft' | 'google', redirect: string) {
		this.oauth.events
			.pipe(filter((event) => event.type == 'token_received'))
			.pipe(take(1))
			.subscribe(() => {
				this.store.dispatch(
					fromUser.loginWithCredentials({
						payload: {
							token: this.oauth.getIdToken(),
							token_type: provider,
						},
						redirect,
					})
				);
			});

		this.oauth.configure(environment.auth[provider]);
		await this.oauth.loadDiscoveryDocumentAndTryLogin();
	}

	private async completeRegistrationFlow(provider: 'microsoft' | 'google', uuid_token: string) {
		this.oauth.events
			.pipe(filter((event) => event.type == 'token_received'))
			.pipe(take(1))
			.subscribe(() => {
				this.store.dispatch(
					fromUser.registerWithToken({
						payload: {
							sso_token: this.oauth.getIdToken(),
							uuid_token,
						},
						provider,
					})
				);
			});

		this.oauth.configure(environment.auth[provider]);
		await this.oauth.loadDiscoveryDocumentAndTryLogin();
	}
}
