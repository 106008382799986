<app-card class="card" [type]="theme" [ngClass]="theme">
	<div class="title">
		<div class="title-text" *ngIf="!loading">{{ data?.title }}</div>
		<app-skeleton-loader
			*ngIf="loading"
			width="150px"
			height="20px"
			[backgroundColor]="getSkeletonLoaderBackground()"
			[animation]="getSkeletonLoaderTheme()"></app-skeleton-loader>
	</div>
	<div class="container">
		<ng-container *ngIf="!loading; else loadingTemplate">
			<div class="donut-wrapper">
				<div class="pie-container">
					<div class="pie-chart" [style.background]="getGradient()"></div>
					<div class="background-overlay"></div>
					<div class="content" *ngIf="data?.value || data?.header">
						<div class="amount-container">
							<div class="amount" *ngIf="data?.value">{{ data?.value }}</div>
							<ng-content select="[custom-value-unit]"></ng-content>
						</div>
						<div class="label" *ngIf="data?.header">{{ data?.header }}</div>
					</div>
				</div>
			</div>
			<div class="trend-indicator" *ngIf="data?.change">
				<app-trend-indicator [type]="theme" [data]="data?.change"></app-trend-indicator>
			</div>
		</ng-container>

		<ng-template #loadingTemplate>
			<app-skeleton-loader
				width="280px"
				height="280px"
				borderRadius="50%"
				[backgroundColor]="getSkeletonLoaderBackground()"
				[animation]="getSkeletonLoaderTheme()"></app-skeleton-loader>
			<div class="trend-indicator">
				<app-skeleton-loader
					width="130px"
					height="25px"
					borderRadius="9px"
					[backgroundColor]="getSkeletonLoaderBackground()"
					[animation]="getSkeletonLoaderTheme()"></app-skeleton-loader>
			</div>
		</ng-template>
		<app-insights-data-legend
			*ngIf="showLegend"
			class="legend-container"
			[loading]="loading"
			[data]="data?.series"></app-insights-data-legend>
	</div>
</app-card>
