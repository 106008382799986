import { Component, OnInit } from '@angular/core';
import { combineLatest, distinctUntilChanged, map, skip, skipWhile } from 'rxjs';
import { Store } from '@ngrx/store';
import { Page } from 'src/app/components/apple/apple-pagination/models/page';

import * as fromRoot from '../../store';
import * as fromTouchpoint from 'src/app/features/dashboard/state';
import * as fromSession from '../../store/session';

import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
	public loading$ = this.store.select(fromTouchpoint.getTouchpointLoading);
	public summary$ = this.store.select(fromTouchpoint.getTouchpointSummary);
	public cards$ = this.store.select(fromTouchpoint.getTouchpointCards);
	public count$ = this.store.select(fromTouchpoint.getAllTouchpointCards).pipe(map((cards) => cards.length));
	public filters$ = this.store.select(fromSession.getFilters);
	public companies$ = this.store.select(fromSession.getHoldingCompanies);
	public page$ = this.store.select(fromTouchpoint.getTouchpointPage);

	constructor(
		private store: Store<fromRoot.State>,
		private route: ActivatedRoute,
		private router: Router
	) {
		this.store
			.select(fromSession.getFilters)
			.pipe(
				skipWhile((filters) => !filters.company.id || !filters.company.name),
				untilDestroyed(this)
			)
			.subscribe((filters) => {
				this.store.dispatch(fromTouchpoint.loadTouchpoints({ filter: filters.company.id }));
			});
	}

	ngOnInit(): void {
		this.route.queryParams.subscribe((params) => {
			const page = parseInt(params['page']) || 1;
			this.store.dispatch(fromTouchpoint.setPage({ page, size: 5 }));
		});
	}

	onPageChange(event: Page): void {
		this.router.navigate([], {
			relativeTo: this.route,
			queryParams: { page: event.page },
		});
	}
}
