import { createAction, props } from '@ngrx/store';
import { Booking, BookingInformation } from '../models';
import { Store as PlatformStore } from 'src/app/shared/models/api/store/Store';

export const loadBookingInformation = createAction(
	'[Booking Create] Load Booking Information',
	props<{ id: string }>()
);

export const loadBookingInformationSuccess = createAction(
	'[Booking Create] Load Booking Information Success',
	props<{ info: BookingInformation }>()
);

export const loadStores = createAction('[Booking Create] Load Stores', props<{ categoryId: number }>());

export const loadStoresSuccess = createAction(
	'[Booking Create] Load Stores Success',
	props<{ stores: PlatformStore[] }>()
);

export const selectStores = createAction('[Booking Create] Select Store', props<{ stores: PlatformStore[] }>());
export const deselectStores = createAction('[Booking Create] Deselect Store', props<{ stores: PlatformStore[] }>());
export const selectAllStores = createAction('[Booking Create] Select All Stores', props<{ select: boolean }>());

export const setSearchText = createAction('[Booking Create] Set Search Text', props<{ searchText: string }>());

export const setSort = createAction(
	'[Booking Create] Set Sort',
	props<{ column: string | null; direction: string | null }>()
);
export const setPage = createAction('[Booking Create] Set Page', props<{ page: number; size: number }>());

export const setAppliedFilters = createAction(
	'[Booking Create] Set Applied Filters',
	props<{ filters: { name: string; values: string[] }[] }>()
);
export const resetAppliedFilters = createAction('[Booking Create] Reset Applied Filters');

export const submitBooking = createAction('[Booking Create] Submit A Booking', props<{ booking: Booking }>());
export const submitBookingSuccess = createAction(
	'[Booking Create] Submit A Booking Success',
	props<{ booking: Booking }>()
);
export const submitBookingFailure = createAction('[Booking Create] Submit A Booking Failure');

export const cancelBooking = createAction('[Booking Create] Cancel Booking');

export const uploadMediaFile = createAction(
	'[Media] Upload Media File',
	props<{ variant: number; file: File; previewUrl: string }>()
);

export const deleteMediaFile = createAction('[Media] Delete Media File', props<{ variant: number }>());

export const submitMediaContent = createAction(
	'[Media] Submit Media Content',
	props<{
		bookingId: string;
		approverIds: number[];
		rgb: string;
		mediaFiles: { [key: string]: File };
	}>()
);
export const confirmBooking = createAction('[Booking] Confirm Booking', props<{ bookingId: string }>());
export const deleteBooking = createAction('[Booking] Delete Booking', props<{ bookingId: string }>());
