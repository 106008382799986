import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateFn } from '@angular/router';
import { Observable } from 'rxjs';
import { take, map, filter } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import * as fromRoot from '../store';
import * as fromSession from '../store/session';
import { ReportNavigation } from '../shared/models/api/reports/ReportNavigation';

@Injectable({
	providedIn: 'root',
})
export class RouteGuard {
	constructor(
		private store: Store<fromRoot.State>,
		private router: Router
	) {}

	canActivate: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
		const allowedPaths = ['/network/dashboard', '/network/error', '/network/404'];
		if (allowedPaths.some((path) => state.url.startsWith(path))) {
			return true;
		}

		this.store.dispatch(fromSession.retrieveRoutes());

		return this.checkRoute(state.url);
	};

	private checkRoute(url: string): Observable<boolean> {
		return this.store.pipe(
			select(fromSession.getReportItems),
			filter((reports: ReportNavigation[]) => reports && reports.length > 0),
			take(1),
			map((reports) => {
				const validPaths = reports
					.map((report) =>
						report.items.map((item) => (item.path.startsWith('/') ? item.path : `/network/report/${item.path}`))
					)
					.flat();

				const pathExists = validPaths.includes(url);

				if (!pathExists) {
					this.router.navigate(['/not-found']);
				}

				return pathExists;
			})
		);
	}
}
