import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-touchpoint-data',
	templateUrl: './touchpoint-data.component.html',
	styleUrls: ['./touchpoint-data.component.scss'],
})
export class TouchpointDataComponent {
	@Input() public loading: boolean = false;
	@Input() public name?: string;
	@Input() public category?: string;
	@Input() public image?: string;
	@Input() public value?: string;
}
