import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-insights-emissions-measurement-indicator',
	templateUrl: './measurement-indicator.component.html',
	styleUrls: ['./measurement-indicator.component.scss'],
})
export class EmissionsMeasurementIndicatorComponent {
	@Input()
	public value: string | null = null;

	constructor() {}
}
