import { createSelector, createFeatureSelector } from '@ngrx/store';
import { UserState } from './user.reducers';

export const getUserState = createFeatureSelector<UserState>('user');
export const getUserAuthToken = createSelector(getUserState, (state) => state.token);
export const getUser = createSelector(getUserState, (state) => {
	return {
		name: state.details.name,
		email: state.details.email,
		image: state.details.image,
	};
});
export const getUserError = createSelector(getUserState, (state) => state.error);
