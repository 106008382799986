import { Component } from '@angular/core';

@Component({
	selector: 'app-insights-sustainability-feature-overview',
	templateUrl: './feature-overview.component.html',
	styleUrls: ['./feature-overview.component.scss'],
})
export class SustainabilityFeatureOverviewComponent {
	constructor() {}
}
