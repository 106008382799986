import { Component, Input } from '@angular/core';
import { MonetizedImpressions } from '../../models';

@Component({
	selector: 'app-bar-graph-insight',
	templateUrl: './insight-bar-graph.component.html',
	styleUrls: ['./insight-bar-graph.component.scss'],
})
export class InsightBarGraphComponent {
	@Input()
	public loading: boolean = true;

	@Input()
	public data?: MonetizedImpressions | null;
}
