import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { UnleashService } from '@karelics/angular-unleash-proxy-client';
import * as fromSession from '../store/session';

@Injectable({
	providedIn: 'root',
})
export class FeatureFlagService {
	constructor(private unleashService: UnleashService) {}

	start(): void {
		this.unleashService.unleash.start();
	}

	stop(): void {
		this.unleashService.unleash.stop();
	}

	updateContext(context: string, value: string | null): void {
		const contextValue = value !== null ? value : '';
		this.unleashService.unleash.setContextField(context, contextValue);
	}

	isEnabled(feature: string): boolean {
		return this.unleashService.isEnabled(feature);
	}

	isDisabled(feature: string): boolean {
		return this.unleashService.isDisabled(feature);
	}
}
