import { createReducer, on } from '@ngrx/store';
import * as fromTouchpoints from './touchpoint.actions';
import { TouchpointSummaryItem, TouchpointCard } from './touchpoint.models';

export interface TouchpointState {
	loading: boolean;
	filters: {
		page: number;
		pageSize: number;
	};
	summary: TouchpointSummaryItem[];
	cards: TouchpointCard[];
}

export const initialState: TouchpointState = {
	loading: true,
	filters: {
		page: 1,
		pageSize: 5,
	},
	summary: [],
	cards: [],
};

export const reducer = createReducer(
	initialState,
	on(fromTouchpoints.loadTouchpoints, (state) => ({ ...state, loading: true })),
	on(fromTouchpoints.loadTouchpointsSuccess, (state, { summary, cards }) => ({
		...state,
		summary,
		cards,
		loading: false,
	})),
	on(fromTouchpoints.setPage, (state, { page, size }) => ({ ...state, filters: { page, pageSize: size } }))
);
