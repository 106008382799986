import { Component, Input } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'format-date',
	templateUrl: './format-date.component.html',
})
export class FormatDateComponent {
	@Input() public formattedDate!: Date;
	constructor() {}
}
