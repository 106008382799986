<app-apple-card>
	<app-touchpoint-details
		[name]="card.name"
		[category]="card.categoryName"
		(onVariantVisibilityChange)="setVariantVisibility($event)"
		[information]="card.touchpointInformation"
		[previews]="card.previews"
		[metrics]="card.metrics"
		[bookingsAllowed]="card.bookingsAllowed"
		[imageUrl]="card.imageUrl"
		[id]="card.id"
		[categoryId]="card.categoryId"
		[orientation]="card.orientation"
		[variantCount]="card.variants.length"></app-touchpoint-details>

	<div [@smoothCollapse]="showVariants ? 'open' : 'closed'">
		<ng-container *ngFor="let variant of card.variants; index as i">
			<div class="spacer-line"></div>
			<div class="variant">
				<app-touchpoint-details
					class="variant"
					[name]="variant.name"
					[category]="card.categoryName"
					[previews]="variant.previews"
					[metrics]="variant.metrics"
					[information]="variant.variantInformation"
					[imageUrl]="card.imageUrl"
					[id]="card.id"
					[variantId]="variant.id"
					[categoryId]="card.categoryId"
					[orientation]="card.orientation"></app-touchpoint-details>
			</div>
		</ng-container>
	</div>
</app-apple-card>
