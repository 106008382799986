import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InsightsState } from '../../state';
import { RevenueState } from './revenue.reducers';

export const selectInsightState = createFeatureSelector<InsightsState>('insights');

export const selectRevenueState = createSelector(selectInsightState, (state: InsightsState) => state.revenue);

export const selectFilters = createSelector(selectRevenueState, (state: RevenueState) => state.filters);

export const selectCategoryFilters = createSelector(
	selectRevenueState,
	(state: RevenueState) => state.header.filters.categories || []
);

export const selectLoading = createSelector(selectRevenueState, (state) => state.loading);

export const selectCurrency = createSelector(selectRevenueState, (state: RevenueState) => state.header.currency);

export const selectPowerNumbers = createSelector(selectRevenueState, (state: RevenueState) => state.data.powerNumbers);

export const selectTrendNumbers = createSelector(selectRevenueState, (state: RevenueState) => state.data.trendNumbers);

export const selectMonetizedImpressions = createSelector(
	selectRevenueState,
	(state: RevenueState) => state.data.monetizedImpressions
);

export const selectTotalRevenue = createSelector(selectRevenueState, (state: RevenueState) => state.data.totalRevenue);

export const selectRevenueAnalysis = createSelector(
	selectRevenueState,
	(state: RevenueState) => state.data.revenueAnalysis
);

export const selectTouchpointRevenue = createSelector(
	selectRevenueState,
	(state: RevenueState) => state.data.touchpointRevenue
);

export const selectClientRevenue = createSelector(
	selectRevenueState,
	(state: RevenueState) => state.data.clientRevenue
);

export const selectCampaignRevenue = createSelector(
	selectRevenueState,
	(state: RevenueState) => state.data.campaignRevenue
);

export const selectTouchpointDetails = createSelector(selectRevenueState, (state: RevenueState) =>
	state.data.touchpointDetails ? state.data.touchpointDetails.slice(0, 4) : []
);

export const selectInsightsLoading = createSelector(selectRevenueState, (state: RevenueState) =>
	state ? state.loading : false
);

export const selectInsightsError = createSelector(selectRevenueState, (state: RevenueState) =>
	state ? state.error : null
);
