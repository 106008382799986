import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-apple-card',
	templateUrl: './apple-card.component.html',
	styleUrls: ['./apple-card.component.scss'],
})
export class AppleCardComponent {
	@Input()
	public shadow: boolean = false;

	@Input()
	public style: { [key: string]: string } = {};
}
