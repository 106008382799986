export const TableHelpers = {
	paginate: (items: any[], page: number, pageSize: number) => {
		const start = (page - 1) * pageSize;
		const end = page * pageSize;
		return items.slice(start, end);
	},
	search: (items: any[], searchTerm: string) => {
		const lowerCaseSearchTerm = searchTerm.toLowerCase();
		const search = (obj: any) => {
			for (const key in obj) {
				if (obj.hasOwnProperty(key)) {
					const value = obj[key];
					if (typeof value === 'string' && value.toLowerCase().includes(lowerCaseSearchTerm)) {
						return true;
					} else if (Array.isArray(value)) {
						if (value.some((item) => typeof item === 'string' && item.toLowerCase().includes(lowerCaseSearchTerm))) {
							return true;
						} else if (value.some((item) => typeof item === 'object' && item !== null && search(item))) {
							return true;
						}
					} else if (typeof value === 'object' && value !== null) {
						if (search(value)) {
							return true;
						}
					}
				}
			}
			return false;
		};
		return items.filter(search);
	},
	sort: (items: any[], column: string, direction: string) => {
		if (!column || !direction) {
			return items;
		}

		return [...items].sort((a: any, b: any) => {
			if (a[column] < b[column]) return direction === 'asc' ? -1 : 1;
			if (a[column] > b[column]) return direction === 'asc' ? 1 : -1;
			return 0;
		});
	},
};
