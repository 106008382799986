import { Component, Input } from '@angular/core';
import { TouchpointData } from 'src/app/features/insights/models/view/TouchpointData';
import { TableColumn } from 'src/app/components/apple/apple-table/models/column';

import { TouchpointDataComponent } from '../touchpoint-data/touchpoint-data.component';
import { SellThroughRateComponent } from './components/sell-through-rate/sell-through-rate.component';
import { InsightsTrendIndicatorComponent } from '../trend-indicator/trend-indicator.component';

@Component({
	selector: 'app-insights-touchpoint-details',
	templateUrl: './touchpoint-details.component.html',
	styleUrls: ['./touchpoint-details.component.scss'],
})
export class InsightsTouchpointDetailsComponent {
	@Input() public loading: boolean = true;
	@Input() public data: TouchpointData[] = [];

	public columns: TableColumn[] = [
		{ header: 'Touchpoint', field: 'touchpoint', sortable: false, component: TouchpointDataComponent },
		{ header: 'Sell-Through Rate', field: 'sellThroughRate', sortable: false, component: SellThroughRateComponent },
		{ header: 'Total Impressions', field: 'totalImpressions', sortable: false },
		{ header: 'Campaign Impressions', field: 'campaignImpressions', sortable: false },
		{ header: 'Campaign Value', field: 'campaignValue', sortable: false },
		{ header: 'Spill Value', field: 'spillValue', sortable: false },
		{
			header: 'Previous Trend (4 Month)',
			field: 'previousTrend',
			sortable: false,
			component: InsightsTrendIndicatorComponent,
		},
	];
}
