<div class="campaign-container" *ngLet="loading$ | async as loading">
	@if (loading) {
		<app-apple-loader [loading]="loading"></app-apple-loader>
	} @else {
		<ng-container *ngLet="mode$ | async as mode">
			<div class="page-header">
				<app-action-link [text]="'Back'" (click)="back()"></app-action-link>
				<app-page-header
					[heading]="mode == 'edit' ? 'Edit Campaign' : 'Create a Campaign'"
					description="Please complete the form below by filling in your touchpoint and campaign details.">
					<app-pill badge [type]="(bookingStatus$ | async) ?? ''" *ngIf="mode == 'edit'"></app-pill>
				</app-page-header>
			</div>
			<div class="page-navigation" *ngIf="mode == 'edit'">
				<app-tab-navigation [items]="(navigation$ | async) ?? []"></app-tab-navigation>
			</div>
			<router-outlet></router-outlet>
		</ng-container>
	}
</div>
