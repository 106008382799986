<div class="dialog-container">
	<div class="header-container">
		<h2 class="title">Reason for Rejection</h2>
		<i class="fas fa-times fa-2x close" (click)="close()"></i>
	</div>

	<div>
		<p class="subtitle">Please provide your motivation for rejecting this content.</p>
		<textarea class="input" [(ngModel)]="reason" placeholder="Enter your comment here..."></textarea>
	</div>
	<div class="footer">
		<button class="cancel" (click)="close()">Cancel</button>
		<button class="submit" (click)="submit()" [disabled]="!reason.trim()">Submit Rejection</button>
	</div>
</div>