import { Component, ElementRef, HostListener, OnInit } from '@angular/core';

@Component({
	selector: 'app-dropdown',
	templateUrl: './dropdown.component.html',
	styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit {
	public show = false;

	constructor(private _elementRef: ElementRef) {}

	public ngOnInit() {}

	style() {
		return this.show ? { display: 'block' } : { display: 'none' };
	}

	toggle() {
		this.show = !this.show;
	}

	@HostListener('document:click', ['$event.target'])
	close(target: string): void {
		if (!this._elementRef.nativeElement.contains(target)) {
			this.show = false;
		}
	}
}
