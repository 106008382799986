import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-booking-snapshot',
	templateUrl: './booking-snapshot.component.html',
	styleUrls: ['./booking-snapshot.component.scss'],
})
export class BookingSnapshotComponent {
	@Input() public url!: string;
}
