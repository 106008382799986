<ng-container *ngIf="!loading; else loadingTemplate">
	<div class="container" *ngFor="let legend of data">
		<div class="header">
			<div class="icon" [style.background-color]="legend.color"></div>
			<div class="title">{{ legend.header }}</div>
		</div>
		<div class="items" *ngIf="legend.items">
			<div class="item" *ngFor="let item of legend.items">
				<div class="name">{{ item.title }}</div>
				<div class="value">
					{{ item.value }}
					<ng-container *ngIf="item.customUnit" [ngComponentOutlet]="item.customUnit"></ng-container>
				</div>
			</div>
		</div>
	</div>
</ng-container>
<ng-template #loadingTemplate>
	<div class="container">
		<div class="header">
			<app-skeleton-loader width="20px" height="20px" borderRadius="50%"></app-skeleton-loader>
			<app-skeleton-loader width="150px" height="20px"></app-skeleton-loader>
		</div>
		<div class="items">
			<div class="item">
				<app-skeleton-loader width="250px" height="18px"></app-skeleton-loader>
			</div>
			<div class="item">
				<app-skeleton-loader width="190px" height="18px"></app-skeleton-loader>
			</div>
		</div>
	</div>
	<div class="container">
		<div class="header">
			<app-skeleton-loader width="20px" height="20px" borderRadius="50%"></app-skeleton-loader>
			<app-skeleton-loader width="150px" height="20px"></app-skeleton-loader>
		</div>
		<div class="items">
			<div class="item">
				<app-skeleton-loader width="250px" height="18px"></app-skeleton-loader>
			</div>
			<div class="item">
				<app-skeleton-loader width="190px" height="18px"></app-skeleton-loader>
			</div>
		</div>
	</div>
</ng-template>
