import { Input, Component } from '@angular/core';
import { CampaignOverview } from '../../models';

@Component({
	selector: 'app-insights-campaign-overview',
	templateUrl: './overview.component.html',
	styleUrls: ['./overview.component.scss'],
})
export class InsightsCampaignOverviewComponent {
	@Input()
	public loading: boolean = true;

	@Input()
	public data?: CampaignOverview | null;
}
