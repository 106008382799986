export interface Comment {
	first_name: string;
	last_name: string;
	created_at: string;
	timestamp: string;
	text: string;
	avatar: string;
}

export interface ContentDetails {
	id: number;
	uuid: string;
	url: string;
	title: string;
	category: string;
	touchpoint: string;
	ratio: string;
	dimensions: string;
	description: string | null;
	filetype: string;
	uploader: string;
	framerate: number;
}

export interface ContentApprover {
	approved: boolean | null;
	avatar: string;
	email: string;
	first_name: string;
	last_name: string;
}

export interface LoadCommentsPayload {
	uuid: string;
}

export interface LoadCommentsSuccessPayload {
	comments: Comment[];
}

export interface LoadCommentsFailPayload {
	error: any;
}

export interface AddCommentFailPayload {
	error: any;
}

export interface AddCommentPayload {
	comment: string;
	comment_timestamp: number | null;
}
