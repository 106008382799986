import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

export interface Banner {
	type: 'success' | 'error' | 'warning' | 'info';
	title?: string;
	description?: string;
}

@Component({
	selector: 'app-banner',
	templateUrl: './banner.component.html',
	styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnChanges {
	@Input()
	public open: boolean = true;

	@Input()
	public data: Banner | null = null;

	constructor() {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['data']) {
			this.open = true;
		}
	}

	getIcon(): string {
		return `assets/banner-${this.data?.type}.svg`;
	}

	close(): void {
		this.open = false;
	}
}
