import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { CustomValidators } from 'src/app/validators/custom-validators';
import * as fromRoot from '../../../../store';
import * as fromUser from '../../../../store/user/user.actions';
import { Observable } from 'rxjs';
import { NeptuneError } from 'src/app/shared/models/api/NeptuneError';

@Component({
	selector: 'app-apple-registration',
	templateUrl: './apple-registration.component.html',
	styleUrls: ['./apple-registration.component.scss'],
})
export class AppleRegistrationComponent implements OnInit {
	public token!: string;
	public loading = false;
	public form!: FormGroup;
	public name$: Observable<string | null>;
	public email$: Observable<string | null>;
	public loading$: Observable<boolean>;
	public error$: Observable<NeptuneError | null>;
	public type!: 'google' | 'microsoft' | 'traditional';

	constructor(private _route: ActivatedRoute, private _fb: FormBuilder, private _store: Store<fromRoot.State>) {
		this.form = this._fb.group(
			{
				email: ['', [Validators.required, CustomValidators.emailValidator()]],
				password: ['', [Validators.required, CustomValidators.passwordComplexityValidator]],
				confirmPassword: ['', Validators.required],
			},
			{
				validators: [CustomValidators.passwordMatchValidator()],
			}
		);
		this.loading$ = this._store.select((state) => state.user.loading);
		this.error$ = this._store.select((state) => state.user.error);
		this.name$ = this._store.select((state) => state.user.details.name);
		this.email$ = this._store.select((state) => state.user.details.email);
		this.email$.subscribe((email) => {
			this.form.controls['email'].setValue(email);
		});
	}

	ngOnInit(): void {
		const params = this._route.snapshot.params;
		this.token = params['uuid_token'];
		this.type = params['login_method'];
		this._store.dispatch(
			fromUser.requestAccountTokenValidation({
				request: {
					uuid_token: this.token,
					token_type: this.type,
				},
			})
		);
	}

	registerWithEmail() {
		this.loading = true;
		this._store.dispatch(
			fromUser.registerWithCredentials({
				payload: {
					uuid_token: this.token,
					username: this.form.controls['email'].value,
					password: this.form.controls['password'].value,
				},
			})
		);
	}

	registerWithGoogle() {
		this._store.dispatch(fromUser.registerWithOAuth({ uuid_token: this.token, provider: 'google' }));
	}

	registerWithMicrosoft() {
		this._store.dispatch(fromUser.registerWithOAuth({ uuid_token: this.token, provider: 'microsoft' }));
	}

	public invalid(form: AbstractControl, prop: string, validator: string) {
		return form.get(prop)?.errors?.[validator];
	}
}
