<app-card>
	<div class="title">
		<div class="title-text">Sustainability Features</div>
	</div>
	<div class="container">
		<div class="item">
			<img
				class="item-image"
				src="assets/sustainability-renewable-energy-1.svg"
				alt="Servers Powered by Renewable Energy" />
			<div class="item-title">Renewable-Powered Servers</div>
		</div>
		<div class="item">
			<img class="item-image" src="assets/sustainability-energy-efficient.svg" alt="Energy Efficient" />
			<div class="item-title">Energy Efficient</div>
		</div>
	</div>
</app-card>
