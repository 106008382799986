import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, Observable } from 'rxjs'; // Import Observable
import { switchMap, catchError, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import * as contentActions from './content.actions';
import { environment } from 'src/environments/environment';
import { Action, Store } from '@ngrx/store'; // Import Action
import { AddCommentPayload, ContentDetails, ContentApprover, Comment } from './content.models';
import * as fromRoot from '../../../store';

@Injectable()
export class ContentEffects {
	constructor(
		private actions$: Actions,
		private _http: HttpClient,
		private store: Store<fromRoot.State>
	) {}

	loadComments$ = createEffect(() =>
		this.actions$.pipe(
			ofType(contentActions.loadComments),
			switchMap((action) =>
				this._http
					.get<{
						comments: Comment[];
					}>(`${environment.api}/client_portal/content_approval/${action.uuid}/?action_type=comment-history`)
					.pipe(
						map((response) => contentActions.loadCommentsSuccess({ comments: response.comments })),
						catchError((error) => of(contentActions.loadCommentsFail({ error })))
					)
			)
		)
	);

	addComment$ = createEffect(() =>
		this.actions$.pipe(
			ofType(contentActions.addComment),
			switchMap((action) =>
				this._http
					.post<AddCommentPayload>(
						`${environment.api}/client_portal/content_approval/${action.uuid}/?action_type=create-comment`,
						action.comment
					)
					.pipe(
						map((response) => contentActions.addCommentSuccess({ comment: response as AddCommentPayload })),
						tap(() => {
							this.store.dispatch(contentActions.loadComments({ uuid: action.uuid }));
							this.store.dispatch(contentActions.resetAddCommentSuccess());
						}),
						catchError((error): Observable<Action> => of(contentActions.addCommentFail({ error })))
					)
			)
		)
	);

	loadContentDetails$ = createEffect(() =>
		this.actions$.pipe(
			ofType(contentActions.loadContentDetails),
			switchMap((action) =>
				this._http
					.get<ContentDetails>(
						`${environment.api}/client_portal/content_approval/${action.uuid}/?action_type=video-details`
					)
					.pipe(
						map((contentDetails) => contentActions.loadContentDetailsSuccess({ contentDetails })),
						catchError((error) => of(contentActions.loadContentDetailsFail({ error })))
					)
			)
		)
	);

	// For approving and denying content

	approveContent$ = createEffect(() =>
		this.actions$.pipe(
			ofType(contentActions.approveContent),
			switchMap((action) =>
				this._http
					.post(`${environment.api}/client_portal/content_approval/${action.uuid}/?action_type=content-approval`, {
						approved: true,
					})
					.pipe(
						tap(() => this.store.dispatch(contentActions.loadContentApproval({ uuid: action.uuid }))),
						map(() => contentActions.approveContentSuccess()),
						catchError((error) => of(contentActions.approveContentFail({ error })))
					)
			)
		)
	);

	denyContent$ = createEffect(() =>
		this.actions$.pipe(
			ofType(contentActions.denyContent),
			switchMap((action) =>
				this._http
					.post(`${environment.api}/client_portal/content_approval/${action.uuid}/?action_type=content-approval`, {
						approved: false,
						reason: action.reason,
					})
					.pipe(
						tap(() => this.store.dispatch(contentActions.loadContentApproval({ uuid: action.uuid }))),
						map(() => contentActions.denyContentSuccess()),
						catchError((error) => of(contentActions.denyContentFail({ error })))
					)
			)
		)
	);

	loadContentApproval$ = createEffect(() =>
		this.actions$.pipe(
			ofType(contentActions.loadContentApproval),
			switchMap((action) =>
				this._http
					.get<{
						approvers: ContentApprover[];
					}>(`${environment.api}/client_portal/content_approval/${action.uuid}/?action_type=content-approver`)
					.pipe(
						map((data) => {
							const approvers = data.approvers.map((approver: ContentApprover) => approver);
							return contentActions.loadContentApprovalSuccess({ approvers });
						}),
						catchError((error) => of(contentActions.loadContentApprovalFail({ error })))
					)
			)
		)
	);
}
