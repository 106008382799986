import { Component, Input } from '@angular/core';
import * as XLSX from 'xlsx';

interface ExportData {
	[key: string]: any;
}

@Component({
	selector: 'app-data-export',
	templateUrl: './data-export.component.html',
	styleUrl: './data-export.component.scss'
})
export class DataExportComponent {
	@Input() label: string = 'Download';
	@Input() data: ExportData[] = [];

	formatDataForExport(data: ExportData[]): ExportData[] {
		const headers = Object.keys(data[0]);
		return data.map(item => {
			return headers.reduce((acc: ExportData, header: string) => {
				acc[header] = item[header] ?? '';
				return acc;
			}, {} as ExportData);
		});
	}

	createWorkbook(formattedData: ExportData[]): XLSX.WorkBook {
		const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(formattedData);
		const workbook: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
		return workbook;
	}

	downloadFile(blob: Blob, fileName: string): void {
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.href = url;
		anchor.download = fileName;
		anchor.click();
		window.URL.revokeObjectURL(url);
	}

	handleExport(): void {
		if (this.data.length === 0) return;

		const formattedData = this.formatDataForExport(this.data);
		const workbook: XLSX.WorkBook = this.createWorkbook(formattedData);

		const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
		const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

		const date = new Date().toLocaleDateString('en-GB').replace(/\//g, '-').slice(0, 8);
		this.downloadFile(blob, `${date}_Export.xlsx`);
	}
}
