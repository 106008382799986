import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InsightsState } from '../../state';
import { CampaignState } from './campaign.reducers';
import { TableHelpers } from 'src/app/helpers/table';

export const selectInsightState = createFeatureSelector<InsightsState>('insights');

export const selectCampaignState = createSelector(selectInsightState, (state: InsightsState) => state.campaign);

export const selectLoading = createSelector(selectCampaignState, (state) => state.loading);

export const selectError = createSelector(selectCampaignState, (state) => state.error);

export const selectFilters = createSelector(selectCampaignState, (state) => state.header.filters);

export const selectSelectedFilters = createSelector(selectCampaignState, (state) => state.filters);

export const selectPowerNumbers = createSelector(
	selectCampaignState,
	(state: CampaignState) => state.data.powerNumbers
);

export const selectCampaignOverview = createSelector(selectCampaignState, (state: CampaignState) => state.data.details);

export const selectUptime = createSelector(selectCampaignState, (state: CampaignState) => state.data.uptime);

export const selectAllStores = createSelector(selectCampaignState, (state: CampaignState) => state.data.stores);

export const selectStores = createSelector(selectCampaignState, (state) => {
	const stores = state.data.stores;
	return TableHelpers.paginate(stores, state.filters.stores.page, state.filters.stores.pageSize);
});

export const selectStoreCount = createSelector(selectCampaignState, (state) => state.data.stores.length);

export const selectCampaignMedia = createSelector(
	selectCampaignState,
	(state: CampaignState) => state.data.mediaGallery
);
