<div class="error">
	<div class="img">
		<img src="assets/no-results.png" />
	</div>

	<div class="text">{{ route.snapshot.data['title'] ?? title }}</div>
	<div class="subtext">
		<div>{{ route.snapshot.data['message'] ?? message }}</div>
	</div>

	<div class="button-container" *ngLet="route.snapshot.data['actions'] as actions">
		<button class="button margin-right" routerLink="/network/dashboard" *ngIf="actions.redirect ?? true">
			<div>Home</div>
		</button>

		<button class="button" (click)="mailTo()" *ngIf="actions.contact ?? true">
			<div>Contact Us</div>
		</button>
	</div>
</div>
