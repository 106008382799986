import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorData } from './models/ErrorData';

@Component({
	selector: 'app-error',
	templateUrl: './error.component.html',
	styleUrls: ['./error.component.scss'],
})
export class ErrorComponent {
	public title = 'Something went wrong…';
	public message = 'Please return to the home page or contact us for assistance.';

	constructor(public route: ActivatedRoute) {}

	mailTo() {
		const mailText = 'mailto:cshelp@barrowsglobal.com';
		window.location.href = mailText;
	}
}
