import { Input, Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FilterChange, FilterOption } from './models';
import { DateTime } from 'ts-luxon';

@Component({
	selector: 'app-insights-filters',
	templateUrl: './filters.component.html',
	styleUrls: ['./filters.component.scss'],
})
export class InsightsFiltersComponent {
	@Input()
	public filterOptions: FilterOption[] = [];

	@Output()
	public filterChange = new EventEmitter<FilterChange>();

	public filterForm: FormGroup;
	private startDate: Date;
	private endDate: Date;

	constructor(private fb: FormBuilder) {
		this.filterForm = this.fb.group({});

		const now = DateTime.now();
		const startOfQuarter = now.startOf('quarter');
		const endOfFirstMonthOfQuarter = startOfQuarter.plus({ months: 1 }).minus({ days: 1 });

		if (now <= endOfFirstMonthOfQuarter) {
			this.startDate = startOfQuarter.minus({ months: 3 }).startOf('month').toJSDate();
			this.endDate = now.toJSDate();
		} else {
			this.startDate = startOfQuarter.toJSDate();
			this.endDate = now.toJSDate();
		}
	}

	ngOnInit(): void {
		this.filterOptions.forEach((option) => {
			this.filterForm.addControl(option.name, this.fb.control(option.value));
		});

		for (const controlName of Object.keys(this.filterForm.controls)) {
			this.filterForm.get(controlName)?.valueChanges.subscribe((value) => {
				if (value !== undefined) {
					this.filterChange.emit({
						field: controlName,
						value: value,
					});
				}
			});
		}

		const periodFilter = this.filterOptions.find((option) => option.name === 'period');
		if (periodFilter) {
			this.filterForm.get('period')?.setValue({
				start: this.startDate,
				end: this.endDate,
			});
		}
	}
}
