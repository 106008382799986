import { Component } from '@angular/core';
import { TableColumn } from '../../components/apple/apple-table/models/column';
import { Observable, map } from 'rxjs';
import { TouchpointDrilldownRow } from './state/models/TouchpointDrilldownRow';
import * as fromRoot from '../../store';
import * as fromTouchpointDrilldown from './state';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ScreenshotComponent } from '../../components/apple/apple-table/components/screenshot/screenshot.component';
import { AppleHyperLinkComponent } from '../../components/apple/apple-table/components/apple-hyper-link/apple-hyper-link.component';
import { Location } from '@angular/common';
import { TouchpointDrilldownHeader } from './state/models/TouchpointDrilldownHeader';

@UntilDestroy()
@Component({
	selector: 'app-touchpoint-drilldown',
	templateUrl: './touchpoint-drilldown.html',
	styleUrls: ['./touchpoint-drilldown.scss'],
})
export class TouchpointDrilldownComponent {
	public loading$: Observable<boolean> = this._store
		.select(fromTouchpointDrilldown.selectLoading)
		.pipe(untilDestroyed(this));

	public initializing$: Observable<boolean> = this._store
		.select(fromTouchpointDrilldown.selectInitializing)
		.pipe(untilDestroyed(this));

	public header$: Observable<TouchpointDrilldownHeader> = this._store
		.select(fromTouchpointDrilldown.selectHeader)
		.pipe(untilDestroyed(this));

	public filters$: Observable<string[]> = this._store
		.select(fromTouchpointDrilldown.selectFilters)
		.pipe(map((items) => items.applied.flatMap((item) => item.values)))
		.pipe(untilDestroyed(this));

	public data$: Observable<TouchpointDrilldownRow[]> = this._store
		.select(fromTouchpointDrilldown.selectFilteredTouchpoints)
		.pipe(untilDestroyed(this));

	public total$: Observable<number> = this._store
		.select(fromTouchpointDrilldown.selectFilteredTotalCount)
		.pipe(untilDestroyed(this));

	public page$: Observable<number> = this._store
		.select(fromTouchpointDrilldown.selectFilters)
		.pipe(map((items) => items.page))
		.pipe(untilDestroyed(this));

	public columns: TableColumn[] = [
		{ header: 'Retailer', field: 'retailer', sortable: false },
		{ header: 'Region', field: 'region', sortable: false },
		{ header: 'Store Name', field: 'store_name', sortable: true },
		{ header: 'Store ID', field: 'stp', sortable: false },
		{ header: 'Asset ID', field: 'assnum', sortable: false, component: AppleHyperLinkComponent },
		{ header: 'Touchpoint Type', field: 'type_group', sortable: false },
		{ header: 'Category', field: 'category', sortable: false },
		{ header: 'Status', field: 'status', sortable: false },
		{ header: 'Screenshot', field: 'screenshot', sortable: false, component: ScreenshotComponent },
	];

	constructor(private _store: Store<fromRoot.State>, private location: Location) {
		this._store.dispatch(fromTouchpointDrilldown.initializeTouchpointDrilldownData());
	}

	search(state: string) {
		this._store.dispatch(fromTouchpointDrilldown.setSearchText({ searchText: state }));
	}

	filter(event: any) {
		if (event.filters.length) {
			this._store.dispatch(fromTouchpointDrilldown.setAppliedFilters(event));
		} else {
			this._store.dispatch(fromTouchpointDrilldown.resetAppliedFilters());
		}
	}

	sort(event: { column: string | null; direction: string | null }) {
		this._store.dispatch(fromTouchpointDrilldown.setSort({ column: event.column, direction: event.direction }));
	}

	page(event: { page: number; size: number }) {
		this._store.dispatch(fromTouchpointDrilldown.setPage({ page: event.page, size: event.size }));
	}

	back(): void {
		this.location.back();
	}
}
