import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';

import * as fromRoot from '../store';
import * as fromUser from '../store/user';
import { ToastService } from '../services/toast.service';
import { NeptuneError } from '../shared/models/api/NeptuneError';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor(
		private store: Store<fromRoot.State>,
		private router: Router,
		private toastService: ToastService
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				const neptuneError = error.error as NeptuneError;
				const errorTitle = neptuneError?.title || 'UnexpectedError';
				const errorMessage = neptuneError?.message || 'An unexpected error occurred';

				if (error.status === 401) {
					this.store.dispatch(fromUser.logout());
				} else if (error.status >= 400 && error.status < 600) {
					this.toastService.open('error', errorTitle, errorMessage);
				}

				throw error;
			})
		);
	}
}
