import {
	Directive,
	ElementRef,
	Input,
	OnDestroy,
	Renderer2,
	ComponentRef,
	ViewContainerRef,
	OnInit,
} from '@angular/core';
import { TooltipComponent } from './tooltip.component';

export type TooltipPosition = 'top' | 'bottom' | 'left' | 'right';

@Directive({
	selector: '[tooltip]',
})
export class TooltipDirective implements OnInit, OnDestroy {
	@Input('tooltip')
	public text = '';

	@Input()
	public position: TooltipPosition = 'top';

	@Input()
	public showOnClick = false;

	private tooltipComponent?: ComponentRef<TooltipComponent>;
	private scrollHandler?: () => void;

	constructor(
		private element: ElementRef,
		private renderer: Renderer2,
		private viewContainerRef: ViewContainerRef
	) {}

	ngOnInit(): void {
		this.initializeTooltipListeners();
	}

	ngOnDestroy(): void {
		if (this.scrollHandler) {
			window.removeEventListener('scroll', this.scrollHandler, true);
		}

		this.destroyTooltip();
	}

	private initializeTooltipListeners(): void {
		if (this.showOnClick) {
			this.renderer.listen(this.element.nativeElement, 'click', () => this.show());
		} else {
			this.renderer.listen(this.element.nativeElement, 'mouseenter', () => this.show());
			this.renderer.listen(this.element.nativeElement, 'mouseleave', () => this.hide());
		}

		this.scrollHandler = () => this.hide();
		window.addEventListener('scroll', this.scrollHandler, true);
	}

	private show(): void {
		this.createTooltip();
	}

	private hide(): void {
		this.destroyTooltip();
	}

	private createTooltip(): void {
		this.tooltipComponent = this.viewContainerRef.createComponent(TooltipComponent);
		const instance = this.tooltipComponent.instance;
		const hostEl = this.element.nativeElement;

		instance.text = this.text;
		instance.position = this.position;
		instance.hostElement = hostEl;

		instance.updatePosition();
	}

	private destroyTooltip(): void {
		if (this.tooltipComponent) {
			this.tooltipComponent.destroy();
			this.tooltipComponent = undefined;
		}
	}
}
