<div class="button-group">
	<button
		*ngFor="let button of buttons"
		[class.active]="isActive(button)"
		(click)="onButtonClick($event, button)"
		[title]="button.title">
		<ng-container *ngIf="button.svgPath; else text">
			<img
				[src]="isActive(button) ? button.svgPath.active : button.svgPath.inactive"
				alt="{{ button.title }}"
				class="icon" />
		</ng-container>
		<ng-template #text>
			{{ button.title }}
		</ng-template>
	</button>
</div>
