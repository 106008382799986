<div class="filters">
	<app-insights-filters [filterOptions]="filterOptions" (filterChange)="onFilterChange($event)"></app-insights-filters>
</div>
<div class="dashboard" *ngLet="!!(loading$ | async) as loading">
	<app-insights-campaign-overview
		class="campaign-overview"
		[loading]="loading"
		[data]="campaignOverview$ | async"></app-insights-campaign-overview>
	<ng-container *ngIf="!loading; else loadingPowerNumbers">
		<ng-container *ngIf="powerNumbers$ | async as powerNumbers">
			<div class="power-numbers" *ngIf="powerNumbers.length > 0">
				<app-insights-power-number
					class="power-number"
					*ngFor="let powerNumber of powerNumbers"
					[data]="powerNumber"></app-insights-power-number>
			</div>
		</ng-container>
	</ng-container>
	<ng-template #loadingPowerNumbers>
		<div class="power-numbers">
			<app-insights-power-number class="power-number" *ngFor="let i of [1, 2, 3, 4]"></app-insights-power-number>
		</div>
	</ng-template>
	<app-map
		class="store-map"
		[locations]="locationsArray"
		[draggable]="false"
		[zoomControl]="true"
		[defaultZoom]="zoom"
		[storeLocation]="locationDefault"></app-map>
	<app-insights-line-graph class="line-graph" [loading]="loading" [data]="uptime$ | async"></app-insights-line-graph>
	<app-insights-campaigns-store-list
		class="store-details"
		[loading]="loading"
		[data]="(stores$ | async) || []"></app-insights-campaigns-store-list>
	<app-insights-campaign-gallery
		class="campaign-gallery"
		[count]="1"
		[loading]="loading"
		[data]="campaignMedia$ | async"></app-insights-campaign-gallery>
</div>
