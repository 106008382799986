import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-insights-emissions-percentage-indicator',
	templateUrl: './percentage-indicator.component.html',
	styleUrls: ['./percentage-indicator.component.scss'],
})
export class EmissionsPercentageIndicatorComponent {
	@Input()
	public value: string | null = null;

	@Input()
	public image: string | null = null;

	constructor() {}
}
