<app-apple-card [shadow]="true" [style]="{ width: '75vw', 'max-width': '420px' }">
	<div class="content">
		<div class="header">
			<div class="title">Create a new password</div>
			<div class="subtext">
				<span class="text">Please enter a new password that has not been used before.</span>
			</div>
		</div>
		<form [formGroup]="form">
			<div class="reset-form">
				<div class="field">
					<app-apple-input label="Password" formControlName="password"></app-apple-input>
					<div class="validation">
						<div class="item">
							<span class="icon">
								<img
									class="cross"
									*ngIf="invalid(form, 'password', 'lengthCheck')"
									src="../../../../assets/cross-black.svg" />
								<img
									class="tick"
									*ngIf="!invalid(form, 'password', 'lengthCheck')"
									src="../../../../assets/tick-green.svg" />
							</span>
							<span class="message">Must contain at least 8 characters</span>
						</div>
						<div class="item">
							<span class="icon">
								<img
									class="cross"
									*ngIf="invalid(form, 'password', 'uppercase')"
									src="../../../../assets/cross-black.svg" />
								<img
									class="tick"
									*ngIf="!invalid(form, 'password', 'uppercase')"
									src="../../../../assets/tick-green.svg" />
							</span>
							<span class="message">Must contain at least 1 uppercase letter</span>
						</div>
						<div class="item">
							<span class="icon">
								<img
									class="cross"
									*ngIf="invalid(form, 'password', 'lowercase')"
									src="../../../../assets/cross-black.svg" />
								<img
									class="tick"
									*ngIf="!invalid(form, 'password', 'lowercase')"
									src="../../../../assets/tick-green.svg" />
							</span>
							<span class="message">Must contain at least 1 lowercase letter</span>
						</div>
						<div class="item">
							<span class="icon">
								<img
									class="cross"
									*ngIf="invalid(form, 'password', 'numeric')"
									src="../../../../assets/cross-black.svg" />
								<img
									class="tick"
									*ngIf="!invalid(form, 'password', 'numeric')"
									src="../../../../assets/tick-green.svg" />
							</span>
							<span class="message">Must contain at least 1 number</span>
						</div>
						<div class="item" *ngIf="invalid(form, 'password', 'required')">
							<span class="icon">
								<img class="cross" src="../../../../assets/cross-black.svg" />
							</span>
							<span class="invalid">This field is required.</span>
						</div>
					</div>
				</div>
				<div class="field">
					<app-apple-input label="Confirm Password" formControlName="confirmPassword"></app-apple-input>
					<div class="validation">
						<div class="item" *ngIf="form.get('confirmPassword')?.touched && form.hasError('passwordMismatch')">
							<span class="icon">
								<img class="cross" src="../../../../assets/cross-black.svg" />
							</span>
							<span class="message">Passwords do not match</span>
						</div>
						<div class="item" *ngIf="invalid(form, 'confirmPassword', 'required')">
							<span class="icon">
								<img class="cross" src="../../../../assets/cross-black.svg" />
							</span>
							<span class="invalid">This field is required.</span>
						</div>
					</div>
				</div>
			</div>
			<div class="reset-button">
				<app-apple-button
					color="var(--white)"
					background="var(--black)"
					[style]="{ 'font-weight': 'bold', 'font-size': '12px' }"
					[disabled]="form.invalid || loading"
					(click)="passwordReset()">
					<div class="button-content">
						<div *ngIf="!loading">RESET PASSWORD</div>
						<div *ngIf="loading">
							<i class="fa fa-solid fa-spinner fa-spin"></i>
						</div>
					</div>
				</app-apple-button>
			</div>
		</form>
		<div class="logo">
			<img class="image" src="../../../../assets/barrows.png" />
		</div>
	</div>
</app-apple-card>
