import { Component, Input, OnInit } from '@angular/core';
import { Location } from '../../../../models/Location';

@Component({
	selector: 'app-map',
	templateUrl: './map.component.html',
	styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
	private _locations: { latitude: number; longitude: number; id: number }[] = [];
	private _selected: number[] = [];

	@Input()
	public set locations(value: { latitude: number; longitude: number; id: number }[]) {
		this._locations = value;
		this.createLocationPins();
	}

	@Input()
	public set selected(value: Array<{ id: number }>) {
		this._selected = value.map((item) => item.id);
		this.updateLocationPins();
	}

	@Input()
	public storeLocation: Location | null = null;

	@Input()
	public draggable: boolean = true;

	@Input()
	public zoomControl: boolean = false;

	@Input()
	public defaultZoom: number = 5;

	public map!: google.maps.Map;
	public markers: { id: number; marker: google.maps.Marker }[] = [];

	ngOnInit(): void {
		const mapOptions: google.maps.MapOptions = {
			center: {
				lat: this.storeLocation?.latitude ?? 39.1019099,
				lng: this.storeLocation?.longitude ?? -84.5178565,
			},
			zoom: this.defaultZoom,
			fullscreenControl: false,
			zoomControl: this.zoomControl,
			streetViewControl: false,
			mapTypeControl: false,
			gestureHandling: 'greedy',
			draggable: this.draggable,
			scrollwheel: false,
		};

		this.map = new google.maps.Map(document.getElementById('map') as HTMLElement, mapOptions);
	}

	createLocationPins(): void {
		this.markers.map((marker) => marker.marker.setMap(null));
		for (let i = 0; i < this._locations.length; i++) {
			this.markers.push({
				id: this._locations[i].id,
				marker: new google.maps.Marker({
					position: { lat: this._locations[i].latitude, lng: this._locations[i].longitude },
					map: this.map,
					icon: this.getMarkerIcon(this._locations[i].id),
				}),
			});
		}
	}

	updateLocationPins(): void {
		for (let i = 0; i < this.markers.length; i++) {
			const current = this.markers[i];
			current.marker.setIcon(this.getMarkerIcon(current.id));
		}
	}

	getMarkerIcon(id: number): google.maps.Icon {
		const iconUrl = this._selected.includes(id) ? '../../../assets/blue-marker.png' : '../../../assets/grey-marker.png';
		return {
			url: iconUrl,
			scaledSize: new google.maps.Size(20, 25),
		};
	}
}
