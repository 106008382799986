import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-booking-sov',
	templateUrl: './booking-sov.component.html',
	styleUrls: ['./booking-sov.component.scss'],
})
export class BookingSovComponent {
	@Input() type!: string;

	constructor() {}
}
