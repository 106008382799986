import { createSelector } from '@ngrx/store';
import { State } from '../../../store';
import { ContentState } from './content.reducers';

export const selectContentState = (state: State) => state.content;

export const selectComments = createSelector(selectContentState, (state: ContentState) => state.comments);

export const selectApprovals = createSelector(selectContentState, (state: ContentState) => state.approvals);

export const selectDetails = createSelector(selectContentState, (state: ContentState) => state.details);

export const selectCommentsLoading = createSelector(selectContentState, (state: ContentState) => state.commentsLoading);

export const selectDetailsLoading = createSelector(selectContentState, (state: ContentState) => state.detailsLoading);

export const selectApprovalLoading = createSelector(selectContentState, (state: ContentState) => state.approvalLoading);

export const selectError = createSelector(selectContentState, (state: ContentState) => state.error);

export const selectSuccess = createSelector(selectContentState, (state: ContentState) => state.success);

export const selectApprovalStatus = createSelector(selectContentState, (state: ContentState) => state.approvalStatus);

export const selectApprovalError = createSelector(selectContentState, (state: ContentState) => state.approvalError);
