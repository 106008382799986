import { Component, Input, forwardRef } from '@angular/core';
import {
	ControlValueAccessor,
	NG_VALUE_ACCESSOR,
	FormControl,
	FormGroup,
	ValidatorFn,
	Validators,
} from '@angular/forms';
import { Prefix } from 'src/app/components/apple/apple-input/models/prefix';

@Component({
	selector: 'app-dynamic-form-control',
	templateUrl: './dynamic-form-control.component.html',
	styleUrl: './dynamic-form-control.component.scss',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DynamicFormControlComponent),
			multi: true,
		},
	],
})
export class DynamicFormControlComponent implements ControlValueAccessor {
	@Input()
	public type: string = '';

	@Input()
	public label: string = '';

	@Input()
	public formControlName: string = '';

	@Input()
	public formGroup!: FormGroup;

	@Input()
	public regex: string = '';

	@Input()
	public validationWarningText: string = '';

	@Input()
	public required: boolean = false;

	@Input()
	public placeholder: string = '';

	@Input()
	public prefix: Prefix | null = null;

	@Input()
	public options: { id: number; name: string }[] = [];

	@Input()
	public disabled: boolean = false;

	public control!: FormControl;
	public customValidators: { [key: string]: string } = {};

	private onChange: (value: any) => void = () => {};
	private onTouched: () => void = () => {};

	ngOnInit(): void {
		this.control = this.formGroup.get(this.formControlName) as FormControl;

		const validators: ValidatorFn[] = [];
		if (this.required) {
			validators.push(Validators.required);
		}
		if (this.regex) {
			validators.push(Validators.pattern(new RegExp(this.regex)));
			this.customValidators['pattern'] = this.validationWarningText;
		}

		this.control.setValidators(validators);
		this.control.updateValueAndValidity();
	}

	writeValue(value: any): void {
		if (this.control) {
			this.control.setValue(value);
		}
	}

	registerOnChange(fn: (value: any) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		if (this.control) {
			isDisabled ? this.control.disable() : this.control.enable();
		}
	}

	private valueChanged(value: any): void {
		this.onChange(value);
	}

	private touched(): void {
		this.onTouched();
	}
}
