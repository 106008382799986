import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-metric',
	templateUrl: './metric.component.html',
	styleUrls: ['./metric.component.scss'],
})
export class InsightsMetricComponent {
	@Input() public title: string | null | undefined;
	@Input() public value: string | null | undefined;

	isValueLoading(): boolean {
		return this.value === null || this.value === undefined;
	}

	isTitleLoading(): boolean {
		return this.title === null || this.title === undefined;
	}
}
