import { createSelector, createFeatureSelector } from '@ngrx/store';
import { SessionState } from './session.reducers';
import { ReportRetoolComponent } from 'src/app/pages/report/retool/retool.component';
import { ReportTableauComponent } from '../../pages/report/tableau/tableau.component';

export const getSessionState = createFeatureSelector<SessionState>('session');
export const getLoading = createSelector(getSessionState, (state) => state.loading);
export const getReportItems = createSelector(getSessionState, (state) => state.reports);
export const getHoldingCompanies = createSelector(getSessionState, (state) => state.companies);
export const getFilters = createSelector(getSessionState, (state) => state.filters);
export const getRoles = createSelector(getSessionState, (state) => state.filters.company.roles);
export const getOutstandingApprovalCount = createSelector(getSessionState, (state) => {
	return {
		approvalCount: state.notifications.approvalCount,
		latestApprovalUrl: state.notifications.latestApprovalUrl,
	};
});
export const selectSelectedItemPath = createSelector(getSessionState, (state) => state.selectedItemPath);

export const selectFilteredReports = createSelector(
	getReportItems,
	selectSelectedItemPath,
	(reports, selectedItemPath) => {
		if (!selectedItemPath) return [];

		const matchedReport = reports.find((report) => report.items?.some((item) => selectedItemPath.includes(item.path)));
		if (!matchedReport) return [];

		const mappedItems = matchedReport.items.map((item) => {
			let component = null;
			let data = null;

			if (item.type === 'tableau') {
				component = ReportTableauComponent;
				data = {
					data: item.data?.url || '',
				};
			} else if (item.type === 'retool') {
				component = ReportRetoolComponent;
				data = {
					data: item.data?.id || '',
				};
			}

			return {
				name: item.name,
				url: item.path,
				component: component,
				beta: item.beta,
				data: data,
			};
		});

		return [
			{
				heading: matchedReport.friendly_name,
				reports: mappedItems,
			},
		];
	}
);
