<ng-container *ngIf="data; else loadingTemplate">
	<div class="insights-trend-indicator-badge" [ngClass]="type || 'white'">
		<img *ngIf="data?.image" [src]="data.image" />
		<div class="value-container">
			<div>{{ data.value }}</div>
			<ng-container *ngIf="data.customUnit" [ngComponentOutlet]="data.customUnit"></ng-container>
			<div *ngIf="data.period">{{ data.period }}</div>
		</div>
	</div>
</ng-container>

<ng-template #loadingTemplate>
	<ngx-skeleton-loader [theme]="{ width: '130px', height: '23px', 'border-radius': '9px' }"></ngx-skeleton-loader>
</ng-template>
