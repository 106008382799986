import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import * as XLSX from 'xlsx';

@Component({
	selector: 'app-data-import',
	templateUrl: './data-import.component.html',
	styleUrl: './data-import.component.scss',
})
export class DataImportComponent {
	@Input() label: string = 'Upload';
	@Output() dataImported = new EventEmitter<{ [key: string]: any }[]>();

	@Input()
	public disabled: boolean = false;

	@ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

	onFileSelected(event: Event): void {
		const target = event.target as HTMLInputElement;
		if (target.files && target.files.length > 0) {
			const file = target.files[0];
			const fileExtension = file.name.split('.').pop()?.toLowerCase();
			this.readFile(file, fileExtension);
		}
	}

	private readFile(file: File, fileExtension: string | undefined): void {
		const reader = new FileReader();

		if (fileExtension === 'csv') {
			reader.onload = (e) => this.processFileContent(e.target?.result as string, 'string');
			reader.readAsText(file);
		} else {
			reader.onload = (e) => this.processFileContent(new Uint8Array(e.target!.result as ArrayBuffer), 'array');
			reader.readAsArrayBuffer(file);
		}
	}

	private processFileContent(content: string | Uint8Array, type: 'string' | 'array'): void {
		try {
			const workbook = XLSX.read(content, { type });
			const worksheet = workbook.Sheets[workbook.SheetNames[0]];
			const jsonData = XLSX.utils.sheet_to_json(worksheet);

			this.dataImported.emit(jsonData as { [key: string]: any }[]);
		} catch (error) {
			console.error('Error processing file:', error);
		}
	}

	handleImport(): void {
		this.fileInput.nativeElement.click();
	}
}
