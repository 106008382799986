<app-apple-card>
	<div class="root-container">
		<app-apple-table
			[columns]="columns"
			[loading]="!!(loading$ | async) || !!(initializing$ | async)"
			[data]="(data$ | async) ?? []"
			(sort)="sort($event)">
			<app-apple-dual-slot-header header>
				<div class="header" header-left>
					<div class="back" (click)="back()">
						<img class="img" src="../../../../assets/arrow-back.svg" />
					</div>
					<div class="label">Content View</div>
				</div>
				<div class="header" header-right>
					<app-apple-applied-filters [filters]="(filters$ | async) ?? []" direction="rtl"></app-apple-applied-filters>
					<app-apple-input
						[prefix]="{ value: '../../../assets/search.svg', type: 'image' }"
						placeholder="Search..."
						[style]="{ width: '300px' }"
						(stateChange)="search($event)"></app-apple-input>
					<app-apple-filter
						[loading]="!!(initializing$ | async)"
						[icon]="'../../../assets/filter-blue.svg'"
						[label]="'Filter'"
						[tableFilters]="(header$ | async)?.filters ?? []"
						(stateChange)="filter($event)"></app-apple-filter>
				</div>
			</app-apple-dual-slot-header>
			<app-no-data no-data></app-no-data>
			<div class="footer" footer>
				<app-apple-pagination
					(pageChange)="page($event)"
					[totalItems]="(total$ | async) ?? 0"
					[currentPage]="(page$ | async) ?? 1"
					position="left"
					[enablePageSizePicker]="true"></app-apple-pagination>
			</div>
		</app-apple-table>
	</div>
</app-apple-card>
