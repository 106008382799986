import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { createEffect } from '@ngrx/effects';
import { Actions, ofType } from '@ngrx/effects';
import { switchMap, map, tap, catchError } from 'rxjs/operators';
import { PlausibleService } from '@notiz/ngx-plausible';
import { FeatureFlagService } from 'src/app/services/unleash.service';
import { environment } from 'src/environments/environment';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { User } from './models/user';
import { AccountRequestTokenValidationResponse } from './models/request';

import * as fromUser from './user.actions';
import { NeptuneError } from 'src/app/shared/models/api/NeptuneError';

@Injectable()
export class UserEffects {
	private plausible: PlausibleService = inject(PlausibleService);
	private unleash: FeatureFlagService = inject(FeatureFlagService);
	private router: Router = inject(Router);
	private actions: Actions = inject(Actions);
	private handler: HttpBackend = inject(HttpBackend);
	private http: HttpClient = new HttpClient(this.handler);
	private oauth: OAuthService = inject(OAuthService);

	loginWithCredentials$ = createEffect(() => {
		return this.actions.pipe(
			ofType(fromUser.loginWithCredentials),
			switchMap(({ payload, redirect }) => {
				return this.http
					.post<User>(`${environment.api}/client_portal/login/`, payload)
					.pipe(map((user) => fromUser.loginSuccess({ user, redirect })));
			}),
			catchError((exception: any) => this.handleError(exception))
		);
	});

	loginWithOAuth$ = createEffect(
		() => {
			return this.actions.pipe(
				ofType(fromUser.loginWithOAuth),
				map(async ({ provider, redirect }) => {
					this.oauth.configure(environment.auth[provider]);
					this.oauth.loadDiscoveryDocument();
					this.oauth.initLoginFlow(`provider=${provider}&redirect_uri=${redirect}`);
				})
			);
		},
		{ dispatch: false }
	);

	loginSuccess$ = createEffect(
		() => {
			return this.actions.pipe(
				ofType(fromUser.loginSuccess),
				tap(({ user, redirect }) => {
					this.plausible.event('login', { props: { email: user.email } });
					this.router.navigateByUrl(redirect || '/network/dashboard?page=1');
				})
			);
		},
		{ dispatch: false }
	);

	requestAccount$ = createEffect(
		() => {
			return this.actions.pipe(
				ofType(fromUser.requestAccount),
				switchMap((action) =>
					this.http
						.post(environment.api + '/client_portal/account_request/', action.user)
						.pipe(tap(() => this.router.navigateByUrl('/auth/account-request/confirmation')))
				),
				catchError((exception: any) => this.handleError(exception))
			);
		},
		{ dispatch: false }
	);

	requestAccountTokenValidation$ = createEffect(() =>
		this.actions.pipe(
			ofType(fromUser.requestAccountTokenValidation),
			switchMap(({ request }) => {
				const url = `${environment.api}/client_portal/registration/?uuid_token=${request.uuid_token}&login_method=${request.token_type}`;
				return this.http
					.get<AccountRequestTokenValidationResponse>(url)
					.pipe(map((response) => fromUser.requestAccountTokenValidationSuccess({ ...response })));
			}),
			catchError((exception: any) => this.handleError(exception))
		)
	);

	registerWithCredentials = createEffect(() => {
		return this.actions.pipe(
			ofType(fromUser.registerWithCredentials),
			switchMap(({ payload }) =>
				this.http
					.post(`${environment.api}/client_portal/registration/`, payload)
					.pipe(map(() => fromUser.loginWithCredentials({ payload, redirect: '/network/dashboard?page=1' })))
			),
			catchError((exception: any) => this.handleError(exception))
		);
	});

	registerWithToken$ = createEffect(() => {
		return this.actions.pipe(
			ofType(fromUser.registerWithToken),
			switchMap(({ payload, provider }) =>
				this.http.post(`${environment.api}/client_portal/registration/`, payload).pipe(
					map(() => {
						return fromUser.loginWithCredentials({
							payload: { token: payload.sso_token, token_type: provider },
							redirect: '/network/dashboard?page=1',
						});
					})
				)
			),
			catchError((exception: any) => this.handleError(exception))
		);
	});

	registerWithOAuth$ = createEffect(
		() => {
			return this.actions.pipe(
				ofType(fromUser.registerWithOAuth),
				map(async ({ provider, uuid_token }) => {
					this.oauth.configure(environment.auth[provider]);
					this.oauth.loadDiscoveryDocument();
					this.oauth.initLoginFlow(`provider=${provider}&registration=true&uuid_token=${uuid_token}`);
				})
			);
		},
		{ dispatch: false }
	);

	requestPasswordReset$ = createEffect(() => {
		return this.actions.pipe(
			ofType(fromUser.requestPasswordReset),
			switchMap((user) => {
				return this.http.post(`${environment.api}/client_portal/reset_request/`, { ...user }).pipe(
					map(() => fromUser.requestPasswordResetSuccess({ ...user })),
					tap(() => this.router.navigateByUrl('auth/forgot-password/confirmation'))
				);
			}),
			catchError((exception: any) => this.handleError(exception))
		);
	});

	passwordReset$ = createEffect(() => {
		return this.actions.pipe(
			ofType(fromUser.resetPassword),
			switchMap((user) => {
				return this.http.post(`${environment.api}/client_portal/reset_password/`, { ...user }).pipe(
					map(() => fromUser.resetPasswordSuccess()),
					tap(() => this.router.navigateByUrl('/auth/login'))
				);
			}),
			catchError((exception: any) => this.handleError(exception))
		);
	});

	logout$ = createEffect(
		() => {
			return this.actions.pipe(
				ofType(fromUser.logout),
				tap(() => localStorage.clear()),
				tap(() => this.unleash.updateContext('userId', null)),
				tap(() => this.router.navigateByUrl('/'))
			);
		},
		{ dispatch: false }
	);

	private handleError(error: any) {
		console.log(error);
		const neptuneError: NeptuneError = error.error as NeptuneError;
		return of(fromUser.error({ error: neptuneError }));
	}
}
