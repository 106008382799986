<div class="card-content">
	<div class="status-container pending-container">
		<div class="status-header">
			<div class="title">Pending Requests</div>
		</div>
		<div class="approved-users">
			<ng-container *ngFor="let approver of approvers$ | async">
				<ng-container *ngIf="approver.approved === null">
					<div class="avatar-container">
						<img [src]="approver.avatar" alt="User Avatar" class="user-avatar" (error)="setDefaultPicture($event)" />
						<i class="fas fa-clock approval-icon-clock"></i>
						<div class="username-tooltip">{{ approver.first_name }} {{ approver.last_name }}</div>
					</div>
				</ng-container>
			</ng-container>
		</div>
	</div>
	<div class="vertical-divider"></div>
	<div class="status-container approved-container">
		<div class="status-header">
			<div class="title">Completed Approvals</div>
		</div>
		<div class="approved-users">
			<ng-container *ngFor="let approver of approvers$ | async">
				<ng-container *ngIf="approver.approved !== null">
					<div class="avatar-container">
						<img [src]="approver.avatar" alt="User Avatar" class="user-avatar" (error)="setDefaultPicture($event)" />
						<ng-container *ngIf="approver.approved; else deniedIcon">
							<i class="fas fa-check-circle approval-icon-check"></i>
						</ng-container>
						<ng-template #deniedIcon>
							<i class="fas fa-times-circle approval-icon-times"></i>
						</ng-template>
						<div class="username-tooltip">{{ approver.first_name }} {{ approver.last_name }}</div>
					</div>
				</ng-container>
			</ng-container>
		</div>
	</div>
</div>
