import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { Page } from './models/page';

@Component({
	selector: 'app-apple-pagination',
	templateUrl: './apple-pagination.component.html',
	styleUrls: ['./apple-pagination.component.scss'],
})
export class ApplePaginationComponent implements OnInit {
	public _currentPageSize!: number;

	@Input()
	public currentPage: number = 1;

	@Input()
	public totalItems!: number;

	@Input()
	public position: 'left' | 'center' | 'right' = 'center';

	@Input()
	public pageSizes: { id: number; name: string; default?: boolean }[] = [
		{ id: 1, name: '50' },
		{ id: 2, name: '100' },
		{ id: 3, name: '150' },
		{ id: 3, name: '200' },
	];

	@Input()
	public enablePageSizePicker: boolean = false;

	@Output()
	public pageChange: EventEmitter<Page> = new EventEmitter<Page>();

	constructor(private _cdr: ChangeDetectorRef) {}

	ngOnInit(): void {}

	get totalPages(): number {
		return Math.ceil(this.totalItems / this._currentPageSize);
	}

	get startIndex(): number {
		return (this.currentPage - 1) * this._currentPageSize;
	}

	get endIndex(): number {
		return Math.min(this.startIndex + this._currentPageSize - 1, this.totalItems - 1);
	}

	prevPage(): void {
		this.onPageChange(this.currentPage - 1);
	}

	nextPage(): void {
		this.onPageChange(this.currentPage + 1);
	}

	onPageChange(page: number): void {
		if (page >= 1 && page <= this.totalPages) {
			this.currentPage = page;
			this.pageChange.emit({
				page: this.currentPage,
				size: this._currentPageSize,
			});
		}
	}

	onPageSizeChanged(event: any) {
		this._currentPageSize = parseInt(event.value.name);

		const newTotalPages = Math.ceil(this.totalItems / this._currentPageSize);

		if (this.currentPage > newTotalPages && newTotalPages > 0) {
			this.currentPage = newTotalPages;
		}

		this._cdr.detectChanges();

		this.pageChange.emit({
			page: this.currentPage,
			size: this._currentPageSize,
		});
	}

	buildPages() {
		const pages: number[] = [];
		for (let i = 1; i <= this.totalPages; i++) {
			pages.push(i);
		}

		const result: number[] = [];
		const lastPage = pages[pages.length - 1];

		if (pages.length <= 5) {
			return pages;
		} else {
			if ([1, 2].includes(this.currentPage)) {
				result.push(1, 2, 3, -1, lastPage);
			} else if (this.currentPage == 3) {
				result.push(1, 2, 3, 4, -1, lastPage);
			} else if (this.currentPage > lastPage - 2) {
				result.push(1, -1, lastPage - 2, lastPage - 1, lastPage);
			} else if (this.currentPage == lastPage - 2) {
				result.push(1, -1, lastPage - 3, lastPage - 2, lastPage - 1, lastPage);
			} else {
				result.push(1, -1, this.currentPage - 1, this.currentPage, this.currentPage + 1, -1, lastPage);
			}
		}

		return result;
	}
}
