import { Component, Input, OnInit } from '@angular/core';
import { TouchpointSummaryItem } from 'src/app/features/dashboard/state';

@Component({
	selector: 'app-universe-summary',
	templateUrl: './universe-summary.component.html',
	styleUrls: ['./universe-summary.component.scss'],
})
export class UniverseSummaryComponent implements OnInit {
	@Input() summary!: TouchpointSummaryItem[];

	constructor() {}

	ngOnInit(): void {}
}
