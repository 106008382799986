<div class="container">
	<div
		class="image-container overlay"
		(mouseenter)="showFullSizePreview = true"
		(mouseleave)="showFullSizePreview = false">
		<img [src]="url" class="thumbnail-image" [ngClass]="orientation" />
		<div class="icon-timestamp-container">
			<span class="icon">
				<img class="cross" src="../../../../assets/clock.svg" />
			</span>
			<span class="timestamp">{{ latest_screenshot_date | relativeTime | async }}</span>
		</div>
		<div
			class="refresh-container"
			(click)="requestScreenshot()"
			(mouseenter)="actionHover = true"
			(mouseleave)="actionHover = false">
			<span class="icon">
				<ng-container *ngIf="!loading">
					<img class="refresh-icon" *ngIf="!actionHover" src="../../../assets/screenshot-blue.svg" />
					<img class="refresh-icon" *ngIf="actionHover" src="../../../assets/screenshot-blue-fill.svg" />
				</ng-container>
				<ng-container *ngIf="loading">
					<img class="refresh-icon" *ngIf="!actionHover" src="../../../assets/pending-blue.svg" />
					<img class="refresh-icon" *ngIf="actionHover" src="../../../assets/pending-blue-fill.svg" />
				</ng-container>
			</span>
		</div>
		<div *ngIf="showFullSizePreview" class="full-size-image-container" [ngStyle]="getContainerStyles(orientation)">
			<img [src]="url" [ngClass]="orientation" />
		</div>
	</div>
</div>
