import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-action-link',
	templateUrl: './action-link.component.html',
	styleUrls: ['./action-link.component.scss'],
})
export class ActionLinkComponent {
	@Input() public text = 'Back';
}
