import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
	providedIn: 'root',
})
export class ToastService {
	constructor(private toastr: ToastrService) {}

	open(
		type: 'success' | 'error' | 'info',
		title: string,
		message: string,
		duration: number = 3000,
		position?: 'left' | 'center' | 'right'
	) {
		let toastrOptions: any = {
			timeOut: duration,
			positionClass: position ? `toast-bottom-${position}` : 'toast-bottom-center',
		};

		switch (type) {
			case 'success':
				toastrOptions.toastClass = 'success';
				this.toastr.success(message, title, toastrOptions);
				break;
			case 'error':
				toastrOptions.toastClass = 'error';
				this.toastr.error(message, title, toastrOptions);
				break;
			case 'info':
				toastrOptions.toastClass = 'info';
				this.toastr.info(message, title, toastrOptions);
				break;
			default:
				console.warn('Invalid toast type');
		}
	}
}
