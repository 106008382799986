import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-stacked-column-row',
	templateUrl: './stacked-column-row.component.html',
	styleUrl: './stacked-column-row.component.scss',
})
export class StackedColumnRowComponent {
	@Input()
	public firstValue!: string;

	@Input()
	public secondValue!: string;
}
