import { createAction, props } from '@ngrx/store';
import { Option, DateRange } from '../../components/filters/models';
import { PowerNumber, DonutGraph, LineGraph } from '../../models';
import { EmissionsTable } from '../models';

// Sustainability Filters
export const loadFilterOptions = createAction('[Insights] Load Insights Sustainability Filter Options');

export const loadFilterOptionsSuccess = createAction(
	'[Insights] Load Insights Sustainability Filter Options Success',
	props<{ touchpoints: Option[] }>()
);

export const loadFilterOptionsFailure = createAction(
	'[Insights] Load Insights Sustainability Filter Options Failure',
	props<{ error: string }>()
);

export const getFilters = createAction('[Insights] Get Insights Sustainability Filters');

export const setFilter = createAction(
	'[Insights] Set Insights Sustainability Filter',
	props<{ filterName: string; filterValue: any }>()
);

export const setFilters = createAction(
	'[Insights] Set Insights Sustainability Filters',
	props<{ touchpoints: Option[] }>()
);

// Load Sustainability Insights
export const loadSustainability = createAction(
	'[Insights] Load Insights Sustainability',
	props<{
		holdingCompanyId: number;
	}>()
);

export const loadSustainabilitySuccess = createAction(
	'[Insights] Load Insights Sustainability Success',
	props<{
		emissionTrend: LineGraph;
		emissionsTable: {
			touchpoint: {
				name: string;
				category: {
					id: number;
					name: string;
				};
				image: string;
			};
			totalEmissions: string;
			screenEnergy: string;
			otherEmissions: string;
			emissionsTrend: {
				value: string;
				percentage: string;
				image: string;
			};
		}[];
		powerNumbers: PowerNumber[];
		totalEmissions: DonutGraph;
	}>()
);

export const loadSustainabilityFailure = createAction(
	'[Insights] Load Insights Sustainability Failure',
	props<{ error: string }>()
);

// Pagination
export const setPage = createAction(
	'[Insights] Set Insights Sustainability Page',
	props<{ page: number; size: number }>()
);
