import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-apple-applied-filters',
	templateUrl: './apple-applied-filters.component.html',
	styleUrls: ['./apple-applied-filters.component.scss'],
})
export class AppleAppliedFiltersComponent {
	private _filters: string[] = [];

	@Input()
	public direction: 'rtl' | 'ltr' = 'ltr';

	@Input()
	set filters(value: string[]) {
		if (value.length > 2) {
			const truncated = value.slice(0, 1);
			truncated.push(`${value.length - 1} more...`);
			this._filters = truncated;
		} else {
			this._filters = value;
		}

		if (this.direction === 'rtl') {
			this._filters.reverse();
		}
	}

	get filters(): string[] {
		return this._filters;
	}
}
