import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-no-data',
	templateUrl: './no-data.component.html',
	styleUrls: ['./no-data.component.scss'],
})
export class NoDataComponent {
	@Input()
	public image: string = '../../../../assets/no-data-default.svg';

	@Input()
	public title: string = 'No Data Available';

	@Input()
	public message: string = '';
}
