import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-video-column',
	templateUrl: './video-column.component.html',
	styleUrls: ['./video-column.component.scss'],
})
export class VideoColumnComponent {
	@Input()
	public url!: string;

	@Input()
	public orientation!: string;
}
