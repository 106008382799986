import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router,
	Route,
	UrlSegment,
	CanActivateFn,
	CanActivateChildFn,
	CanMatchFn,
} from '@angular/router';
import { Observable } from 'rxjs';
import { take, map, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import * as fromRoot from '../store';
import * as fromUser from '../store/user';

@Injectable({
	providedIn: 'root',
})
export class AnonymousGuard {
	constructor(
		private store: Store<fromRoot.State>,
		private router: Router
	) {}

	canActivate: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
		return this.check();
	};

	canActivateChild: CanActivateChildFn = (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
		return this.check();
	};
	canMatch: CanMatchFn = (route: Route, segments: UrlSegment[]) => {
		return this.check();
	};

	private check(): Observable<boolean> {
		return this.store.pipe(select(fromUser.getUserState)).pipe(
			take(1),
			tap((state) => {
				if (!!state.token) {
					this.router.navigate(['/network/dashboard']);
				}
			}),
			map((state) => !!state.token == false)
		);
	}
}
