<app-card>
	<!-- <img
		class="tooltip-icon"
		src="/assets/information-tooltip-grey.svg"
		tooltip="This is an example of a tooltip explanation" /> -->
	<div class="metric">
		<app-metric [title]="data?.header" [value]="data?.value"></app-metric>
	</div>
	<div class="trend-indicator" *ngIf="data?.change">
		<app-trend-indicator [data]="data?.change"></app-trend-indicator>
	</div>
	<div class="donut-indicator" *ngIf="data?.percentage">
		<app-mini-donut-graph [percentage]="data?.percentage"></app-mini-donut-graph>
	</div>
</app-card>
