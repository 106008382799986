import { createReducer, on } from '@ngrx/store';
import * as SustainabilityActions from './sustainability.actions';
import { Option } from '../../components/filters/models';
import { PowerNumber, LineGraph, DonutGraph } from '../../models';
import { EmissionsTable } from '../models';
import { EmissionsPerMinuteComponent } from '../components/emissions-per-minute/emissions-per-minute.component';

export interface SustainabilityState {
	initializing: boolean;
	loading: boolean;
	header: {
		filters: {
			touchpoints: Option[];
			period: {
				start: Date | null;
				end: Date | null;
			};
		};
	};
	filters: {
		touchpoints: number[];
		period: {
			start: Date | null;
			end: Date | null;
		};
		table: {
			page: number;
			pageSize: number;
		};
	};
	data: {
		powerNumbers: PowerNumber[];
		emissionTrend: LineGraph | null;
		emissionsTable: EmissionsTable[] | null;
		totalEmissions: DonutGraph | null;
	};
	error: any;
}

export const initialState: SustainabilityState = {
	initializing: true,
	loading: true,
	header: {
		filters: {
			touchpoints: [],
			period: {
				start: null,
				end: null,
			},
		},
	},
	filters: {
		touchpoints: [],
		period: {
			start: null,
			end: null,
		},
		table: {
			page: 1,
			pageSize: 5,
		},
	},
	data: {
		powerNumbers: [],
		emissionTrend: null,
		emissionsTable: null,
		totalEmissions: null,
	},
	error: null,
};

export const sustainabilityReducer = createReducer(
	initialState,
	on(SustainabilityActions.loadSustainability, (state) => ({
		...state,
		loading: true,
		error: null,
	})),
	on(
		SustainabilityActions.loadSustainabilitySuccess,
		(state, { powerNumbers, emissionTrend, emissionsTable, totalEmissions }) => {
			const updatedTotalEmissions = totalEmissions
				? {
						...totalEmissions,
						series: totalEmissions.series?.map((series) => ({
							...series,
							items: series.items?.map((item) => ({
								...item,
								customUnit: EmissionsPerMinuteComponent,
							})),
						})),
						change: totalEmissions.change
							? {
									...totalEmissions.change,
									customUnit: EmissionsPerMinuteComponent,
								}
							: {
									value: '0',
									image: '/assets/trend-neutral.svg',
									customUnit: EmissionsPerMinuteComponent,
								},
					}
				: null;

			return {
				...state,
				initializing: false,
				loading: false,
				header: {
					...state.header,
				},
				data: {
					powerNumbers,
					emissionTrend,
					emissionsTable: emissionsTable.map((item) => ({
						touchpoint: {
							name: item.touchpoint.name,
							category: item.touchpoint.category.name,
							image: item.touchpoint.image,
						},
						totalEmissions: {
							value: item.totalEmissions,
						},
						screenEnergy: {
							value: item.screenEnergy,
						},
						otherEmissions: {
							value: item.otherEmissions,
						},
						emissionsTrend: {
							value: item.emissionsTrend.value,
							image: item.emissionsTrend.image,
						},
					})),
					totalEmissions: updatedTotalEmissions,
				},
				error: null,
			};
		}
	),
	on(SustainabilityActions.loadSustainabilityFailure, (state, { error }) => ({
		...state,
		initializing: false,
		loading: false,
		error,
	})),
	on(SustainabilityActions.loadFilterOptionsSuccess, (state, { touchpoints }) => ({
		...state,
		header: {
			...state.header,
			filters: {
				...state.header.filters,
				touchpoints,
			},
		},
	})),
	on(SustainabilityActions.getFilters, (state) => ({
		...state,
		filters: {
			...state.filters,
		},
	})),
	on(SustainabilityActions.setFilter, (state, { filterName, filterValue }) => ({
		...state,
		loading: true,
		filters: {
			...state.filters,
			[filterName]: filterValue,
		},
	})),
	on(SustainabilityActions.setPage, (state, { page, size }) => ({
		...state,
		filters: {
			...state.filters,
			table: {
				...state.filters.table,
				page,
				pageSize: size,
			},
		},
	}))
);
