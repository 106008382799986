import { Component, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TouchpointCard } from 'src/app/features/dashboard/state';

@Component({
	selector: 'app-touchpoint-card',
	templateUrl: './touchpoint-card.component.html',
	styleUrls: ['./touchpoint-card.component.scss'],
	animations: [
		trigger('smoothCollapse', [
			state(
				'open',
				style({
					height: '*',
				})
			),
			state(
				'closed',
				style({
					height: '0px',
					overflow: 'hidden',
				})
			),
			transition('open <=> closed', [animate('0.5s')]),
		]),
	],
})
export class TouchpointCardComponent {
	@Input()
	public card!: TouchpointCard;

	public showVariants: boolean = false;

	setVariantVisibility(showVariants: boolean) {
		this.showVariants = showVariants;
	}
}
