import { createReducer, on } from '@ngrx/store';
import * as fromSession from './session.actions';
import { HoldingCompany } from '../../shared/models/api/HoldingCompany';
import { ReportNavigation } from 'src/app/shared/models/api/reports/ReportNavigation';
import { NavigationItem } from 'src/app/shared/models/api/navigation/NavigationItem';

export interface SessionState {
	loading: boolean;
	reports: ReportNavigation[];
	selectedItemPath: string | null;
	companies: HoldingCompany[];
	filters: {
		company: HoldingCompany;
	};
	notifications: {
		approvalCount: number;
		latestApprovalUrl: string | null;
	};
}

export const initialState: SessionState = {
	loading: false,
	reports: [],
	selectedItemPath: null,
	companies: [],
	filters: {
		company: {
			id: -1,
			name: '',
			roles: [],
			branding: {
				logo: '',
				color: {
					primary: '',
					secondary: '',
				},
				map: {
					zoom: 0,
					coordinates: {
						longitude: 0,
						latitude: 0,
					},
				},
			},
		},
	},
	notifications: {
		approvalCount: 0,
		latestApprovalUrl: null,
	},
};

export const reducer = createReducer(
	initialState,
	on(fromSession.initializeSession, (state) => ({
		...state,
		loading: true,
	})),
	on(fromSession.initializeSessionSuccess, (state, { companies }) => {
		if (state.filters.company.id && state.filters.company.name) {
			return {
				...state,
				companies,
				loading: false,
			};
		}

		return {
			...state,
			companies,
			filters: {
				company: {
					id: companies[0].id,
					name: companies[0].name,
					roles: companies[0].roles,
					branding: {
						logo: companies[0].branding.logo,
						color: {
							primary: companies[0].branding.color.primary,
							secondary: companies[0].branding.color.secondary,
						},
						map: {
							zoom: companies[0].branding.map.zoom,
							coordinates: {
								longitude: companies[0].branding.map.coordinates.longitude,
								latitude: companies[0].branding.map.coordinates.latitude,
							},
						},
					},
				},
			},
			loading: false,
		};
	}),
	on(fromSession.updateReportItemsSuccess, (state, { reports }) => ({
		...state,
		reports,
		loading: false,
	})),
	on(fromSession.updateSessionFilters, (state, { company }) => ({
		...state,
		filters: { company },
		loading: true,
	})),
	on(fromSession.outstandingNotificationsSuccess, (state, { data }) => ({
		...state,
		notifications: {
			...state.notifications,
			approvalCount: data.message.approvalsCount,
			latestApprovalUrl: data.message.latestApprovalUrl,
		},
	})),
	 on(fromSession.setSelectedItemPath, (state, { path }) => ({
    ...state,
    selectedItemPath: path,
  })),
);
