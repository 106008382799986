import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-mini-donut-graph',
	templateUrl: './mini-donut-graph.component.html',
	styleUrls: ['./mini-donut-graph.component.scss'],
})

export class InsightsDonutGraphIndicatorComponent {
	@Input() public percentage?: number;
}
