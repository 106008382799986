import { map, Observable } from 'rxjs';

import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { AppleHyperLinkComponent } from '../../components/apple/apple-table/components/apple-hyper-link/apple-hyper-link.component';
import { ScreenshotComponent } from '../../components/apple/apple-table/components/screenshot/screenshot.component';
import { TableColumn } from '../../components/apple/apple-table/models/column';
import * as fromRoot from '../../store';
import * as fromContentDrilldown from './state';
import { ContentDrilldownHeader } from './state/models/ContentDrilldownHeader';
import { ContentDrillDownRow } from './state/models/ContentDrillDownRow';
import { VideoColumnComponent } from 'src/app/components/apple/apple-table/components/video-column/video-column.component';

@UntilDestroy()
@Component({
	selector: 'app-content-drilldown',
	templateUrl: './content-drilldown.component.html',
	styleUrls: ['./content-drilldown.component.scss'],
})
export class AppleContentDrilldownComponent {
	public hoveringOnPlayPreview: boolean = false;
	public interactingWithPlayPreview: boolean = false;
	public hoveringOnApproverIndex: number = -1;

	public loading$: Observable<boolean> = this._store
		.select(fromContentDrilldown.selectLoading)
		.pipe(untilDestroyed(this));

	public initializing$: Observable<boolean> = this._store
		.select(fromContentDrilldown.selectInitializing)
		.pipe(untilDestroyed(this));

	public header$: Observable<ContentDrilldownHeader> = this._store
		.select(fromContentDrilldown.selectHeader)
		.pipe(untilDestroyed(this));

	public filters$: Observable<string[]> = this._store
		.select(fromContentDrilldown.selectFilters)
		.pipe(map((items) => items.applied.flatMap((item) => item.values)))
		.pipe(untilDestroyed(this));

	public page$: Observable<number> = this._store
		.select(fromContentDrilldown.selectFilters)
		.pipe(map((items) => items.page))
		.pipe(untilDestroyed(this));

	public data$: Observable<ContentDrillDownRow[]> = this._store
		.select(fromContentDrilldown.selectFilteredTouchpoints)
		.pipe(untilDestroyed(this));

	public total$: Observable<number> = this._store
		.select(fromContentDrilldown.selectFilteredTotalCount)
		.pipe(untilDestroyed(this));

	public columns: TableColumn[] = [
		{ header: 'Region', field: 'region', sortable: false },
		{ header: 'Store Name', field: 'store_name', sortable: true },
		{ header: 'Store ID', field: 'stp', sortable: false },
		{ header: 'Asset ID', field: 'assnum', sortable: false, component: AppleHyperLinkComponent },
		{ header: 'Status', field: 'status', sortable: false },
		{ header: 'Flighted', field: 'flightlog_date', sortable: true },
		{ header: 'Content', field: 'content', sortable: false, component: VideoColumnComponent },
		{ header: 'Screenshot', field: 'screenshot', sortable: false, component: ScreenshotComponent },
	];

	constructor(
		private _store: Store<fromRoot.State>,
		private _route: ActivatedRoute,
		private location: Location
	) {
		const { category, touchpoint, media, variant, result } = this._route.snapshot.queryParams;

		this._store.dispatch(
			fromContentDrilldown.initializeContentDrilldownData({
				categoryId: category,
				touchpointId: touchpoint,
				mediaId: media,
				variantId: variant,
				isResult: result == 'true',
			})
		);

		this._store.dispatch(fromContentDrilldown.listenForBulkScreenshotUpdates());
	}

	search(state: string) {
		this._store.dispatch(fromContentDrilldown.setSearchText({ searchText: state }));
	}

	filter(event: any) {
		if (event.filters.length) {
			this._store.dispatch(fromContentDrilldown.setAppliedFilters(event));
		} else {
			this._store.dispatch(fromContentDrilldown.resetAppliedFilters());
		}
	}

	sort(event: { column: string | null; direction: string | null }) {
		this._store.dispatch(fromContentDrilldown.setSort({ column: event.column, direction: event.direction }));
	}

	page(event: { page: number; size: number }) {
		this._store.dispatch(fromContentDrilldown.setPage({ page: event.page, size: event.size }));
	}

	export() {
		this._store.dispatch(fromContentDrilldown.exportContentDrilldownData());
	}

	screenshots() {
		this._store.dispatch(fromContentDrilldown.bulkScreenshotUpdate());
	}

	back(): void {
		this.location.back();
	}
}
