<div class="button-container filter">
	<button class="button" (click)="toggleFilterDropdown()">
		<img *ngIf="icon" class="icon" [src]="icon" />
		{{ label }}
		<img class="arrow-icon" src="../../../assets/arrow-down-blue.svg" />
	</button>
	<div id="dropdown-content" class="dropdown-content">
		<app-apple-tooltip [open]="filtersOpen">
			<ng-container tooltip-content>
				<app-apple-loader [loading]="loading"></app-apple-loader>
				<div *ngIf="!loading">
					<div class="tooltip-item" *ngFor="let filter of tableFilters">
						<ng-container *ngIf="filter | formatTableFilters as fmt">
							<div class="tooltip-item-name">{{ fmt.label | titlecase }}</div>
							<div class="tooltip-item-value">
								<ng-container>
									<app-apple-select
										[name]="fmt.label"
										[items]="fmt.values"
										[clearable]="filter.type === 'single'"
										[multiple]="filter.type === 'multi'"
										[closeOnSelect]="false"
										(changed)="onFiltersChanged($event)">
									</app-apple-select>
								</ng-container>
							</div>
						</ng-container>
					</div>
				</div>
			</ng-container>
		</app-apple-tooltip>
	</div>
</div>
