import { Component, OnInit, Input } from '@angular/core';
import { Comment } from '../store/content.models';

@Component({
	selector: 'app-comment',
	templateUrl: './comment.component.html',
	styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit {
	@Input() comment!: Comment;

	constructor() {}

	ngOnInit(): void {}

	setDefaultPicture(event: any) {
		event.target.src = 'assets/avatar.png';
	}
}
