<ng-container *ngLet="loading$ | async as loading">
	<ng-container *ngLet="error$ | async as error">
		<ng-container *ngIf="loading">
			<app-loader [loading]="loading"></app-loader>
		</ng-container>
		<ng-container *ngIf="!loading && error">
			<app-apple-error [title]="error.title" [message]="error.message"> </app-apple-error>
		</ng-container>
		<ng-container *ngIf="!loading && !error">
			<app-apple-card [shadow]="true" [style]="{ width: '75vw', 'max-width': '420px' }">
				<div class="content">
					<div class="header">
						<div class="title">Welcome {{ name$ | async }}!</div>
						<div class="subtext">
							<span class="text" *ngIf="type !== 'traditional'">Please sign in to your account.</span>
							<span class="text" *ngIf="type === 'traditional'">
								Please complete your registration to the Barrows Network Manager by setting a secure password below:
							</span>
						</div>
					</div>
					<div class="sso" *ngIf="type === 'google' || type == 'microsoft'">
						<div class="button" *ngIf="type === 'google'">
							<app-apple-button
								color="var(--black)"
								background="var(--white)"
								border="var(--grey-border)"
								[style]="{ padding: '12px', 'font-weight': 'bold', 'font-size': '12px' }"
								(click)="registerWithGoogle()">
								<div class="button-content">
									<img class="image" src="../../../../assets/google-black.svg" />
									<span class="text">Sign in with Google</span>
								</div>
							</app-apple-button>
						</div>
						<div class="button" *ngIf="type === 'microsoft'">
							<app-apple-button
								color="var(--black)"
								background="var(--white)"
								border="var(--grey-border)"
								[style]="{ padding: '12px', 'font-weight': 'bold', 'font-size': '12px' }"
								(click)="registerWithMicrosoft()">
								<div class="button-content">
									<img class="image" src="../../../../assets/microsoft-black.svg" />
									<span class="text">Sign in with Microsoft</span>
								</div>
							</app-apple-button>
						</div>
					</div>
					<div class="traditional" *ngIf="type === 'traditional'">
						<form [formGroup]="form">
							<div class="request-form">
								<div class="field">
									<app-apple-input label="Email" formControlName="email" [disabled]="true"></app-apple-input>
									<div class="validation">
										<div class="item" *ngIf="invalid(form, 'email', 'required')">
											<span class="icon">
												<img class="cross" src="../../../../assets/cross-black.svg" />
											</span>
											<span class="invalid">This field is required.</span>
										</div>
									</div>
								</div>
								<div class="field">
									<app-apple-input type="password" label="Password" formControlName="password"></app-apple-input>
									<div class="validation">
										<div class="item">
											<span class="icon">
												<img
													class="cross"
													*ngIf="invalid(form, 'password', 'lengthCheck')"
													src="../../../../assets/cross-black.svg" />
												<img
													class="tick"
													*ngIf="!invalid(form, 'password', 'lengthCheck')"
													src="../../../../assets/tick-green.svg" />
											</span>
											<span class="message">Must contain at least 8 characters</span>
										</div>
										<div class="item">
											<span class="icon">
												<img
													class="cross"
													*ngIf="invalid(form, 'password', 'uppercase')"
													src="../../../../assets/cross-black.svg" />
												<img
													class="tick"
													*ngIf="!invalid(form, 'password', 'uppercase')"
													src="../../../../assets/tick-green.svg" />
											</span>
											<span class="message">Must contain at least 1 uppercase letter</span>
										</div>
										<div class="item">
											<span class="icon">
												<img
													class="cross"
													*ngIf="invalid(form, 'password', 'lowercase')"
													src="../../../../assets/cross-black.svg" />
												<img
													class="tick"
													*ngIf="!invalid(form, 'password', 'lowercase')"
													src="../../../../assets/tick-green.svg" />
											</span>
											<span class="message">Must contain at least 1 lowercase letter</span>
										</div>
										<div class="item">
											<span class="icon">
												<img
													class="cross"
													*ngIf="invalid(form, 'password', 'numeric')"
													src="../../../../assets/cross-black.svg" />
												<img
													class="tick"
													*ngIf="!invalid(form, 'password', 'numeric')"
													src="../../../../assets/tick-green.svg" />
											</span>
											<span class="message">Must contain at least 1 number</span>
										</div>
										<div class="item">
											<span class="icon">
												<img
													class="cross"
													*ngIf="invalid(form, 'password', 'required')"
													src="../../../../assets/cross-black.svg" />
												<img
													class="tick"
													*ngIf="!invalid(form, 'password', 'required')"
													src="../../../../assets/tick-green.svg" />
											</span>
											<span class="invalid">This field is required.</span>
										</div>
									</div>
								</div>
								<div class="field">
									<app-apple-input
										type="password"
										label="Confirm Password"
										formControlName="confirmPassword"></app-apple-input>
									<div class="validation">
										<div class="item" *ngIf="form.get('confirmPassword')?.touched && form.hasError('passwordMismatch')">
											<span class="icon">
												<img class="cross" src="../../../../assets/cross-black.svg" />
											</span>
											<span class="message">Passwords do not match</span>
										</div>
										<div class="item" *ngIf="invalid(form, 'confirmPassword', 'required')">
											<span class="icon">
												<img class="cross" src="../../../../assets/cross-black.svg" />
											</span>
											<span class="invalid">This field is required.</span>
										</div>
									</div>
								</div>
							</div>
							<div class="request-button">
								<app-apple-button
									[disabled]="!this.form.valid"
									color="var(--white)"
									background="var(--black)"
									[style]="{ 'font-weight': 'bold', 'font-size': '12px' }"
									[disabled]="form.invalid || loading"
									(click)="registerWithEmail()">
									<div class="button-content">
										<div *ngIf="!loading">SUBMIT</div>
										<div *ngIf="loading">
											<i class="fa fa-solid fa-spinner fa-spin"></i>
										</div>
									</div>
								</app-apple-button>
							</div>
						</form>
					</div>
					<div class="logo">
						<img class="img" src="../../../../assets/barrows.png" />
					</div>
				</div>
			</app-apple-card>
		</ng-container>
	</ng-container>
</ng-container>
