
<div class="schedule-container">
	<div class="dropdown">
		<app-apple-select
			name="Filter"
			[items]="zoomOptions"
			[selected]="zoomOptions[0].id"
			(changed)="onZoomChanged($event)"></app-apple-select>
	</div>
	<bryntum-scheduler
		[barMargin]="10"
		[columns]="columns"
		[startDate]="start"
		[endDate]="end"
		[events]="events"
		[resources]="resources"
		[rowHeight]="80"
		[hoverCls]="'none'"
		[treeFeature]="false"
		[scheduleMenuFeature]="false"
		[timeAxisHeaderMenuFeature]="false"
		[cellMenuFeature]="false"
		[eventMenuFeature]="false"
		[eventEditFeature]="false"
		[regionResizeFeature]="false"
		[timeRangesFeature]="false"
		[eventTooltipFeature]="false"
		[eventRenderer]="eventRenderer"
		[managedEventSizing]="false"
		[viewPreset]="currentPreset"
		[zoomOnTimeAxisDoubleClick]="false"
		[zoomOnMouseWheel]="false"></bryntum-scheduler>
</div>