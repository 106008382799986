import * as fromRoot from 'src/app/store';
import * as fromSession from 'src/app/store/session';
import * as fromBookingCreationActions from '../state/bookings-create.actions';
import * as fromBookingCreationSelectors from '../state/bookings-create.selectors';

import { withLatestFrom, map, switchMap, tap, filter, EMPTY, catchError } from 'rxjs';

import { environment } from 'src/environments/environment';
import { BookingInformation } from '../models';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Store as PlatformStore } from 'src/app/shared/models/api/store/Store';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/services/toast.service';

@Injectable()
export class BookingCreationEffects {
	constructor(
		private _actions$: Actions,
		private _http: HttpClient,
		private _store: Store<fromRoot.State>,
		private _router: Router,
		private _toast: ToastService
	) {}

	loadBookingInformation$ = createEffect(() =>
		this._actions$.pipe(
			ofType(fromBookingCreationActions.loadBookingInformation),
			withLatestFrom(this._store.select(fromSession.getFilters)),
			switchMap(([action, filters]) => {
				const params = `holdingCompanyId=${filters.company.id}${!!action.id ? '&bookingId=' + action.id : ''}`;
				const url = `${environment.api}/client_portal/bookings/?${params}`;
				return this._http
					.get<BookingInformation>(url)
					.pipe(map((info) => fromBookingCreationActions.loadBookingInformationSuccess({ info })));
			})
		)
	);

	loadStores$ = createEffect(() =>
		this._actions$.pipe(
			ofType(fromBookingCreationActions.loadStores),
			switchMap(({ categoryId }) => {
				const url = `${environment.api}/client_portal/bookings/get_stores/?categoryId=${categoryId}`;
				return this._http
					.get<PlatformStore[]>(url)
					.pipe(map((stores) => fromBookingCreationActions.loadStoresSuccess({ stores })));
			})
		)
	);

	submitBooking$ = createEffect(
		() =>
			this._actions$.pipe(
				ofType(fromBookingCreationActions.submitBooking),
				withLatestFrom(this._store.select(fromSession.getFilters)),
				switchMap(([action, filters]) => {
					const url = `${environment.api}/client_portal/bookings/?holdingCompanyId=${filters.company.id}`;
					return this._http.post(url, action.booking).pipe(
						tap(() => {
							this._store.dispatch(fromBookingCreationActions.submitBookingSuccess({ booking: action.booking }));
							this._router.navigateByUrl('/network/campaigns/view');
						}),
						catchError(() => {
							this._store.dispatch(fromBookingCreationActions.submitBookingFailure());
							return EMPTY;
						})
					);
				})
			),
		{ dispatch: false }
	);

	submitMediaContent$ = createEffect(
		() =>
			this._actions$.pipe(
				ofType(fromBookingCreationActions.submitMediaContent),
				withLatestFrom(this._store.select(fromBookingCreationSelectors.selectBooking)),
				switchMap(([{ bookingId, approverIds, rgb, mediaFiles }, booking]) => {
					const data = new FormData();

					data.append('bookingId', bookingId);
					data.append('approverIds', JSON.stringify(approverIds));
					data.append('rgb', rgb.slice(1));

					Object.keys(mediaFiles).forEach((key) => {
						data.append(key, mediaFiles[key]);
					});

					const url = `${environment.api}/client_portal/submit_media/`;

					return this._http.post(url, data).pipe(
						tap(() => {
							this._router.navigateByUrl('/network/campaigns/view');
						})
					);
				})
			),
		{ dispatch: false }
	);

	deleteBooking$ = createEffect(
		() =>
			this._actions$.pipe(
				ofType(fromBookingCreationActions.deleteBooking),
				switchMap(({ bookingId }) =>
					this._http.delete<any>(`${environment.api}/client_portal/bookings?id=${bookingId}`).pipe(
						tap(() => {
							this._router.navigateByUrl('/network/campaigns/view');
						})
					)
				)
			),
		{ dispatch: false }
	);

	cancelBooking$ = createEffect(
		() =>
			this._actions$.pipe(
				ofType(fromBookingCreationActions.cancelBooking),
				map(() => {
					this._router.navigateByUrl('/network/campaigns/view');
				})
			),
		{ dispatch: false }
	);

	confirmBooking$ = createEffect(
		() =>
			this._actions$.pipe(
				ofType(fromBookingCreationActions.confirmBooking),
				switchMap(({ bookingId }) =>
					this._http.post<any>(`${environment.api}/client_portal/bookings/confirm_booking`, { bookingId }).pipe(
						tap(() => {
							this._router.navigateByUrl('/network/campaigns/view');
						})
					)
				)
			),
		{ dispatch: false }
	);
}
