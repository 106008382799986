import { createSelector, createFeatureSelector } from '@ngrx/store';
import { TouchpointState } from './touchpoint.reducers';
import { TableHelpers } from 'src/app/helpers/table';

export const getTouchpointState = createFeatureSelector<TouchpointState>('touchpoints');
export const getTouchpointSummary = createSelector(getTouchpointState, (state) => state.summary);
export const getAllTouchpointCards = createSelector(getTouchpointState, (state) => state.cards);
export const getTouchpointCards = createSelector(getTouchpointState, (state) => {
	return TableHelpers.paginate(state.cards, state.filters.page, state.filters.pageSize);
});
export const getTouchpointLoading = createSelector(getTouchpointState, (state) => state.loading);
export const getTouchpointPage = createSelector(getTouchpointState, (state) => state.filters.page);
