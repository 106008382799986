import {
	Component,
	OnInit,
	ViewChild,
	ElementRef,
	Input,
	OnChanges,
	SimpleChanges,
	AfterViewInit,
} from '@angular/core';
import { Chart, ChartConfiguration, ChartType, registerables } from 'chart.js';
import { default as Annotation } from 'chartjs-plugin-annotation';
import { LineGraph } from '../../models';

@Component({
	selector: 'app-insights-line-graph',
	templateUrl: './insight-line-graph.component.html',
	styleUrls: ['./insight-line-graph.component.scss'],
})
export class InsightLineGraphComponent implements OnInit, OnChanges, AfterViewInit {
	@ViewChild('chart') private chartRef!: ElementRef;
	private chart?: Chart;

	public readonly CHART_COLORS = ['#3f5efb', '#c4d4fc'];

	placeholderHeights: string[] = [
		'220px',
		'260px',
		'280px',
		'320px',
		'220px',
		'260px',
		'280px',
		'320px',
		'220px',
		'260px',
	];

	@Input() loading: boolean = true;
	@Input() data?: LineGraph | null;

	private currentHoveredLineIndex: number = 0;

	constructor() {}

	ngOnInit() {
		Chart.register(...registerables, Annotation);
	}

	ngAfterViewInit() {
		if (!this.loading) {
			this.initOrUpdateChart();
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['loading'] && !changes['loading'].currentValue) {
			this.initOrUpdateChart();
		} else if (this.chart && changes['data']) {
			this.updateChartData();
		}
	}

	private initOrUpdateChart() {
		if (!this.chartRef) {
			console.warn('Chart reference not available');
			return;
		}

		if (!this.chart) {
			this.createChart();
		} else {
			this.updateChartData();
		}
	}

	private updateChartData() {
		if (this.chart && this.data) {
			this.chart.data.labels = this.data.x;
			this.chart.data.datasets = this.createDatasets();

			if (this.chart.options.scales?.['y']) {
				this.chart.options.scales['y'].ticks = {
					count: this.data.y?.length,
					callback: (_, index) => {
						if (!this.data?.y) return '';
						return index < this.data.y.length ? this.data.y[index] : '';
					},
				};
			}

			this.chart.update();
		}
	}

	private createDatasets() {
		if (!this.data) return [];

		const ctx = this.chartRef.nativeElement.getContext('2d');
		const createGradient = (color: string) => {
			const gradient = ctx.createLinearGradient(0, 0, 0, 400);
			gradient.addColorStop(0, color);
			gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');
			return gradient;
		};

		return this.data.series.map((series, index) => {
			const color = series.color || this.CHART_COLORS[index % this.CHART_COLORS.length];
			return {
				label: series.name,
				data: series.data.map((point) => point.value),
				borderColor: color,
				backgroundColor: createGradient(`${color}80`),
				borderWidth: 4,
				pointRadius: 0,
				fill: true,
				tension: 0.4,
				pointBorderColor: 'rgba(0, 0, 0, 0)',
				pointBackgroundColor: 'rgba(0, 0, 0, 0)',
				pointHoverBackgroundColor: color,
				pointHoverBorderColor: '#FFF',
				pointHoverRadius: 8,
				pointHoverBorderWidth: 4,
			};
		});
	}

	private createChart() {
		const ctx = this.chartRef.nativeElement.getContext('2d');
		if (!ctx) {
			console.warn('Unable to get 2D context for chart');
			return;
		}

		const config: ChartConfiguration = {
			type: 'line' as ChartType,
			data: {
				labels: this.data?.x || [],
				datasets: this.createDatasets(),
			},
			options: this.createChartOptions(),
		};

		this.chart = new Chart(ctx, config);
	}

	private createChartOptions(): ChartConfiguration['options'] {
		return {
			hover: { intersect: false },
			responsive: true,
			maintainAspectRatio: false,
			scales: {
				x: {
					grid: {
						display: false,
					},
				},
				y: {
					beginAtZero: true,
					grid: { color: 'rgba(0, 0, 0, 0.1)' },
					ticks: {
						count: this.data?.y?.length,
						callback: (_, index) => {
							if (!this.data?.y) return '';
							return index < this.data.y.length ? this.data.y[index] : '';
						},
					},
				},
			},
			onHover: (event, elements) => {
				if (elements && elements.length > 0) {
					this.currentHoveredLineIndex = elements[0].datasetIndex;
				}
			},
			plugins: {
				legend: {
					display: false,
				},
				tooltip: {
					enabled: false,
					mode: 'index',
					intersect: false,
					external: this.externalTooltipHandler.bind(this),
				},
			},
		};
	}

	private externalTooltipHandler(context: any) {
		const tooltipModel = context.tooltip;
		let tooltipEl = document.getElementById('chart-tooltip');

		if (!tooltipEl) {
			tooltipEl = document.createElement('div');
			tooltipEl.id = 'chart-tooltip';
			Object.assign(tooltipEl.style, {
				background: '#FFF',
				borderRadius: '8px',
				minWidth: '60px',
				padding: '8px 16px',
				textAlign: 'center',
				boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
				zIndex: '1',
			});
			tooltipEl.innerHTML = '<div></div>';
			document.body.appendChild(tooltipEl);
		}

		if (tooltipModel.opacity === 0) {
			tooltipEl.style.opacity = '0';
			return;
		}

		if (tooltipModel.body) {
			const titleLines = tooltipModel.title || [];
			const dataPoint = tooltipModel.dataPoints[this.currentHoveredLineIndex];
			const series = this.data?.series[this.currentHoveredLineIndex];
			const label = series?.data[dataPoint.dataIndex]?.label;

			const innerHtml = `
                <div class="tooltip-content">
                    <div class="tooltip-total-cost" style="margin-bottom: 3px; font-size: 15px; font-weight: 400;">${label}</div>
                    <div class="tooltip-title" style="color: #636363; font-size: 12px;">${titleLines[0]}</div>
                </div>
            `;

			tooltipEl.innerHTML = innerHtml;
		}

		const position = context.chart.canvas.getBoundingClientRect();
		const dataPoint = tooltipModel.dataPoints[this.currentHoveredLineIndex];
		const xScale = context.chart.scales['x'];
		const yScale = context.chart.scales['y'];

		const xPixel = xScale.getPixelForValue(dataPoint.dataIndex);
		const yPixel = yScale.getPixelForValue(dataPoint.raw as number);

		tooltipEl.style.opacity = '1';
		tooltipEl.style.position = 'absolute';
		tooltipEl.style.left = position.left + window.pageXOffset + xPixel + 'px';
		tooltipEl.style.top = position.top + window.pageYOffset + yPixel + 'px';
		tooltipEl.style.transform = 'translate(-90%, -120%)';
		tooltipEl.style.pointerEvents = 'none';
	}
}
