<app-card class="card">
	<div class="wrapper">
		<div class="header">
			<ngx-skeleton-loader *ngIf="loading" [theme]="{ width: '180px', height: '20px', 'border-radius': '8px' }">
			</ngx-skeleton-loader>
			<div class="title" *ngIf="!loading">{{ data?.header }}</div>
			<div class="legend" *ngIf="!loading">
				<span
					*ngFor="let series of data?.series; let i = index"
					class="item"
					[ngStyle]="{ '--legend-color': series.color || CHART_COLORS[i % CHART_COLORS.length] }">
					{{ series.name }}
				</span>
			</div>
			<ngx-skeleton-loader *ngIf="loading" [theme]="{ width: '100px', height: '20px', 'border-radius': '8px' }">
			</ngx-skeleton-loader>
		</div>
		<div class="chart-loading" *ngIf="loading">
			<ngx-skeleton-loader
				*ngFor="let height of placeholderHeights"
				[theme]="{ width: '40px', height: height, 'border-radius': '8px' }">
			</ngx-skeleton-loader>
		</div>
		<canvas #chart [ngStyle]="{ display: !loading ? 'block' : 'none' }"></canvas>
	</div>
</app-card>
