<app-apple-card [shadow]="true" [style]="{ width: '75vw', 'max-width': '400px' }">
	<div class="content">
		<div class="header">
			<div class="title">Sign In</div>
			<div class="subtext">
				<span class="text">Don’t have an account?</span>
				<span id="account-request" class="link" routerLink="/auth/account-request">Request here</span>
			</div>
		</div>
		<div class="sso">
			<div class="button">
				<app-apple-button
					(click)="loginWithGoogle()"
					color="var(--black)"
					background="var(--white)"
					border="var(--grey-border)"
					[style]="{ padding: '12px', 'font-weight': 'bold', 'font-size': '12px' }">
					<div class="button-content">
						<img class="image" src="../../../../assets/google-black.svg" />
						<span class="text">Sign in with Google</span>
					</div>
				</app-apple-button>
			</div>
			<div class="button">
				<app-apple-button
					(click)="loginWithMicrosoft()"
					color="var(--black)"
					background="var(--white)"
					border="var(--grey-border)"
					[style]="{ padding: '12px', 'font-weight': 'bold', 'font-size': '12px' }">
					<div class="button-content">
						<img class="image" src="../../../../assets/microsoft-black.svg" />
						<span class="text">Sign in with Microsoft</span>
					</div>
				</app-apple-button>
			</div>
		</div>
		<div class="separator">or</div>
		<div class="login-form" [formGroup]="form">
			<div class="field">
				<app-apple-input formControlName="email" label="Email"></app-apple-input>
			</div>
			<div class="field">
				<app-apple-input formControlName="password" type="password" label="Password"></app-apple-input>
			</div>
		</div>
		<div class="remember-me">
			<app-apple-checkbox type="secondary">Remember me</app-apple-checkbox>
			<div id="forgot-password" class="link" routerLink="/auth/forgot-password">Forgot Password</div>
		</div>
		<div class="login-button">
			<app-apple-button
				[disabled]="!this.form.valid"
				color="var(--white)"
				background="var(--black)"
				[style]="{ 'font-weight': 'bold', 'font-size': '12px' }"
				(click)="loginWithEmail()">
				<div class="button-content">
					<div>SIGN IN</div>
				</div>
			</app-apple-button>
		</div>
		<div class="logo">
			<img class="image" src="../../../../assets/barrows.png" />
		</div>
	</div>
</app-apple-card>
