import { Pipe, PipeTransform } from '@angular/core';
import { interval, Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateTime } from 'ts-luxon';

@Pipe({
	name: 'relativeTime',
})
export class RelativeTimePipe implements PipeTransform {
	transform(date: string): Observable<string> {
		return timer(0, 1000).pipe(
			map(() => {
				let current = DateTime.local().toUTC(0).toUnixInteger() * 1000;
				let input = DateTime.fromISO(date, { zone: 'UTC' }).toUnixInteger() * 1000;

				var msPerMinute = 60 * 1000;
				var msPerHour = msPerMinute * 60;
				var msPerDay = msPerHour * 24;

				var elapsed = current - input;

				if (elapsed < msPerMinute) {
					return Math.round(elapsed / 1000) + 's ago';
				} else if (elapsed < msPerHour) {
					return Math.round(elapsed / msPerMinute) + 'm ago';
				} else if (elapsed < msPerDay) {
					return Math.round(elapsed / msPerHour) + 'h ago';
				} else {
					return '> 24h ago';
				}
			})
		);
	}
}
