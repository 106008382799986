import { Action, combineReducers } from '@ngrx/store';
import * as fromRevenue from '../revenue/state';
import * as fromCampaign from '../campaign/state';
import * as fromSustainability from '../sustainability/state';

export interface InsightsState {
	revenue: fromRevenue.RevenueState;
	campaign: fromCampaign.CampaignState;
	sustainability: fromSustainability.SustainabilityState;
}

export const reducer = combineReducers<InsightsState, Action>({
	revenue: fromRevenue.revenueReducer,
	campaign: fromCampaign.campaignReducer,
	sustainability: fromSustainability.sustainabilityReducer,
});
