<div class="insights-metric">
	<div class="heading">
		<ng-container *ngIf="value !== null && value !== undefined; else valueLoadingTemplate">
			{{ value }}
		</ng-container>
		<ng-template #valueLoadingTemplate>
			<ngx-skeleton-loader
				[theme]="{
					width: '150px',
					height: '37px',
					'border-radius': '9px',
				}"></ngx-skeleton-loader>
		</ng-template>
	</div>
	<div class="subheading">
		<ng-container *ngIf="title !== null && title !== undefined; else titleLoadingTemplate">
			{{ title }}
		</ng-container>
		<ng-template #titleLoadingTemplate>
			<ngx-skeleton-loader
				style="display: flex"
				[theme]="{ width: '180px', height: '28px', 'border-radius': '9px' }"></ngx-skeleton-loader>
		</ng-template>
	</div>
</div>
