import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonConfig } from './models/buttonConfig';

@Component({
	selector: 'app-apple-button-group',
	templateUrl: './apple-button-group.component.html',
	styleUrls: ['./apple-button-group.component.scss'],
})
export class AppleButtonGroupComponent {
	@Input()
	public buttons: ButtonConfig[] = [];

	@Output()
	public click: EventEmitter<ButtonConfig> = new EventEmitter<ButtonConfig>();

	isActive(button: ButtonConfig): boolean {
		return button.url === window.location.pathname;
	}

	onButtonClick(event: any, button: ButtonConfig) {
		event.stopPropagation();
		this.click.emit(button);
	}
}
