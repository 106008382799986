import { Component, EventEmitter, HostListener, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-apple-select',
	templateUrl: './apple-select.component.html',
	styleUrls: ['./apple-select.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => AppleSelectComponent),
			multi: true,
		},
	],
})
export class AppleSelectComponent implements ControlValueAccessor {
	private _selected: any;
	private _propagateChange: any = () => {};
	private _propagateTouched: any = () => {};

	public loading: boolean = false;
	public dropdownOpen: boolean = false;

	@Input()
	public label!: string;

	@Input()
	public name!: string;

	@Input()
	public clearable: boolean = false;

	@Input()
	public multiple: boolean = false;

	@Input()
	public truncateCount: number = -1;

	@Input()
	public placeholder: string = '';

	@Input()
	public items: { id?: number | string; name: string }[] = [];

	@Input()
	public closeOnSelect: boolean = true;

	@Input()
	public type: 'input' | 'textarea' = 'input';

	@Input()
	public maxSelections: number = 1000;

	@Input()
	public allowAddNewItem: boolean = false;

	@Input()
	public disabled: boolean = false;

	@Input()
	get selected() {
		return this._selected;
	}

	set selected(value: any) {
		this.writeValue(value);
	}

	@Output()
	public changed = new EventEmitter<any>();

	writeValue(value: any): void {
		if (!value) {
			return;
		}

		if (Array.isArray(value)) {
			this._selected = value.map((item) => (typeof item === 'object' ? item : this.items.find((i) => i.id === item)));
		} else {
			this._selected = typeof value === 'object' ? value : this.items.find((item) => item.id === value);
		}

		this.onSelectedItemsChanged(this._selected);
	}

	registerOnChange(fn: any): void {
		this._propagateChange = fn;
	}

	registerOnTouched(fn: any): void {
		this._propagateTouched = fn;
	}

	onBlur(): void {
		this._propagateTouched();
	}

	addNewItem = (name: string) => {
		const item = { name };
		this.items = [...this.items, item];
		this._selected = this.multiple ? [...(this._selected || []), item] : item;
		this.onSelectedItemsChanged(this._selected);
		return item;
	};

	onSelectedItemsChanged(items: any) {
		if (items && items.type != 'change') {
			this.changed.emit({
				name: this.name,
				value: items,
			});
			this._propagateChange(items);
		}
	}

	// Toggles the dropdown
	toggleDropdown() {
		if (this.disabled) return;
		if (!this.closeOnSelect) {
			this.dropdownOpen = true;
		} else {
			this.dropdownOpen = !this.dropdownOpen;
		}
	}

	// Closes the dropdown if clicked outside
	@HostListener('document:click', ['$event.target'])
	closeDropdownOptions(targetElement: HTMLElement) {
		if (this.dropdownOpen && !targetElement.closest('.select-container')) {
			this.dropdownOpen = false;
		}
	}

	// Close dropdown when clicking on the select container (after opening)
	closeDropdown() {
		this.dropdownOpen = false;
	}
}
