<div class="filters">
	<app-insights-filters [filterOptions]="filterOptions" (filterChange)="onFilterChange($event)"></app-insights-filters>
</div>
<div class="dashboard" *ngLet="!!(loading$ | async) as loading">
	<app-insights-donut-graph
		class="emissions-breakdown"
		theme="white"
		[loading]="loading"
		[data]="totalEmissions$ | async"
		[showLegend]="true">
		<app-insights-emissions-per-minute custom-value-unit></app-insights-emissions-per-minute>
	</app-insights-donut-graph>
	<app-insights-line-graph
		class="emissions-graph"
		[loading]="loading"
		[data]="emissionsGraph$ | async"></app-insights-line-graph>
	<app-insights-sustainability-feature-overview class="features"></app-insights-sustainability-feature-overview>
	<div class="power-numbers">
		<ng-container *ngIf="!loading; else loadingPowerNumbers">
			<ng-container *ngIf="powerNumbers$ | async as powerNumbers">
				<app-insights-power-number
					*ngFor="let powerNumber of powerNumbers"
					[data]="powerNumber"></app-insights-power-number>
			</ng-container>
		</ng-container>
		<ng-template #loadingPowerNumbers>
			<app-insights-power-number *ngFor="let i of [1, 2]"></app-insights-power-number>
		</ng-template>
	</div>
	<app-insights-emissions-table
		class="emissions-table"
		[loading]="loading"
		[data]="(emissionsTable$ | async) || []"></app-insights-emissions-table>
</div>
