<div class="dropzone-wrapper" (drop)="onDrop($event)">
	<div class="dropzone-title">{{ title }}</div>

	<div class="dropzone-container" *ngIf="state.status === 'empty'" [ngClass]="[orientation, size]">
		<div class="dropzone-empty">
			<img src="assets/upload.svg" alt="Upload files" class="upload-icon" />
			<span class="dropzone-text">Drag and Drop a file here or</span>
			<input #file hidden type="file" (change)="onFileChange($event)" accept="video/*" />
			<app-apple-button
				(click)="file.click()"
				color="var(--white)"
				background="var(--blue)"
				[style]="{ 'text-align': 'center', padding: '11px', width: '90px' }">
				Browse
			</app-apple-button>
		</div>
	</div>

	<div class="dropzone-container" *ngIf="state.status === 'uploading'" [ngClass]="[orientation, size]">
		<div class="dropzone-progress">
			<div class="loader" [ngStyle]="{ background: 'conic-gradient(#0066cc ' + state.data + '%, #e6e6e6 0%)' }">
				<div class="loader-text">{{ state.data }}%</div>
			</div>
			<div class="dropzone-text">Uploading file...</div>
		</div>
	</div>

	<div *ngIf="state.status === 'preview'" [ngClass]="[orientation, size]" class="video-preview">
		<span class="delete-button" (click)="onDelete()">
			<img src="/assets/bin-white.svg" />
		</span>
		<video autoplay [muted]="true" loop>
			<source [src]="state.data" type="video/mp4" />
		</video>
	</div>
</div>
