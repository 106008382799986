import { Component } from '@angular/core';
import * as fromRoot from 'src/app/store';
import * as fromBookingCreationActions from '../../state/bookings-create.actions';
import * as fromBookingCreationSelectors from '../../state/bookings-create.selectors';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, switchMap, take } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Booking } from '../../models';

@UntilDestroy()
@Component({
	selector: 'app-media-upload',
	templateUrl: './media-upload.component.html',
	styleUrl: './media-upload.component.scss',
})
export class MediaUploadComponent {
	public loading = false;
	public colors = [
		{ name: 'white', hex: '#FFFFFF' },
		{ name: 'red', hex: '#FF0000' },
		{ name: 'green', hex: '#00FF00' },
		{ name: 'blue', hex: '#0000FF' },
		{ name: 'yellow', hex: '#FFFF00' },
		{ name: 'magneta', hex: '#FF00FF' },
		{ name: 'cyan', hex: '#00FFFF' },
		{ name: 'orange', hex: '#FF8000' },
		{ name: 'rose', hex: '#FF0080' },
		{ name: 'lime', hex: '#80FF00' },
		{ name: 'spring green', hex: '#00FF80' },
		{ name: 'violet', hex: '#8000FF' },
		{ name: 'azure', hex: '#0080FF ' },
		{ name: 'light pink', hex: '#FFB3B3' },
		{ name: 'pale green', hex: '#B3FFB3' },
		{ name: 'lavender', hex: '#B3B3FF' },
	];

	public form = new FormGroup({
		approvers: new FormControl<{ id: number; name: string }[]>([], { validators: [Validators.required] }),
		color: new FormControl<string | null>('#FFFFFF', { validators: Validators.required }),
	});

	public orientation$: Observable<string | null> = this._store
		.select(fromBookingCreationSelectors.selectTouchpointOrientation)
		.pipe(untilDestroyed(this));

	public approvers$: Observable<{ id: number; name: string }[]> = this._store
		.select(fromBookingCreationSelectors.selectApprovers)
		.pipe(untilDestroyed(this));

	public variants$: Observable<number[]> = this._store
		.select(fromBookingCreationSelectors.selectScreenVariants)
		.pipe(untilDestroyed(this));

	public booking$: Observable<Booking | null> = this._store
		.select(fromBookingCreationSelectors.selectBooking)
		.pipe(untilDestroyed(this));

	public mediaFiles: { [key: string]: File } = {};

	constructor(
		private _store: Store<fromRoot.State>,
		private _route: ActivatedRoute
	) {}

	ngOnInit() {
		this.booking$.pipe(take(1)).subscribe((booking) => {
			const approvers =
				booking?.media[0].contentApprovers?.map((approver) => ({
					id: approver.id,
					name: `${approver.firstName} ${approver.lastName}`,
				})) ?? [];

			const color = booking?.media[0].rgb;
			this.form.patchValue({
				approvers: approvers,
				color: `#${color}`,
			});
		});
	}

	onFileDeleted(variant: number) {
		this._store.dispatch(fromBookingCreationActions.deleteMediaFile({ variant }));
	}

	onFileReceived(variant: number, file: File): void {
		const reader = new FileReader();
		reader.onload = () => {
			const previewUrl = reader.result as string;
			this._store.dispatch(fromBookingCreationActions.uploadMediaFile({ variant, file, previewUrl }));
		};
		reader.readAsDataURL(file);
	}

	getDropZoneState(variant: number) {
		return this._store
			.select(fromBookingCreationSelectors.selectVariantDropzoneState(variant))
			.pipe(untilDestroyed(this));
	}

	submit(): void {
		const bookingId = this._route.parent?.snapshot.params['uid'];
		const approvers = this.form.value.approvers;
		const approverIds = approvers ? approvers.map((approver) => approver.id) : [];
		const rgb = this.form.value.color ?? '';

		this._store
			.select(fromBookingCreationSelectors.selectBooking)
			.pipe(take(1))
			.subscribe((booking) => {
				booking?.media.forEach((media) => {
					if (media.file) {
						this.mediaFiles[`media_file-${media.screenVariant}`] = media.file;
					}
				});

				this._store.dispatch(
					fromBookingCreationActions.submitMediaContent({
						bookingId,
						approverIds,
						rgb,
						mediaFiles: this.mediaFiles,
					})
				);
			});
		this.loading = true;
	}

	cancel(): void {
		this._store.dispatch(fromBookingCreationActions.cancelBooking());
	}

	public variantValidation(): Observable<boolean> {
		return this.variants$.pipe(
			switchMap((variants) =>
				combineLatest(
					variants.map((variant) =>
						this._store.select(fromBookingCreationSelectors.selectVariantDropzoneState(variant)).pipe(
							take(1),
							map((state) => {
								const key = `media_file-${variant}`;
								return !!this.mediaFiles[key] || state.status !== 'empty';
							})
						)
					)
				)
			),
			map((validationResults) => validationResults.every((isUploaded) => isUploaded))
		);
	}
}
