import { AfterViewInit, Component, ContentChild, Input, TemplateRef, ViewChild } from '@angular/core';
import { EmblaCarouselDirective, EmblaCarouselType } from 'embla-carousel-angular';
import Autoplay from 'embla-carousel-autoplay';

@Component({
	selector: 'app-carousel',
	templateUrl: './carousel.component.html',
	styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements AfterViewInit {
	@ViewChild(EmblaCarouselDirective)
	emblaRef: EmblaCarouselDirective = new EmblaCarouselDirective();

	@Input() autoplay: boolean = true;
	@Input() delay = 3000;
	@Input() items: any[] = [];
	@Input() count = 3;
	@Input() gap = 22;
	@Input() options: any = {};

	@ContentChild('slideTemplate') slideTemplate!: TemplateRef<any>;

	private emblaApi?: EmblaCarouselType;

	get slideWidth(): string {
		const gapTotal = this.gap * (this.count - 1);
		const slidePercentage = 100 / this.count;
		const gapPercentage = gapTotal / this.count;
		return `calc(${slidePercentage}% - ${gapPercentage}px)`;
	}

	get carouselOptions() {
		return {
			loop: true,
			align: 'start',
			...this.options,
			slidesToScroll: this.count,
		};
	}

	get emblaPlugins() {
		return [
			Autoplay({
				playOnInit: this.autoplay,
				stopOnInteraction: false,
				delay: this.delay,
			}),
		];
	}

	ngAfterViewInit() {
		this.emblaApi = this.emblaRef.emblaApi;
	}
}
