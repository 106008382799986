import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';

import * as fromRoot from '../../../../store';
import * as fromSession from '../../../../store/session';
import * as fromBooking from '.';
import { withLatestFrom, map, switchMap, tap } from 'rxjs';

import { TimelineItem } from '../models/api/TimelineItem';
import { environment } from 'src/environments/environment';
import { DateTime } from 'ts-luxon';
import { Router } from '@angular/router';
import { DateHelper } from 'src/app/helpers/date';

@Injectable()
export class BookingEffects {
	constructor(
		private actions$: Actions,
		private http: HttpClient,
		private store: Store<fromRoot.State>,
		private _router: Router
	) {}

	loadBookingData$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromBooking.loadBookingData, fromBooking.setDateRange),
			withLatestFrom(
				this.store.select(fromBooking.selectMeta),
				this.store.select(fromSession.getFilters).pipe(map((filters) => filters.company.id))
			),
			switchMap(([action, meta, holdingCompanyId]) => {
				const endDate = DateHelper.formatDate(meta.endDate);
				const startDate = DateHelper.formatDate(meta.startDate);
				return this.http
					.get<
						TimelineItem[]
					>(`${environment.api}/client_portal/timeline/?holdingCompanyId=${holdingCompanyId}&startDate=${startDate}&endDate=${endDate}`)
					.pipe(map((data) => fromBooking.loadBookingDataSuccess({ data })));
			})
		)
	);

	editBooking$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromBooking.editBooking),
				map(({ bookingId }) => this._router.navigateByUrl(`/network/campaign/${bookingId}`))
			),
		{ dispatch: false }
	);
}
