import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TableColumn } from './models/column';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-apple-table',
	templateUrl: './apple-table.component.html',
	styleUrls: ['./apple-table.component.scss'],
})
export class AppleTableComponent {
	public sortColumn: string | null = null;
	public sortDirection: string | null = null;

	@Input()
	public loading: boolean = true;

	@Input()
	public disabled: boolean = false;

	@Input()
	public selectable: boolean = false;

	@Input()
	public selectedItems: any[] = [];

	@Input()
	public allRowsSelected: boolean = false;

	@Input()
	public columns!: TableColumn[];

	@Input()
	public data!: any[];

	@Input()
	public columnPadding: number = 20;

	@Input()
	public textTruncateWidth: number = 200;

	@Input()
	public minimumRowHeight: number = 44;

	@Input()
	public enableHover: boolean = false;

	@Output()
	public sort = new EventEmitter<{
		column: string | null;
		direction: string | null;
	}>();

	@Output()
	public rowSelected = new EventEmitter<any>();

	@Output()
	public rowDeselected = new EventEmitter<any>();

	@Output()
	public allSelected = new EventEmitter<boolean>();

	@Output()
	public rowClicked = new EventEmitter<any>();

	onSort(column: TableColumn) {
		if (column.sortable) {
			if (column.field != this.sortColumn) {
				this.sortColumn = column.field;
				this.sortDirection = 'asc';
			} else {
				switch (this.sortDirection) {
					case null:
						this.sortDirection = 'asc';
						break;
					case 'asc':
						this.sortDirection = 'desc';
						break;
					case 'desc':
						this.sortColumn = null;
						this.sortDirection = null;
						break;
				}
			}
			this.sort.emit({ column: column.sortValue ?? column.field, direction: this.sortDirection });
		}
	}

	isSelected(item: any) {
		return this.selectedItems.includes(item);
	}

	handleRowSelected(checked: any, item: any) {
		if (checked) {
			this.rowSelected.emit(item);
		} else {
			this.rowDeselected.emit(item);
		}
	}

	handleRowClicked(item: any) {
		if (this.disabled) return;
		this.handleRowSelected(!this.isSelected(item), item);
		this.rowClicked.emit(item);
	}

	handleAllRowsSelected() {
		if (this.allRowsSelected) {
			this.allSelected.emit(false);
		} else {
			this.allSelected.emit(true);
		}
		this.allRowsSelected = !this.allRowsSelected;
	}
}
