import { createReducer, on } from '@ngrx/store';
import * as fromBookingCreationActions from './bookings-create.actions';
import { BrandParent } from '../../../shared/models/api/brands/BrandParent';
import { FilterOption } from 'src/app/components/apple/apple-filter/models/filters';
import { Touchpoint, Booking, ShareType, BookingApprover } from '../models';
import { Store } from 'src/app/shared/models/api/store/Store';
import { Location } from '../models/Location';
import { CustomFormField } from '../models/CustomFormField';

export interface BookingCreationState {
	loading: boolean;
	header: {
		filters: FilterOption[];
	};
	filters: {
		page: number;
		pageSize: number;
		search: string | null;
		column: string | null;
		direction: string | null;
		applied: { name: string; values: string[] }[];
	};
	data: {
		currency: string;
		touchpoints: Touchpoint[];
		brands: BrandParent[];
		stores: Store[];
		share: ShareType[];
		approvers: BookingApprover[];
		customFields: CustomFormField[];
		location: Location | null;
	};
	selected: {
		stores: Store[];
	};
	booking: Booking | null;
	saving: boolean;
}

export const initialState: BookingCreationState = {
	loading: true,
	header: {
		filters: [],
	},
	filters: {
		page: 1,
		pageSize: 9,
		search: '',
		applied: [],
		column: null,
		direction: null,
	},
	data: {
		currency: '',
		touchpoints: [],
		brands: [],
		stores: [],
		share: [],
		approvers: [],
		customFields: [],
		location: null,
	},
	selected: {
		stores: [],
	},
	booking: null,
	saving: false,
};

export const reducer = createReducer(
	initialState,
	on(fromBookingCreationActions.loadBookingInformation, (state) => ({
		...state,
		loading: true,
	})),
	on(fromBookingCreationActions.loadBookingInformationSuccess, (state, { info }) => {
		return {
			...state,
			loading: false,
			data: {
				...state.data,
				currency: info.currency,
				touchpoints: info.touchpoints,
				brands: info.brands,
				approvers: info.approvers,
				customFields: info.customFields,
				share: info.share,
				location: info.location,
			},
			selected: {
				stores: info.booking ? info.booking.storesSelected : [],
			},
			booking: info.booking,
		};
	}),
	on(fromBookingCreationActions.loadStoresSuccess, (state, { stores }) => ({
		...state,
		data: {
			...state.data,
			stores,
		},
	})),
	on(fromBookingCreationActions.selectAllStores, (state, { select }) => ({
		...state,
		selected: {
			...state.selected,
			stores: select ? state.data.stores : [],
		},
	})),
	on(fromBookingCreationActions.selectStores, (state, { stores }) => ({
		...state,
		selected: {
			...state.selected,
			stores: [...state.selected.stores, ...stores],
		},
	})),
	on(fromBookingCreationActions.deselectStores, (state, { stores }) => ({
		...state,
		selected: {
			...state.selected,
			stores: state.selected.stores.filter((s) => !stores.some((store) => store.id === s.id)),
		},
	})),
	on(fromBookingCreationActions.setPage, (state, { page, size }) => ({
		...state,
		filters: {
			...state.filters,
			page,
			pageSize: size,
		},
	})),
	on(fromBookingCreationActions.uploadMediaFile, (state, { variant, file, previewUrl }) => {
		const media = [
			...(state.booking?.media.filter((m) => m.screenVariant !== variant) ?? []),
			{ screenVariant: variant, mediaUrl: previewUrl, file },
		];
		return {
			...state,
			booking: {
				...state.booking!,
				media: media,
			},
		};
	}),
	on(fromBookingCreationActions.deleteMediaFile, (state, { variant }) => {
		const media = state.booking && state.booking.media.filter((m) => m.screenVariant !== variant);
		return {
			...state,
			booking: {
				...state.booking!,
				media: media ?? [],
			},
		};
	}),
	on(fromBookingCreationActions.confirmBooking, (state, { bookingId }) => {
		return {
			...state,
			booking: {
				...state.booking!,
				bookingStatus: 'Confirmed',
			},
		};
	}),
	on(fromBookingCreationActions.submitBooking, (state) => ({
		...state,
		saving: true,
	})),
	on(fromBookingCreationActions.submitBookingSuccess, (state) => ({
		...state,
		saving: false,
	})),
	on(fromBookingCreationActions.submitBookingFailure, (state) => ({
		...state,
		saving: false,
	}))
);
