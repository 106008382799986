import { Component, Input } from '@angular/core';
import { PowerNumberChange } from '../../models/view/shared/PowerNumberChange';

@Component({
	selector: 'app-trend-indicator',
	templateUrl: './trend-indicator.component.html',
	styleUrls: ['./trend-indicator.component.scss'],
})
export class InsightsTrendIndicatorComponent {
	@Input()
	public type: string = 'white';

	@Input()
	public data?: PowerNumberChange | null;
}
