import { createSelector, createFeatureSelector } from '@ngrx/store';
import { BookingState } from './booking-view.reducers';
import { TimelineResourceViewModel } from '../models/view/TimelineResourceViewModel';
import { TimelineItemEventViewModel } from '../models/view/TimelineItemEventViewModel';
import { TableHelpers } from 'src/app/helpers/table';

export const getBookingState = createFeatureSelector<BookingState>('booking');

export const selectMeta = createSelector(getBookingState, (state) => state.meta);
export const selectHeader = createSelector(getBookingState, (state) => state.header);
export const selectFilters = createSelector(getBookingState, (state) => state.filters);
export const selectLoading = createSelector(getBookingState, (state) => state.loading);
export const selectZoomLevel = createSelector(getBookingState, (state) => state.header.zoomLevel);
export const selectSearchTerm = createSelector(getBookingState, (state) => state.filters.search);

export const selectTimelineTouchpoints = createSelector(getBookingState, (state) =>
	filterResourcesByTouchpoints(state.bookings.resources, state.filters.applied)
);

export const selectTimelineBookings = createSelector(getBookingState, selectSearchTerm, (state, searchTerm) => {
	const events = filterEventsByFilters(state.bookings.events, state.filters.applied);
	return TableHelpers.search(events, searchTerm ?? '');
});

export const selectTabularBookings = createSelector(getBookingState, selectTimelineBookings, (state, bookings) => {
	const sortedBookings = TableHelpers.sort(
		bookings,
		state.filters.column ?? 'createdDate',
		state.filters.direction ?? 'desc'
	);
	return TableHelpers.paginate(sortedBookings, state.filters.page, state.filters.pageSize);
});

export const selectAllTabularBookings = createSelector(getBookingState, (state) => {
	return state.bookings.events;
});

export const selectTabularBookingCount = createSelector(selectTimelineBookings, (bookings) => bookings.length);

const filterResourcesByTouchpoints = (
	resources: TimelineResourceViewModel[],
	filters: { name: string; values: string[] }[]
) => {
	const touchpoints = filters.find((filter) => filter.name === 'touchpoint');
	if (!touchpoints) {
		return resources;
	}

	const set = new Set(touchpoints.values);
	return resources.filter((resource: TimelineResourceViewModel) => set.has(resource.name));
};

const filterEventsByFilters = (events: TimelineItemEventViewModel[], filters: { name: string; values: string[] }[]) => {
	let filteredEvents = events;

	const touchpoints = filters.find((filter) => filter.name === 'touchpoint');
	if (touchpoints) {
		const set = new Set(touchpoints.values);
		filteredEvents = filteredEvents.filter((event) => set.has(event.touchpointName));
	}

	const brands = filters.find((filter) => filter.name === 'brand');
	if (brands) {
		const set = new Set(brands.values);
		filteredEvents = filteredEvents.filter((event) => event.brands.some((brand) => set.has(brand.name)));
	}

	return filteredEvents;
};
