<bryntum-scheduler
	[zoomOnMouseWheel]="false"
	[resources]="resources"
	[eventLayout]="'stack'"
	[managedEventSizing]="false"
	[rowHeight]="80"
	[events]="events"
	[startDate]="start ?? ''"
	[endDate]="end ?? ''"
	[barMargin]="5"
	[columns]="columns"
	[eventRenderer]="eventRenderer"
	[scheduleMenuFeature]="false"
	[timeAxisHeaderMenuFeature]="false"
	[cellMenuFeature]="false"
	[eventMenuFeature]="false"
	[eventEditFeature]="false"
	[regionResizeFeature]="false"
	[timeRangesFeature]="{
		showCurrentTimeLine: true,
		showHeaderElements: false,
	}"
	[eventTooltipFeature]="eventTooltipTemplateRenderer"
	[listeners]="listeners"
	[hoverCls]="'none'"
	[treeFeature]="false"
	[readOnly]="true"
	[allowOverlap]="false"
	[bbar]="bottomBar">
</bryntum-scheduler>
