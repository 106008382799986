<div class="container">
	<label *ngIf="label" class="label">{{ label }}</label>
	<div class="datepicker">
		<div class="input">
			<div class="display" (click)="toggleCalendar()" [ngClass]="{ disabled: disabled }">
				<div class="text">{{ displayDate }}</div>
				<img class="icon" src="assets/icons/calendar.svg" />
			</div>
		</div>
		<div class="calendar" [class.hidden]="!showCalendar">
			<div class="month-container" [style.grid-template-columns]="gridTemplate">
				<div *ngFor="let monthIndex of monthIndices" [style.padding-left]="monthIndex > 0 ? '20px' : '0'">
					<div class="header">
						<button *ngIf="monthIndex === 0" class="button back" (click)="setHeaderNav(-1)">
							<i class="icon fa-solid fa-chevron-left"></i>
						</button>
						<div class="current-month-year">
							<ng-container [ngSwitch]="granularity">
								<span *ngSwitchCase="'month'" class="year">{{ getYear(monthIndex) }}</span>
								<span *ngSwitchCase="'year'" class="year">{{ getYearRangeText(monthIndex) }}</span>
								<ng-container *ngSwitchDefault>
									<span class="month">{{ getMonthName(monthIndex) }}</span>
									<span class="year">{{ getYear(monthIndex) }}</span>
								</ng-container>
							</ng-container>
						</div>
						<button *ngIf="monthIndex === monthCount - 1" class="button front" (click)="setHeaderNav(1)">
							<i class="icon fa-solid fa-chevron-right"></i>
						</button>
					</div>

					<div class="days" *ngIf="granularity === 'day'">
						<div class="cell" *ngFor="let day of days">
							<span class="cellitem">{{ day.slice(0, 2) }}</span>
						</div>
					</div>

					<div class="main" [class.month-grid]="granularity === 'month'" [class.year-grid]="granularity === 'year'">
						<div
							class="cell"
							*ngFor="let item of getMonthDetails(monthIndex)"
							[class.selected]="isSelectedDate(item)"
							[class.start-date]="isStartDate(item)"
							[class.end-date]="isEndDate(item)"
							[class.disabled]="isDateDisabled(item)"
							[class.in-range]="isInRange(item)"
							[class.hovered]="isHovered(item)"
							[class.iscurrent]="granularity === 'day' && isCurrentDay(item)"
							[class.other-month]="granularity === 'day' && item.month !== 0"
							[class.current]="granularity === 'day' && item.month === 0"
							(click)="onDateClick(item)"
							(mouseenter)="onDateHover(item)">
							<span class="cellitem">
								{{
									granularity === 'month'
										? months[item.dayString - 1]
										: granularity === 'year'
											? item.dayString
											: item.dayString
								}}
							</span>
						</div>
					</div>
				</div>
			</div>
			<div class="options" *ngIf="options && granularity === 'day'">
				<div class="item" *ngFor="let option of periodOptions" (click)="onOptionClick(option.value)">
					{{ option.name }}
				</div>
			</div>
		</div>
	</div>
</div>
