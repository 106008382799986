import { Component, Input } from '@angular/core';
import { DonutGraph } from '../../models';
import { DataLegend } from '../data-legend/models';

@Component({
	selector: 'app-insights-donut-graph',
	templateUrl: './insight-donut-graph.component.html',
	styleUrls: ['./insight-donut-graph.component.scss'],
})
export class InsightDonutGraphComponent {
	@Input()
	public loading: boolean = true;

	@Input()
	public data?: DonutGraph | null;

	@Input()
	public theme: 'blue' | 'white' = 'blue';

	@Input()
	public showLegend: boolean = false;

	getGradient(): string {
		if (!this.data?.series) return '';

		let currentPercentage = 0;
		const gradientParts: string[] = [];

		const sortedSeries = [...this.data.series].sort((a, b) => (b.percentage || 0) - (a.percentage || 0));

		sortedSeries.forEach((series) => {
			if (series.percentage) {
				const nextPercentage = currentPercentage + series.percentage;
				gradientParts.push(`${series.color} ${currentPercentage}% ${nextPercentage}%`);
				currentPercentage = nextPercentage;
			}
		});

		return `conic-gradient(${gradientParts.join(', ')})`;
	}

	getSkeletonLoaderTheme(): 'progress-dark' | 'progress-light' {
		if (this.theme === 'blue') {
			return 'progress-dark';
		} else {
			return 'progress-light';
		}
	}

	getSkeletonLoaderBackground(): string | null {
		if (this.theme === 'blue') {
			return 'var(--skeleton-loader)';
		} else {
			return null;
		}
	}
}
