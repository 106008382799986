import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
	selector: 'app-apple-error',
	templateUrl: './apple-error.component.html',
	styleUrls: ['./apple-error.component.scss'],
})
export class AppleErrorComponent implements OnInit {
	@Input() title!: string;
	@Input() message!: string;

	sanitized!: SafeHtml;

	constructor(private sanitizer: DomSanitizer) {}

	ngOnInit(): void {
		this.sanitized = this.sanitizer.bypassSecurityTrustHtml(this.message.replace(/<a /g, '<a class="link"'));
	}
}
