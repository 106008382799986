import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
	selector: 'app-form-field',
	templateUrl: './form-field.component.html',
	styleUrls: ['./form-field.component.scss'],
})
export class FormFieldComponent {
	@Input() public control!: AbstractControl;
	@Input() public customValidators: { [key: string]: string } = {};

	hasError(): boolean {
		return this.control && this.control.invalid && this.control.touched;
	}

	get errorKey() {
		return this.control && this.control.errors && Object.keys(this.control.errors)[0];
	}

	getErrorMessage(errorKey: string): string {
		return this.customValidators[errorKey] || `Invalid input`;
	}
}
