import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { DateRange, FilterChange, FilterOption, Option } from '../../insights/components/filters/models';
import { DonutGraph, LineGraph, PowerNumber } from '../models';
import { EmissionsTable } from './models';
import { DataLegend } from '../components/data-legend/models';
import { EmissionsPerMinuteComponent } from './components/emissions-per-minute/emissions-per-minute.component';

import * as fromRoot from '../../../store';
import * as fromSustainability from './state';
import * as fromSession from '../../../store/session';
import { Observable, tap } from 'rxjs';

@UntilDestroy()
@Component({
	selector: 'app-insights-sustainability',
	templateUrl: './sustainability.component.html',
	styleUrls: ['./sustainability.component.scss'],
})
export class InsightsSustainabilityComponent implements OnInit {
	public holdingCompany: number | undefined;

	public loading$: Observable<boolean>;
	public filters$: Observable<{ touchpoints: Option[] } | null>;

	public powerNumbers$: Observable<PowerNumber[] | null>;
	public totalEmissions$: Observable<DonutGraph | null>;
	public emissionsGraph$: Observable<LineGraph | null>;
	public emissionsTable$: Observable<EmissionsTable[] | null>;

	public filterOptions: FilterOption[] = [
		{
			name: 'touchpoints',
			label: 'Touchpoints',
			type: 'multi-select',
			placeholder: 'All Touchpoints',
			options: [],
		},
		{
			name: 'period',
			label: 'Period',
			type: 'date-range',
		},
	];

	constructor(private store: Store<fromRoot.State>) {
		this.loading$ = this.store.select(fromSustainability.selectLoading);
		this.filters$ = this.store.select(fromSustainability.selectAvailableFilters).pipe(untilDestroyed(this));

		this.powerNumbers$ = this.store.select(fromSustainability.selectPowerNumbers);
		this.totalEmissions$ = this.store.select(fromSustainability.selectTotalEmissions);
		this.emissionsGraph$ = this.store.select(fromSustainability.selectEmissionsGraph);
		this.emissionsTable$ = this.store.select(fromSustainability.selectEmissionsTable);
	}

	ngOnInit(): void {
		this.store
			.select(fromSession.getFilters)
			.pipe(untilDestroyed(this))
			.subscribe((filters) => {
				this.holdingCompany = filters.company.id;
				this.store.dispatch(fromSustainability.loadFilterOptions());
				this.loadSustainability();
			});

		this.filters$.pipe(untilDestroyed(this)).subscribe((filters) => {
			if (filters) {
				const touchpointsFilter = this.filterOptions.find((option) => option.name === 'touchpoints');

				if (touchpointsFilter && touchpointsFilter.options) {
					touchpointsFilter.options = filters.touchpoints.map((touchpoint) => ({
						id: touchpoint.id,
						name: touchpoint.name,
					}));
				}
			}
		});
	}

	private loadSustainability(): void {
		if (this.holdingCompany !== undefined) {
			this.store.dispatch(
				fromSustainability.loadSustainability({
					holdingCompanyId: this.holdingCompany,
				})
			);
		}
	}

	onFilterChange(filters: FilterChange): void {
		if (filters.field === 'period') {
			const periodValue = filters.value as DateRange;
			if (periodValue.start && periodValue.end) {
				this.store.dispatch(
					fromSustainability.setFilter({
						filterName: 'period',
						filterValue: {
							start: periodValue.start,
							end: periodValue.end,
						},
					})
				);
			}
		} else if (filters.field === 'touchpoints') {
			const touchpointsValue = filters.value as Option[];
			const touchpointIds = touchpointsValue.map((touchpoint) => Number(touchpoint.id));
			this.store.dispatch(
				fromSustainability.setFilter({
					filterName: 'touchpoints',
					filterValue: touchpointIds,
				})
			);
		}
	}
}
