import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { first, Observable } from 'rxjs';
import { Booking } from '../../models';
import * as fromBookingCreationSelectors from '../../state/bookings-create.selectors';
import { Store } from '@ngrx/store';
import * as fromRoot from 'src/app/store';
import { DateHelper } from 'src/app/helpers/date';

@UntilDestroy()
@Component({
	selector: 'app-media-schedule',
	templateUrl: './media-schedule.component.html',
	styleUrl: './media-schedule.component.scss',
})
export class MediaScheduleComponent {
	public start = new Date();
	public end = new Date();
	public events: any[] = [];
	public zoomOptions: { id: number; name: any }[] = [
		{ id: 1, name: 'Daily View' },
		{ id: 2, name: 'Hourly View' },
	];

	public booking$: Observable<Booking | null> = this._store
		.select(fromBookingCreationSelectors.selectBooking)
		.pipe(untilDestroyed(this));

	constructor(private _store: Store<fromRoot.State>,) {}

	ngOnInit(): void {
		this.booking$.pipe(first()).subscribe((booking) => {
			if (booking) {
				this.start = new Date(booking.start);
				this.end = new Date(booking.end);
			}

			const events = [
				{
					resourceId: 'l1',
					startDate: this.start,
					endDate: this.end,
					name: 'Render for entire Booking Duration',
				}
			];

			const currentDate = new Date(this.start);
			while (new Date(currentDate).setHours(0, 0, 0, 0) <= new Date(this.end).setHours(0, 0, 0, 0)) {
				const startOfDay = new Date(currentDate);
				startOfDay.setHours(0, 0, 0, 0);

				const endOfDay = new Date(currentDate);
				endOfDay.setHours(6, 30, 0, 0);

				events.push({
					resourceId: 'l2',
					startDate: new Date(startOfDay),
					endDate: new Date(endOfDay),
					name: 'This is from Midnight to 6:30am',
				});

				if (currentDate.getDay() === 6) {
					const saturdayStart = new Date(currentDate);
					saturdayStart.setHours(0, 0, 0, 0);

					const sundayEnd = new Date(currentDate);
					sundayEnd.setDate(sundayEnd.getDate() + 1);
					sundayEnd.setHours(23, 59, 59, 999);

					events.push({
						resourceId: 'l3',
						startDate: new Date(saturdayStart),
						endDate: new Date(sundayEnd),
						name: 'This is from Saturday to Sunday',
					});
				}

				currentDate.setDate(currentDate.getDate() + 1);
			}

			this.events = events;
		});
	}

	public columns = [
		{
			text: 'Priority Order',
			htmlEncode: false,
			width: 150,
			renderer: ({ record }: { record: any }) => {
				return `<div style="color: #64748B; font-size: 12px; font-weight: 500;">${record.name}</div>`;
			}
		}
	];

	public resources = [
		{ id: 'l1', name: 'Default Content' },
		{ id: 'l2', name: 'Store Associate Content' },
		{ id: 'l3', name: 'Promotion 1' },
	];

	public eventRenderer = ({ eventRecord, renderData }: { eventRecord: any; renderData: any; }) => {
		renderData.style = `
		background-color: #D6E8FB; 
		color: black; 
		font-weight: 500;
		font-size: 14px;
		font: Roboto;
        border-left: 5px solid #0071E3; 
        border-radius: 5px; 
        padding: 10px 0px
        `;

		return `
		<div class="event-item">
			<div class="title">${eventRecord.name}</div>
			<div class="timestamp">${DateHelper.formatDate(eventRecord.startDate)} - ${DateHelper.formatDate(eventRecord.endDate)}</div>
		</div>
		`;
	};

	public viewPresetConfig = {
		hourlyViewPreset: {
			base: 'minuteAndHour',
			tickWidth: 70,
			headers: [
				{
					unit: 'hour',
					renderer: (startDate: Date): string => {
						const dateFormatoptions: Intl.DateTimeFormatOptions = {
							weekday: 'short',
							month: '2-digit',
							day: '2-digit',
							hour: 'numeric',
							hour12: true,
						};
						const formattedDate = startDate.toLocaleDateString(undefined, dateFormatoptions).replace(',', '');
						return `<div style="color: #64748B; font-size: 14px;">${formattedDate}</div>`;
					},
				},
				{
					unit: 'minute',
					increment: 30,
					renderer: (startDate: Date): string => {
						const startMinutes = startDate.getMinutes();
						const formattedMinutes = startMinutes === 0 ? '00' : '30';
						return `<div style="color: #64748B; font-size: 12px; font-weight: 400;">${formattedMinutes}</div>`;
					},
				},
			],
		},
		dailyViewPreset: {
			base: 'hourAndDay',
			tickWidth: 150,
			headers: [
				{
					unit: 'day',
					renderer: (startDate: Date): string => {
						return `<div style="color: #64748B; font-size: 14px; font-weight: 400;">
									${startDate.toLocaleDateString(undefined, { weekday: 'short', month: '2-digit', day: '2-digit' }).replace(',', '')}
								</div>`;
					},
				}
			],
		},
	};

	public currentPreset = this.viewPresetConfig.dailyViewPreset;

	onZoomChanged(zoomLevel: any): void {
		this.currentPreset = zoomLevel.value.name === 'Daily View' ? this.viewPresetConfig.dailyViewPreset : this.viewPresetConfig.hourlyViewPreset;
	}

}
