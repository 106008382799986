import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromRoot from '../../../../store';

@Component({
	selector: 'app-apple-forgot-password-confirmation',
	templateUrl: './apple-forgot-password-confirmation.component.html',
	styleUrls: ['./apple-forgot-password-confirmation.component.scss'],
})
export class AppleForgotPasswordConfirmationComponent {
	public email$: Observable<string | null>;

	constructor(private store: Store<fromRoot.State>) {
		this.email$ = this.store.select((state) => state.user.details.email);
	}
}
