import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, withLatestFrom } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SustainabilityFiltersResponse, SustainabilityResponse } from '../models/api/Response';
import * as SustainabilityActions from './sustainability.actions';
import * as fromSustainability from './sustainability.selectors';
import * as fromSession from 'src/app/store/session';
import * as fromRoot from 'src/app/store';
import { Store } from '@ngrx/store';
import { DateHelper } from 'src/app/helpers/date';

@Injectable()
export class SustainabilityEffects {
	constructor(
		private actions$: Actions,
		private http: HttpClient,
		private store: Store<fromRoot.State>
	) {}

	loadFilterOptions$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(SustainabilityActions.loadFilterOptions),
			withLatestFrom(this.store.select(fromSession.getFilters)),
			switchMap(([action, sessionFilters]) => {
				if (!sessionFilters || !sessionFilters.company) {
					return of(
						SustainabilityActions.loadFilterOptionsFailure({
							error: 'Retailer not specified, cannot load filter options. Please ensure a retailer is selected.',
						})
					);
				}

				const url = `${environment.api}/client_portal/insights/sustainability/filters?holdingCompanyId=${sessionFilters.company.id}`;

				return this.http.get<SustainabilityFiltersResponse>(url).pipe(
					map((response) => SustainabilityActions.loadFilterOptionsSuccess({ touchpoints: response.touchpoints })),
					catchError((error) =>
						of(
							SustainabilityActions.loadFilterOptionsFailure({
								error:
									'Unable to load filter options. Please try again later. If the issue persists, please contact support.',
							})
						)
					)
				);
			})
		);
	});

	loadSustainability$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(SustainabilityActions.loadSustainability, SustainabilityActions.setFilter),
			withLatestFrom(this.store.select(fromSession.getFilters), this.store.select(fromSustainability.selectFilters)),
			switchMap(([action, sessionFilters, sustainabilityFilters]) => {
				if (!sessionFilters || !sessionFilters.company) {
					return of(
						SustainabilityActions.loadSustainabilityFailure({
							error: 'Retailer not specified, cannot load sustainability data. Please ensure a retailer is selected.',
						})
					);
				}

				let url = `${environment.api}/client_portal/insights/sustainability?holdingCompanyId=${sessionFilters.company.id}`;

				if (sustainabilityFilters.period.start && sustainabilityFilters.period.end) {
					url += `&start=${DateHelper.formatDate(sustainabilityFilters.period.start)}&end=${DateHelper.formatDate(sustainabilityFilters.period.end)}`;
				}

				if (sustainabilityFilters.touchpoints && sustainabilityFilters.touchpoints.length > 0) {
					url += `&touchpoints=${sustainabilityFilters.touchpoints.join(',')}`;
				}

				return this.http.get<SustainabilityResponse>(url, {}).pipe(
					map((response) => {
						return SustainabilityActions.loadSustainabilitySuccess({
							emissionTrend: response.emissionTrend,
							emissionsTable: response.emissionsTable.rows,
							powerNumbers: response.powerNumbers,
							totalEmissions: response.totalEmissions,
						});
					}),
					catchError((error) => {
						return of(
							SustainabilityActions.loadSustainabilityFailure({
								error:
									'Unable to display the Sustainability metrics at this time, please try again later. If the issue persists, please contact support.',
							})
						);
					})
				);
			})
		);
	});
}
