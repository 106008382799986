import { createSelector, createFeatureSelector } from '@ngrx/store';
import { TouchpointDrilldownState } from './touchpoint-drilldown.reducers';

export const getTouchpointDrilldownState = createFeatureSelector<TouchpointDrilldownState>('touchpointDrilldown');
export const selectHeader = createSelector(getTouchpointDrilldownState, (state) => state.header);
export const selectFilters = createSelector(getTouchpointDrilldownState, (state) => state.filters);
export const selectLoading = createSelector(getTouchpointDrilldownState, (state) => state.loading);
export const selectInitializing = createSelector(getTouchpointDrilldownState, (state) => state.initializing);
export const selectFilteredTouchpoints = createSelector(getTouchpointDrilldownState, (state) => state.data.displays);
export const selectFilteredTotalCount = createSelector(getTouchpointDrilldownState, (state) => state.data.total_count);
