import { createReducer, on } from '@ngrx/store';
import { Option } from '../../components/filters/models';
import { CampaignMedia, LineGraph } from '../../models';
import { CampaignOverview, StoreData } from '../models/index';
import * as CampaignActions from './campaign.actions';
import { PowerNumber } from '../../models';

export interface CampaignState {
	initializing: boolean;
	loading: boolean;
	header: {
		filters: {
			campaigns: Option[];
			touchpoints: Option[];
			companies: Option[];
			brands: Option[];
			period: {
				start: Date | null;
				end: Date | null;
			};
		};
	};
	filters: {
		campaign: string;
		touchpoints: number[];
		companies: number[];
		stores: {
			page: number;
			pageSize: number;
		};
		period: {
			start: Date | null;
			end: Date | null;
		};
	};
	data: {
		details: CampaignOverview;
		stores: StoreData[];
		mediaGallery: CampaignMedia;
		powerNumbers: PowerNumber[];
		uptime: LineGraph;
	};
	error: string | null;
}

export const initialState: CampaignState = {
	initializing: true,
	loading: true,
	header: {
		filters: {
			touchpoints: [],
			campaigns: [],
			companies: [],
			brands: [],
			period: {
				start: null,
				end: null,
			},
		},
	},
	filters: {
		touchpoints: [],
		campaign: '',
		companies: [],
		stores: {
			page: 1,
			pageSize: 5,
		},
		period: {
			start: null,
			end: null,
		},
	},
	data: {
		details: {
			touchpoint: '',
			category: '',
			image: '',
			items: [],
			status: '',
		},
		stores: [],
		mediaGallery: {
			header: '',
			subHeader: '',
			body: [],
		},
		powerNumbers: [],
		uptime: {
			header: '',
			series: [],
			x: [],
			y: [],
		},
	},
	error: null,
};

export const campaignReducer = createReducer(
	initialState,
	on(CampaignActions.loadCampaign, (state) => ({
		...state,
		loading: true,
		error: null,
	})),
	on(CampaignActions.loadCampaignSuccess, (state, { details, stores, mediaGallery, powerNumbers, uptime }) => ({
		...state,
		initializing: false,
		loading: false,
		header: {
			...state.header,
		},
		data: {
			details,
			mediaGallery,
			powerNumbers,
			uptime,
			stores: stores.map((item) => ({
				id: item.store.id,
				storeName: item.store.name,
				storeAddress: item.store.address,
				storeNumber: item.store.number,
				periodPlays: item.periodPlays,
				uptimePercentage: item.uptime.percentage,
				uptimeValue: item.uptime.value,
				coordinates: item.store.coordinates,
			})),
		},
		error: null,
	})),
	on(CampaignActions.loadCampaignFailure, (state, { error }) => ({
		...state,
		initializing: false,
		loading: false,
		error: error,
	})),
	on(CampaignActions.loadFiltersSuccess, (state, { touchpoints, campaigns, companies }) => ({
		...state,
		header: {
			...state.header,
			filters: {
				...state.header.filters,
				touchpoints,
				campaigns,
				companies,
			},
		},
		error: null,
	})),
	on(CampaignActions.loadFiltersFailure, (state, { error }) => ({
		...state,
		error: error,
	})),
	on(CampaignActions.setFilter, (state, { filterName, filterValue }) => ({
		...state,
		filters: {
			...state.filters,
			[filterName]: filterValue,
		},
	})),
	on(CampaignActions.setPage, (state, { page, size }) => ({
		...state,
		filters: {
			...state.filters,
			stores: { page, pageSize: size },
		},
	}))
);
