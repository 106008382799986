import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss'],
})

export class InsightsCardComponent {
	@Input() public type: string = 'white';
}
