import { createAction, props } from '@ngrx/store';
import { TimelineItem } from '../models/api/TimelineItem';

// Search
export const setSearchText = createAction('[Booking] Set Search Text', props<{ searchText: string }>());

// Zoom Level
export const setZoomLevel = createAction('[Booking] Set Zoom level', props<{ zoomLevel: string }>());
export const setZoomLevelVisibility = createAction('[Booking] Set Zoom Level Visibility', props<{ show: boolean }>());

// Sort
export const setSort = createAction('[Booking] Set Sort', props<{ column: string | null; direction: string | null }>());

//Pagination
export const setPage = createAction('[Booking] Set Page', props<{ page: number; size: number }>());

//Filters
export const setAppliedFilters = createAction(
	'[Booking] Set Applied Filters',
	props<{ filters: { name: string; values: string[] }[] }>()
);
export const resetAppliedFilters = createAction('[Booking] Reset Applied Filters');

// Date Range
export const setDateRange = createAction('[Booking] Set Date Range', props<{ start: Date; end: Date }>());

// Data
export const loadBookingData = createAction('[Booking] Load Boooking Data', props<{ holdingCompanyId: number }>());
export const loadBookingDataSuccess = createAction(
	'[Booking] Load Timeline Data Success',
	props<{ data: TimelineItem[] }>()
);

//Edit
export const editBooking = createAction('[Booking] Edit Booking', props<{ bookingId: string }>());
