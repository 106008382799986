<div class="touchpoint-card">
	<div class="left">
		<div class="image-container">
			<img [src]="imageUrl" />
		</div>
	</div>

	<div class="middle">
		<div class="top">
			<div class="information">
				<div class="name">{{ name }}</div>
				<div class="category">{{ category }}</div>
			</div>
			<div class="metrics">
				<ng-container *ngFor="let item of metrics; let i = index">
					<div class="metrics-item">
						<div class="metrics-item-title">{{ item.name }}</div>
						<div class="metrics-item-content">
							<span class="metrics-item-value">{{ item.number }}</span>
							<span class="metrics-item-change">
								<span class="metrics-item-change-value">{{ item.percentageGrowth }}</span>
								<span class="metrics-item-change-period">{{ item.growthPeriod }}</span>
							</span>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
		<div class="bottom">
			<div class="button-container dropdown">
				<app-button class="primary thin rounded" (click)="this.tooltip = !this.tooltip">
					<div class="content">
						<div class="icon left">
							<img class="booking-icon" src="../../../assets/information.svg" />
						</div>
						<div label="label">Info</div>
					</div>
				</app-button>
				<div class="info">
					<app-apple-tooltip [open]="tooltip">
						<ng-container tooltip-content>
							<div class="tooltip-item" *ngFor="let info of information">
								<p class="tooltip-item-name">
									{{ info.name }}
								</p>
								<div class="tooltip-item-value" *ngFor="let value of info.values">
									<ng-container *ngIf="value.type == 'string'">
										<p class="tooltip-item-value">{{ value.text }}</p>
									</ng-container>
									<ng-container *ngIf="value.type == 'link'">
										<a class="tooltip-item-value link" href="{{ value.data }}">{{ value.text }}</a>
									</ng-container>
								</div>
							</div>
						</ng-container>
					</app-apple-tooltip>
				</div>
			</div>
			<div class="button-container">
				<app-button class="primary thin rounded" *ngIf="variantCount" (click)="toggleVariantVisibility()">
					<div class="content">
						<div label="label">{{ variantCount + ' Variants' }}</div>
						<div class="icon right">
							<img [ngClass]="{ active: expanded }" src="../../../assets/arrow-down-white.svg" />
						</div>
					</div>
				</app-button>
			</div>
		</div>
	</div>

	<div class="right">
		<div class="previews" [style.visibility]="expanded ? 'hidden' : 'initial'">
			<div
				class="carousel-control left"
				[style.visibility]="navigation && !expanded && !atGalleryStart ? 'initial' : 'hidden'">
				<img class="image" src="../../../assets/arrow-left.svg" (click)="back()" />
			</div>
			<div #container class="carousel-container">
				<div #carousel class="items">
					<div
						class="item"
						[ngClass]="orientation"
						[ngStyle]="width()"
						*ngFor="let campaign of pages[index]; let i = index">
						<a
							*ngIf="!campaign.isSplash"
							class="displays"
							routerLink="/network/content"
							[queryParams]="{
								touchpoint: id,
								category: categoryId,
								media: campaign.mediaId,
								variant: variantId,
								result: campaign.isResult,
							}"
							(mouseenter)="showNpcPopup[i] = true"
							(mouseleave)="showNpcPopup[i] = false">
							<span class="units">
								<ng-container [ngPlural]="campaign.numDisplays">
									<ng-template ngPluralCase="=1">{{ campaign.numDisplays }} Unit</ng-template>
									<ng-template ngPluralCase="other">{{ campaign.numDisplays }} Units</ng-template>
								</ng-container>
							</span>
							<span class="npc" *ngIf="campaign.numNpcDisplays > 0">
								<img src="../../../assets/content-warning.svg" [hidden]="showNpcPopup[i]" />
								<img src="../../../assets/content-warning-white.svg" [hidden]="!showNpcPopup[i]" />
							</span>
						</a>
						<div class="splash" *ngIf="campaign.isSplash">Splash Content</div>
						<div *ngIf="campaign.numNpcDisplays > 0" class="npc-issue">
							<div [hidden]="!showNpcPopup[i]" [ngStyle]="getTooltipStyle()" class="tooltip-content">
								<ng-container [ngPlural]="campaign.numNpcDisplays">
									<ng-template ngPluralCase="=1">{{ campaign.numNpcDisplays }} Issue</ng-template>
									<ng-template ngPluralCase="other">{{ campaign.numNpcDisplays }} Issues</ng-template>
								</ng-container>
							</div>
						</div>
						<video
							[ngStyle]="height()"
							class="video"
							inViewport
							[inViewportOptions]="{ threshold: [0] }"
							(inViewportAction)="onVideoVisible($event)"
							*ngIf="campaign.videoUrl"
							[loop]="true"
							[muted]="true"
							controls
							[src]="campaign.videoUrl"></video>
						<img
							*ngIf="campaign.videoUrl === ''"
							[src]="
								orientation === 'portrait'
									? '../../../../assets/no-preview-portrait.svg'
									: '../../../../assets/no-preview-landscape.svg'
							"
							alt="" />
					</div>
				</div>
			</div>
			<div
				class="carousel-control right"
				[style.visibility]="navigation && !expanded && !atGalleryEnd ? 'initial' : 'hidden'">
				<img class="image" src="../../../assets/arrow-right.svg" (click)="next()" />
			</div>
		</div>
	</div>
</div>
