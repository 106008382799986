import { Component, Input, Renderer2, OnChanges, ChangeDetectorRef, ElementRef } from '@angular/core';
import { TableauViz } from '../../../../assets/js/tableau';

import * as fromRoot from '../../../store';
import * as fromSession from '../../../store/session';

import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { UntilDestroy } from '@ngneat/until-destroy';
import { take } from 'rxjs';

@UntilDestroy()
@Component({
	selector: 'app-report-tableau',
	templateUrl: './tableau.component.html',
	styleUrls: ['./tableau.component.scss'],
})
export class ReportTableauComponent implements OnChanges {
	public loading = true;

	@Input()
	public data: string | undefined = undefined;

	constructor(
		private store: Store<fromRoot.State>,
		private http: HttpClient,
		private renderer: Renderer2,
		private elementRef: ElementRef,
		private cdf: ChangeDetectorRef
	) {}

	ngOnChanges() {
		this.loading = true;
		this.store
			.select(fromSession.getFilters)
			.pipe(take(1))
			.subscribe(async (filters) => {
				const container = this.elementRef.nativeElement.querySelector('#viz-container');
				if (container && this.data) {
					container.innerHTML = '';
					await this.renderTableau(container, this.data, filters.company.name);
				}
			});
	}

	async renderTableau(container: HTMLElement, url: string | undefined, company: string) {
		const token = await this.http.get<any>(environment.api + '/client_portal/tableau_cloud_token/').toPromise();
		if (token) {
			const viz = new TableauViz();
			viz.id = 'report-viz';
			viz.style.display = 'block';
			viz.src = url;
			viz.token = token.tableau_token;
			viz.style.height = 'calc(100vh - 60px)';
			viz.style.width = '1366px';
			viz.toolbar = 'hidden';
			viz.addEventListener('firstinteractive', () => {
				this.loading = false;
				this.cdf.detectChanges();
			});

			// Jono was here. This is a hack to get the height and width of the viz to be the same as specified in the URL
			const overrides = new URLSearchParams(url?.split('?')[1]);
			const height = overrides.get('height');
			const width = overrides.get('width');
			if (height) {
				viz.style.height = height;
			}
			if (width) {
				viz.style.width = width;
			}

			const filter = this.renderer.createElement('viz-filter');
			filter.setAttribute('field', 'Holding Company');
			filter.setAttribute('value', company);
			viz.appendChild(filter);

			if (container) {
				this.renderer.appendChild(container, viz);
			}
		}
	}
}
