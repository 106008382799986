import { Component, Input } from '@angular/core';

interface SkeletonTheme {
	height: string;
	width: string;
	'background-color'?: string;
	'border-radius'?: string;
}

@Component({
	selector: 'app-skeleton-loader',
	templateUrl: './skeleton-loader.component.html',
	styleUrl: './skeleton-loader.component.scss',
})
export class SkeletonLoaderComponent {
	@Input()
	public height: string = '30px';

	@Input()
	public width: string = '30px';

	@Input()
	public backgroundColor: string | null = null;

	@Input()
	public borderRadius: string = '5px';

	@Input()
	public appearance: 'circle' | 'line' = 'line';

	@Input()
	public animation: 'progress-dark' | 'progress-light' = 'progress-light';

	getTheme(): SkeletonTheme {
		const theme: SkeletonTheme = {
			height: this.height,
			width: this.width,
		};

		if (this.backgroundColor) {
			theme['background-color'] = this.backgroundColor;
		}

		if (this.borderRadius) {
			theme['border-radius'] = this.borderRadius;
		}

		return theme;
	}
}
