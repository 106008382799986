import { Component, inject, OnInit, Type } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FeatureFlagService } from 'src/app/services/unleash.service';
import { Store } from '@ngrx/store';

import * as fromRoot from '../../store';
import * as fromSession from '../../store/session';

import { TabNavigationItem } from '../../shared/components/tab-navigation/models/TabNavigationItem';
import { InsightsRevenueComponent } from './revenue/revenue.component';
import { InsightsCampaignComponent } from './campaign/campaign.component';
import { ReportTableauComponent } from 'src/app/pages/report/tableau/tableau.component';
import { combineLatest, map } from 'rxjs';
import { InsightsSustainabilityComponent } from './sustainability/sustainability.component';

@UntilDestroy()
@Component({
	selector: 'app-insights',
	templateUrl: './insights.component.html',
	styleUrls: ['./insights.component.scss'],
})
export class InsightsComponent implements OnInit {
	constructor() {}
	ngOnInit() {}
}
