import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FilterOption } from './models/filters';

@Component({
	selector: 'app-apple-filter',
	templateUrl: './apple-filter.component.html',
	styleUrls: ['./apple-filter.component.scss'],
})
export class AppleFilterComponent {
	@Input()
	public tableFilters!: FilterOption[];

	@Input()
	public icon!: string;

	@Input()
	public label!: string;

	@Input()
	public loading!: boolean;

	@Output()
	public stateChange: EventEmitter<any> = new EventEmitter<any>();

	public filters: { name: string; values: string[] }[] = [];
	public filtersOpen: boolean = false;

	toggleFilterDropdown() {
		this.filtersOpen = !this.filtersOpen;
	}

	onFiltersChanged(event: any) {
		const filter = {
			name: event.name,
			values: Array.isArray(event.value)
				? event.value.map((value: any) => value.name)
				: event.value
					? [event.value.name]
					: [],
		};

		this.filters = this.filters
			.filter((item: { name: string; values: string[] }) => item.name !== filter.name)
			.concat(filter.values.length > 0 ? filter : []);
		this.stateChange.emit({ filters: this.filters });
	}

	@HostListener('document:click', ['$event.target'])
	closeTooltip(target: any) {
		if (!target.closest('.ng-value-icon') && !target.closest('.filter')) {
			this.filtersOpen = false;
		}
	}
}
