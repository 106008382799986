import { Mutex } from 'async-mutex';
import { Subject } from 'rxjs';
import { WebSocketSubject } from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';

import { Injectable, Type } from '@angular/core';
import { WebSocketResponse } from '../shared/models/api/WebSocketResponse';
import { Screenshot } from '../shared/models/api/Screenshot';

@Injectable({
	providedIn: 'root',
})
export class ScreenshotService {
	private _connected = false;
	private _lock = new Mutex();
	private _data = new Subject<WebSocketResponse<Screenshot>>();
	private _socket$!: WebSocketSubject<any>;

	public screenshots = this._data.asObservable();

	connect(token: string) {
		this._lock.runExclusive(async () => {
			if (!this._connected) {
				this._connected = true;

				this._socket$ = new WebSocketSubject<any>(`${environment.ws}/ws/screenshot/update/?token=${token}`);

				this._socket$.subscribe((data) => {
					this._data.next(data);
				});
			}
		});
	}

	requestScreenshot(serial: string) {
		const data = {
			type: 'request_screenshot',
			display_serial: serial,
		};

		this._socket$.next(data);
	}

	requestBulkScreenshots(mediaId: string, touchpointId: string, variantId: string, categoryId: string) {
		const data = {
			type: 'request_screenshot',
			mediaId,
			touchpointId,
			variantId,
			categoryId,
		};

		this._socket$.next(data);
	}
}
