import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-apple-checkbox',
	templateUrl: './apple-checkbox.component.html',
	styleUrls: ['./apple-checkbox.component.scss'],
})
export class AppleCheckboxComponent {
	@Input()
	public checked: boolean = false;

	@Input()
	public disabled: boolean = false;

	@Input()
	public type: 'primary' | 'secondary' = 'primary';

	@Output()
	public checkedChanged = new EventEmitter();

	onChange(event: any) {
		const isChecked = event.target.checked;
		this.checked = isChecked;
		this.checkedChanged.emit(this.checked);
	}
}
