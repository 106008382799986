import { Observable, map } from 'rxjs';

import { Component, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import * as fromRoot from '../../../../../store';
import * as fromBooking from '../../state';
import { PresetType } from './components/timeline/models/preset';
import { TimelineComponent } from './components/timeline/timeline.component';
import { TimelineResourceViewModel } from '../../models/view/TimelineResourceViewModel';
import { TimelineItemEventViewModel } from '../../models/view/TimelineItemEventViewModel';

@UntilDestroy()
@Component({
	selector: 'app-booking-timeline',
	templateUrl: './booking-timeline.component.html',
	styleUrls: ['./booking-timeline.component.scss'],
})
export class BookingTimelineComponent {
	@ViewChild(TimelineComponent, { static: false })
	public timeline!: TimelineComponent;

	public loading$: Observable<boolean> = this._store.select(fromBooking.selectLoading).pipe(untilDestroyed(this));

	public start$: Observable<Date> = this._store
		.select(fromBooking.selectMeta)
		.pipe(map((meta) => meta.startDate))
		.pipe(untilDestroyed(this));

	public end$: Observable<Date> = this._store
		.select(fromBooking.selectMeta)
		.pipe(map((meta) => meta.endDate))
		.pipe(untilDestroyed(this));

	public resources$: Observable<TimelineResourceViewModel[]> = this._store
		.select(fromBooking.selectTimelineTouchpoints)
		.pipe(untilDestroyed(this));

	public events$: Observable<TimelineItemEventViewModel[]> = this._store
		.select(fromBooking.selectTimelineBookings)
		.pipe(untilDestroyed(this));

	constructor(private _store: Store<fromRoot.State>) {
		this._store
			.select(fromBooking.selectZoomLevel)
			.pipe(untilDestroyed(this))
			.subscribe((zoomLevel) => {
				if (this.timeline) {
					this.timeline.setZoomLevel(zoomLevel as PresetType);
				}
			});

		this._store.dispatch(fromBooking.setZoomLevelVisibility({ show: true }));
	}
}
