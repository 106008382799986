<div class="container" *ngLet="filters$ | async as filters">
	<div
		class="header"
		[style.height.px]="settings['header-height']"
		[style.border-top]="
			filters?.company?.branding?.color?.primary ? '4px solid #' + filters?.company?.branding?.color?.primary : ''
		">
		<div class="left">
			<div class="menu" [style.width.px]="settings['menu-width']" (click)="menu()">
				<img src="../../../assets/menu/hamburger.svg" />
			</div>
			<div class="logo-container" routerLink="/network/dashboard" [queryParams]="{ page: 1 }">
				<div class="logo">
					<img class="mobile-only" src="../assets/connect-os-logo-mobile.png" />
					<img class="desktop-only" src="../assets/connect-os-logo-desktop.png" />
				</div>
				<div class="desktop-only" *ngIf="filters?.company?.branding?.logo as brandingLogo">
					<div class="holding-company">
						<img [src]="brandingLogo" />
					</div>
				</div>
			</div>
		</div>
		<ng-container>
			<div class="right" *ngIf="user$ | async as user">
				<div
					class="impersonate"
					*ngIf="settings['render-accounts'] && filters?.company?.name"
					data-testid="holding-company-dropdown">
					<div class="company">{{ filters?.company?.name }}</div>
					<app-dropdown class="select">
						<div
							class="item"
							*ngFor="let company of companies$ | async"
							(click)="filter(company)"
							[ngClass]="{ active: filters?.company?.id == company.id }">
							{{ company.name }}
						</div>
					</app-dropdown>
				</div>
				<div class="notification-container" *featureEnabled="'client-portal-notification-bell'">
					<app-notification-icon class="bell-icon"></app-notification-icon>
				</div>
				<div class="profile">
					<div class="picture">
						<img [src]="user.image" referrerpolicy="no-referrer" />
					</div>
					<div class="name desktop-only">{{ user.name }}</div>
					<app-dropdown class="select desktop-only">
						<div class="item" (click)="logout()">Log Out</div>
					</app-dropdown>
				</div>
			</div>
		</ng-container>
	</div>
	<div class="content">
		<div
			class="menu"
			[ngClass]="{ expanded: settings['menu-open'], 'mobile-hidden': !settings['menu-open'] }"
			[style.min-width.px]="settings['menu-open'] ? settings['menu-width-expanded'] : settings['menu-width']"
			[style.top.px]="55">
			<ng-container *ngLet="loading$ | async as loading">
				@if (loading) {
					<ng-container>
						<div class="item" *ngFor="let item of [1, 2, 3, 4]">
							<div class="content">
								<app-skeleton-loader></app-skeleton-loader>
							</div>
						</div>
					</ng-container>
				} @else {
					<ng-container *ngFor="let report of navigation$ | async">
						<div
							class="item"
							(click)="report.items.length > 1 ? expand($event, report) : null"
							[routerLink]="report.items.length === 1 ? report.items[0].path : null"
							[class.active]="isParentActive(report.items)">
							<div class="content">
								<div class="icon-container">
									<img class="icon" [src]="report.icon" />
									<img class="beta" src="../../../assets/beta-badge.svg" *ngIf="report.beta" />
								</div>
								<span class="label reveal" *ngIf="settings['menu-open']">{{ report.friendly_name }}</span>
							</div>
							<div
								class="toggle"
								*ngIf="settings['menu-open'] && report.items.length > 1"
								[class.expanded]="settings['menu-expansion-state'] === report.id">
								<img src="../../../assets/menu.svg" />
							</div>
						</div>
						<div
							*ngIf="settings['menu-open'] && report.items.length > 1 && settings['menu-expansion-state'] === report.id"
							class="child-items">
							<ul>
								<li>
									<span class="circle-top"></span>
								</li>
								<ng-container *ngFor="let item of report.items">
									<li>
										<span class="corner"></span>
										<span class="circle"></span>
										<span class="label" [class.active]="isChildActive(item)" [routerLink]="item.path">
											{{ item.name }}
										</span>
									</li>
								</ng-container>
							</ul>
						</div>
					</ng-container>
				}
			</ng-container>
			<div class="logout-container mobile-only">
				<div class="item" (click)="logout()">Log Out</div>
			</div>
		</div>
		<div
			#outlet
			[style]="style()"
			[style.margin-left.px]="settings['menu-width']"
			class="outlet"
			*ngIf="isMobileSupported(); else mobileUnsupported">
			<div *ngFor="let report of filteredNavigation$ | async" class="tab-container">
				<app-page-header [heading]="report.heading"></app-page-header>
				<div class="navigation" *ngIf="report.reports.length > 1">
					<app-tab-navigation [items]="report.reports"></app-tab-navigation>
				</div>
			</div>
			<router-outlet></router-outlet>
		</div>
		<ng-template #mobileUnsupported>
			<div class="mobile-unsupported">
				<app-no-data
					image="../../../assets/only-desktop.svg"
					title="Only Available on Desktop"
					message="We're working on making this feature accessible on mobile devices. In the meantime, please try using a desktop."></app-no-data>
				<app-apple-button class="button" (click)="redirectHandler()">
					{{ redirectText }}
				</app-apple-button>
			</div>
		</ng-template>
	</div>
</div>
