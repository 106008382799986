import { Component, Input, AfterViewInit, ViewChild, ViewEncapsulation, OnInit } from '@angular/core';
import { DateHelper, GridColumnConfig, ResourceModel, Scheduler } from '@bryntum/scheduler';
import { BryntumSchedulerComponent } from '@bryntum/scheduler-angular';
import { formatDate } from '@angular/common';
import { PresetConfig, PresetType } from './models/preset';
import { TimelineItemEventViewModel } from '../../../../models/view/TimelineItemEventViewModel';
import { TimelineResourceViewModel } from '../../../../models/view/TimelineResourceViewModel';

@Component({
	selector: 'app-timeline',
	templateUrl: './timeline.component.html',
	styleUrls: ['./timeline.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class TimelineComponent implements AfterViewInit {
	@ViewChild(BryntumSchedulerComponent, { static: false })
	private _scheduler!: BryntumSchedulerComponent;

	public columns: Partial<GridColumnConfig>[] = [
		{
			width: '250',
			htmlEncode: false,
			renderer: ({ record, row, size }: any) => {
				const overlaps = this.getOverlaps(this.events);
				const overlapCount = overlaps.find((overlap) => overlap.resourceId === record.id);
				if (overlapCount && overlapCount.maxOverlapCount > 1) {
					if (overlapCount.maxOverlapCount === 2) {
						size.height = 150;
					} else if (overlapCount.maxOverlapCount > 2 && overlapCount.maxOverlapCount <= 4) {
						size.height = 100;
					}
				} else {
					size.height = 300;
				}

				return `
						<div class="column-container">
							<div class="column-touchpoint">${record.name}</div>
								<div class="column-category">${record.category}</div>
								<div class="column-img-container">
										<img src="${record.image}" class="img" />
								</div>
						</div>`;
			},
		},
	];

	public listeners = {
		beforeDragCreate({}) {
			return false;
		},
		renderEvent: ({ renderData, element }: any) => {
			const rowHeight = renderData.row.maxRequestedHeight;
			const top = Math.round(renderData.top / rowHeight) * rowHeight;
			if (rowHeight > 80) {
				let transform = '';
				const multiplier = top >= rowHeight ? top / rowHeight : -1;
				if (multiplier != -1) {
					const value = (rowHeight - 80) * multiplier;
					transform = `translateY(-${value}px)`;
					element.style.setProperty('transform', transform);
				}
			}
		},
	};

	public bottomBar = [
		{
			type: 'widget',
			html: `<div class="bottom-bar">
							<div class="bottom-bar-item">
								<div class="key exclusive"></div>
								<span>Exclusive</span>
							</div>
							<div class="bottom-bar-item">
								<div class="key exclusive-provisional"></div>
								<span>Exclusive (Provisional)</span>
							</div>
							<div class="bottom-bar-item">
								<div class="key shared"></div>
								<span>Shared</span>
							</div>
							<div class="bottom-bar-item">
								<div class="key shared-provisional"></div>
								<span>Shared (Provisional)</span>
							</div>
							<div class="bottom-bar-item">
								<div class="key retailer"></div>
								<span>Retailer</span>
							</div>
							<div class="bottom-bar-item">
								<div class="key retailer-provisional"></div>
								<span>Retailer (Provisional)</span>
							</div>
						</div>`,
			flex: 1,
		},
	];

	@Input()
	public start: Date | null = null;

	@Input()
	public end: Date | null = null;

	@Input()
	public events: TimelineItemEventViewModel[] = [];

	@Input()
	public resources: TimelineResourceViewModel[] = [];

	ngAfterViewInit(): void {
		this.setZoomLevel('Years');
		this._scheduler.instance.scrollToDate(new Date(), { block: 'center' });
	}

	public setZoomLevel(type: PresetType) {
		const presets: { [key in PresetType]: PresetConfig } = {
			Days: {
				base: 'hourAndDay',
				tickWidth: 70,
				headers: [
					{ unit: 'day', dateFormat: 'dddd' },
					{ unit: 'hour', dateFormat: 'hh:mm a' },
				],
			},
			Weekly: {
				base: 'weekAndDay',
				tickWidth: 120,
				headers: [
					{ unit: 'week', dateFormat: 'MMMM YYYY' },
					{ unit: 'day', dateFormat: 'ddd DD' },
				],
			},
			Monthly: {
				base: 'weekAndMonth',
				tickWidth: 200,
				headers: [
					{ unit: 'month', dateFormat: 'MMMM YYYY' },
					{
						unit: 'day',
						renderer: (startDate: Date, endDate: Date): string =>
							`${DateHelper.format(startDate, 'DD')}-${DateHelper.format(endDate, 'DD')}`,
					},
				],
			},
			Quarterly: {
				base: 'year',
				tickWidth: 300,
				headers: [
					{
						unit: 'quarter',
						renderer: (startDate: Date, endDate: Date): string => {
							const quarter = Math.floor((startDate.getMonth() + 3) / 3);
							return `Q${quarter}`;
						},
					},
					{ unit: 'month', dateFormat: 'MMMM' },
				],
			},
			Years: {
				base: 'monthAndYear',
				tickWidth: 400,
				headers: [
					{ unit: 'year', dateFormat: 'YYYY' },
					{ unit: 'month', dateFormat: 'MMMM' },
				],
			},
		};

		this._scheduler.instance.viewPreset = presets[type];
		this._scheduler.instance.scrollToDate(new Date(), { block: 'center' });
	}

	private getOverlaps = (events: TimelineItemEventViewModel[]) => {
		const overlaps: any[] = [];
		const resources = new Set(events.map((event) => event.resourceId));
		for (const resource of resources) {
			const resourceEvents = events.filter((event) => event.resourceId == resource);

			let maxOverlapCount = 0;
			for (let j = 0; j < resourceEvents.length; j++) {
				let eventA = resourceEvents[j];
				let currentOverlapCount = 1;

				for (let k = 0; k < resourceEvents.length; k++) {
					if (j === k) {
						continue;
					}

					let eventB = resourceEvents[k];
					if (eventA.startDate <= eventB.endDate && eventA.endDate >= eventB.startDate) {
						currentOverlapCount++;
					}
				}
				maxOverlapCount = Math.max(maxOverlapCount, currentOverlapCount);
			}

			overlaps.push({ resourceId: resource, maxOverlapCount });
		}

		return overlaps;
	};

	public eventRenderer = ({ eventRecord, renderData }: { eventRecord: any; renderData: any }) => {
		const map: any = {
			Shared: eventRecord.bookingStatus === 'Provisional' ? 'shared-provisional-booking' : 'shared-booking',
			Exclusive: eventRecord.bookingStatus === 'Provisional' ? 'exclusive-provisional-booking' : 'exclusive-booking',
		};

		renderData.cls.add(
			map[eventRecord.shareType] ||
				(eventRecord.bookingStatus === 'Provisional' ? 'retailer-provisional-booking' : 'retailer-booking')
		);

		return `
				<div class="event-item">
					<div class="name">${eventRecord.bookingName}</div>
					<div class="brands">${eventRecord.brandsDisplay}</div>
					<div class="stores">Stores: ${eventRecord.storeCountDisplay}</div>
				</div>
		`;
	};

	public eventTooltipTemplateRenderer({ eventRecord }: { eventRecord: TimelineItemEventViewModel }) {
		let length = (() => {
			const start = new Date(eventRecord.startDate);
			const end = new Date(eventRecord.endDate);
			const timeDifference = Math.abs(end.getTime() - start.getTime());
			return Math.ceil(timeDifference / (1000 * 60 * 60 * 24)) + 1;
		})();

		return `
			<app-apple-tooltip>
				<ng-container>
					<ng-container tooltip-content>
						<div class="tooltip-item">
							<h2>${eventRecord.bookingName}</h2>
							<div class="tooltip-item-email">
								<div>Duration: ${formatDate(eventRecord.startDate, 'dd MMM YYYY', 'en')} to ${formatDate(
									eventRecord.endDate,
									'dd MMM YYYY',
									'en'
								)}</div>
								<div>Length: ${length} days</div>
								<div>Store Count: ${eventRecord.storeCountDisplay}</div>
								<div>Booking Status: ${eventRecord.bookingStatus}</div>
								<div>Booking Type: ${eventRecord.shareType}</div>
								<div>Holding Company: ${eventRecord.companyName}</div>
								<div>Brands: ${eventRecord.brandsDisplay}</div>
								<div>Value: ${eventRecord.valueDisplay}</div>
								<div>Status: ${eventRecord.contentStatus || 'N/A'}</div>
							</div>
						</div>
					</ng-container>
				</ng-container>
			</app-apple-tooltip>`;
	}
}
