import { createAction, props } from '@ngrx/store';
import { TouchpointDrilldownHeader } from './models/TouchpointDrilldownHeader';
import { TouchpointDrilldownRow } from './models/TouchpointDrilldownRow';
import { DrillDownResponse } from 'src/app/models/_old/drilldown/drilldown';

// Search
export const setSearchText = createAction('[Touchpoint Drilldown] Set Search Text', props<{ searchText: string }>());

//Pagination
export const setPage = createAction('[Touchpoint Drilldown] Set Page', props<{ page: number; size: number }>());

//Sort
export const setSort = createAction(
	'[Touchpoint Drilldown] Set Sort',
	props<{ column: string | null; direction: string | null }>()
);

//Filters
export const setAppliedFilters = createAction(
	'[Touchpoint Drilldown] Set Applied Filters',
	props<{ filters: { name: string; values: string[] }[] }>()
);
export const resetAppliedFilters = createAction('[Touchpoint Drilldown] Reset Applied Filters');

// Data
export const initializeTouchpointDrilldownData = createAction('[Touchpoint Drilldown] Load Touchpoint Data');
export const initializeTouchpointDrilldownDataSuccess = createAction(
	'[Touchpoint Drilldown] Load Touchpoint Data Success',
	props<{ header: TouchpointDrilldownHeader }>()
);

export const refreshTouchpointDrilldownData = createAction('[Touchpoint Drilldown] Refresh Touchpoint Data');
export const refreshTouchpointDrilldownDataSuccess = createAction(
	'[Touchpoint Drilldown] Refresh Touchpoint Data Success',
	props<{ data: DrillDownResponse<TouchpointDrilldownRow> }>()
);
