import { Component, OnInit, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromRoot from 'src/app/store';
import * as contentSelectors from '../store/content.selectors';
import * as contentActions from '../store/content.actions';
import { ContentApprover } from '../store/content.models';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-pending-approval',
	templateUrl: './pending-approval.component.html',
	styleUrls: ['./pending-approval.component.scss'],
})
export class PendingApprovalComponent implements OnInit {
	@Input() uuid: string | null = null;
	approvers$: Observable<ContentApprover[]> = this.store.pipe(select(contentSelectors.selectApprovals));

	constructor(private store: Store<fromRoot.State>) {}

	ngOnInit(): void {
		if (this.uuid) {
			this.store.dispatch(contentActions.loadContentApproval({ uuid: this.uuid }));
		}
	}

	setDefaultPicture(event: any) {
		event.target.src = 'assets/avatar.png';
	}
}
