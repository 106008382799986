import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RevenueResponse, Category } from './../models';
import * as RevenueActions from './revenue.actions';
import * as fromRevenue from './revenue.selectors';
import * as fromSession from 'src/app/store/session';
import * as fromRoot from 'src/app/store';
import { DateHelper } from 'src/app/helpers/date';
import { Store } from '@ngrx/store';

@Injectable()
export class RevenueEffects {
	constructor(
		private actions$: Actions,
		private http: HttpClient,
		private store: Store<fromRoot.State>
	) {}

	loadCategories$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(RevenueActions.loadInsights),
			switchMap((action) => {
				const url = `${environment.api}/client_portal/insights/revenue?holdingCompanyId=${action.holdingCompany}&action_type=initialization`;
				return this.http.post<Category[]>(url, {}).pipe(
					map((response) => {
						return RevenueActions.loadCategoriesSuccess({
							categories: response,
						});
					}),
					catchError((error) => {
						return of(RevenueActions.loadCategoriesFailure({ error }));
					})
				);
			})
		);
	});

	loadInsights$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(RevenueActions.loadInsights),
			withLatestFrom(this.store.select(fromSession.getFilters), this.store.select(fromRevenue.selectFilters)),
			switchMap(([action, sessionFilters, revenueFilters]) => {
				if (!revenueFilters.period.start || !revenueFilters.period.end) {
					return of(
						RevenueActions.loadInsightsFailure({
							error: 'Unable to load insights, please ensure a period is selected.',
						})
					);
				}

				const payload = {
					startDate: DateHelper.formatDate(revenueFilters.period.start),
					endDate: DateHelper.formatDate(revenueFilters.period.end),
					categoryIds: action.selectedCategories,
				};

				const url = `${environment.api}/client_portal/insights/revenue?holdingCompanyId=${sessionFilters.company.id}&action_type=dashboard`;
				return this.http.post<RevenueResponse>(url, payload).pipe(
					map((response) => {
						return RevenueActions.loadInsightsSuccess({
							currency: response.currency,
							powerNumbers: Object.values(response.powerNumbers),
							trendNumbers: response.trendNumbers,
							monetizedImpressions: response.monetizedImpressions,
							totalRevenue: response.totalRevenue,
							revenueAnalysis: response.revenueAnalysis,
							touchpointRevenue: response.touchpointRevenue,
							clientRevenue: response.clientRevenue,
							campaignRevenue: response.campaignRevenue,
							touchpointDetails: Object.values(response.touchpointDetails),
						});
					}),
					catchError((error) => {
						return of(RevenueActions.loadInsightsFailure({ error }));
					})
				);
			})
		);
	});
}
