<app-card>
	<div class="container">
		<ng-container *ngIf="!loading; else loadingState">
			<img class="image" [src]="data?.image" />
			<div class="wrapper">
				<div class="header">
					<div class="touchpoint">{{ data?.touchpoint }}</div>
					<div class="category">{{ data?.category }}</div>
				</div>
				<div class="details">
					<div class="item" *ngFor="let item of data?.items">
						<div class="label">{{ item.title }}</div>
						<a *ngIf="item.link; else valueTemplate" class="link" [href]="item.link" target="_blank">
							<div class="value">{{ item.value }}</div>
						</a>
						<ng-template #valueTemplate>
							<div class="value">{{ item.value }}</div>
						</ng-template>
					</div>
				</div>
			</div>
		</ng-container>
		<ng-template #loadingState>
			<app-skeleton-loader width="400px" height="400px"></app-skeleton-loader>
			<div class="wrapper">
				<div class="header">
					<app-skeleton-loader width="275px" height="24px"></app-skeleton-loader>
					<app-skeleton-loader width="190px" height="20px"></app-skeleton-loader>
				</div>
				<div class="details">
					<div class="item" *ngFor="let i of [1, 2, 3, 4]">
						<app-skeleton-loader width="120px" height="18px"></app-skeleton-loader>
						<app-skeleton-loader width="80px" height="18px"></app-skeleton-loader>
					</div>
				</div>
			</div>
		</ng-template>
	</div>
	<div class="status" *ngIf="!loading">
		<app-apple-pill>{{ data?.status }}</app-apple-pill>
	</div>
</app-card>
