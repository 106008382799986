<ng-container *ngIf="loading$ | async">
	<app-apple-loader [loading]="loading$ | async"></app-apple-loader>
</ng-container>
<div class="timeline-container" *ngIf="!(loading$ | async)">
	<app-timeline
		[start]="start$ | async"
		[end]="end$ | async"
		[resources]="(resources$ | async) || []"
		[events]="(events$ | async) || []">
	</app-timeline>
</div>
