import { createAction, props } from '@ngrx/store';
import { HoldingCompany } from '../../shared/models/api/HoldingCompany';
import { ReportNavigation } from 'src/app/shared/models/api/reports/ReportNavigation';
import { WebSocketResponse } from 'src/app/shared/models/api/WebSocketResponse';
import { NotificationResponse } from 'src/app/shared/models/api/NotificationResponses';
import { NavigationItem } from 'src/app/shared/models/api/navigation/NavigationItem';

export const initializeSession = createAction('[Session] Initialize Session');
export const initializeSessionSuccess = createAction(
	'[Session] Initialize Session Success',
	props<{ companies: HoldingCompany[] }>()
);
export const updateReportItemsSuccess = createAction(
	'[Session] Update Report Items Success',
	props<{ reports: ReportNavigation[] }>()
);
export const updateSessionFilters = createAction('[Session] Update Filters', props<{ company: HoldingCompany }>());

export const listenForOutstandingNotifications = createAction('[Notifications] Listen For Outstanding Notifications');
export const outstandingNotificationsSuccess = createAction(
	'[Notifications] Outstanding Notification Success',
	props<{ data: WebSocketResponse<NotificationResponse> }>()
);

export const setSelectedItemPath = createAction('[Reports] Set Selected Item Path', props<{ path: string | null }>());

export const retrieveRoutes = createAction('[Session] Retrieve Routes');
