<app-apple-card>
	<div class="root-container">
		<app-apple-table
			[columns]="columns"
			[loading]="!!(loading$ | async) || !!(initializing$ | async)"
			[data]="(data$ | async) || []"
			(sort)="sort($event)">
			<app-apple-dual-slot-header header>
				<div class="header" header-left>
					<div class="touchpoint">
						<div class="back" (click)="back()">
							<img class="img" src="../../../../assets/arrow-back.svg" />
						</div>
						<div *ngIf="header$ | async as header">
							<div class="name">{{ header.touchpoint }}</div>
							<div class="category">{{ header.category }}</div>
						</div>
					</div>
					<div class="media" *ngIf="header$ | async as header">
						<div class="item" *ngFor="let info of header.info">
							<div class="label">{{ info.name }}</div>
							<div class="value">{{ info.value }}</div>
						</div>
						<div class="item" *ngIf="header?.content?.video">
							<div class="label">Content:</div>
							<div
								class="preview-container"
								(mouseenter)="hoveringOnPlayPreview = true"
								(mouseleave)="hoveringOnPlayPreview = false"
								(click)="this.interactingWithPlayPreview = !this.interactingWithPlayPreview">
								<img
									class="icon"
									src="{{
										hoveringOnPlayPreview
											? '../../../assets/play-icon-blue-fill.svg'
											: '../../../assets/play-icon-blue.svg'
									}}" />
								<div class="value">Play Preview</div>
							</div>
							<div class="preview" *ngIf="header$ | async as header">
								<app-apple-tooltip [open]="interactingWithPlayPreview">
									<ng-container tooltip-content>
										<div class="tooltip-item video-container">
											<video controls [ngClass]="header.content.orientation">
												<source [src]="header.content.video" type="video/mp4" />
												Your browser does not support the video tag.
											</video>
										</div>
										<div class="tooltip-item">
											<div class="tooltip-item-name">
												<ng-container>
													<div
														class="tooltip-container"
														[ngStyle]="{
														'flex-direction': header.content.orientation == 'Landscape' ? 'row' : 'column',
													}">
														<div class="item">
															<div class="label">Dimensions:</div>
															<div class="value">{{ header.content.dimension }}</div>
														</div>
														<div class="item">
															<div class="label">Aspect Ratio:</div>
															<div class="value">{{ header.content.ratio }}</div>
														</div>
														<div class="item">
															<div class="label">File Type:</div>
															<div class="value">{{ header.content.filetype | titlecase }}</div>
														</div>
													</div>
												</ng-container>
											</div>
										</div>
									</ng-container>
								</app-apple-tooltip>
							</div>
						</div>
					</div>
					<div class="approver" *ngIf="header$ | async as header">
						<span
							class="avatar"
							*ngFor="let approver of header.approvers; index as i"
							(mouseenter)="hoveringOnApproverIndex = i"
							(mouseleave)="hoveringOnApproverIndex = -1">
							<i class="fas fa-check-circle approval-icon-check"></i>
							<img
								class="image"
								[src]="approver.avatar.avatar_available ? approver.avatar.avatar_url : 'assets/avatar.png'"
								alt="" />
							<app-apple-tooltip [open]="hoveringOnApproverIndex == i">
								<ng-container tooltip-content>
									<div class="tooltip-item">
										<span class="avatar">
											<img
												class="image"
												[src]="approver.avatar.avatar_available ? approver.avatar.avatar_url : 'assets/avatar.png'"
												alt="" />
										</span>
									</div>
									<div class="tooltip-item">
										<div class="tooltip-item-name">
											{{ approver.name }}
										</div>
										<div class="tooltip-item-email">
											<img class="icon" src="../../../assets/email-blue.svg" width="12px" height="12px" />
											<span class="email-text">{{ approver.email }}</span>
										</div>
									</div>
								</ng-container>
							</app-apple-tooltip>
						</span>
					</div>
				</div>
				<div class="header" header-right>
					<div class="button-container">
						<app-apple-applied-filters [filters]="(filters$ | async) || []" direction="rtl"></app-apple-applied-filters>
					</div>
					<div class="button-container">
						<app-apple-input
							[prefix]="{ value: '../../../assets/search.svg', type: 'image' }"
							placeholder="Search..."
							[style]="{ width: '300px' }"
							(stateChange)="search($event)"></app-apple-input>
					</div>
					<div class="button-container">
						<button class="button" (click)="screenshots()">
							<img class="icon" src="../../../assets/refresh-blue.svg" />
							Screenshots
						</button>
					</div>
					<div class="button-container">
						<button class="button" (click)="export()">
							<img class="icon" src="../../../assets/export-blue.svg" />
							Export
						</button>
					</div>
					<div class="button-container">
						<app-apple-filter
							[loading]="!!(initializing$ | async)"
							[icon]="'../../../assets/filter-blue.svg'"
							[label]="'Filter'"
							[tableFilters]="(header$ | async)?.filters ?? []"
							(stateChange)="filter($event)"></app-apple-filter>
					</div>
				</div>
			</app-apple-dual-slot-header>
			<app-no-data no-data></app-no-data>
			<div class="footer" footer>
				<app-apple-pagination
					(pageChange)="page($event)"
					[totalItems]="(total$ | async) ?? 0"
					[currentPage]="(page$ | async) ?? 1"
					position="left"
					[enablePageSizePicker]="true"></app-apple-pagination>
			</div>
		</app-apple-table>
	</div>
</app-apple-card>
