<div class="comment-container custom-card-style">
	<div class="user-icon">
		<img [src]="comment.avatar" alt="" (error)="setDefaultPicture($event)" />
	</div>
	<div class="comment-details">
		<div class="comment-metadata">
			<div class="comment-info">
				<p class="user">{{ comment.first_name }} {{ comment.last_name }}</p>
				<span class="spacer">•</span>
				<p class="date">{{ comment.created_at | date: 'medium' }}</p>
			</div>
		</div>
		<div class="comment-text">
			<p>{{ comment.text }}</p>
		</div>
	</div>
</div>
