import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-apple-forgot-password-failure',
	templateUrl: './apple-forgot-password-failure.component.html',
	styleUrls: ['./apple-forgot-password-failure.component.scss'],
})
export class AppleForgotPasswordFailureComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
