import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BookingCreationState } from './bookings-create.reducers';
import { TableHelpers } from 'src/app/helpers/table';
import { Store as PlatformStore } from 'src/app/shared/models/api/store/Store';

export const getBookingCreationState = createFeatureSelector<BookingCreationState>('bookingCreation');

export const selectLoading = createSelector(getBookingCreationState, (state) => state.loading);
export const selectBooking = createSelector(getBookingCreationState, (state) => state.booking);
export const selectCurrency = createSelector(getBookingCreationState, (state) => state.data.currency);
export const selectTouchpoints = createSelector(getBookingCreationState, (state) => state.data.touchpoints);
export const selectApprovers = createSelector(getBookingCreationState, (state) =>
	state.data.approvers.map((approver) => ({
		id: approver.id,
		name: `${approver.firstName} ${approver.lastName}`,
	}))
);
export const selectShareTypes = createSelector(getBookingCreationState, (state) => state.data.share);
export const selectBrandParents = createSelector(getBookingCreationState, (state) => state.data.brands);
export const selectAllStores = createSelector(getBookingCreationState, (state) => state.data.stores);
export const selectSelectedStores = createSelector(getBookingCreationState, (state) => state.selected.stores);

export const selectFilteredStores = createSelector(
	selectAllStores,
	selectSelectedStores,
	(allStores: PlatformStore[], selectedStores: PlatformStore[]) => {
		return allStores.filter((store) => selectedStores.some((selectedStore) => selectedStore.id === store.id));
	}
);

export const selectExportStores = createSelector(selectAllStores, (stores) =>
	stores.map((store) => ({
		'Store Name': store.storeName,
		Region: store.region,
		'Store Code': store.storeCode,
	}))
);

export const storeLocation = createSelector(getBookingCreationState, (state) => state.data.location);
export const customFields = createSelector(getBookingCreationState, (state) => state.data.customFields);
export const selectPagedStores = createSelector(getBookingCreationState, (state) => {
	return TableHelpers.paginate(state.data.stores, state.filters.page, state.filters.pageSize);
});

export const selectScreenVariants = createSelector(
	getBookingCreationState,
	(state) => (state.booking && state.booking.touchpoint.categories[0].screenVariants) ?? []
);

export const selectTouchpointOrientation = createSelector(
	getBookingCreationState,
	(state) => (state.booking && state.booking.touchpoint.orientation) ?? 'landscape'
);

export const selectVariantDropzoneState = (variant: number) =>
	createSelector(getBookingCreationState, (state) => {
		const preview = state.booking && state.booking.media.find((m) => m.screenVariant === variant);

		return {
			status: preview ? 'preview' : 'empty',
			data: preview ? preview.mediaUrl : null,
		};
	});

export const selectSaving = createSelector(getBookingCreationState, (state) => state.saving);
