import { createAction, props } from '@ngrx/store';
import { Option, FilterValueOption } from '../../components/filters/models';
import { StoreDataResponse, CampaignOverview } from '../models/index';
import { PowerNumber, LineGraph, CampaignMedia } from '../../models/';

// Load Filters
export const loadFilters = createAction(
	'[Insights] Load Filters',
	props<{
		holdingCompanyId: number;
		touchpoints?: Option[];
		period?: {
			start: string;
			end: string;
		};
	}>()
);

export const loadFiltersSuccess = createAction(
	'[Insights] Load Filters Success',
	props<{
		touchpoints: Option[];
		campaigns: Option[];
		companies: Option[];
		brands: Option[];
	}>()
);

export const loadFiltersFailure = createAction('[Insights] Load Filters Failure', props<{ error: string }>());

// Filters
export const getFilters = createAction('[Insights] Get Filters');

export const setFilter = createAction(
	'[Insight] Set Filter',
	props<{
		filterName: string;
		filterValue: FilterValueOption;
	}>()
);

export const setPage = createAction('[Insights] Set Page', props<{ page: number; size: number }>());

// Load Insights
export const loadCampaign = createAction(
	'[Insights] Load Campaign',
	props<{
		holdingCompanyId?: number;
		campaignId?: string;
	}>()
);

export const loadCampaignSuccess = createAction(
	'[Insights] Load Campaign Success',
	props<{
		details: CampaignOverview;
		powerNumbers: PowerNumber[];
		stores: StoreDataResponse[];
		mediaGallery: CampaignMedia;
		uptime: LineGraph;
	}>()
);

export const loadCampaignFailure = createAction('[Insights] Load Campaign Failure', props<{ error: string }>());
