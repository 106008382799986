<app-apple-card [shadow]="true" [style]="{ width: '75vw', 'max-width': '420px' }">
	<div class="content">
		<div class="header">
			<div class="title">
				{{ title }}
			</div>
			<div class="subtext">
				<span class="text">
					<div [innerHTML]="sanitized"></div>
				</span>
			</div>
		</div>
		<div class="action">
			<div class="button">
				<a class="link" href="/auth/login">Sign In</a>
			</div>
		</div>
		<div class="logo">
			<img class="img" src="../../../../assets/barrows.png" />
		</div>
	</div>
</app-apple-card>
