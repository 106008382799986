import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-apple-hyper-link',
	templateUrl: './apple-hyper-link.component.html',
	styleUrls: ['./apple-hyper-link.component.scss'],
})
export class AppleHyperLinkComponent {
	@Input() public link!: string;
	@Input() public text!: string;

	constructor() {}
}
