import { Observable, map } from 'rxjs';

import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { BookingSnapshotComponent } from '../../../../../components/apple/apple-table/components/booking-snapshot/booking-snapshot.component';
import { BookingSovComponent } from '../../../../../components/apple/apple-table/components/booking-sov/booking-sov.component';
import { TableColumn } from '../../../../../components/apple/apple-table/models/column';
import * as fromRoot from '../../../../../store';
import * as fromBooking from '../../state';
import { TimelineItemEventViewModel } from '../../models/view/TimelineItemEventViewModel';
import { FormatDateComponent } from '../../../../../components/apple/apple-table/components/format-date/format-date.component';
import { StackedColumnRowComponent } from 'src/app/components/apple/apple-table/components/stacked-column-row/stacked-column-row.component';

@UntilDestroy()
@Component({
	selector: 'app-booking-list',
	templateUrl: './booking-list.component.html',
	styleUrls: ['./booking-list.component.scss'],
})
export class BookingListComponent {
	public loading$: Observable<boolean> = this._store.select(fromBooking.selectLoading).pipe(untilDestroyed(this));

	public bookings$: Observable<TimelineItemEventViewModel[]> = this._store
		.select(fromBooking.selectTabularBookings)
		.pipe(untilDestroyed(this));

	public page$: Observable<number> = this._store
		.select(fromBooking.selectFilters)
		.pipe(map((filters) => filters.page))
		.pipe(untilDestroyed(this));

	public total$: Observable<number> = this._store
		.select(fromBooking.selectTabularBookingCount)
		.pipe(untilDestroyed(this));

	public columns: TableColumn[] = [
		{ header: 'Image', field: 'image', sortable: false, component: BookingSnapshotComponent },
		{
			header: 'Touchpoint',
			field: 'touchpointAndCategory',
			sortable: true,
			component: StackedColumnRowComponent,
		},
		{
			header: 'Company',
			field: 'companyAndBrands',
			sortable: true,
			component: StackedColumnRowComponent,
		},
		{ header: 'Stores', field: 'storeCountDisplay', sortable: true, sortValue: 'storeCount' },
		{
			header: 'Start Date',
			field: 'startDateDisplay',
			sortable: true,
			sortValue: 'startDate',
			component: FormatDateComponent,
		},
		{
			header: 'End Date',
			field: 'endDateDisplay',
			sortable: true,
			sortValue: 'endDate',
			component: FormatDateComponent,
		},
		{ header: 'Value', field: 'valueDisplay', sortable: true, sortValue: 'value' },
		{ header: 'Status', field: 'bookingStatus', sortable: true },
		{
			header: 'Type',
			field: 'shareTypeDisplay',
			sortable: true,
			component: BookingSovComponent,
			sortValue: 'shareType',
		},
		{ header: 'Name', field: 'bookingName', sortable: true },
	];

	constructor(private _store: Store<fromRoot.State>) {
		this._store.dispatch(fromBooking.setZoomLevelVisibility({ show: false }));
	}

	clicked(item: TimelineItemEventViewModel) {
		this._store.dispatch(fromBooking.editBooking({ bookingId: item.bookingId }));
	}

	sort(event: { column: string | null; direction: string | null }) {
		this._store.dispatch(fromBooking.setSort({ column: event.column, direction: event.direction }));
	}

	page(event: { page: number; size: number }) {
		this._store.dispatch(fromBooking.setPage({ page: event.page, size: event.size }));
	}
}
