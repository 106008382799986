<div class="booking-view-container">
	<app-apple-card>
		<div class="wrapper">
			<app-apple-dual-slot-header>
				<div header-left>
					<div class="control-container">
						<app-apple-button-group (click)="onButtonGroupClick($event)" [buttons]="buttons"></app-apple-button-group>
						<app-apple-filter
							[loading]="!!(loading$ | async)"
							[icon]="'../../../assets/filter-blue.svg'"
							[label]="'Filter'"
							[tableFilters]="(header$ | async)?.filters ?? []"
							(stateChange)="onFilterStateChanged($event)"></app-apple-filter>
						<div class="dropdown" [style.display]="(header$ | async)?.showZoomLevel ? 'block' : 'none'">
							<app-apple-select
								name="Filter"
								[items]="zoomOptions"
								[selected]="zoomOptions[4].id"
								(changed)="onZoomChanged($event)"></app-apple-select>
						</div>
						<app-data-export [label]="'Export'" [data]="exportData$ | async"></app-data-export>
						<app-apple-input
							[prefix]="{ value: '../../../assets/search.svg', type: 'image' }"
							placeholder="Search..."
							[style]="{ width: '300px' }"
							(stateChange)="onSearchStateChanged($event)"></app-apple-input>
						<form [formGroup]="filtersForm" class="form-filters">
							<app-datepicker
								class="date-range"
								[type]="'range'"
								[options]="true"
								formControlName="period"></app-datepicker>
						</form>
						<app-apple-applied-filters [filters]="(filters$ | async) ?? []"></app-apple-applied-filters>
					</div>
				</div>
				<div class="create-booking" header-right>
					<app-apple-button
						color="var(--white)"
						background="var(--blue)"
						[style]="{ padding: '13px 16px' }"
						routerLink="/network/campaign">
						Create Campaign</app-apple-button
					>
				</div>
			</app-apple-dual-slot-header>
			<router-outlet></router-outlet>
		</div>
	</app-apple-card>
</div>
