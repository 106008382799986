<app-card>
	<div class="container" *ngIf="!loading; else loadingTemplate">
		<div class="header">
			<div class="header-title">{{ data?.header }}</div>
			<div class="header-subtitle">{{ data?.subHeader }}</div>
		</div>
		<div class="connection-points-list">
			<ng-container *ngIf="data!.body && data!.body.length > 0; else noItems">
				<div *ngFor="let item of data?.body | slice: 0 : count" class="connection-point-item">
					<div class="item-image">
						<img [src]="item.image" [alt]="item.header || ''" />
					</div>
					<div class="item-details">
						<div class="item-title">{{ item.header }}</div>
						<div class="item-subtitle">{{ item.subHeader }}</div>
					</div>
					<div class="item-value">{{ item.value }}</div>
				</div>
			</ng-container>
			<ng-template #noItems>
				<app-no-data
					class="no-data"
					no-data
					image="../../../../../assets/no-data-category.svg"
					title="Not Results"
					message="Try updating your filters."></app-no-data>
			</ng-template>
		</div>
	</div>
	<ng-template #loadingTemplate>
		<div class="container">
			<div class="header">
				<ngx-skeleton-loader [theme]="{ width: '120px', height: '19px', 'border-radius': '6px' }"></ngx-skeleton-loader>
				<ngx-skeleton-loader [theme]="{ width: '180px', height: '17px', 'border-radius': '6px' }"></ngx-skeleton-loader>
			</div>
			<div class="connection-points-list">
				<div class="connection-point-item" *ngFor="let i of [1, 2, 3, 4]">
					<div class="item-image">
						<ngx-skeleton-loader
							[theme]="{ width: '65px', height: '65px', 'border-radius': '8px' }"></ngx-skeleton-loader>
					</div>
					<div class="item-details">
						<ngx-skeleton-loader
							[theme]="{ width: '120px', height: '17px', 'border-radius': '6px' }"></ngx-skeleton-loader>
						<ngx-skeleton-loader
							[theme]="{ width: '80px', height: '17px', 'border-radius': '6px' }"></ngx-skeleton-loader>
					</div>
					<div class="item-value">
						<ngx-skeleton-loader
							[theme]="{ width: '60px', height: '26px', 'border-radius': '8px' }"></ngx-skeleton-loader>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
</app-card>
