<app-loader [loading]="isLoading$ | async"></app-loader>
<div *ngIf="!(isLoading$ | async)">
	<div class="root-container">
		<div class="video-card" *ngIf="contentDetails$ | async as contentDetails">
			<div class="video-container">
				<video class="custom-video" [loop]="true" [muted]="true" [autoplay]="true" controls>
					<source [src]="contentDetails.url" type="video/mp4" />
					Your browser does not support the video tag.
				</video>
				<div class="video-placeholder">
					<div class="video-name">{{ contentDetails.title }}</div>
					<div class="touchpoint-category">{{ contentDetails.touchpoint }} • {{ contentDetails.category }}</div>
				</div>
			</div>

			<div class="video-details-container">
				<div>
					<div class="title">Touchpoint:</div>
					<div class="value">{{ contentDetails.touchpoint }}</div>
				</div>
				<div *ngIf="contentDetails.category">
					<div class="title">Category:</div>
					<div class="value">{{ contentDetails.category }}</div>
				</div>
				<div *ngIf="contentDetails.dimensions">
					<div class="title">Dimensions:</div>
					<div class="value">{{ contentDetails.dimensions }}</div>
				</div>
				<div *ngIf="contentDetails.ratio">
					<div class="title">Ratio:</div>
					<div class="value">{{ contentDetails.ratio }}</div>
				</div>
				<div *ngIf="contentDetails.framerate">
					<div class="title">Frame Rate:</div>
					<div class="value" [ngClass]="{ warning: contentDetails.framerate != 30 }">
						{{ contentDetails.framerate }}
					</div>
				</div>
				<div>
					<div class="title">Uploader:</div>
					<div class="value">{{ contentDetails.uploader }}</div>
				</div>
				<div>
					<div class="title">Media ID:</div>
					<div class="value">{{ contentDetails.id }}</div>
				</div>
				<div>
					<div class="title">File Type:</div>
					<div class="value">{{ contentDetails.filetype | titlecase }}</div>
				</div>
			</div>

			<div class="input-card">
				<div *ngIf="user$ | async as user">
					<div class="user-icon">
						<img [src]="user.details.image" alt="User Avatar" class="avatar-image" />
					</div>
				</div>
				<input class="comment-input" type="text" placeholder="Leave your comment here..." [(ngModel)]="createComment" />
			</div>

			<div class="divider-container">
				<div class="custom-divider"></div>
			</div>

			<div class="controls-card">
				<button class="send-button" [disabled]="isLoadingComments$ | async" (click)="postComment()">
					<ng-container *ngIf="!(isLoadingComments$ | async)"> Send </ng-container>
					<ng-container *ngIf="isLoadingComments$ | async">
						<div>Loading...</div>
					</ng-container>
				</button>
			</div>
		</div>
		<div class="approval-card">
			<div class="content-approval-card">
				<div>
					<div class="title">Content Approval:</div>
					<div class="subtitle">
						Approving content before publication is a <b>critical</b> quality control step. As the approver, please
						ensure:
					</div>

					<div class="content-approval-list">
						<ul>
							<li>
								This exact version of the content has written approval from the relevant brands and retailers. Barrows
								employees cannot, under any circumstance, approve content on behalf of clients.
							</li>
							<li>
								All grammar, spelling, and pricing information is double-checked for accuracy. Pricing must have
								explicit approval from the retailer.
							</li>
							<li>Legal disclaimers, disclosures, and required copy provided by brands/legal have been included.</li>
							<li>
								Verify there is no plagiarised, improperly credited or unlicensed content. Make sure it meets our
								editorial standards and guidelines.
							</li>
						</ul>
					</div>
				</div>

				<div class="approval-buttons">
					<button
						class="btn reject"
						[disabled]="(approvalLoading$ | async) || (isUserApproved$ | async)"
						(click)="approvalConfirmation(false)">
						<ng-container *ngIf="!(approvalLoading$ | async); else loadingIcon">Reject</ng-container>
					</button>

					<button
						class="btn approve"
						[disabled]="approvalComplianceConfirmation || (approvalLoading$ | async) || (isUserApproved$ | async)"
						(click)="approvalConfirmation(true)">
						<ng-container *ngIf="!(approvalLoading$ | async); else loadingIcon">Approve</ng-container>
					</button>

					<ng-template #loadingIcon>
						<app-loader [loading]="approvalLoading$ | async" [fontSize]="'16px'"></app-loader>
					</ng-template>
				</div>

				<div *ngIf="isDialogOpen" class="dialog-overlay">
					<div class="dialog-content">
						<app-decline-dialog #declineDialog (dialogResult)="handleDialogResult($event)"></app-decline-dialog>
					</div>
				</div>

				<div class="divider-container">
					<div class="custom-divider"></div>
				</div>

				<div class="comment-container">
					<ng-container *ngIf="!(isLoadingComments$ | async); else loadingComments">
						<ng-container *ngIf="comments$ | async as comments; else noComments">
							<app-comment *ngFor="let comment of comments" [comment]="comment"></app-comment>
						</ng-container>
						<ng-template #noComments>
							<div class="centered-text">No comments available...</div>
						</ng-template>
					</ng-container>

					<ng-template #loadingComments>
						<div class="centered-text">
							<app-loader [loading]="isLoadingComments$ | async"></app-loader>
						</div>
					</ng-template>
				</div>

				<div class="divider-container">
					<div class="custom-divider"></div>
				</div>

				<app-pending-approval class="approvals" [uuid]="uuid"></app-pending-approval>
			</div>
		</div>
	</div>
</div>
