import { createReducer, on } from '@ngrx/store';

import * as fromContentDrilldownActions from './content-drilldown.actions';
import { ContentDrilldownHeader } from './models/ContentDrilldownHeader';
import { ContentDrillDownRow } from './models/ContentDrillDownRow';

export interface ContentDrilldownState {
	initializing: boolean;
	loading: boolean;
	meta: {
		mediaId: string;
		categoryId: string;
		touchpointId: string;
		variantId: string | null;
		isResult: boolean;
	};
	header: ContentDrilldownHeader;
	filters: {
		page: number;
		pageSize: number;
		search: string | null;
		applied: { name: string; values: string[] }[];
		column: string | null;
		direction: string | null;
	};
	data: {
		total_count: number;
		displays: ContentDrillDownRow[];
	};
}

export const initialState: ContentDrilldownState = {
	initializing: true,
	loading: true,
	meta: {
		mediaId: '',
		categoryId: '',
		touchpointId: '',
		variantId: null,
		isResult: false,
	},
	header: {
		category: null,
		touchpoint: null,
		info: [],
		content: {
			video: null,
			dimension: null,
			orientation: null,
			ratio: null,
			filetype: null,
		},
		approvers: [],
		filters: [],
	},
	filters: {
		page: 1,
		pageSize: 50,
		search: '',
		applied: [],
		column: null,
		direction: null,
	},
	data: {
		total_count: 0,
		displays: [],
	},
};

export const reducer = createReducer(
	initialState,
	on(fromContentDrilldownActions.setSearchText, (state, { searchText }) => ({
		...state,
		filters: {
			...state.filters,
			page: 1,
			search: searchText,
		},
	})),
	on(fromContentDrilldownActions.setPage, (state, { page, size }) => ({
		...state,
		filters: {
			...state.filters,
			page,
			pageSize: size,
		},
	})),
	on(fromContentDrilldownActions.setSort, (state, { column, direction }) => ({
		...state,
		filters: {
			...state.filters,
			column,
			direction,
		},
	})),
	on(fromContentDrilldownActions.setAppliedFilters, (state, { filters }) => ({
		...state,
		filters: {
			...state.filters,
			page: 1,
			applied: filters,
		},
	})),
	on(fromContentDrilldownActions.resetAppliedFilters, (state) => ({
		...state,
		filters: {
			...state.filters,
			page: 1,
			applied: [],
		},
	})),
	on(
		fromContentDrilldownActions.initializeContentDrilldownData,
		(state, { mediaId, categoryId, touchpointId, variantId, isResult }) => ({
			...state,
			initializing: true,
			meta: {
				mediaId,
				categoryId,
				touchpointId,
				variantId,
				isResult,
			},
			header: {
				category: null,
				touchpoint: null,
				info: [],
				content: {
					video: null,
					dimension: null,
					orientation: null,
					ratio: null,
					filetype: null,
				},
				approvers: [],
				filters: [],
			},
			filters: {
				page: 1,
				pageSize: 50,
				search: '',
				applied: [],
				column: null,
				direction: null,
			},
			data: {
				total_count: 0,
				displays: [],
			},
		})
	),
	on(fromContentDrilldownActions.initializeContentDrilldownDataSuccess, (state, { header }) => ({
		...state,
		initializing: false,
		header: {
			...header,
		},
	})),
	on(fromContentDrilldownActions.refreshContentDrilldownData, (state) => ({
		...state,
		loading: true,
	})),
	on(fromContentDrilldownActions.refreshContentDrilldownDataSuccess, (state, { data }) => ({
		...state,
		loading: false,
		data: {
			total_count: data.filtered_display_count,
			displays: data.displays,
		},
	})),
	on(fromContentDrilldownActions.bulkScreenshotUpdateSuccess, (state, { data }) => ({
		...state,
		data: {
			...state.data,
			displays: state.data.displays.map((row: ContentDrillDownRow) => {
				if (row.screenshot.serial == data.message.serial) {
					return {
						...row,
						screenshot: data.message,
					};
				}
				return row;
			}),
		},
	}))
);
