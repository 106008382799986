import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-apple-pill',
	templateUrl: './apple-pill.component.html',
	styleUrls: ['./apple-pill.component.scss'],
})
export class ApplePillComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
