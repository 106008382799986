<div class="container">
	<app-apple-button
		(click)="handleImport()"
		[disabled]="disabled"
		background="var(--white)"
		border="var(--grey-border)"
		[style]="{ padding: '10px 12px' }">
		<div class="button-content">
			<div class="icon">
				<img [src]="'../../../../../assets/import.svg'" alt="Upload Icon" />
				<span class="text">{{ label }}</span>
			</div>
		</div>
	</app-apple-button>

	<input type="file" #fileInput class="upload" (change)="onFileSelected($event)" accept=".xlsx, .xls" />
</div>
