import { Component, Input } from '@angular/core';
import { CampaignMedia } from '../../models/index';

@Component({
	selector: 'app-insights-campaign-gallery',
	templateUrl: './campaign-gallery.component.html',
	styleUrls: ['./campaign-gallery.component.scss'],
})
export class InsightCampaignGalleryComponent {
	@Input()
	public loading: boolean = true;

	@Input()
	public data?: CampaignMedia | null;

	@Input()
	public count: number = 3;
}
