import { Component, Input } from '@angular/core';
import { RevenueTable } from '../../models/index';

@Component({
	selector: 'app-client-list',
	templateUrl: './client-list.component.html',
	styleUrls: ['./client-list.component.scss'],
})
export class InsightsClientListComponent {
	@Input()
	public loading: boolean = true;

	@Input()
	public count: number = 4;

	@Input()
	public data?: RevenueTable | null;
}
