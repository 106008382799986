import { ValidatorFn, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';

export class CustomValidators {
	public static regexValidator(validator: ValidatorFn, errorKey: string): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const error = validator(control);
			return error ? { [errorKey]: error[Object.keys(error)[0]] } : null;
		};
	}

	public static passwordMatchValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const password = control.get('password');
			const confirmPassword = control.get('confirmPassword');

			if (!password || !confirmPassword) {
				return null;
			}
			return password.value === confirmPassword.value ? null : { passwordMismatch: true };
		};
	}

	public static passwordComplexityValidator(control: FormControl) {
		const value = control.value;

		const uppercaseError = !/[A-Z]/.test(value) ? { uppercase: true } : null;
		const lowercaseError = !/[a-z]/.test(value) ? { lowercase: true } : null;
		const numericError = !/[0-9]/.test(value) ? { numeric: true } : null;
		const lengthError = value.length < 8 ? { lengthCheck: true } : null;
		return Object.assign({}, uppercaseError, lowercaseError, numericError, lengthError);
	}

	public static emailValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i;

			if (!emailPattern.test(control.value)) {
				return { invalidEmail: true };
			}
			return null;
		};
	}

	public static campaignPeriodValidator(control: AbstractControl): ValidationErrors | null {
		const start = control.parent?.get('start')?.value;
		const end = control.parent?.get('end')?.value;

		if (start && end && start > end) {
			return { campaignPeriodValidator: true };
		}

		return null;
	}

	public static maxStoresValidator(control: AbstractControl): ValidationErrors | null {
		const categoryControl = control.parent?.get('category');
		const maxStores = categoryControl?.value?.totalTouchpoints || 0;
		if (control.value > maxStores) {
			return { maxStores: true };
		}
		return null;
	}
}
