import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-insights-emissions-per-minute',
	templateUrl: './emissions-per-minute.component.html',
	styleUrls: ['./emissions-per-minute.component.scss'],
})
export class EmissionsPerMinuteComponent {
	@Input()
	public size: 'extra-small' | 'small' | 'medium' = 'small';

	@Input()
	public data: number | null = null;

	constructor() {}
}
