import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatest, map, Observable, startWith, take } from 'rxjs';
import * as fromRoot from 'src/app/store';
import * as fromSession from 'src/app/store/session';
import * as fromBookingCreationSelectors from '../../state/bookings-create.selectors';
import * as fromBookingCreationActions from '../../state/bookings-create.actions';
import { Store } from '@ngrx/store';
import { BrandParent } from '../../../../shared/models/api/brands/BrandParent';
import { Item } from 'src/app/models/_old/item';
import { TableColumn } from 'src/app/components/apple/apple-table/models/column';
import { Store as PlatformStore } from 'src/app/shared/models/api/store/Store';
import { Touchpoint, Category, Booking, ShareType } from '../../models';
import { regex } from 'src/app/shared/components/form-field/utils/regex';
import { Brand } from 'src/app/shared/models/api/brands/Brand';
import { CustomValidators } from 'src/app/validators/custom-validators';
import { ActivatedRoute } from '@angular/router';
import { Location } from '../../models/Location';
import { CustomFormField } from '../../models/CustomFormField';
import { DateHelper } from 'src/app/helpers/date';
import { Banner } from 'src/app/components/banner/banner.component';

@UntilDestroy()
@Component({
	selector: 'app-booking',
	templateUrl: './booking.component.html',
	styleUrls: ['./booking.component.scss'],
})
export class BookingComponent implements OnInit {
	public banner: Banner | null = null;
	public bookingId = this._route.parent?.snapshot.params['uid'];
	public roles$: Observable<string[]> = this._store.select(fromSession.getRoles).pipe(untilDestroyed(this));
	public saving$: Observable<boolean> = this._store.select(fromBookingCreationSelectors.selectSaving);

	public touchpoints$: Observable<Touchpoint[]> = this._store
		.select(fromBookingCreationSelectors.selectTouchpoints)
		.pipe(untilDestroyed(this));

	public customFields$: Observable<CustomFormField[]> = this._store
		.select(fromBookingCreationSelectors.customFields)
		.pipe(untilDestroyed(this));

	public currency$: Observable<string> = this._store
		.select(fromBookingCreationSelectors.selectCurrency)
		.pipe(untilDestroyed(this));

	public types$: Observable<Item[]> = this._store
		.select(fromBookingCreationSelectors.selectShareTypes)
		.pipe(untilDestroyed(this));

	public brands$: Observable<BrandParent[]> = this._store
		.select(fromBookingCreationSelectors.selectBrandParents)
		.pipe(untilDestroyed(this));

	public pagedStores$: Observable<PlatformStore[]> = this._store
		.select(fromBookingCreationSelectors.selectPagedStores)
		.pipe(untilDestroyed(this));

	public allStores$: Observable<PlatformStore[]> = this._store
		.select(fromBookingCreationSelectors.selectAllStores)
		.pipe(untilDestroyed(this));

	public storeLocation$: Observable<Location | null> = this._store
		.select(fromBookingCreationSelectors.storeLocation)
		.pipe(untilDestroyed(this));

	public exportStores$: Observable<any> = this._store
		.select(fromBookingCreationSelectors.selectExportStores)
		.pipe(untilDestroyed(this));

	public selectedStores$: Observable<PlatformStore[]> = this._store
		.select(fromBookingCreationSelectors.selectFilteredStores)
		.pipe(untilDestroyed(this));

	public booking$: Observable<Booking | null> = this._store
		.select(fromBookingCreationSelectors.selectBooking)
		.pipe(untilDestroyed(this));

	public defaultZoom$: Observable<number> = this._store.select(fromSession.getFilters).pipe(
		map((location) => location?.company.branding.map.zoom),
		untilDestroyed(this)
	);

	public form: FormGroup = new FormGroup({
		id: new FormControl<string | null>(null),
		name: new FormControl<string>('', { validators: [Validators.required] }),
		touchpoint: new FormControl<Touchpoint | null>(null, { validators: [Validators.required] }),
		category: new FormControl<Category | null>(null, { validators: [Validators.required] }),
		duration: new FormGroup({
			start: new FormControl(null, { validators: [Validators.required] }),
			end: new FormControl(null, { validators: [Validators.required] }),
		}),
		storesRequested: new FormControl<number>(0, {
			validators: [
				Validators.required,
				CustomValidators.regexValidator(Validators.pattern(regex.numbers), 'numbers'),
				CustomValidators.maxStoresValidator,
			],
		}),
		storesSelected: new FormControl<any[]>([], {}),
		brand: new FormControl<BrandParent | null>(null, { validators: [Validators.required] }),
		brandChildren: new FormControl<Brand[]>([], { validators: [Validators.required] }),
		value: new FormControl<string>('', {
			validators: [Validators.required, CustomValidators.regexValidator(Validators.pattern(regex.numbers), 'numbers')],
		}),
		type: new FormControl<ShareType | null>(null, { validators: [Validators.required] }),
		client: new FormGroup({
			email: new FormControl<string>('', {
				validators: [Validators.required, CustomValidators.regexValidator(Validators.pattern(regex.email), 'email')],
			}),
			firstname: new FormControl<string>('', { validators: [Validators.required] }),
			lastname: new FormControl<string>('', { validators: [Validators.required] }),
		}),
		bookingStatus: new FormControl<string>(''),
		campaignStatus: new FormControl<string>(''),
		customFields: new FormGroup({}),
	});

	public columns: TableColumn[] = [
		{ header: 'Store Name', field: 'storeName', sortable: false },
		{ header: 'Region', field: 'region', sortable: false, breakpoint: 'tablet' },
		{ header: 'Store Code', field: 'storeCode', sortable: false },
	];

	public canDelete$!: Observable<boolean>;
	public canUpdate$!: Observable<boolean>;

	public prefixField$: Observable<CustomFormField | undefined> = this.customFields$.pipe(
		map((fields) => fields.find((field) => field.placeholder)),
		untilDestroyed(this)
	);

	public regularCustomFields$: Observable<CustomFormField[]> = this.customFields$.pipe(
		map((fields) => fields.filter((field) => !field.placeholder)),
		untilDestroyed(this)
	);

	constructor(
		private _store: Store<fromRoot.State>,
		private _route: ActivatedRoute,
		private _cdr: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.customFields$.pipe(untilDestroyed(this)).subscribe((customFields) => {
			customFields.forEach((field) => {
				if (!this.form.contains(field.name)) {
					const control = new FormControl(field.value);
					this.form.addControl(field.name, control);
				}
			});
		});
		// prettier-ignore
		combineLatest([this.touchpoints$, this.types$, this.booking$, this.brands$])
			.pipe(take(1))
			.subscribe(([touchpoints, types, booking, brands]) => {
				const lookup = <T extends { id: number }>(list: T[], id: number | undefined, defaultValue: T): T =>
					list.find((item) => item.id === id) || defaultValue;

				const touchpoint = lookup(touchpoints, booking?.touchpoint.id, touchpoints[0]);
				const category = lookup(touchpoint.categories, booking?.touchpoint.categories[0]?.id, touchpoint.categories[0]);
				const type = lookup(types, booking?.share?.id, types[0]);
				const brand = booking ? brands.find((brand) => brand.id === booking.brands.id) : null;
				const brandChildren =
					booking && brand ? brand.children.filter((c) => booking.brands.children.some((i) => i.id === c.id)) : [];
				const duration = {
					start: booking?.start ? DateHelper.convertDateString(booking.start) : null,
					end: booking?.end ? DateHelper.convertDateString(booking.end) : null,
				};

				//we have to subscribe to the category value changes to trigger the store loading for edits
				this.form.controls['category'].valueChanges.pipe(untilDestroyed(this)).subscribe((category) => {
					this._store.dispatch(fromBookingCreationActions.loadStores({ categoryId: category.id }));
				});

				this.form.patchValue({
					...booking,
					touchpoint,
					category,
					type,
					brand,
					brandChildren,
					duration,
				});

				this.form.controls['touchpoint'].valueChanges.pipe(untilDestroyed(this)).subscribe((touchpoint) => {
					this.form.controls['category'].setValue(touchpoint.categories[0]);
				});

				//we dont want the reset logic until after we have patched the initial values
				this.form.controls['category'].valueChanges.pipe(untilDestroyed(this)).subscribe((category) => {
					this.form.patchValue({
						storesRequested: 0,
						storesSelected: [],
					});
					this.onSelectAllStores(false);
				});

				this.form.controls['brand'].valueChanges.pipe(untilDestroyed(this)).subscribe((brand) => {
					this.form.controls['brandChildren'].setValue([]);
				});
			}
			);

		const bookingStatus$ = this.form.controls['bookingStatus'].valueChanges.pipe(
			startWith(this.form.controls['bookingStatus'].value)
		);
		this.canDelete$ = combineLatest([this.roles$, bookingStatus$]).pipe(
			map(
				([roles, bookingStatus]) =>
					(bookingStatus === 'Confirmed' && !roles.includes('campaign-admin')) ||
					bookingStatus === 'Provisional' ||
					this.isCampaignStatus('active')
			),
			untilDestroyed(this)
		);
		this.canUpdate$ = combineLatest([this.roles$, bookingStatus$]).pipe(
			map(([roles, bookingStatus]) => bookingStatus !== 'Confirmed' || roles.includes('campaign-admin')),
			untilDestroyed(this)
		);

		this.selectedStores$.pipe(untilDestroyed(this)).subscribe((stores) => {
			this.form.controls['storesSelected'].setValue(stores);
		});
	}

	updateStatus() {
		this._store.dispatch(fromBookingCreationActions.confirmBooking({ bookingId: this.bookingId }));
	}

	handleDataImported(data: any[]): void {
		this.banner = null;
		const storeCodes = data.map((item) => `${item['Store Code']}`);

		if (storeCodes.length === 0) {
			this.banner = {
				type: 'error',
				title: 'Store Code Missing',
				description: 'No Store Code was found in the uploaded data.',
			};
			return;
		}

		this.allStores$.pipe(take(1)).subscribe((stores) => {
			const validStores = stores.filter((store) => storeCodes.includes(store.storeCode));
			const requestedStoresCount = this.form.controls['storesRequested'].value;
			const invalidCount = storeCodes.length - validStores.length;

			if (validStores.length === 0) {
				this.banner = {
					type: 'error',
					title: 'No Stores Found',
					description: 'Upload a file containing store data to continue.',
				};
				return;
			}

			if (validStores.length > requestedStoresCount) {
				this.banner = {
					type: 'warning',
					title: 'Too Many Stores',
					description: `Found ${validStores.length} stores, ${requestedStoresCount} requested.`,
				};
				return;
			}

			let type: 'success' | 'warning' = 'warning';
			let title = 'Not Enough Stores';
			let description = `Found ${validStores.length} of ${requestedStoresCount} requested stores.`;

			if (validStores.length === Number(requestedStoresCount)) {
				if (invalidCount > 0) {
					title = 'Review Required';
					description = `Found ${validStores.length} of ${requestedStoresCount} requested stores. Some stores uploaded were invalid but the rest were valid.`;
				} else {
					type = 'success';
					title = 'Successfully Uploaded';
					description = `${validStores.length} stores ready.`;
				}
			} else if (invalidCount > 0) {
				description += ` There are ${invalidCount} stores that were not found in the list. These might be duplicates stores or not valid stores.`;
			}

			this.banner = { type, title, description };

			this._store.dispatch(fromBookingCreationActions.selectAllStores({ select: false }));
			this._store.dispatch(fromBookingCreationActions.selectStores({ stores: validStores }));
		});
	}

	submitBooking(): void {
		if (this.form.valid) {
			this.customFields$.pipe(take(1)).subscribe((customFields) => {
				const prefixField = customFields.find((field) => field.placeholder);
				const nameValue = this.form.controls['name'].value;
				const isEditing = !!this.form.controls['id'].value;
				const campaignName = isEditing
					? nameValue
					: prefixField
						? `${prefixField.placeholder} ${nameValue}`
						: nameValue;

				const customFieldsArray = customFields.map<any>((field) => {
					const formValue = this.form.get(field.name)?.value;
					const value = typeof formValue === 'object' && formValue !== null ? formValue.id : formValue;
					return {
						id: field.id,
						type: field.type,
						value: value,
					};
				});

				this._store.dispatch(
					fromBookingCreationActions.submitBooking({
						booking: {
							id: this.form.controls['id'].value,
							name: campaignName,
							touchpoint: {
								...this.form.controls['touchpoint'].value,
								categories: [this.form.controls['category'].value],
							},
							start: DateHelper.formatDate(this.form.controls['duration'].get('start')?.value),
							end: DateHelper.formatDate(this.form.controls['duration'].get('end')?.value),
							storesRequested: this.form.controls['storesRequested'].value,
							storesSelected: this.form.controls['storesSelected'].value.map((store: PlatformStore) => store.id),
							brands: {
								...this.form.controls['brand'].value,
								children: this.form.controls['brandChildren'].value,
							},
							value: this.form.controls['value'].value,
							share: this.form.controls['type'].value,
							client: {
								email: this.form.get('client.email')!.value,
								firstname: this.form.get('client.firstname')!.value,
								lastname: this.form.get('client.lastname')!.value,
							},
							bookingStatus: this.form.controls['bookingStatus'].value,
							campaignStatus: this.form.controls['campaignStatus'].value,
							media: [],
							customFields: customFieldsArray,
						},
					})
				);
			});
		}
	}

	pageStores(event: { page: number; size: number }) {
		this._store.dispatch(fromBookingCreationActions.setPage({ page: event.page, size: event.size }));
	}

	onStoresSelected(store: any) {
		this._store.dispatch(fromBookingCreationActions.selectStores({ stores: [store] }));
	}

	onStoresDeselected(store: any) {
		this._store.dispatch(fromBookingCreationActions.deselectStores({ stores: [store] }));
	}

	onSelectAllStores(select: boolean) {
		this._store.dispatch(fromBookingCreationActions.selectAllStores({ select }));
	}

	getStoreCountClass(): string {
		const selected = this.form.controls['storesSelected'].value.length;
		const requested = this.form.controls['storesRequested'].value;
		return selected <= requested ? '' : selected == requested ? 'store-count-green' : 'store-count-red';
	}

	deleteBooking(): void {
		this._store.dispatch(fromBookingCreationActions.deleteBooking({ bookingId: this.bookingId }));
	}

	cancelBooking(): void {
		this.form.reset();
		this._store.dispatch(fromBookingCreationActions.cancelBooking());
	}

	isBookingStatus(statuses: string | string[]): boolean {
		const currentStatus = this.form.controls['bookingStatus'].value;
		if (Array.isArray(statuses)) {
			return statuses.includes(currentStatus);
		}
		return currentStatus === statuses;
	}

	isCampaignStatus(statuses: string | string[]): boolean {
		const currentStatus = this.form.controls['campaignStatus'].value;
		if (Array.isArray(statuses)) {
			return statuses.includes(currentStatus);
		}
		return currentStatus === statuses;
	}
}
