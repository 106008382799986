<app-apple-card [shadow]="true" [style]="{ width: '75vw', 'max-width': '350px' }">
	<div class="content">
		<div class="icon">
			<img class="img" src="../../../../assets/message.svg" />
		</div>
		<div class="title">Thank you!</div>
		<div class="message">Please check your inbox, the team will be reaching out soon to confirm your account.</div>
		<div class="action">
			<div class="button">
				<a class="link" href="/auth/login">Sign In</a>
			</div>
		</div>
		<div class="logo">
			<img class="img" src="../../../../assets/barrows.png" />
		</div>
	</div>
</app-apple-card>
