import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-apple-tooltip',
	templateUrl: './apple-tooltip.component.html',
	styleUrls: ['./apple-tooltip.component.scss'],
})
export class AppleTooltipComponent {
	@Input()
	public open: boolean = false;
}
