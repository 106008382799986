<app-card>
	<div class="container" *ngIf="!loading; else loadingTemplate">
		<div class="header">
			<div class="header-title">{{ data?.header }}</div>
			<div class="header-subtitle">{{ data?.subHeader }}</div>
		</div>
		<ng-container *ngIf="data?.body as items">
			<ng-container *ngIf="items.length > 0; else noItems">
				<app-carousel [items]="items" [count]="count">
					<ng-template #slideTemplate let-item>
						<div class="item-wrapper">
							<div class="item-preview">
								<video autoplay loop [muted]="true" [src]="item.video"></video>
							</div>
							<div class="item-container">
								<div class="item-details">
									<div *ngIf="item.header" class="item-title">{{ item.header }}</div>
									<div *ngIf="item.subHeader" class="item-subtitle">{{ item.subHeader }}</div>
								</div>
								<div *ngIf="item.value" class="item-value">{{ item.value }}</div>
							</div>
						</div>
					</ng-template>
				</app-carousel>
			</ng-container>
		</ng-container>
		<ng-template #noItems>
			<div class="connection-points-list">
				<app-no-data
					class="no-data"
					no-data
					image="../../../../../assets/no-data-category.svg"
					title="No Results"
					message="Try updating your filters."></app-no-data>
			</div>
		</ng-template>
	</div>
	<ng-template #loadingTemplate>
		<div class="container">
			<div class="header">
				<ngx-skeleton-loader [theme]="{ width: '120px', height: '19px', 'border-radius': '6px' }"></ngx-skeleton-loader>
				<ngx-skeleton-loader [theme]="{ width: '180px', height: '17px', 'border-radius': '6px' }"></ngx-skeleton-loader>
			</div>
			<div class="item-list">
				<div class="item-wrapper" *ngFor="let item of [].constructor(count)">
					<div class="item-preview">
						<ngx-skeleton-loader [theme]="{ height: '270px', 'border-radius': '8px' }"></ngx-skeleton-loader>
					</div>
					<div class="item-container">
						<div class="item-details">
							<ngx-skeleton-loader
								[theme]="{ width: '140px', height: '17px', 'border-radius': '6px' }"></ngx-skeleton-loader>
							<ngx-skeleton-loader
								[theme]="{ width: '100px', height: '17px', 'border-radius': '6px' }"></ngx-skeleton-loader>
						</div>
						<div class="item-value">
							<ngx-skeleton-loader
								[theme]="{ width: '50px', height: '26px', 'border-radius': '8px' }"></ngx-skeleton-loader>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
</app-card>
