import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import * as fromRoot from '../../store';
import * as fromUser from '../../store/user';
import { Observable, map, combineLatest } from 'rxjs';
import { tap, filter } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import * as contentActions from './store/content.actions';
import * as contentSelectors from './store/content.selectors';

import { ToastService } from '../../services/toast.service';
import { ContentApprover, ContentDetails } from './store';

@UntilDestroy()
@Component({
	selector: 'app-content-approval',
	templateUrl: './content-approval.component.html',
	styleUrls: ['./content-approval.component.scss'],
})
export class ContentApprovalComponent implements OnInit {
	approvalComplianceConfirmation: boolean = false;
	uuid: string | null = '';
	createComment: string = '';
	public user$: Observable<fromUser.UserState>;
	isDialogOpen = false;
	comments$ = this.store.select(contentSelectors.selectComments);
	isLoading$: Observable<boolean> = this.store.select(contentSelectors.selectDetailsLoading);
	isLoadingComments$: Observable<boolean> = this.store.pipe(select(contentSelectors.selectCommentsLoading));
	contentDetails$: Observable<ContentDetails | null> = this.store.select(contentSelectors.selectDetails);
	approvalLoading$: Observable<boolean>;

	approvers$: Observable<ContentApprover[]> = this.store.pipe(select(contentSelectors.selectApprovals));
	isUserApproved$!: Observable<boolean>;

	constructor(
		private store: Store<fromRoot.State>,
		private route: ActivatedRoute,
		private toastService: ToastService
	) {
		this.user$ = this.store.select(fromUser.getUserState).pipe(untilDestroyed(this));
		this.contentDetails$ = this.store.select(contentSelectors.selectDetails);
		this.approvalLoading$ = this.store.pipe(select(contentSelectors.selectApprovalLoading));
		this.isLoadingComments$ = this.store.pipe(select(contentSelectors.selectCommentsLoading));
	}

	ngOnInit(): void {
		this.isLoading$ = this.store.select(contentSelectors.selectDetailsLoading);
		this.isLoadingComments$ = this.store.select(contentSelectors.selectCommentsLoading);
		this.uuid = this.route.snapshot.paramMap.get('uuid');

		if (this.uuid) {
			this.store.dispatch(contentActions.loadContentDetails({ uuid: this.uuid }));
			this.store.dispatch(contentActions.loadContentApproval({ uuid: this.uuid }));
			this.store.dispatch(contentActions.loadComments({ uuid: this.uuid }));
		}

		this.subscribeToStoreUpdates();

		this.isUserApproved$ = combineLatest([this.approvers$, this.user$]).pipe(
			map(([approvers, user]) => {
				const approver = approvers.find((a) => a.email === user.details.email);
				return (approver && approver.approved !== null) || false;
			})
		);
	}

	private subscribeToStoreUpdates(): void {
		this.store
			.pipe(
				select(contentSelectors.selectSuccess),
				filter((success) => success),
				tap(() => {
					if (this.uuid) {
						this.showPopup('success', 'success', 'Your comment has been posted successfully!');
					}
				}),
				untilDestroyed(this)
			)
			.subscribe();

		this.store
			.pipe(
				select(contentSelectors.selectApprovalStatus),
				tap((approvalStatus) => {
					if (approvalStatus === 'approved') {
						this.showPopup('success', 'success', 'This content has been approved!');
					} else if (approvalStatus === 'denied') {
						this.showPopup('info', 'information', 'This content has been rejected!');
					}
				}),
				untilDestroyed(this)
			)
			.subscribe();
	}

	postComment(): void {
		if (this.createComment.trim() !== '' && this.uuid !== null) {
			const commentObj = {
				comment: this.createComment,
				comment_timestamp: null,
			};

			this.store.dispatch(contentActions.addComment({ uuid: this.uuid, comment: commentObj }));
			this.createComment = '';
		}
	}

	showPopup(type: 'success' | 'error' | 'info', title: string, message: string): void {
		this.toastService.open(type, title, message, 4000);
	}

	approvalConfirmation(approved: boolean, reason: string = '') {
		if (approved) {
			this.store.dispatch(contentActions.approveContent({ uuid: this.uuid! }));
			this.approvalComplianceConfirmation = true;
		} else {
			this.isDialogOpen = true;
		}
	}

	handleDialogResult(event: any) {
		if (event.submit) {
			this.store.dispatch(contentActions.denyContent({ uuid: this.uuid!, reason: event.reason }));
		}
		this.isDialogOpen = false;
	}
}
