import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-apple-loader',
	templateUrl: './apple-loader.component.html',
	styleUrls: ['./apple-loader.component.scss'],
})
export class AppleLoaderComponent {
	@Input() loading: boolean | null = false;
	@Input() fontSize: string = '4em';
	@Input() title!: string;
	@Input() message!: string;

	constructor() {}
}
