<div class="form-field" [class.form-field__show-error]="hasError()">
	<div class="form-field__control">
		<ng-content></ng-content>

		<div class="form-field__error-container">
			<div *ngIf="hasError()" class="form-field__show-error__error-details">
				<ng-container *ngIf="control && errorKey" [ngSwitch]="errorKey">
					<span *ngSwitchCase="'required'">This field is required</span>
					<span *ngSwitchDefault>{{ getErrorMessage(errorKey) }}</span>
				</ng-container>
			</div>
		</div>
	</div>
</div>
