import { Component, Input, OnInit } from '@angular/core';
import * as fromRoot from '../../../../../store';
import { Store } from '@ngrx/store';
import { switchMap } from 'rxjs';
import { ScreenshotService } from 'src/app/services/screenshot.service';
import { WebSocketResponse } from 'src/app/shared/models/api/WebSocketResponse';
import { Screenshot } from 'src/app/shared/models/api/Screenshot';

@Component({
	selector: 'app-screenshot',
	templateUrl: './screenshot.component.html',
	styleUrls: ['./screenshot.component.scss'],
})
export class ScreenshotComponent implements OnInit {
	@Input()
	public serial!: string;

	@Input()
	public url!: string;

	@Input()
	public orientation!: string;

	@Input()
	public latest_screenshot_date!: string;

	@Input()
	public loading: boolean = false;

	public actionHover: boolean = false;
	public showFullSizePreview: boolean = false;

	constructor(
		private _store: Store<fromRoot.State>,
		private _screenshots: ScreenshotService
	) {}

	ngOnInit(): void {
		this._store
			.select((state) => state.user.token)
			.pipe(
				switchMap((token) => {
					this._screenshots.connect(`${token}`);
					return this._screenshots.screenshots;
				})
			)
			.subscribe((data: WebSocketResponse<Screenshot>) => {
				if (this.serial == data.message.serial) {
					this.url = data.message.url;
					this.orientation = data.message.orientation;
					this.latest_screenshot_date = data.message.latest_screenshot_date;
					this.loading = false;
				}
			});
	}

	requestScreenshot() {
		if (this.loading == false) {
			this.loading = true;
			this._screenshots.requestScreenshot(this.serial);
		}
	}

	getContainerStyles(item: String = 'Landscape') {
		return {
			'left.px': item === 'Landscape' ? -282 : -192,
		};
	}
}
