import { createAction, props } from '@ngrx/store';
import { LoginPayload } from './models/login';
import { User } from './models/user';
import { AccountRequestPayload, AccountRequestTokenValidationPayload } from './models/request';
import { OAuthRegistrationPayload, PasswordRegistrationPayload, RegistrationPayload } from './models/register';
import { NeptuneError } from 'src/app/shared/models/api/NeptuneError';

/* Login */
export const loginWithOAuth = createAction(
	'[User] Login with OAuth',
	props<{ provider: 'google' | 'microsoft'; redirect: string }>()
);
export const loginWithCredentials = createAction(
	'[User] Login with Credentials',
	props<{ payload: LoginPayload; redirect: string }>()
);
export const loginSuccess = createAction('[User] Login Success', props<{ user: User; redirect: string }>());

/* Account Request */
export const requestAccount = createAction('[User] Account Requested', props<{ user: AccountRequestPayload }>());

/* Account Request Token Validation */
export const requestAccountTokenValidation = createAction(
	'[User] Get User Data',
	props<{ request: AccountRequestTokenValidationPayload }>()
);
export const requestAccountTokenValidationSuccess = createAction(
	'[User] User Data Retrieved',
	props<{ name: string; email: string }>()
);

/* Registration */
export const registerWithOAuth = createAction(
	'[User] Registration with OAuth',
	props<{ provider: 'google' | 'microsoft'; uuid_token: string }>()
);
export const registerWithToken = createAction(
	'[User] Registration with Token',
	props<{ payload: OAuthRegistrationPayload; provider: 'google' | 'microsoft' }>()
);
export const registerWithCredentials = createAction(
	'[User] Registration with Credentials',
	props<{ payload: PasswordRegistrationPayload }>()
);

/* Password Reset */
export const requestPasswordReset = createAction('[User] Password Reset Requested', props<{ email: string }>());
export const requestPasswordResetSuccess = createAction(
	'[User] Password Reset Requested Success',
	props<{ email: string }>()
);
export const resetPassword = createAction('[User] Password Reset', props<{ password: string; reset_token: string }>());
export const resetPasswordSuccess = createAction('[User] Password Reset Success');

/* Error */
export const error = createAction('[User] Error', props<{ error: NeptuneError }>());

/* Logout */
export const logout = createAction('[User] Logout');
