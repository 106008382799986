import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-unit-preview',
	templateUrl: './unit-preview.component.html',
	styleUrls: ['./unit-preview.component.scss'],
})
export class UnitPreviewComponent {
	@Input()
	public image: string = '';

	@Input()
	public touchpoint: string = '';

	@Input()
	public category: string = '';
}
