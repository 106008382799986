import { Component, Input } from '@angular/core';
import { TouchpointCard } from 'src/app/features/dashboard/state';

@Component({
	selector: 'app-touchpoint-container',
	templateUrl: './touchpoint-container.component.html',
	styleUrls: ['./touchpoint-container.component.scss'],
})
export class TouchpointContainerComponent {
	@Input() public cards!: TouchpointCard[];
}
