import {
	AfterContentChecked,
	AfterViewChecked,
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { TouchpointInformation, TouchpointCampaign, TouchpointMetric } from '../../../state';

@Component({
	selector: 'app-touchpoint-details',
	templateUrl: './touchpoint-details.component.html',
	styleUrls: ['./touchpoint-details.component.scss'],
})
export class TouchpointDetailsComponent implements AfterViewInit {
	@ViewChild('carousel') carousel!: ElementRef;
	@ViewChild('container') container!: ElementRef;

	@Input() name!: string;
	@Input() category!: string;
	@Input() information!: TouchpointInformation[];
	@Input() previews!: TouchpointCampaign[];
	@Input() metrics!: TouchpointMetric[];
	@Input() bookingsAllowed: boolean = false;
	@Input() imageUrl!: string;
	@Input() id!: number;
	@Input() categoryId!: number;
	@Input() orientation!: string;
	@Input() variantCount!: number;
	@Input() variantId: number | null = null;

	@Output() onVariantVisibilityChange = new EventEmitter<boolean>();

	index: number = 0;
	pages: any[] = [];
	tooltip: boolean = false;
	expanded: boolean = false;
	showNpcPopup: boolean[] = [];
	atGalleryStart: boolean = true;
	atGalleryEnd: boolean = false;

	dimensions: any = {
		portrait: {
			width: 150,
			height: 267,
		},
		landscape: {
			width: 300,
			height: 170,
		},
	};

	navigation: boolean = false;

	constructor(private cdr: ChangeDetectorRef) {}

	ngAfterViewInit(): void {
		const elem = this.container.nativeElement as HTMLElement;
		const width = Math.floor(elem.offsetWidth);
		const visible = Math.floor(width / this.dimensions[this.orientation].width);
		if (visible > 0) {
			for (let i = 0; i < this.previews.length; i += visible) {
				this.pages.push(this.previews.slice(i, i + (visible + 1)));
			}
			this.navigation = this.pages.length > 1;
			this.cdr.detectChanges();
		}
		this.updateGalleryState();
	}

	@HostListener('document:click', ['$event.target'])
	closeTooltip(target: any) {
		if (!target.closest('.dropdown')) {
			this.tooltip = false;
		}
	}

	toggleVariantVisibility() {
		this.expanded = !this.expanded;
		this.onVariantVisibilityChange.emit(this.expanded);
	}

	updateGalleryState() {
		this.atGalleryStart = this.index === 0;
		this.atGalleryEnd = this.index === this.pages.length - 1;
	}

	back() {
		var next = this.index - 1;
		if (next >= 0) {
			this.index = next;
			this.updateGalleryState();
		}
	}

	next() {
		var next = this.index + 1;
		if (next < this.pages.length) {
			this.index = next;
			this.updateGalleryState();
		}
	}

	width() {
		let width = this.dimensions[this.orientation].width;
		return {
			width: `${width}px`,
		};
	}

	height() {
		return {
			height: `${this.dimensions[this.orientation].height}px`,
		};
	}

	onVideoVisible({ target, visible }: { target: Element; visible: boolean }) {
		let video = target as any;
		if (visible) {
			var nopromise = {
				catch: new Function(),
			};
			(video.play() || nopromise).catch(function () {});
		} else {
			video.pause();
		}
	}

	isFirstSplashContent(index: number) {
		return this.previews.findIndex((x) => x.isSplash == true) == index;
	}

	getTooltipStyle() {
		const bottomPx = this.orientation === 'landscape' ? 108 : this.orientation === 'portrait' ? 155 : 0;
		return bottomPx ? { 'bottom.px': bottomPx } : {};
	}
}
