<div class="tab-navigation" *ngIf="items.length > 0">
	<div class="pages">
		<ng-container *ngFor="let item of items; trackBy: trackByFn">
			<div
				*ngIf="item.url; else noUrl"
				class="item"
				[routerLink]="item.url"
				[queryParams]="item.params"
				routerLinkActive="active"
				[routerLinkActiveOptions]="{ exact: true }">
				{{ item.name }}
				<img class="beta-badge" *ngIf="item.beta" src="/assets/beta-badge.svg" />
			</div>
			<ng-template #noUrl>
				<div class="item">
					{{ item.name }}
				</div>
			</ng-template>
		</ng-container>
	</div>
</div>
<!-- <ng-container *ngFor="let item of items; trackBy: trackByFn">
	<div *ngIf="item === selectedItem && item.component" class="tab-content">
		<ng-container *ngComponentOutlet="item.component; inputs: item.data"></ng-container>
	</div>
</ng-container> -->
