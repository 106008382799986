<div class="dashboard">
	<app-apple-loader
		[loading]="loading$ | async"
		title="Building your experience..."
		message="This should only take a moment.">
	</app-apple-loader>
	<div class="content" *ngIf="!(loading$ | async)">
		<ng-container *ngIf="summary$ | async as summary">
			<app-universe-summary [summary]="summary"></app-universe-summary>
		</ng-container>
		<ng-container *ngIf="cards$ | async as cards">
			<app-touchpoint-container [cards]="cards"></app-touchpoint-container>
		</ng-container>
		<app-apple-pagination
			*ngIf="!(loading$ | async)"
			(pageChange)="onPageChange($event)"
			[currentPage]="(page$ | async) ?? 1"
			[totalItems]="(count$ | async) ?? 0"
			[pageSizes]="[{ id: 1, name: '5' }]"></app-apple-pagination>
	</div>
</div>
