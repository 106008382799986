<div class="input-control">
	<label *ngIf="label" class="label">{{ label }}</label>
	<div class="wrapper" [ngClass]="{ disabled: disabled }">
		<ng-container [ngSwitch]="type">
			<ng-container *ngSwitchCase="'textarea'">
				<textarea
					[ngStyle]="style"
					class="input"
					[value]="value"
					[placeholder]="placeholder || ''"
					rows="3"
					[attr.disabled]="disabled ? true : null"
					(keyup)="onKeyup($event)"
					(blur)="onBlur()"></textarea>
			</ng-container>
			<ng-container *ngSwitchDefault>
				<div class="prefix" *ngIf="prefix">
					<span *ngIf="prefix.type === 'string'">{{ prefix.value }}</span>
					<img *ngIf="prefix.type === 'image'" [src]="prefix.value" alt="prefix" />
				</div>
				<input
					class="input"
					[ngStyle]="style"
					[type]="type"
					[value]="value"
					[placeholder]="placeholder || ''"
					[attr.disabled]="disabled ? true : null"
					(change)="onKeyup($event)"
					(keyup)="onKeyup($event)"
					(blur)="onBlur()" />
			</ng-container>
		</ng-container>
	</div>
</div>
