<div class="pagination" [class]="position" [style.visibility]="totalItems > 0 ? 'visible' : 'hidden'">
	<button (click)="prevPage()">
		<img class="nav" src="../../../../assets/page-back.svg" />
	</button>
	<ng-container *ngFor="let page of buildPages()">
		<ng-container *ngIf="page === -1; else pageContent">
			<span class="elipsis">&hellip;</span>
		</ng-container>
		<ng-template #pageContent>
			<div class="button-container">
				<button (click)="onPageChange(page)" [disabled]="+currentPage === page">
					{{ page }}
				</button>
			</div>
		</ng-template>
	</ng-container>
	<button (click)="nextPage()" class="next">
		<img class="nav" src="../../../../assets/page-forward.svg" />
	</button>
	<div class="page-sizes" [ngStyle]="{ display: enablePageSizePicker ? 'flex' : 'none' }">
		<div class="label">Items per page</div>
		<div class="dropdown">
			<app-apple-select
				name="pageSize"
				[items]="pageSizes"
				[selected]="pageSizes[0].id"
				(changed)="onPageSizeChanged($event)"></app-apple-select>
		</div>
	</div>
</div>
