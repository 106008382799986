import { combineLatest, distinctUntilChanged, map, Observable } from 'rxjs';

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { DateTime } from 'ts-luxon';

import { ButtonConfig } from '../../../components/apple/apple-button-group/models/buttonConfig';
import { FilterOption } from '../../../components/apple/apple-filter/models/filters';
import * as fromRoot from '../../../store';
import * as fromSession from '../../../store/session';
import * as fromBooking from './state';
import { FormGroup, FormBuilder } from '@angular/forms';
import { PresetType } from './components/booking-timeline/components/timeline/models/preset';
import { DateHelper } from '../../../helpers/date';

@UntilDestroy()
@Component({
	selector: 'app-booking-view',
	templateUrl: './booking-view.component.html',
	styleUrls: ['./booking-view.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingViewComponent {
	public loading$: Observable<boolean> = this._store.select(fromBooking.selectLoading).pipe(untilDestroyed(this));

	public header$: Observable<{ zoomLevel: string; showZoomLevel: boolean; filters: FilterOption[] }> = this._store
		.select(fromBooking.selectHeader)
		.pipe(untilDestroyed(this));

	public filters$: Observable<string[]> = this._store
		.select(fromBooking.selectFilters)
		.pipe(map((items) => items.applied.flatMap((item) => item.values)))
		.pipe(untilDestroyed(this));

	public filtersForm: FormGroup = this.fb.group({
		period: {
			start: null,
			end: null,
		},
	});

	public exportData$: Observable<any> = combineLatest([
		this._store.select(fromBooking.selectTabularBookings),
		this._store.select(fromBooking.selectAllTabularBookings),
		this._store.select((state) => state.booking.filters.applied),
		this._store.select((state) => state.booking.filters.search),
	]).pipe(
		map(([paginatedData, allData, filtersApplied, searchTerm]) =>
			(searchTerm || (filtersApplied && filtersApplied.length > 0) ? paginatedData : allData).map((booking) => ({
				Touchpoint: booking.touchpointAndCategory.firstValue,
				Category: booking.touchpointAndCategory.secondValue,
				'Holding Company': booking.companyAndBrands.firstValue,
				Brands: booking.companyAndBrands.secondValue,
				Stores: booking.storeCountDisplay,
				'Start Date': new Date(booking.startDateDisplay.formattedDate).toLocaleDateString('en-GB'),
				'End Date': new Date(booking.endDateDisplay.formattedDate).toLocaleDateString('en-GB'),
				Value: booking.valueDisplay,
				Status: booking.bookingStatus,
				Type: booking.shareTypeDisplay.type,
				Name: booking.bookingName,
			}))
		)
	);

	constructor(
		private _store: Store<fromRoot.State>,
		private _router: Router,
		private fb: FormBuilder
	) {
		this._store
			.select(fromSession.getFilters)
			.pipe(untilDestroyed(this))
			.subscribe((holdingCompany) => {
				this._store.dispatch(fromBooking.loadBookingData({ holdingCompanyId: holdingCompany.company.id }));
			});

		this._store
			.select(fromBooking.selectMeta)
			.pipe(untilDestroyed(this))
			.subscribe((meta) => {
				this.filtersForm.patchValue(
					{
						period: {
							start: meta.startDate,
							end: meta.endDate,
						},
					},
					{ emitEvent: false }
				);
			});
	}

	public zoomOptions: { id: number; name: PresetType }[] = [
		{ id: 1, name: 'Days' },
		{ id: 2, name: 'Weekly' },
		{ id: 3, name: 'Monthly' },
		{ id: 4, name: 'Quarterly' },
		{ id: 5, name: 'Years' },
	];

	public buttons: ButtonConfig[] = [
		{
			url: '/network/campaigns/view',
			svgPath: { inactive: '../../../assets/list-blue.svg', active: '../../../assets/list-white.svg' },
			title: 'List',
		},
		{
			url: '/network/campaigns/timeline',
			svgPath: { inactive: '../../../assets/timeline-blue.svg', active: '../../../assets/timeline-white.svg' },
			title: 'Timeline',
		},
	];

	onButtonGroupClick(button: ButtonConfig) {
		this._router.navigate([button.url]);
	}

	onZoomChanged(zoomLevel: any): void {
		this._store.dispatch(fromBooking.setZoomLevel({ zoomLevel: zoomLevel.value.name }));
	}

	onSearchStateChanged(state: string): void {
		this._store.dispatch(fromBooking.setSearchText({ searchText: state }));
	}

	onFilterStateChanged(event: any) {
		if (event.filters.length) {
			this._store.dispatch(fromBooking.setAppliedFilters(event));
		} else {
			this._store.dispatch(fromBooking.resetAppliedFilters());
		}
	}

	ngOnInit(): void {
		this.filtersForm
			.get('period')
			?.valueChanges.pipe(
				untilDestroyed(this),
				distinctUntilChanged((prev, curr) => prev.start === curr.start && prev.end === curr.end)
			)
			.subscribe((period: { start: Date; end: Date }) => {
				if (period && period.start && period.end) {
					this._store.dispatch(
						fromBooking.setDateRange({
							start: period.start,
							end: period.end,
						})
					);
				}
			});
	}

	back(): void {
		this._router.navigate(['/dashboard']);
	}
}
