export const environment = {
	type: 'staging',
	domain: 'portal-staging.barrows.tech',
	api: 'https://staging.barrows.tech',
	ws: 'wss://staging.barrows.tech',
	auth: {
		microsoft: {
			issuer: 'https://login.microsoftonline.com/common',
			redirectUri: window.location.origin + '/auth/callback',
			clientId: '362d9e93-7c62-4bc8-9dbf-8c217fa720b1',
			responseType: 'code',
			scope: 'openid profile email',
			strictDiscoveryDocumentValidation: false,
			showDebugInformation: true,
			skipIssuerCheck: true,
		},
		google: {
			issuer: 'https://accounts.google.com',
			redirectUri: window.location.origin + '/auth/callback',
			clientId: '82852893666-qujrek9il6scqt8aih60jbohc5euhhqf.apps.googleusercontent.com',
			scope: 'openid profile email',
			strictDiscoveryDocumentValidation: false,
		},
	},
	unleash: {
		endpoint: 'https://unleash.barrows.tech/api/frontend',
		clientKey: '*:development.1c6b8ec250267bb75605a8ee8074ef094822f855b7e16bf0b055895d',
		appName: '*',
		envrionment: 'development',
	},
};
