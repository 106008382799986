import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../store';
import * as fromUser from '../../../../store/user/user.actions';
import { CustomValidators } from 'src/app/validators/custom-validators';
import { Observable } from 'rxjs';
import { NeptuneError } from 'src/app/shared/models/api/NeptuneError';

@Component({
	selector: 'app-apple-forgot-password',
	templateUrl: './apple-forgot-password.component.html',
	styleUrls: ['./apple-forgot-password.component.scss'],
})
export class AppleForgotPasswordComponent {
	public form!: FormGroup;
	public loading = false;
	public email = new FormControl('');
	public loading$: Observable<boolean>;
	public error$: Observable<NeptuneError | null>;

	constructor(private _fb: FormBuilder, private _store: Store<fromRoot.State>) {
		this.loading$ = this._store.select((state) => state.user.loading);
		this.error$ = this._store.select((state) => state.user.error);

		this.form = this._fb.group({
			email: ['', [Validators.required, CustomValidators.emailValidator()]],
		});
	}

	resetPassword() {
		this.loading = true;
		this._store.dispatch(
			fromUser.requestPasswordReset({
				email: this.form.controls['email'].value,
			})
		);
	}

	public invalid(form: AbstractControl, prop: string, validator: string) {
		return form.get(prop)?.errors?.[validator];
	}
}
