import { Component, Input, Output, forwardRef, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Prefix } from './models/prefix';

@Component({
	selector: 'app-apple-input',
	templateUrl: './apple-input.component.html',
	styleUrls: ['./apple-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => AppleInputComponent),
			multi: true,
		},
	],
})
export class AppleInputComponent implements ControlValueAccessor {
	private _propagateChange: any = () => {};
	private _propagateTouched: any = () => {};

	@Input()
	public type: string = 'text';

	@Input()
	public label: string = '';

	@Input()
	public disabled: boolean = false;

	@Input()
	public placeholder: string = '';

	@Input()
	public style: { [key: string]: string } = {};

	@Input()
	public prefix: Prefix | null = null;

	@Output()
	public stateChange: EventEmitter<string> = new EventEmitter<string>();

	public value: string = '';

	writeValue(value: string): void {
		this.value = value;
	}

	registerOnChange(fn: any): void {
		this._propagateChange = fn;
	}

	registerOnTouched(fn: any): void {
		this._propagateTouched = fn;
	}

	onKeyup(value: any): void {
		this.value = value.target.value;
		this._propagateChange(this.value);
		this.stateChange.emit(this.value);
	}

	onBlur(): void {
		this._propagateTouched();
	}
}
