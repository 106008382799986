import { Pipe, PipeTransform } from '@angular/core';
import { FilterOption } from '../models/filters';

@Pipe({
	name: 'formatTableFilters',
})
export class FormatTableFiltersPipe implements PipeTransform {
	transform(input: FilterOption): any {
		return {
			label: input.name,
			type: input.type,
			values: input.values.map((value, index) => {
				return {
					id: index + 1,
					name: value,
				};
			}),
		};
	}
}
