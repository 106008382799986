import { Component, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { TabNavigationItem } from './models/TabNavigationItem';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
	selector: 'app-tab-navigation',
	templateUrl: './tab-navigation.component.html',
	styleUrls: ['./tab-navigation.component.scss'],
})
export class TabNavigationComponent {
	@Input() items: TabNavigationItem[] = [];
	selectedItem: TabNavigationItem | null = null;

	constructor(private router: Router) {
		this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe(() => this.updateSelectedItem());
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['items']) {
			this.updateSelectedItem();
		}
	}

	private updateSelectedItem(): void {
		const baseUrl = this.router.url;
		this.selectedItem = this.items.find((item) => baseUrl.startsWith(item.url)) || null;
	}

	trackByFn(index: number, item: TabNavigationItem): string {
		return item.url || index.toString();
	}
}
