import { ChangeDetectionStrategy, Component, HostListener, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';

import { filter, map, Observable, withLatestFrom } from 'rxjs';

import * as fromRoot from '../../store';
import * as fromUser from '../../store/user';
import * as fromSession from '../../store/session';

import { Store } from '@ngrx/store';
import { PlausibleService } from '@notiz/ngx-plausible';
import { FeatureFlagService } from 'src/app/services/unleash.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HoldingCompany } from 'src/app/shared/models/api/HoldingCompany';
import { ReportNavigation } from 'src/app/shared/models/api/reports/ReportNavigation';
import { NavigationItem } from 'src/app/shared/models/api/navigation/NavigationItem';
import { ReportNavigationItem } from 'src/app/shared/models/api/reports/ReportNavigationItem';

@UntilDestroy()
@Component({
	selector: 'app-container',
	templateUrl: './container.component.html',
	styleUrls: ['./container.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContainerComponent {
	public defaults = {
		'header-height': 60,
		'menu-width': 75,
		'menu-width-expanded': 300,
		'menu-open': false,
		'menu-expansion-state': -1,
		'render-accounts': true,
		'mobile-support': true,

		'outlet-padding-left': 25,
		'outlet-padding-right': 25,
		'outlet-padding-top': 0,
		'outlet-padding-bottom': 0,
		'background-color': 'var(--grey-background)',
	};
	public settings = {
		...this.defaults,
	};

	public loading$: Observable<boolean> = this.store.select(fromSession.getLoading).pipe(untilDestroyed(this));

	public navigation$: Observable<ReportNavigation[]> = this.store
		.select(fromSession.getReportItems)
		.pipe(untilDestroyed(this));

	public filteredNavigation$: Observable<any[]> = this.store
		.select(fromSession.selectFilteredReports)
		.pipe(untilDestroyed(this));

	public user$: Observable<{ name: string | null; image: string | null; email: string | null }> = this.store
		.select(fromUser.getUser)
		.pipe(untilDestroyed(this));

	public filters$: Observable<{ company: HoldingCompany }> = this.store
		.select(fromSession.getFilters)
		.pipe(untilDestroyed(this));

	public companies$: Observable<HoldingCompany[]> = this.store
		.select(fromSession.getHoldingCompanies)
		.pipe(untilDestroyed(this));

	constructor(
		private store: Store<fromRoot.State>,
		private route: ActivatedRoute,
		private router: Router,
		private location: Location,
		private plausible: PlausibleService,
		private featureFlagService: FeatureFlagService,
		private cdr: ChangeDetectorRef
	) {
		this.store.dispatch(fromSession.initializeSession());

		this.router.events
			.pipe(
				untilDestroyed(this),
				filter((event): event is NavigationEnd => event instanceof NavigationEnd),
				withLatestFrom(
					this.store.select((state) => state.user),
					this.store.select((state) => state.session),
					(event, user, session) => ({ event, user, session })
				),
				map(({ event, user, session }) => {
					// Update selected path on every navigation
					this.store.dispatch(fromSession.setSelectedItemPath({ path: event.urlAfterRedirects }));

					// accumulate the settings based on all child routes
					let route = this.route;
					let obj = { ...route.snapshot.data };

					while (route.firstChild) {
						route = route.firstChild;
						obj = {
							...obj,
							...(route.snapshot?.data || {}),
						};
					}

					// apply the settings ontop of the default set
					this.settings = { ...this.defaults, ...obj };
					this.cdr.markForCheck();

					return {
						email: user.details.email,
						page_url: event.urlAfterRedirects,
						company: session.filters.company,
					};
				})
			)
			.subscribe(({ email, page_url, company }) => {
				this.plausible.event('navigation', {
					props: {
						email,
						page_url,
						holdingCompany: company.id,
					},
				});
			});
	}

	filter(value: HoldingCompany) {
		this.store.dispatch(
			fromSession.updateSessionFilters({
				company: {
					id: value.id,
					name: value.name,
					roles: value.roles,
					branding: {
						logo: value.branding.logo,
						color: {
							primary: value.branding.color.primary,
							secondary: value.branding.color.secondary,
						},
						map: {
							zoom: value.branding.map.zoom,
							coordinates: {
								longitude: value.branding.map.coordinates.longitude,
								latitude: value.branding.map.coordinates.latitude,
							},
						},
					},
				},
			})
		);
	}

	style() {
		return {
			position: 'relative',
			'box-sizing': 'border-box',
			'padding-left': `${this.settings['outlet-padding-left']}px`,
			'padding-right': `${this.settings['outlet-padding-right']}px`,
			'padding-top': `calc(${this.settings['outlet-padding-top']}px + ${this.settings['header-height']}px)`,
			'padding-bottom': `${this.settings['outlet-padding-bottom']}px`,
			'background-color': this.settings['background-color'],
			'min-width': `calc(100vw - (${this.settings['menu-width']}px + ${this.settings['outlet-padding-left']}px + ${this.settings['outlet-padding-right']}px))`,
			'min-height': '100vh',
			display: 'flex',
			'flex-direction': 'column',
		};
	}

	menu() {
		this.settings['menu-open'] = !this.settings['menu-open'];
	}

	expand(event: any, item: ReportNavigation) {
		// stop the event bubbling
		event.stopPropagation();

		//the menu should always be open for expansion
		this.settings['menu-open'] = true;

		// check if the item is already expanded
		const current = this.settings['menu-expansion-state'];
		if (current === item.id) {
			this.settings['menu-expansion-state'] = -1;
		} else {
			this.settings['menu-expansion-state'] = item.id;
		}
	}

	isParentActive(items: ReportNavigationItem[]) {
		const active = items.find(
			(item) => this.router.url.startsWith(`/network/report/${item.path}`) || this.router.url.startsWith(item.path)
		);
		return !!active;
	}

	isChildActive(item: ReportNavigationItem) {
		return item.path.startsWith('/')
			? this.router.url === item.path
			: this.router.url == `/network/report/${item.path}`;
	}

	isMobileSupported(): boolean {
		const isMobile = window.innerWidth <= 768;
		const mobileSupported =
			!isMobile ||
			this.settings['mobile-support'] ||
			this.featureFlagService.isEnabled('client-portal-mobile-support-override');

		if (!mobileSupported) {
			this.plausible.event('mobile-unsupported', {
				props: {
					route: this.router.url,
				},
			});
		}

		return mobileSupported;
	}

	get redirectText() {
		const previousUrl = document.referrer;
		const currentDomain = window.location.origin;
		if (previousUrl && previousUrl.startsWith(currentDomain) && window.history.length > 1) {
			return 'Go Back';
		} else {
			return 'Go to Dashboard';
		}
	}

	redirectHandler() {
		if (window.history.length > 1) {
			this.location.back();
		} else {
			this.router.navigate(['/network/dashboard']);
		}
	}

	logout() {
		this.store.dispatch(fromUser.logout());
	}

	@HostListener('document:click', ['$event.target'])
	closeMenu(target: any) {
		if (!target.closest('.menu') && this.settings['menu-open']) {
			this.settings['menu-open'] = false;
		}
	}
}
