import { DateTime } from 'ts-luxon';

export class DateHelper {
	static formatDate(date: Date): string {
		const dateTime = DateTime.fromJSDate(date);
		return dateTime.toFormat('yyyy-MM-dd');
	}

	static convertDateString(dateString: string): Date {
		return DateTime.fromISO(dateString).toJSDate();
	}
}
