<ng-container *ngLet="loading$ | async as loading">
	<ng-container *ngLet="error$ | async as error">
		<ng-container *ngIf="loading">
			<app-loader [loading]="loading"></app-loader>
		</ng-container>
		<ng-container *ngIf="!loading && error">
			<app-apple-error>
				<span title> {{ error.title }} </span>
				<span message> {{ error.message }} </span>
			</app-apple-error>
		</ng-container>
		<ng-container *ngIf="!loading && !error">
			<app-apple-card [shadow]="true" [style]="{ width: '75vw', 'max-width': '400px' }">
				<div class="content">
					<div class="header">
						<div class="title">Forgot your password?</div>
						<div class="subtext">
							<span class="text"
								>No worries, enter the email associated with your account and we’ll send you reset instructions.</span
							>
						</div>
					</div>
					<form [formGroup]="form">
						<div class="reset-form">
							<div class="field">
								<app-apple-input label="Email" formControlName="email"></app-apple-input>
								<div class="validation">
									<div class="item" *ngIf="form.get('email')?.touched && invalid(form, 'email', 'required')">
										<span class="icon">
											<img class="cross" src="../../../../assets/cross-black.svg" />
										</span>
										<span class="invalid">This field is required.</span>
									</div>
									<div class="item" *ngIf="form.get('email')?.touched && invalid(form, 'email', 'invalidEmail')">
										<span class="icon">
											<img class="cross" src="../../../../assets/cross-black.svg" />
										</span>
										<span class="invalid">This is not a valid email.</span>
									</div>
								</div>
							</div>
						</div>
						<div class="reset-button">
							<app-apple-button
								color="var(--white)"
								background="var(--black)"
								[style]="{ 'font-weight': 'bold', 'font-size': '12px' }"
								[disabled]="form.invalid || loading"
								(click)="resetPassword()">
								<div class="button-content">
									<div *ngIf="!loading">RESET PASSWORD</div>
									<div *ngIf="loading">
										<i class="fa fa-solid fa-spinner fa-spin"></i>
									</div>
								</div>
							</app-apple-button>
						</div>
					</form>
					<div class="logo">
						<img class="image" src="../../../../assets/barrows.png" />
					</div>
				</div>
			</app-apple-card>
		</ng-container>
	</ng-container>
</ng-container>
