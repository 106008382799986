import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../store';
import * as fromUser from '../../../../store/user/user.actions';
import { ActivatedRoute } from '@angular/router';
import { CustomValidators } from '../../../../validators/custom-validators';
import { NeptuneError } from 'src/app/shared/models/api/NeptuneError';

@Component({
	selector: 'app-apple-request-account',
	templateUrl: './apple-request-account.component.html',
	styleUrls: ['./apple-request-account.component.scss'],
})
export class AppleRequestAccountComponent {
	public form!: FormGroup;
	public loading = false;
	public loading$: Observable<boolean>;
	public error$: Observable<NeptuneError | null>;

	constructor(private _route: ActivatedRoute, private _fb: FormBuilder, private _store: Store<fromRoot.State>) {
		this.loading$ = this._store.select((state) => state.user.loading);
		this.error$ = this._store.select((state) => state.user.error);

		this.form = this._fb.group({
			firstName: ['', Validators.required],
			lastName: ['', Validators.required],
			email: ['', [Validators.required, CustomValidators.emailValidator()]],
			company: ['', Validators.required],
		});
	}

	accountRequest(): void {
		this.loading = true;
		this._store.dispatch(
			fromUser.requestAccount({
				user: {
					firstName: this.form.controls['firstName'].value,
					lastName: this.form.controls['lastName'].value,
					email: this.form.controls['email'].value,
					company: this.form.controls['company'].value,
				},
			})
		);
	}

	public invalid(form: AbstractControl, prop: string, validator: string) {
		return form.get(prop)?.errors?.[validator];
	}
}
