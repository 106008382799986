import { Component, OnInit, ElementRef, ChangeDetectorRef, HostBinding } from '@angular/core';
import { TooltipPosition } from './tooltip.directive';

@Component({
	selector: 'app-tooltip',
	templateUrl: './tooltip.component.html',
	styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {
	@HostBinding('class') className = 'tooltip-container';

	text = '';
	position: TooltipPosition = 'top';
	hostElement?: HTMLElement;
	arrowPosition: 'center' | 'left' | 'right' = 'center';

	constructor(
		private elementRef: ElementRef,
		private cdr: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.updatePosition();
	}

	updatePosition(): void {
		if (!this.hostElement) return;

		const hostRect = this.hostElement.getBoundingClientRect();
		const tooltipRect = this.elementRef.nativeElement.getBoundingClientRect();
		const { top, left, arrowPosition } = this.calculatePosition(hostRect, tooltipRect);

		Object.assign(this.elementRef.nativeElement.style, {
			top: `${top}px`,
			left: `${left}px`,
		});

		this.arrowPosition = arrowPosition;
		this.cdr.detectChanges();
	}

	private calculatePosition(
		hostRect: DOMRect,
		tooltipRect: DOMRect
	): { top: number; left: number; arrowPosition: 'center' | 'left' | 'right' } {
		const spacing = -8;
		const viewportPadding = 8;
		let top = 0;
		let left = 0;
		let arrowPosition: 'center' | 'left' | 'right' = 'center';

		// Calculate initial centered position
		switch (this.position) {
			case 'top':
				top = hostRect.top - tooltipRect.height - spacing;
				left = hostRect.left + (hostRect.width - tooltipRect.width) / 2;
				break;
			case 'bottom':
				top = hostRect.bottom + spacing;
				left = hostRect.left + (hostRect.width - tooltipRect.width) / 2;
				break;
			case 'left':
				top = hostRect.top + (hostRect.height - tooltipRect.height) / 2;
				left = hostRect.left - tooltipRect.width - spacing;
				break;
			case 'right':
				top = hostRect.top + (hostRect.height - tooltipRect.height) / 2;
				left = hostRect.right + spacing;
				break;
		}

		const viewport = {
			width: window.innerWidth,
			height: window.innerHeight,
		};

		// Handle horizontal overflow for 'top' and 'bottom' positions
		if (['top', 'bottom'].includes(this.position)) {
			if (left < viewportPadding) {
				arrowPosition = 'left';
				left = hostRect.left;
			} else if (left + tooltipRect.width > viewport.width - viewportPadding) {
				arrowPosition = 'right';
				left = hostRect.right - tooltipRect.width;
			} else {
				arrowPosition = 'center';
			}

			// Ensure tooltip stays within viewport
			if (left < viewportPadding) {
				left = viewportPadding;
			} else if (left + tooltipRect.width > viewport.width - viewportPadding) {
				left = viewport.width - tooltipRect.width - viewportPadding;
			}
		}

		// Handle vertical overflow
		if (top < viewportPadding) {
			top = viewportPadding;
		} else if (top + tooltipRect.height > viewport.height - viewportPadding) {
			top = viewport.height - tooltipRect.height - viewportPadding;
		}

		return { top, left, arrowPosition };
	}
}
