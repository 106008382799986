<app-apple-card class="summary-card">
	<div class="content">
		<div class="logo">
			<img [src]="item.image" class="icon" (error)="setDefaultImage($event)" />
		</div>
		<div class="info">
			<div class="label">{{ item.header }}</div>
			<div class="value">{{ item.powerNumber }}</div>
			<div class="stats">
				<span class="icon">
					<img *ngIf="item.directionImage" [src]="item.directionImage" />
				</span>
				<span class="change">{{ item.relativeChange }}</span>
				<span class="period">{{ item.changePeriod }}</span>
			</div>
		</div>
	</div>
</app-apple-card>
