import { AfterViewInit, Component, forwardRef, Input } from '@angular/core';
import { Color } from './models/Color';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-color-picker',
	templateUrl: './color-picker.component.html',
	styleUrl: './color-picker.component.scss',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ColorPickerComponent),
			multi: true,
		},
	],
})
export class ColorPickerComponent implements ControlValueAccessor {
	public selected: Color | null = null;
	private propagateChange: any = () => {};
	private propagateTouched: any = () => {};

	@Input()
	public colors: Color[] = [];

	onColorClick(color: Color) {
		this.selected = color;
		this.propagateChange(color.hex);
		this.propagateTouched();
	}

	writeValue(value: string | null): void {
		console.log(value);
		if (value) {
			this.selected = this.colors.find((color) => color.hex === value) || null;
		}
	}

	registerOnChange(fn: any): void {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.propagateTouched = fn;
	}
}
