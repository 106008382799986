import { createAction, props } from '@ngrx/store';
import {
	PowerNumber,
	InsightTrends,
	MonetizedImpressions,
	DonutGraph,
	RevenueAnalysis,
	RevenueTable,
	CampaignMedia,
	TouchpointData,
	TouchpointProfile,
	PowerNumberChange,
	LineGraph,
} from '../../models/index';

import { Category } from './../models';

// Load Categories
export const loadCategories = createAction(
	'[Insights] Load Categories',
	props<{
		holdingCompany: number;
	}>()
);

export const loadCategoriesSuccess = createAction(
	'[Insights] Load Categories Success',
	props<{
		categories: Category[];
	}>()
);

export const loadCategoriesFailure = createAction('[Insights] Load Categories Failure', props<{ error: any }>());

// Filters
export const setFilter = createAction(
	'[Insight] Set Revenue Insights Filter',
	props<{
		filterName: string;
		value: any;
	}>()
);

export const getFilters = createAction('[Insights] Get Revenue Insights Filters');

// Load Insights
export const loadInsights = createAction(
	'[Insights] Load Insights',
	props<{
		selectedCategories?: number[];
		holdingCompany: number;
	}>()
);

export const loadInsightsSuccess = createAction(
	'[Insights] Load Insights Success',
	props<{
		currency: {
			code: string;
			symbol: string;
		};
		powerNumbers: PowerNumber[];
		trendNumbers: InsightTrends;
		monetizedImpressions: MonetizedImpressions;
		totalRevenue: DonutGraph;
		revenueAnalysis: LineGraph;
		touchpointRevenue: RevenueTable;
		clientRevenue: RevenueTable;
		campaignRevenue: CampaignMedia;
		touchpointDetails: {
			touchpoint: {
				name: string;
				category: {
					id: number;
					name: string;
				};
				image: string;
			};
			sellThroughRate: {
				value: string;
				percentage: number;
			};
			totalImpressions: {
				value: string;
			};
			campaignImpressions: {
				value: string;
			};
			campaignValue: {
				value: string;
			};
			spillValue: {
				value: string;
			};
			previousTrend: {
				value: string;
				percentage: string;
				change: {
					value: string;
					direction: string;
				};
			};
		}[];
	}>()
);

export const loadInsightsFailure = createAction('[Insights] Load Insights Failure', props<{ error: any }>());
