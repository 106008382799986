import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromRoot from '../../../../store';
import * as fromUser from '../../../../store/user/user.actions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CustomValidators } from '../../../../validators/custom-validators';

@Component({
	selector: 'app-apple-login',
	templateUrl: './apple-login.component.html',
	styleUrls: ['./apple-login.component.scss'],
})
export class AppleLoginComponent implements OnInit {
	public form!: FormGroup;

	constructor(
		private store: Store<fromRoot.State>,
		private route: ActivatedRoute
	) {}

	ngOnInit(): void {
		this.form = new FormGroup({
			email: new FormControl(null, [Validators.required, CustomValidators.emailValidator()]),
			password: new FormControl(null, Validators.required),
		});
	}

	loginWithEmail() {
		this.store.dispatch(
			fromUser.loginWithCredentials({
				payload: {
					username: this.form.controls['email'].value,
					password: this.form.controls['password'].value,
				},
				redirect: this.route.snapshot.queryParamMap.get('redirect') || '',
			})
		);
	}

	loginWithGoogle() {
		this.store.dispatch(
			fromUser.loginWithOAuth({
				provider: 'google',
				redirect: this.route.snapshot.queryParamMap.get('redirect') || '',
			})
		);
	}

	loginWithMicrosoft() {
		this.store.dispatch(
			fromUser.loginWithOAuth({
				provider: 'microsoft',
				redirect: this.route.snapshot.queryParamMap.get('redirect') || '',
			})
		);
	}
}
