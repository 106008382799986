import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import * as fromRoot from 'src/app/store/';
import * as fromSession from 'src/app/store/session';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-notification-icon',
	templateUrl: './notification-icon.component.html',
	styleUrls: ['./notification-icon.component.scss'],
})
export class NotificationIconComponent implements OnInit {

	public user$: Observable<fromSession.SessionState>;
	public outstandingApprovalCount$: Observable<{ approvalCount: number; latestApprovalUrl: string | null }>;

	constructor(private store: Store<fromRoot.State>) {
		this.user$ = this.store.select(fromSession.getSessionState).pipe(untilDestroyed(this));
		this.outstandingApprovalCount$ = this.store.select(fromSession.getOutstandingApprovalCount).pipe(untilDestroyed(this));
	}

	ngOnInit() {
		this.store.dispatch(fromSession.listenForOutstandingNotifications());
	}

	navigateTolatestApproval() {
		this.outstandingApprovalCount$.subscribe(data => {
			if (data.latestApprovalUrl) {
				const url = data.latestApprovalUrl;
				window.location.href = url;
			}
		});
	}
}
