import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-decline-dialog',
  templateUrl: './decline-dialog.component.html',
  styleUrls: ['./decline-dialog.component.scss']
})
export class DeclineDialogComponent {
  reason: string = '';

	@Output() dialogResult = new EventEmitter<{ submit: boolean; reason: string }>();

	close() {
		this.dialogResult.emit({ submit: false, reason: '' });
	}

	submit() {
		this.dialogResult.emit({ submit: true, reason: this.reason });
	}
}
