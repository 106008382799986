import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ContentDrilldownState } from './content-drilldown.reducers';

export const getContentDrilldownState = createFeatureSelector<ContentDrilldownState>('contentDrilldown');
export const selectMeta = createSelector(getContentDrilldownState, (state) => state.meta);
export const selectHeader = createSelector(getContentDrilldownState, (state) => state.header);
export const selectFilters = createSelector(getContentDrilldownState, (state) => state.filters);
export const selectLoading = createSelector(getContentDrilldownState, (state) => state.loading);
export const selectInitializing = createSelector(getContentDrilldownState, (state) => state.initializing);
export const selectFilteredTouchpoints = createSelector(getContentDrilldownState, (state) => state.data.displays);
export const selectFilteredTotalCount = createSelector(getContentDrilldownState, (state) => state.data.total_count);
