import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';

import * as fromTouchpointDrilldown from './touchpoint-drilldown.actions';
import * as fromTouchpointDrilldownSelectors from './touchpoint-drilldown.selectors';
import * as fromSession from '../../../store/session';
import * as fromRoot from '../../../store';
import { environment } from 'src/environments/environment';
import { TouchpointDrilldownHeader } from './models/TouchpointDrilldownHeader';
import { TouchpointDrilldownRow } from './models/TouchpointDrilldownRow';
import { DrillDownResponse } from 'src/app/models/_old/drilldown/drilldown';

@Injectable()
export class TouchpointDrilldownEffects {
	constructor(private actions$: Actions, private http: HttpClient, private store: Store<fromRoot.State>) {}

	private createSimpleEffect(actionType: any, newAction: any) {
		return createEffect(() =>
			this.actions$.pipe(
				ofType(actionType),
				map(() => newAction)
			)
		);
	}

	initializeTouchpointDrilldownData$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromTouchpointDrilldown.initializeTouchpointDrilldownData),
			withLatestFrom(this.store.select(fromSession.getFilters)),
			switchMap(([_, filters]) => {
				const url = `${environment.api}/client_portal/touchpoint_drilldown/?action_type=initialization`;
				const body = { holdingCompanyId: filters.company.id };
				return this.http
					.post<TouchpointDrilldownHeader>(url, body)
					.pipe(map((header) => fromTouchpointDrilldown.initializeTouchpointDrilldownDataSuccess({ header })));
			})
		)
	);

	onSetPage$ = this.createSimpleEffect(
		fromTouchpointDrilldown.setPage,
		fromTouchpointDrilldown.refreshTouchpointDrilldownData()
	);
	onSetSearchText$ = this.createSimpleEffect(
		fromTouchpointDrilldown.setSearchText,
		fromTouchpointDrilldown.refreshTouchpointDrilldownData()
	);
	onSetFilter$ = this.createSimpleEffect(
		fromTouchpointDrilldown.setAppliedFilters,
		fromTouchpointDrilldown.refreshTouchpointDrilldownData()
	);
	onResetFilter$ = this.createSimpleEffect(
		fromTouchpointDrilldown.resetAppliedFilters,
		fromTouchpointDrilldown.refreshTouchpointDrilldownData()
	);
	onSetSort$ = this.createSimpleEffect(
		fromTouchpointDrilldown.setSort,
		fromTouchpointDrilldown.refreshTouchpointDrilldownData()
	);

	refreshTouchpointData$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromTouchpointDrilldown.refreshTouchpointDrilldownData),
			withLatestFrom(
				this.store.select(fromSession.getFilters),
				this.store.select(fromTouchpointDrilldownSelectors.selectFilters)
			),
			switchMap(([_, sessionFilters, filters]) => {
				const url = `${environment.api}/client_portal/touchpoint_drilldown/?action_type=table-construction`;
				const body = {
					holdingCompanyId: sessionFilters.company.id,
					pageSize: filters.pageSize,
					pageNumber: filters.page,
					searchText: filters.search,
					sortColumn: filters.column,
					sortDirection: filters.direction,
					filters: filters.applied,
				};
				return this.http.post<DrillDownResponse<TouchpointDrilldownRow>>(url, body).pipe(
					map((data) => {
						return fromTouchpointDrilldown.refreshTouchpointDrilldownDataSuccess({ data });
					})
				);
			})
		)
	);
}
