<div class="container">
	<app-apple-button
		[disabled]="!data || data.length === 0"
		(click)="handleExport()"
		background="var(--white)"
		border="var(--grey-border)"
		[style]="{ padding: '10px 12px' }">
		<div class="button-content">
			<div class="icon">
				<img src="../../../../../assets/download.svg" alt="Download Icon" />
				<span class="text">{{ label }}</span>
			</div>
		</div>
	</app-apple-button>
</div>
