<ng-content select="[header]"></ng-content>

<div class="table-container">
	<table [style.min-height]="loading || data.length == 0 ? '100%' : 'initial'">
		<thead>
			<tr class="header-row">
				<th *ngIf="selectable" [style.padding-left.px]="columnPadding" [style.padding-right.px]="columnPadding">
					<div class="column-header-wrapper checkbox">
						<app-apple-checkbox
							[disabled]="disabled"
							[checked]="allRowsSelected"
							(checkedChanged)="handleAllRowsSelected()"></app-apple-checkbox>
					</div>
				</th>
				<th
					*ngFor="let column of columns"
					[class.sortable]="column.sortable"
					[class]="column.breakpoint"
					[style.padding-left.px]="columnPadding"
					[style.padding-right.px]="columnPadding"
					[style.max-width.px]="textTruncateWidth">
					<div
						class="column-header-wrapper"
						[ngClass]="{ 'blue-header': sortColumn == column.field }"
						(click)="onSort(column)">
						<div>{{ column.header }}</div>
						<div
							class="image-wrapper"
							*ngIf="column.sortable"
							[ngClass]="(sortColumn == column.field && sortDirection) || 'default'"></div>
					</div>
				</th>
			</tr>
		</thead>
		<tbody>
			<ng-container *ngIf="loading">
				<tr>
					<td [attr.colspan]="columns.length + (selectable ? 1 : 0)">
						<app-apple-loader [loading]="loading"></app-apple-loader>
					</td>
				</tr>
			</ng-container>

			<ng-container *ngIf="!loading && data.length <= 0">
				<tr>
					<td [attr.colspan]="columns.length + (selectable ? 1 : 0)">
						<ng-content select="[no-data]"></ng-content>
					</td>
				</tr>
			</ng-container>

			<ng-container *ngIf="!loading && data.length > 0">
				<tr
					class="data"
					[class.disabled]="disabled"
					[class.hover-enabled]="enableHover"
					*ngFor="let item of data; let i = index"
					[class.selected-row]="isSelected(item) && i % 2 === 0"
					[class.selected-row-gray]="isSelected(item) && i % 2 !== 0">
					<td
						*ngIf="selectable"
						[style.height.px]="minimumRowHeight"
						[style.padding-left.px]="columnPadding"
						[style.padding-right.px]="columnPadding">
						<div class="checkbox">
							<app-apple-checkbox
								[disabled]="disabled"
								[checked]="isSelected(item)"
								(checkedChanged)="handleRowSelected($event, item)"></app-apple-checkbox>
						</div>
					</td>
					<td
						*ngFor="let column of columns"
						[class.text]="!column.component"
						[style.height.px]="minimumRowHeight"
						[style.max-width.px]="textTruncateWidth"
						[style.padding-left.px]="columnPadding"
						[style.padding-right.px]="columnPadding"
						[class]="column.breakpoint"
						(click)="handleRowClicked(item)">
						<ng-container *ngIf="!column.component">
							<span [title]="item[column.field]">{{ item[column.field] }}</span>
						</ng-container>
						<ng-container
							*ngIf="column.component"
							[ngComponentOutlet]="column.component"
							[ngComponentOutletInputs]="item[column.field]">
						</ng-container>
					</td>
				</tr>
			</ng-container>
		</tbody>
	</table>
</div>

<ng-content select="[footer]"></ng-content>
