<app-card>
	<div
		class="wrapper"
		[style.--monetized-percentage.%]="data?.monetized?.percentage"
		[style.--non-monetized-percentage.%]="data?.notMonetized?.percentage">
		<div class="title">
			<ngx-skeleton-loader
				*ngIf="loading"
				[theme]="{ width: '180px', height: '20px', 'border-radius': '8px' }"></ngx-skeleton-loader>
			<span *ngIf="!loading">{{ data?.header }}</span>
		</div>
		<div>
			<div class="metrics-container">
				<div class="lost-opportunity" *ngIf="!loading">
					<div class="lost-opportunity-line"></div>
					<div>
						<div class="heading">{{ data?.lostOpportunity?.value }}</div>
						<div class="subheading">Lost Opportunity</div>
					</div>
				</div>
				<div class="inner-container">
					<div class="metric-wrapper monetized">
						<div class="heading">
							<ngx-skeleton-loader
								*ngIf="loading"
								[theme]="{ width: '80px', height: '32px', 'border-radius': '8px' }"></ngx-skeleton-loader>
							<span *ngIf="!loading">{{ data?.monetized?.value }}</span>
						</div>
						<div class="subheading">
							<ngx-skeleton-loader
								*ngIf="loading"
								[theme]="{ width: '130px', height: '22px', 'border-radius': '8px' }"></ngx-skeleton-loader>
							<span *ngIf="!loading">Monetized</span>
						</div>
					</div>
					<div class="metric-wrapper not-monetized">
						<div class="heading">
							<ngx-skeleton-loader
								*ngIf="loading"
								[theme]="{ width: '80px', height: '32px', 'border-radius': '8px' }"></ngx-skeleton-loader>
							<span *ngIf="!loading">{{ data?.notMonetized?.value }}</span>
						</div>
						<div class="subheading">
							<ngx-skeleton-loader
								*ngIf="loading"
								[theme]="{ width: '130px', height: '22px', 'border-radius': '8px' }"></ngx-skeleton-loader>
							<span *ngIf="!loading">Not Monetized</span>
						</div>
					</div>
				</div>
			</div>
			<div class="bar-container">
				<ngx-skeleton-loader
					*ngIf="loading"
					style="width: 100%"
					[theme]="{ width: '100%', height: '55px', 'border-radius': '5px' }">
				</ngx-skeleton-loader>
				<ng-container *ngIf="!loading">
					<div
						class="bar monetized"
						*ngIf="data?.monetized?.percentage"
						[style.width.%]="(data?.monetized?.percentage || 0) > 100 ? 100 : data?.monetized?.percentage || 0">
						{{ data?.monetized?.percentage }}%
					</div>
					<div
						class="bar not-monetized"
						*ngIf="data?.notMonetized?.percentage && (data?.notMonetized?.percentage || 0) > 0"
						[style.width.%]="data?.notMonetized?.percentage || 0">
						{{ data?.notMonetized?.percentage }}%
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</app-card>
