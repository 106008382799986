import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { Actions, ofType } from '@ngrx/effects';
import { map, switchMap, forkJoin, skipWhile, debounceTime } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TouchpointCard, TouchpointSummaryItem } from 'src/app/features/dashboard/state';

import * as fromTouchpoint from 'src/app/features/dashboard/state';

@Injectable()
export class TouchpointEffects {
	constructor(
		private actions: Actions,
		private http: HttpClient
	) {}

	loadTouchpoints$ = createEffect(() =>
		this.actions.pipe(
			ofType(fromTouchpoint.loadTouchpoints),
			switchMap(({ filter }) => {
				return forkJoin([
					this.http.get<TouchpointCard[]>(environment.api + `/client_portal/cards/?holdingCompanyId=${filter}`),
					this.http.get<TouchpointSummaryItem[]>(environment.api + `/client_portal/header/?holdingCompanyId=${filter}`),
				]);
			}),
			map(([cards, summary]) => {
				return fromTouchpoint.loadTouchpointsSuccess({ summary, cards });
			})
		)
	);
}
