<app-card>
	<app-apple-table
		class="table"
		[ngClass]="{ 'no-data': data.length == 0 }"
		[loading]="loading"
		[columns]="columns"
		[data]="data"
		[minimumRowHeight]="60">
		<app-no-data
			no-data
			image="../../../../../assets/no-data-category.svg"
			title="No Results"
			message="Try updating your filters."></app-no-data>
		<div class="footer" footer>
			<app-apple-pagination
				position="left"
				(pageChange)="page($event)"
				[enablePageSizePicker]="false"
				[totalItems]="(total$ | async) ?? 0"
				[currentPage]="(page$ | async) ?? 1"
				[pageSizes]="[{ id: 1, name: '5', default: true }]"></app-apple-pagination>
		</div>
	</app-apple-table>
</app-card>
