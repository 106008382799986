import { AfterViewInit, Component, Input } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { combineLatest, filter, map, skipUntil, skipWhile, startWith } from 'rxjs';
import * as fromRoot from '../../store';
import * as fromSession from '../../store/session';

import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements AfterViewInit {
	@Input()
	public type: 'tableau' | 'retool' = 'tableau';

	@Input()
	public data: any;

	constructor(
		public route: ActivatedRoute,
		public router: Router,
		private store: Store<fromRoot.State>
	) {}

	ngAfterViewInit() {
		const reports$ = this.store.select(fromSession.getReportItems).pipe(
			untilDestroyed(this),
			map((parent) => {
				return parent.map((child) => child.items).flat();
			})
		);

		const url$ = this.router.events.pipe(
			filter((event) => event instanceof NavigationEnd),
			map(() => this.router.url),
			startWith(this.router.url)
		);

		combineLatest([url$, reports$])
			.pipe(untilDestroyed(this))
			.subscribe(async ([currentUrl, reports]) => {
				const report = reports.find((report) => report.path == currentUrl);
				if (report) {
					switch (report.type) {
						case 'retool':
							this.type = 'retool';
							this.data = report.data.id;
							break;
						case 'tableau':
							this.type = 'tableau';
							this.data = report.data.url;
							break;
					}
				}
			});
	}
}
