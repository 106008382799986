import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';

if (environment.type == 'production') {
	enableProdMode();
}

if (environment.type !== 'development') {
	const plausible: any = window.document.createElement('script');
	plausible.src = 'https://plausible.barrows.tech/js/script.manual.local.js';
	plausible.attributes['data-domain'] = environment.domain;
	window.document.head.appendChild(plausible);

	Sentry.init({
		dsn: 'https://cfada30ca3a895eab90a9aca4485f2e0@o270286.ingest.sentry.io/4506732962316288',
		environment: environment.type,
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false,
				networkDetailAllowUrls: ['https://staging.barrows.tech/', 'https://portal-staging.tech/', 'https://localhost:4200/'],
			}),
		],
		tracesSampleRate: 1.0,
		tracePropagationTargets: ['portal-staging.barrows.tech', 'portal.barrows.tech'],
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.then((success) => console.log('Bootstrap success'))
	.catch((err) => console.error(err));
