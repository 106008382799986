import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-apple-request-account-confirmation',
	templateUrl: './apple-request-account-confirmation.component.html',
	styleUrls: ['./apple-request-account-confirmation.component.scss'],
})
export class AppleRequestAccountConfirmationComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
