import { createReducer, on } from '@ngrx/store';
import * as fromUser from './user.actions';
import { NeptuneError } from 'src/app/shared/models/api/NeptuneError';

export interface UserState {
	loading: boolean;
	details: {
		email: string | null;
		name: string | null;
		image: string | null;
		company: string | null;
	};
	token: string | null;
	error: NeptuneError | null;
}

export const initialState: UserState = {
	loading: false,
	details: {
		email: null,
		name: null,
		image: null,
		company: null,
	},
	token: null,
	error: null,
};

export const reducer = createReducer(
	initialState,
	on(fromUser.loginWithOAuth, (state) => ({
		...state,
		loading: true,
		error: null,
	})),
	on(fromUser.loginWithCredentials, (state) => ({
		...state,
		loading: true,
		error: null,
	})),
	on(fromUser.loginSuccess, (state, { user }) => ({
		...state,
		details: { email: user.email, name: user.name, image: user.image, company: null },
		token: user.token,
		loading: false,
		error: null,
	})),
	on(fromUser.requestAccount, (state, { user }) => ({
		...state,
		loading: false,
		error: null,
	})),
	on(fromUser.requestAccountTokenValidation, (state) => ({
		...state,
		loading: true,
		error: null,
	})),
	on(fromUser.requestAccountTokenValidationSuccess, (state, { name, email }) => ({
		...state,
		loading: false,
		details: { email, name, image: null, company: null },
		error: null,
	})),
	on(fromUser.registerWithOAuth, (state, { provider, uuid_token }) => ({
		...state,
		loading: true,
		error: null,
	})),
	on(fromUser.registerWithToken, (state, { provider, payload }) => ({
		...state,
		loading: true,
		error: null,
	})),
	on(fromUser.registerWithCredentials, (state, { payload }) => ({
		...state,
		loading: true,
		error: null,
	})),
	on(fromUser.requestPasswordReset, (state, { email }) => ({
		...state,
		loading: true,
		details: { email: email, name: null, image: null, company: null },
		error: null,
	})),
	on(fromUser.requestPasswordResetSuccess, (state, { email }) => ({
		...state,
		loading: false,
		error: null,
	})),
	on(fromUser.logout, (state) => ({
		details: { email: null, name: null, image: null, company: null },
		token: null,
		loading: false,
		error: null,
	})),
	on(fromUser.error, (state, { error }) => ({
		...state,
		loading: false,
		error: error,
	}))
);
