import { createReducer, on } from '@ngrx/store';
import * as fromTouchpointDrilldown from './touchpoint-drilldown.actions';
import { FilterOption } from 'src/app/components/apple/apple-filter/models/filters';
import { TouchpointDrilldownRow } from './models/TouchpointDrilldownRow';

export interface TouchpointDrilldownState {
	initializing: boolean;
	loading: boolean;
	header: {
		filters: FilterOption[];
	};
	filters: {
		page: number;
		pageSize: number;
		search: string | null;
		applied: { name: string; values: string[] }[];
		column: string | null;
		direction: string | null;
	};
	data: {
		total_count: number;
		displays: TouchpointDrilldownRow[];
	};
}

export const initialState: TouchpointDrilldownState = {
	initializing: true,
	loading: true,
	header: {
		filters: [],
	},
	filters: {
		page: 1,
		pageSize: 50,
		search: '',
		applied: [],
		column: null,
		direction: null,
	},
	data: {
		total_count: 0,
		displays: [],
	},
};

export const reducer = createReducer(
	initialState,
	on(fromTouchpointDrilldown.setSearchText, (state, { searchText }) => ({
		...state,
		filters: {
			...state.filters,
			page: 1,
			search: searchText,
		},
	})),
	on(fromTouchpointDrilldown.setPage, (state, { page, size }) => ({
		...state,
		filters: {
			...state.filters,
			page,
			pageSize: size,
		},
	})),
	on(fromTouchpointDrilldown.setSort, (state, { column, direction }) => ({
		...state,
		filters: {
			...state.filters,
			column,
			direction,
		},
	})),
	on(fromTouchpointDrilldown.setAppliedFilters, (state, { filters }) => ({
		...state,
		filters: {
			...state.filters,
			page: 1,
			applied: filters,
		},
	})),
	on(fromTouchpointDrilldown.resetAppliedFilters, (state) => ({
		...state,
		filters: {
			...state.filters,
			page: 1,
			applied: [],
		},
	})),
	on(fromTouchpointDrilldown.initializeTouchpointDrilldownData, (state) => ({
		...state,
		initializing: true,
		filters: {
			page: 1,
			pageSize: 50,
			search: '',
			applied: [],
			column: null,
			direction: null,
		},
		data: {
			total_count: 0,
			displays: [],
		},
	})),
	on(fromTouchpointDrilldown.initializeTouchpointDrilldownDataSuccess, (state, { header }) => ({
		...state,
		initializing: false,
		header: {
			filters: header.filters,
		},
	})),
	on(fromTouchpointDrilldown.refreshTouchpointDrilldownData, (state) => ({
		...state,
		loading: true,
	})),
	on(fromTouchpointDrilldown.refreshTouchpointDrilldownDataSuccess, (state, { data }) => ({
		...state,
		loading: false,
		data: {
			total_count: data.filtered_display_count,
			displays: data.displays,
		},
	}))
);
