<app-apple-card [shadow]="true" [style]="{ width: '75vw', 'max-width': '350px' }">
	<div class="content">
		<div class="icon">
			<img class="img" src="../../../../assets/message.svg" />
		</div>
		<div class="title">Check your inbox</div>
		<div class="message">
			We’ve sent reset instructions to <b>{{ email$ | async }}</b>
		</div>
		<div class="action">
			<span class="link" routerLink="/auth/login">Sign In</span>
		</div>
		<div class="logo">
			<img class="img" src="../../../../assets/barrows.png" />
		</div>
	</div>
</app-apple-card>
