<div [formGroup]="formGroup">
	<app-form-field [control]="control" [customValidators]="customValidators">
		<ng-container [ngSwitch]="type">
			<app-apple-input
				*ngSwitchCase="'text'"
				[label]="label"
				[formControlName]="formControlName"
				[prefix]="prefix"
				[disabled]="disabled">
			</app-apple-input>

			<app-apple-checkbox *ngSwitchCase="'boolean'" [checked]="control.value" [disabled]="disabled">
				{{ label }}
			</app-apple-checkbox>

			<app-apple-select
				*ngSwitchCase="'dropdown'"
				[label]="label"
				[formControlName]="formControlName"
				[disabled]="disabled"
				[items]="options"></app-apple-select>
		</ng-container>
	</app-form-field>
</div>
