import { OAuthModule } from 'angular-oauth2-oidc';
/* 3rd Party */
import { InViewportModule } from 'ng-in-viewport';
import { NgLetModule } from 'ng-let';
import { localStorageSync } from 'ngrx-store-localstorage';
import { ToastrModule } from 'ngx-toastr';
import { TouchpointDetailsComponent } from 'src/app/features/dashboard/components/touchpoint-container/touchpoint-details/touchpoint-details.component';
import {
	provideUnleashProxy,
	featureEnabled,
	FeatureEnabledDirective,
	FeatureDisabledDirective,
} from '@karelics/angular-unleash-proxy-client';

import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
/* Angular */
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { BryntumSchedulerModule } from '@bryntum/scheduler-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import * as Sentry from '@sentry/angular';

/* Environment */
import { environment } from '../environments/environment';
/* Unsorted */
import { AppComponent } from './app.component';
import { AppleAuthCallbackComponent } from './components/apple/account/apple-auth-callback/apple-auth-callback.component';
import { AppleErrorComponent } from './components/apple/account/apple-error/apple-error.component';
import { AppleForgotPasswordConfirmationComponent } from './components/apple/account/apple-forgot-password-confirmation/apple-forgot-password-confirmation.component';
import { AppleForgotPasswordFailureComponent } from './components/apple/account/apple-forgot-password-failure/apple-forgot-password-failure.component';
import { AppleForgotPasswordComponent } from './components/apple/account/apple-forgot-password/apple-forgot-password.component';
import { AppleLoginComponent } from './components/apple/account/apple-login/apple-login.component';
import { ApplePasswordResetComponent } from './components/apple/account/apple-password-reset/apple-password-reset.component';
import { AppleRegistrationComponent } from './components/apple/account/apple-registration/apple-registration.component';
import { AppleRequestAccountConfirmationComponent } from './components/apple/account/apple-request-account-confirmation/apple-request-account-confirmation.component';
import { AppleRequestAccountComponent } from './components/apple/account/apple-request-account/apple-request-account.component';
import { AppleAppliedFiltersComponent } from './components/apple/apple-applied-filters/apple-applied-filters.component';
import { AppleButtonGroupComponent } from './components/apple/apple-button-group/apple-button-group.component';
import { AppleButtonComponent } from './components/apple/apple-button/apple-button.component';
import { AppleCardComponent } from './components/apple/apple-card/apple-card.component';
import { AppleCheckboxComponent } from './components/apple/apple-checkbox/apple-checkbox.component';
import { AppleDualSlotHeaderComponent } from './components/apple/apple-dual-slot-header/apple-dual-slot-header.component';
import { AppleFilterComponent } from './components/apple/apple-filter/apple-filter.component';
import { FormatTableFiltersPipe } from './components/apple/apple-filter/pipes/format-table-filters';
import { AppleInputComponent } from './components/apple/apple-input/apple-input.component';
import { AppleLoaderComponent } from './components/apple/apple-loader/apple-loader/apple-loader.component';
import { ApplePaginationComponent } from './components/apple/apple-pagination/apple-pagination.component';
import { ApplePillComponent } from './components/apple/apple-pill/apple-pill.component';
import { AppleSelectComponent } from './components/apple/apple-select/apple-select.component';
import { AppleTableComponent } from './components/apple/apple-table/apple-table.component';
import { AppleHyperLinkComponent } from './components/apple/apple-table/components/apple-hyper-link/apple-hyper-link.component';
import { ScreenshotComponent } from './components/apple/apple-table/components/screenshot/screenshot.component';
import { BookingSnapshotComponent } from './components/apple/apple-table/components/booking-snapshot/booking-snapshot.component';
import { BookingSovComponent } from './components/apple/apple-table/components/booking-sov/booking-sov.component';
import { AppleTooltipComponent } from './components/apple/apple-tooltip/apple-tooltip.component';
import { ButtonComponent } from './components/button/button.component';
import { CommentComponent } from './features/content-approval/comment/comment.component';
import { ContentApprovalComponent } from './features/content-approval/content-approval.component';
import { PendingApprovalComponent } from './features/content-approval/pending-approval/pending-approval.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ToastComponent } from './components/toast/toast.component';
import { TouchpointCardComponent } from './features/dashboard/components/touchpoint-container/touchpoint-card/touchpoint-card.component';
import { TouchpointContainerComponent } from './features/dashboard/components/touchpoint-container/touchpoint-container.component';
import { SummaryCardComponent } from './features/dashboard/components/universe-summary/summary-card/summary-card.component';
import { UniverseSummaryComponent } from './features/dashboard/components/universe-summary/universe-summary.component';
import { BookingListComponent } from './features/bookings/view/components/booking-list/booking-list.component';
import { BookingTimelineComponent } from './features/bookings/view/components/booking-timeline/booking-timeline.component';
import { TimelineComponent } from './features/bookings/view/components/booking-timeline/components/timeline/timeline.component';
import { BookingViewComponent } from './features/bookings/view/booking-view.component';
import { AppleContentDrilldownComponent } from './features/content-drilldown/content-drilldown.component';
import { TouchpointDrilldownComponent } from './features/touchpoint-drilldown/touchpoint-drilldown';
import { AnonymousGuard } from './guards/anonymous.guard';
import { AuthorizedGuard } from './guards/authorized.guard';
import { RouteGuard } from './guards/routes.guard';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { AuthComponent } from './pages/auth/auth.component';
import { ContainerComponent } from './features/container/container.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { ErrorComponent } from './pages/error/error.component';
import { ReportComponent } from './pages/report/report.component';
import { ReportTableauComponent } from './pages/report/tableau/tableau.component';
import { ReportRetoolComponent } from './pages/report/retool/retool.component';
import { RelativeTimePipe } from './pipes/relative-time.pipe';
import { effects, reducers } from './store';
import { GoogleMapsModule } from '@angular/google-maps';
import { SliderComponent } from './shared/components/slider/slider.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { InsightsComponent } from './features/insights/insights.component';
import { NoDataComponent } from './shared/components/no-data/no-data.component';
import { FormatDateComponent } from './components/apple/apple-table/components/format-date/format-date.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { TouchpointDataComponent } from './features/insights/components/touchpoint-data/touchpoint-data.component';
import { PageHeaderComponent } from './shared/components/page-header/page-header.component';
import { InsightsCardComponent } from './features/insights/components/card/card.component';
import { InsightsMetricComponent } from './features/insights/components/metric/metric.component';
import { InsightDonutGraphComponent } from './features/insights/components/donut-graph/insight-donut-graph.component';
import { InsightsClientListComponent } from './features/insights/components/client-list/client-list.component';
import { InsightCampaignGalleryComponent } from './features/insights/components/campaign-gallery/campaign-gallery.component';
import { InsightBarGraphComponent } from './features/insights/components/bar-graph/insight-bar-graph.component';
import { InsightPowerNumberComponent } from './features/insights/components/power-number/insight-power-number.component';
import { InsightLineGraphComponent } from './features/insights/components/line-graph/insight-line-graph.component';
import { InsightsTrendIndicatorComponent } from './features/insights/components/trend-indicator/trend-indicator.component';
import { InsightsDonutGraphIndicatorComponent } from './features/insights/components/power-number/components/mini-donut-graph/mini-donut-graph.component';
import { InsightsTouchpointDetailsComponent } from './features/insights/components/touchpoint-details/touchpoint-details.component';
import { FormFieldComponent } from './shared/components/form-field/form-field.component';
import { NotificationIconComponent } from './features/container/components/notification-icon/notification-icon.component';
import { DeclineDialogComponent } from './features/content-approval/decline-dialog/decline-dialog.component';
import { SkeletonLoaderComponent } from './shared/components/skeleton-loader/skeleton-loader.component';
import { BookingComponent } from './features/campaign/components/booking/booking.component';
import { MapComponent } from './features/campaign/components/booking/components/map/map.component';
import { UnitPreviewComponent } from './features/campaign/components/booking/components/unit-preview/unit-preview.component';
import { TabNavigationComponent } from './shared/components/tab-navigation/tab-navigation.component';
import { CampaignComponent } from './features/campaign/campaign.component';
import { MediaUploadComponent } from './features/campaign/components/media-upload/media-upload.component';
import { MediaDropZoneComponent } from './features/campaign/components/media-upload/components/media-drop-zone/media-drop-zone.component';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { ColorPickerComponent } from './features/campaign/components/media-upload/components/color-picker/color-picker.component';
import { StackedColumnRowComponent } from './components/apple/apple-table/components/stacked-column-row/stacked-column-row.component';
import { PillComponent } from './features/campaign/components/booking/components/pill/pill.component';
import { DataImportComponent } from './shared/components/data-import/data-import.component';
import { DataExportComponent } from './shared/components/data-export/data-export.component';
import { InsightsRevenueComponent } from './features/insights/revenue/revenue.component';
import { ActionLinkComponent } from './components/action-link/action-link.component';
import { InsightsCampaignOverviewComponent } from './features/insights/campaign/components/overview/overview.component';
import { InsightsCampaignComponent } from './features/insights/campaign/campaign.component';
import { InsightsFiltersComponent } from './features/insights/components/filters/filters.component';
import { InsightsCampaignsStoreListComponent } from './features/insights/campaign/components/store-list/store-list.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { EmblaCarouselDirective } from 'embla-carousel-angular';
import { DynamicFormControlComponent } from './shared/components/dynamic-form-control/dynamic-form-control.component';
import { VideoColumnComponent } from './components/apple/apple-table/components/video-column/video-column.component';
import { EmissionsPerMinuteComponent } from './features/insights/sustainability/components/emissions-per-minute/emissions-per-minute.component';
import { InsightsDataLegendComponent } from './features/insights/components/data-legend/data-legend.component';
import { InsightsSustainabilityComponent } from './features/insights/sustainability/sustainability.component';
import { MediaScheduleComponent } from './features/campaign/components/media-schedule/media-schedule.component';
import { SustainabilityFeatureOverviewComponent } from './features/insights/sustainability/components/feature-overview/feature-overview.component';
import { InsightsEmissionsTableComponent } from './features/insights/sustainability/components/emissions-table/emissions-table.component';
import { EmissionsMeasurementIndicatorComponent } from './features/insights/sustainability/components/emissions-table/components/measurement-indicator/measurement-indicator.component';
import { EmissionsPercentageIndicatorComponent } from './features/insights/sustainability/components/emissions-table/components/percentage-indicator/percentage-indicator.component';
import { TooltipDirective } from './directives/tooltip/tooltip.directive';
import { TooltipComponent } from './directives/tooltip/tooltip.component';
import { BannerComponent } from './components/banner/banner.component';

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
	return localStorageSync({
		keys: [{ user: ['details', 'token'] }, { session: ['filters'] }],
		rehydrate: true,
	})(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
	declarations: [
		AppComponent,
		DashboardComponent,
		ReportComponent,
		ReportTableauComponent,
		ReportRetoolComponent,
		ContainerComponent,
		DropdownComponent,
		ErrorComponent,
		ButtonComponent,
		UniverseSummaryComponent,
		TouchpointContainerComponent,
		SummaryCardComponent,
		FormatTableFiltersPipe,
		RelativeTimePipe,
		TouchpointDetailsComponent,
		TouchpointCardComponent,
		LoaderComponent,
		AppleButtonComponent,
		AppleLoginComponent,
		AppleCardComponent,
		AppleCheckboxComponent,
		AppleInputComponent,
		AppleRequestAccountComponent,
		AppleRequestAccountConfirmationComponent,
		AppleRegistrationComponent,
		AppleForgotPasswordComponent,
		AppleForgotPasswordConfirmationComponent,
		ApplePasswordResetComponent,
		AppleForgotPasswordFailureComponent,
		AuthComponent,
		AppleErrorComponent,
		ContentApprovalComponent,
		CommentComponent,
		PendingApprovalComponent,
		ToastComponent,
		ApplePaginationComponent,
		AppleTableComponent,
		AppleContentDrilldownComponent,
		TouchpointDrilldownComponent,
		AppleTooltipComponent,
		AppleSelectComponent,
		ApplePillComponent,
		ScreenshotComponent,
		AppleLoaderComponent,
		AppleAuthCallbackComponent,
		TimelineComponent,
		AppleHyperLinkComponent,
		AppleFilterComponent,
		AppleDualSlotHeaderComponent,
		AppleAppliedFiltersComponent,
		AppleButtonGroupComponent,
		BookingViewComponent,
		BookingListComponent,
		BookingTimelineComponent,
		BookingSnapshotComponent,
		BookingSovComponent,
		BookingComponent,
		UnitPreviewComponent,
		MapComponent,
		SliderComponent,
		NotificationIconComponent,
		NoDataComponent,
		FormatDateComponent,
		TouchpointDataComponent,
		InsightsComponent,
		InsightsCardComponent,
		InsightCampaignGalleryComponent,
		InsightsClientListComponent,
		InsightPowerNumberComponent,
		InsightBarGraphComponent,
		InsightDonutGraphComponent,
		InsightLineGraphComponent,
		InsightsMetricComponent,
		InsightsTrendIndicatorComponent,
		InsightsDonutGraphIndicatorComponent,
		FormFieldComponent,
		DatepickerComponent,
		InsightsTouchpointDetailsComponent,
		DeclineDialogComponent,
		SkeletonLoaderComponent,
		PageHeaderComponent,
		TabNavigationComponent,
		CampaignComponent,
		MediaUploadComponent,
		MediaDropZoneComponent,
		ColorPickerComponent,
		StackedColumnRowComponent,
		PillComponent,
		DataImportComponent,
		DataExportComponent,
		InsightsRevenueComponent,
		ActionLinkComponent,
		InsightsCampaignOverviewComponent,
		InsightsCampaignComponent,
		CarouselComponent,
		DynamicFormControlComponent,
		InsightsFiltersComponent,
		InsightsCampaignsStoreListComponent,
		VideoColumnComponent,
		EmissionsPerMinuteComponent,
		InsightsDataLegendComponent,
		InsightsSustainabilityComponent,
		MediaScheduleComponent,
		SustainabilityFeatureOverviewComponent,
		InsightsEmissionsTableComponent,
		EmissionsMeasurementIndicatorComponent,
		EmissionsPercentageIndicatorComponent,
		TooltipDirective,
		TooltipComponent,
		BannerComponent,
	],
	bootstrap: [AppComponent],
	imports: [
		EmblaCarouselDirective,
		FeatureEnabledDirective,
		FeatureDisabledDirective,
		RouterModule.forRoot(
			[
				{
					path: 'auth',
					component: AuthComponent,
					canActivate: [AnonymousGuard],
					children: [
						{
							path: 'account-request',
							component: AppleRequestAccountComponent,
						},
						{
							path: 'callback',
							component: AppleAuthCallbackComponent,
						},
						{
							path: 'account-request/confirmation',
							component: AppleRequestAccountConfirmationComponent,
						},
						{
							path: 'login',
							component: AppleLoginComponent,
						},
						{
							path: 'forgot-password',
							component: AppleForgotPasswordComponent,
						},
						{
							path: 'forgot-password/confirmation',
							component: AppleForgotPasswordConfirmationComponent,
						},
						{
							path: 'forgot-password/:reset_token',
							component: ApplePasswordResetComponent,
						},
						{
							path: 'registration/:login_method/:uuid_token',
							component: AppleRegistrationComponent,
						},
					],
				},
				{
					path: 'network',
					component: ContainerComponent,
					canActivate: [AuthorizedGuard, RouteGuard],
					children: [
						{
							path: 'dashboard',
							component: DashboardComponent,
						},
						{
							path: 'touchpoint',
							component: TouchpointDrilldownComponent,
						},
						{
							path: 'report/:name',
							component: ReportComponent,
							data: {
								'mobile-support': false,
							},
						},
						{
							path: 'campaigns',
							component: BookingViewComponent,
							children: [
								{
									path: '',
									pathMatch: 'full',
									redirectTo: 'view',
								},
								{
									path: 'view',
									component: BookingListComponent,
								},
								{
									path: 'timeline',
									component: BookingTimelineComponent,
								},
							],
						},
						{
							path: 'campaign',
							component: CampaignComponent,
							data: {
								'background-color': 'var(--white)',
							},
							children: [
								{
									path: '',
									component: BookingComponent,
								},
							],
						},
						{
							path: 'campaign/:uid',
							component: CampaignComponent,
							data: {
								'background-color': 'var(--white)',
							},
							children: [
								{
									path: '',
									component: BookingComponent,
								},
								{
									path: 'media',
									component: MediaUploadComponent,
								},
								{
									path: 'media/schedule',
									component: MediaScheduleComponent,
								},
							],
						},
						{
							path: 'content',
							component: AppleContentDrilldownComponent,
						},
						{
							path: 'insights',
							children: [
								{
									path: 'revenue',
									component: InsightsRevenueComponent,
								},
								{
									path: 'sustainability',
									component: InsightsSustainabilityComponent,
								},
								{
									path: 'campaigns',
									component: InsightsCampaignComponent,
								},
								{
									path: ':name',
									component: ReportComponent,
									data: {
										'mobile-support': false,
									},
								},
							],
							data: {
								'mobile-support': false,
							},
						},
						{
							path: '**',
							redirectTo: '/not-found',
						},
					],
				},
				{
					path: 'content',
					component: ContainerComponent,
					canActivate: [AuthorizedGuard],
					data: {
						background: 'var(--grey-background)',
						padding: '0px 25px 0px 25px',
					},
					children: [
						{
							path: 'approval/:uuid',
							component: ContentApprovalComponent,
						},
					],
				},
				{
					path: 'error',
					component: ContainerComponent,
					children: [
						{
							path: '',
							component: ErrorComponent,
						},
					],
				},
				{
					path: 'not-found',
					component: ContainerComponent,
					children: [
						{
							path: '',
							component: ErrorComponent,
							data: {
								title: 'Not Found',
								message: 'The page you are looking for does not exist, please check the URL.',
								actions: {
									redirect: true,
									contact: false,
								},
							},
						},
					],
				},
				{
					path: '**',
					redirectTo: '/auth/login',
				},
			],
			{
				scrollPositionRestoration: 'enabled',
			}
		),
		BrowserModule,
		InViewportModule,
		BrowserAnimationsModule,
		NgSelectModule,
		FormsModule,
		ReactiveFormsModule,
		NgLetModule,
		CommonModule,
		BryntumSchedulerModule,
		GoogleMapsModule,
		NgxSliderModule,
		DropzoneModule,
		StoreModule.forRoot(reducers, {
			metaReducers,
			runtimeChecks: {
				strictStateImmutability: true,
				strictActionImmutability: true,
			},
		}),
		BrowserAnimationsModule,
		ToastrModule.forRoot({
			toastComponent: ToastComponent,
			closeButton: true,
			maxOpened: 1,
			preventDuplicates: true,
		}),
		EffectsModule.forRoot(effects),
		OAuthModule.forRoot(),
		NgxSkeletonLoaderModule.forRoot({
			theme: {
				margin: '0px',
				height: '20px',
				borderRadius: '6px',
			},
		}),
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false,
			}),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},
		provideUnleashProxy({
			url: environment.unleash.endpoint,
			clientKey: environment.unleash.clientKey,
			appName: environment.unleash.appName,
			environment: environment.unleash.envrionment,
		}),
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class AppModule {}
