import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-report-retool',
	templateUrl: './retool.component.html',
	styleUrls: ['./retool.component.scss'],
})
export class ReportRetoolComponent implements AfterViewInit {
	public loading = true;

	@Input()
	public data: number | undefined = undefined;

	constructor(
		private http: HttpClient,
		private renderer: Renderer2,
		private elementRef: ElementRef,
		private cdf: ChangeDetectorRef
	) {}

	async ngAfterViewInit() {
		const container = this.elementRef.nativeElement.querySelector('#viz-container');
		if (container && this.data) {
			container.innerHTML = '';
			await this.renderRetool(container, this.data);
			this.loading = false;
			this.cdf.detectChanges();
		}
	}

	async renderRetool(container: HTMLElement, id: number | undefined) {
		const data = await this.http.post<any>(environment.api + '/client_portal/retool/', { id }).toPromise();
		if (data.url) {
			const viz = this.renderer.createElement('iframe');
			viz.src = data.url;
			viz.style.width = '100%';
			viz.style.height = 'calc(100vh - 60px)';
			viz.style.display = 'block';
			viz.style.border = 'none';
			viz.id = 'retool';

			if (container) {
				this.renderer.appendChild(container, viz);
			}
		}
	}
}
