import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router,
	Route,
	UrlSegment,
	CanActivateFn,
	CanActivateChildFn,
	CanMatchFn,
} from '@angular/router';
import { Observable } from 'rxjs';
import { take, map, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import * as fromRoot from '../store';
import * as fromUser from '../store/user';

@Injectable({
	providedIn: 'root',
})
export class AuthorizedGuard {
	constructor(
		private store: Store<fromRoot.State>,
		private router: Router
	) {}

	canActivate: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
		return this.check(state.url);
	};

	canActivateChild: CanActivateChildFn = (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
		return this.check(state.url);
	};

	private check(redirect: string): Observable<boolean> {
		return this.store.pipe(select(fromUser.getUserState)).pipe(
			take(1),
			tap((state) => {
				if (!!state.token == false) {
					if (redirect) {
						this.router.navigate([`/auth/login`], { queryParams: { redirect } });
					} else {
						this.router.navigate(['/']);
					}
				}
			}),
			map((state) => !!state.token)
		);
	}
}
