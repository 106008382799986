<ng-container *ngLet="loading$ | async as loading">
	<ng-container *ngLet="error$ | async as error">
		<ng-container *ngIf="loading">
			<app-loader [loading]="loading"></app-loader>
		</ng-container>
		<ng-container *ngIf="!loading && error">
			<app-apple-error>
				<span title> {{ error.title }} </span>
				<span message> {{ error.message }} </span>
			</app-apple-error>
		</ng-container>
		<ng-container *ngIf="!loading && !error">
			<app-apple-card [shadow]="true" [style]="{ width: '75vw', 'max-width': '400px' }">
				<div class="content">
					<div class="header">
						<div class="title">Request Account</div>
						<div class="subtext">
							<span class="text">Already have an account?</span>
							<span class="link" routerLink="/auth/login">Sign in </span>
						</div>
					</div>
					<form [formGroup]="form">
						<div class="request-form">
							<div class="field">
								<app-apple-input label="First Name" formControlName="firstName"></app-apple-input>
								<div class="validation">
									<div class="item" *ngIf="form.get('firstName')?.touched && invalid(form, 'firstName', 'required')">
										<span class="icon">
											<img class="cross" src="../../../../assets/cross-black.svg" />
										</span>
										<span class="invalid">This field is required.</span>
									</div>
								</div>
							</div>
							<div class="field">
								<app-apple-input label="Last Name" formControlName="lastName"></app-apple-input>
								<div class="validation">
									<div class="item" *ngIf="form.get('lastName')?.touched && invalid(form, 'lastName', 'required')">
										<span class="icon">
											<img class="cross" src="../../../../assets/cross-black.svg" />
										</span>
										<span class="invalid">This field is required.</span>
									</div>
								</div>
							</div>
							<div class="field">
								<app-apple-input label="Email" formControlName="email"></app-apple-input>
								<div class="validation">
									<div class="item" *ngIf="form.get('email')?.touched && invalid(form, 'email', 'required')">
										<span class="icon">
											<img class="cross" src="../../../../assets/cross-black.svg" />
										</span>
										<span class="invalid">This field is required.</span>
									</div>
									<div class="item" *ngIf="form.get('email')?.touched && invalid(form, 'email', 'invalidEmail')">
										<span class="icon">
											<img class="cross" src="../../../../assets/cross-black.svg" />
										</span>
										<span class="invalid">This is not a valid email.</span>
									</div>
								</div>
							</div>
							<div class="field">
								<app-apple-input label="Company" formControlName="company"></app-apple-input>
								<div class="validation">
									<div class="item" *ngIf="form.get('company')?.touched && invalid(form, 'company', 'required')">
										<span class="icon">
											<img class="cross" src="../../../../assets/cross-black.svg" />
										</span>
										<span class="invalid">This field is required.</span>
									</div>
								</div>
							</div>
						</div>
						<div class="request-button">
							<app-apple-button
								color="var(--white)"
								background="var(--black)"
								[style]="{ 'font-weight': 'bold', 'font-size': '12px' }"
								[disabled]="form.invalid || loading"
								(click)="accountRequest()">
								<div class="button-content">
									<div *ngIf="!loading">REQUEST ACCESS</div>
									<div *ngIf="loading">
										<i class="fa fa-solid fa-spinner fa-spin"></i>
									</div>
								</div>
							</app-apple-button>
						</div>
					</form>
					<div class="logo">
						<img class="image" src="../../../../assets/barrows.png" />
					</div>
				</div>
			</app-apple-card>
		</ng-container>
	</ng-container>
</ng-container>
