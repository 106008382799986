import { Component, Input } from '@angular/core';
import { DataLegend } from './models';

@Component({
	selector: 'app-insights-data-legend',
	templateUrl: './data-legend.component.html',
	styleUrls: ['./data-legend.component.scss'],
})
export class InsightsDataLegendComponent {
	@Input()
	public data?: DataLegend[] | null;

	@Input()
	public loading = false;

	constructor() {}
}
