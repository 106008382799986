<div class="touchpoint-details-item">
	<div class="item-image">
		<ngx-skeleton-loader
			*ngIf="loading"
			style="display: flex"
			[theme]="{ width: '55px', height: '55px', 'border-radius': '10px' }"></ngx-skeleton-loader>
		<img *ngIf="!loading" [src]="image" [alt]="name" />
	</div>
	<div class="item-details">
		<div class="item-title">
			<ngx-skeleton-loader
				*ngIf="loading"
				[theme]="{ width: '120px', height: '16px', 'border-radius': '12px' }"></ngx-skeleton-loader>
			<span *ngIf="!loading"> {{ name }} </span>
		</div>
		<div class="item-subtitle">
			<ngx-skeleton-loader
				*ngIf="loading"
				[theme]="{ width: '120px', height: '16px', 'border-radius': '12px' }"></ngx-skeleton-loader>
			<span *ngIf="!loading"> {{ category }} </span>
		</div>
	</div>
	<div class="item-value">
		<ngx-skeleton-loader
			*ngIf="loading"
			[theme]="{ width: '120px', height: '16px', 'border-radius': '12px' }"></ngx-skeleton-loader>
		<span *ngIf="!loading"> {{ value }} </span>
	</div>
</div>
