import { Component, Input, OnInit } from '@angular/core';
import { TouchpointSummaryItem } from 'src/app/features/dashboard/state';

@Component({
	selector: 'app-summary-card',
	templateUrl: './summary-card.component.html',
	styleUrls: ['./summary-card.component.scss'],
})
export class SummaryCardComponent implements OnInit {
	@Input() item!: TouchpointSummaryItem;

	constructor() {}

	ngOnInit(): void {}

	setDefaultImage(event: any) {
		event.target.src = '../../../assets//placeholder100.png';
	}
}
