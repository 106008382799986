import { Component, Input } from '@angular/core';
import { EmissionsTable } from '../../models';
import { TableColumn } from 'src/app/components/apple/apple-table/models/column';

import { TouchpointDataComponent } from '../../../components/touchpoint-data/touchpoint-data.component';
import { EmissionsMeasurementIndicatorComponent } from './components/measurement-indicator/measurement-indicator.component';
import { EmissionsPercentageIndicatorComponent } from './components/percentage-indicator/percentage-indicator.component';
import { combineLatest, map, Observable, of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import * as fromRoot from '../../../../../store';
import * as fromSustainability from '../../state';

@UntilDestroy()
@Component({
	selector: 'app-insights-emissions-table',
	templateUrl: './emissions-table.component.html',
	styleUrls: ['./emissions-table.component.scss'],
})
export class InsightsEmissionsTableComponent {
	@Input()
	public loading: boolean = true;

	@Input()
	public data: EmissionsTable[] = [];

	public columns: TableColumn[] = [
		{ header: 'Touchpoint', field: 'touchpoint', sortable: false, component: TouchpointDataComponent },
		{
			header: 'Total Emissions',
			field: 'totalEmissions',
			sortable: false,
			component: EmissionsMeasurementIndicatorComponent,
		},
		{
			header: 'Media Distribution Emissions',
			field: 'screenEnergy',
			sortable: false,
			component: EmissionsMeasurementIndicatorComponent,
		},
		{
			header: 'Creative Delivery Emissions',
			field: 'otherEmissions',
			sortable: false,
			component: EmissionsMeasurementIndicatorComponent,
		},
		{
			header: 'Emissions Trend - Monthly',
			field: 'emissionsTrend',
			sortable: false,
			component: EmissionsPercentageIndicatorComponent,
		},
	];

	constructor(private _store: Store<fromRoot.State>) {}

	public page$: Observable<number> = this._store.select(fromSustainability.selectFilters).pipe(
		map((filters) => filters.table.page),
		untilDestroyed(this)
	);

	public total$: Observable<number | undefined> = this._store
		.select(fromSustainability.selectEmissionsTableTotal)
		.pipe(untilDestroyed(this));

	public exportData$: Observable<any[]> = this._store.select(fromSustainability.selectAllEmissionsTable).pipe(
		map((data: EmissionsTable[] = []) => {
			return data.map((item) => ({
				'Touchpoint/Category': item.touchpoint.name + ' - ' + item.touchpoint.category,
				'Total Emissions (kgCO2PM)': item.totalEmissions.value,
				'Media Distribution Emissions (kgCO2PM)': item.screenEnergy.value,
				'Creative Delivery Emissions (kgCO2PM)': item.otherEmissions.value,
			}));
		})
	);

	page(event: { page: number; size: number }) {
		this._store.dispatch(fromSustainability.setPage({ page: event.page, size: event.size }));
	}
}
