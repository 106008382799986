import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-apple-button',
	templateUrl: './apple-button.component.html',
	styleUrls: ['./apple-button.component.scss'],
})
export class AppleButtonComponent {
	@Input()
	public disabled: boolean | null = false;

	@Input()
	public color: string = '';

	@Input()
	public background: string = '';

	@Input()
	public border: string = '';

	@Input()
	public size: 'small' | 'medium' | 'large' = 'medium';

	@Input()
	public type: 'warning' | 'standard' | 'outline' = 'standard';

	@Input()
	public style: { [key: string]: string } = {};

	getStyles() {
		var styles: any = {
			...this.style,
		};

		if (this.color) {
			styles['color'] = this.color;
		}

		if (this.background) {
			styles['background-color'] = this.background;
		}

		if (this.border) {
			styles['border'] = `1px solid ${this.border}`;
		}

		return styles;
	}
}
