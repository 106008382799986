import { createReducer, on } from '@ngrx/store';
import * as fromBooking from './booking-view.actions';
import { FilterOption } from 'src/app/components/apple/apple-filter/models/filters';
import { DateTime } from 'ts-luxon';
import { TimelineResourceViewModel } from '../models/view/TimelineResourceViewModel';
import { TimelineItemEventViewModel } from '../models/view/TimelineItemEventViewModel';

export interface BookingState {
	loading: boolean;
	meta: {
		startDate: Date;
		endDate: Date;
	};
	header: {
		zoomLevel: string;
		showZoomLevel: boolean;
		filters: FilterOption[];
	};
	filters: {
		page: number;
		pageSize: number;
		search: string | null;
		column: string | null;
		direction: string | null;
		applied: { name: string; values: string[] }[];
	};
	bookings: {
		resources: TimelineResourceViewModel[];
		events: TimelineItemEventViewModel[];
	};
}

export const initialState: BookingState = {
	loading: true,
	meta: {
		startDate: DateTime.now().minus({ years: 1 }).toJSDate(),
		endDate: DateTime.now().plus({ years: 1 }).toJSDate(),
	},
	header: {
		showZoomLevel: false,
		zoomLevel: 'Years',
		filters: [],
	},
	filters: {
		page: 1,
		pageSize: 50,
		search: '',
		applied: [],
		column: null,
		direction: null,
	},
	bookings: {
		resources: [],
		events: [],
	},
};

export const reducer = createReducer(
	initialState,
	on(fromBooking.setSearchText, (state, { searchText }) => ({
		...state,
		filters: {
			...state.filters,
			page: 1,
			search: searchText,
		},
	})),
	on(fromBooking.setZoomLevel, (state, { zoomLevel }) => ({
		...state,
		header: {
			...state.header,
			zoomLevel,
		},
	})),
	on(fromBooking.setZoomLevelVisibility, (state, { show }) => ({
		...state,
		header: {
			...state.header,
			showZoomLevel: show,
		},
	})),
	on(fromBooking.setPage, (state, { page, size }) => ({
		...state,
		filters: {
			...state.filters,
			page,
			pageSize: size,
		},
	})),
	on(fromBooking.setSort, (state, { column, direction }) => ({
		...state,
		filters: {
			...state.filters,
			column,
			direction,
		},
	})),
	on(fromBooking.setAppliedFilters, (state, { filters }) => ({
		...state,
		filters: {
			...state.filters,
			page: 1,
			applied: filters,
		},
	})),
	on(fromBooking.resetAppliedFilters, (state) => ({
		...state,
		filters: {
			...state.filters,
			page: 1,
			applied: [],
		},
	})),
	on(fromBooking.setDateRange, (state, { start, end }) => ({
		...state,
		meta: {
			startDate: start,
			endDate: end,
		},
		loading: true,
	})),
	on(fromBooking.loadBookingData, (state) => ({
		...state,
		loading: true,
		bookings: {
			resources: [],
			events: [],
		},
		filters: {
			...state.filters,
			applied: [],
		},
	})),
	on(fromBooking.loadBookingDataSuccess, (state, { data }) => {
		const resources = data.map((resource) => ({
			id: resource.categoryId,
			name: resource.name,
			image: resource.image,
			category: resource.category,
			currency: resource.currency,
			totalStores: resource.totalStores,
		}));

		const events = data.flatMap((resource) =>
			resource.bookings.map((booking) => ({
				bookingId: booking.id,
				resourceId: resource.categoryId,
				bookingName: booking.name,
				touchpointName: resource.name,
				categoryName: resource.category,
				companyName: booking.companyName,
				brands: booking.brands,
				brandsDisplay: booking.brands.map((brand) => brand.name).join(', '),
				storeCount: booking.storeCount,
				storeCountDisplay: `${booking.storeCount}/${resource.totalStores}`,
				createdDate: booking.createdDate,
				startDate: booking.startDate,
				startDateDisplay: {
					formattedDate: booking.startDate,
				},
				endDate: booking.endDate,
				endDateDisplay: {
					formattedDate: booking.endDate,
				},
				value: booking.value,
				valueDisplay: `${resource.currency}${booking.value}`,
				unitValue: booking.unitValue,
				unitValueDisplay: `${resource.currency}${booking.unitValue}`,
				contentStatus: booking.contentStatus,
				bookingStatus: booking.bookingStatus,
				shareType: booking.share.name,
				shareTypeDisplay: {
					type: booking.share.name,
				},
				image: { url: resource.image },
				touchpointAndCategory: {
					firstValue: resource.name,
					secondValue: resource.category,
				},
				companyAndBrands: {
					firstValue: booking.companyName,
					secondValue: booking.brands.map((brand) => brand.name).join(', '),
				},
			}))
		);

		const touchpoints = {
			name: 'touchpoint',
			values: [...new Set(data.map(({ name }) => name))],
			type: 'multi',
		};

		const brands = {
			name: 'brand',
			values: [
				...new Set(data.flatMap(({ bookings }) => bookings.flatMap(({ brands }) => brands.map((brand) => brand.name)))),
			],
			type: 'multi',
		};

		return {
			...state,
			loading: false,
			header: {
				...state.header,
				filters: [touchpoints, brands],
			},
			bookings: {
				resources,
				events,
			},
		};
	})
);
