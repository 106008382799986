import { Component, Input, Output, EventEmitter, forwardRef, OnInit, ElementRef, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DayDetails, MonthDetails, DayDetailsArguments, DatePickerOption } from './models';
import { DateTime, Info } from 'ts-luxon';

@Component({
	selector: 'app-datepicker',
	templateUrl: './datepicker.component.html',
	styleUrls: ['./datepicker.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DatepickerComponent),
			multi: true,
		},
	],
})
export class DatepickerComponent implements OnInit, ControlValueAccessor {
	@Output()
	public changed = new EventEmitter<any>();

	@Input()
	public label: string = '';

	@Input()
	public type: 'single' | 'range' = 'range';

	@Input()
	public disabled: boolean = false;

	@Input()
	public minDate: Date | null = null;

	@Input()
	public maxDate: Date | null = null;

	@Input()
	public options: boolean = false;

	@Input()
	public monthCount: number = 1;

	@Input()
	public granularity: 'day' | 'month' | 'year' = 'day';

	monthIndices: number[] = [];

	savedMonthDetails: Map<string, MonthDetails> = new Map();

	hoveredDate: Date | null = null;

	periodOptions = [
		{
			name: 'Last 30 Days',
			value: {
				start: DateTime.now().minus({ days: 30 }).startOf('day').toJSDate(),
				end: DateTime.now().endOf('day').toJSDate(),
			},
		},
		{
			name: 'This Month',
			value: {
				start: DateTime.now().startOf('month').toJSDate(),
				end: DateTime.now().endOf('day').toJSDate(),
			},
		},
		{
			name: 'Last Month',
			value: {
				start: DateTime.now().minus({ months: 1 }).startOf('month').toJSDate(),
				end: DateTime.now().minus({ months: 1 }).endOf('month').toJSDate(),
			},
		},
		{
			name: 'This Quarter',
			value: {
				start: DateTime.now().startOf('quarter').toJSDate(),
				end: DateTime.now().endOf('day').toJSDate(),
			},
		},
		{
			name: 'Last Quarter',
			value: {
				start: DateTime.now().minus({ quarters: 1 }).startOf('quarter').toJSDate(),
				end: DateTime.now().minus({ quarters: 1 }).endOf('quarter').toJSDate(),
			},
		},
		{
			name: 'This Year',
			value: {
				start: DateTime.now().startOf('year').toJSDate(),
				end: DateTime.now().endOf('day').toJSDate(),
			},
		},
		{
			name: 'Last Year',
			value: {
				start: DateTime.now().minus({ years: 1 }).startOf('year').toJSDate(),
				end: DateTime.now().minus({ years: 1 }).endOf('year').toJSDate(),
			},
		},
	];

	private _propagateChange: any = () => {};
	private _propagateTouched: any = () => {};

	months: string[] = Info.months();
	days: string[] = Info.weekdays('short').map((_, index) => {
		const sundayWeekdayIndex = (index + 6) % 7;
		return Info.weekdays('short')[sundayWeekdayIndex];
	});

	todayDate: Date = DateTime.now().startOf('day').toJSDate();
	date: Date = DateTime.now().toJSDate();
	displayDate: string = '';
	showCalendar: boolean = false;
	selectedDate: Date | null = null;
	selectedStartDate: Date | null = null;
	selectedEndDate: Date | null = null;
	isActive: boolean = false;

	get gridTemplate(): string {
		return `repeat(${this.monthCount}, 1fr)`;
	}

	constructor(private elementRef: ElementRef) {}

	@HostListener('document:click', ['$event'])
	clickOutside(event: Event) {
		if (!this.elementRef.nativeElement.contains(event.target) && this.showCalendar) {
			this.hideCalendar();
			this._propagateTouched();
		}
	}

	ngOnInit(): void {
		this.monthIndices = Array.from({ length: this.monthCount }, (_, i) => i);
		this.initializeCalendar();
	}

	initializeCalendar(): void {
		this.savedMonthDetails.clear();
		this.monthIndices.forEach((index) => this.getMonthDetails(index));
	}

	getNumberOfDays(year: number, month: number): number {
		return DateTime.local(year, month + 1).daysInMonth;
	}

	getDayDetails(args: DayDetailsArguments): DayDetails {
		const date = args.index - args.firstDay;
		const day = args.index % 7;
		const month = date < 0 ? -1 : date >= args.numberOfDays ? 1 : 0;

		const yearToUse = args.month + month === -1 ? args.year - 1 : args.month + month === 12 ? args.year + 1 : args.year;
		const monthToUse = args.month + month === -1 ? 11 : args.month + month === 12 ? 0 : args.month + month;
		const dayNumber = date < 0 ? this.getNumberOfDays(yearToUse, monthToUse) + date : (date % args.numberOfDays) + 1;

		return {
			date: DateTime.local(yearToUse, monthToUse + 1, dayNumber).toJSDate(),
			day,
			month,
			dayString: dayNumber,
		};
	}

	isCurrentDay(day: DayDetails): boolean {
		return DateTime.fromJSDate(day.date).hasSame(DateTime.fromJSDate(this.todayDate), 'day');
	}

	isSelectedDate(day: DayDetails): boolean {
		return Boolean(
			this.type === 'single' &&
				this.selectedDate &&
				DateTime.fromJSDate(day.date).hasSame(DateTime.fromJSDate(this.selectedDate), 'day')
		);
	}

	isStartDate(day: DayDetails): boolean {
		return Boolean(
			this.type === 'range' &&
				this.selectedStartDate &&
				DateTime.fromJSDate(day.date).hasSame(DateTime.fromJSDate(this.selectedStartDate), 'day')
		);
	}

	isEndDate(day: DayDetails): boolean {
		return Boolean(
			this.type === 'range' &&
				this.selectedEndDate &&
				DateTime.fromJSDate(day.date).hasSame(DateTime.fromJSDate(this.selectedEndDate), 'day')
		);
	}

	isInRange(day: DayDetails): boolean {
		if (this.type !== 'range' || !this.selectedStartDate || !this.selectedEndDate) {
			return false;
		}
		return day.date > this.selectedStartDate && day.date < this.selectedEndDate;
	}

	isDateDisabled(day: DayDetails): boolean {
		return Boolean((this.minDate && day.date < this.minDate) || (this.maxDate && day.date > this.maxDate));
	}

	onDateClick(day: DayDetails): void {
		if (this.isDateDisabled(day)) return;

		this.type === 'single' ? this.handleSingleDateSelection(day.date) : this.handleRangeDateSelection(day.date);
	}

	handleSingleDateSelection(date: Date): void {
		this.selectedDate = date;
		this.updateDisplayDate();
	}

	handleRangeDateSelection(date: Date): void {
		if (!this.isActive || (this.selectedStartDate && date <= this.selectedStartDate)) {
			this.selectedStartDate = date;
			this.selectedEndDate = null;
			this.isActive = true;
		} else {
			this.selectedEndDate = date;
			this.isActive = false;
		}
		this.updateDisplayDate();
	}

	updateDisplayDate(): void {
		if (this.type === 'single') {
			this.displayDate = this.selectedDate ? this.formatDateByGranularity(this.selectedDate) : 'Select Date';
			if (this.selectedDate) {
				this.showCalendar = false;
				this._propagateChange(this.selectedDate);
				this.changed.emit(this.selectedDate);
			}
		} else {
			if (!this.selectedStartDate) {
				this.displayDate = 'Select Date Range';
			} else if (!this.selectedEndDate) {
				this.displayDate = `From ${this.formatDateByGranularity(this.selectedStartDate)} - Select End Date`;
			} else {
				this.displayDate = `${this.formatDateByGranularity(this.selectedStartDate)} - ${this.formatDateByGranularity(this.selectedEndDate)}`;
				const range = { start: this.selectedStartDate, end: this.selectedEndDate };
				this._propagateChange(range);
				this.changed.emit(range);
				this.showCalendar = false;
			}
		}
	}

	private formatDateByGranularity(date: Date): string {
		const dateTime = DateTime.fromJSDate(date);
		switch (this.granularity) {
			case 'month':
				return dateTime.toFormat('MMMM yyyy');
			case 'year':
				return dateTime.toFormat('yyyy');
			default:
				return dateTime.toFormat('MMMM d, yyyy');
		}
	}

	toggleCalendar(): void {
		if (!this.disabled) {
			this.showCalendar = !this.showCalendar;
			if (this.showCalendar) {
				this.initializeCalendar();
			}
		}
	}

	setHeaderNav(offset: number): void {
		if (this.granularity === 'month') {
			this.date = DateTime.fromJSDate(this.date).plus({ years: offset }).toJSDate();
		} else if (this.granularity === 'year') {
			this.date = DateTime.fromJSDate(this.date)
				.plus({ years: offset * 12 })
				.toJSDate();
		} else {
			this.date = DateTime.fromJSDate(this.date).plus({ months: offset }).toJSDate();
		}
		this.initializeCalendar();
	}

	hideCalendar(): void {
		this.showCalendar = false;
	}

	getMonthDetails(monthIndex: number): MonthDetails {
		const targetDate = DateTime.fromJSDate(this.date).plus({ months: monthIndex });
		const monthKey = `${targetDate.year}-${targetDate.month}`;

		if (!this.savedMonthDetails.has(monthKey)) {
			const details = this.generateMonthDetails(targetDate.year, targetDate.month - 1);
			this.savedMonthDetails.set(monthKey, details);
		}

		return this.savedMonthDetails.get(monthKey) || [];
	}

	private generateMonthDetails(year: number, month: number): MonthDetails {
		if (this.granularity === 'month') {
			return Array.from({ length: 12 }, (_, i) => ({
				date: DateTime.local(year, i + 1)
					.endOf('month')
					.toJSDate(),
				day: 1,
				month: i,
				dayString: i + 1,
			}));
		}

		if (this.granularity === 'year') {
			const startYear = Math.floor(year / 12) * 12;
			return Array.from({ length: 12 }, (_, i) => ({
				date: DateTime.local(startYear + i)
					.endOf('year')
					.toJSDate(),
				day: 1,
				month: 0,
				dayString: startYear + i,
			}));
		}

		const firstDay = DateTime.local(year, month + 1, 1).weekday % 7;
		const numberOfDays = this.getNumberOfDays(year, month);
		const monthArray: MonthDetails = [];

		for (let index = 0; index < 42; index++) {
			monthArray.push(
				this.getDayDetails({
					index,
					numberOfDays,
					firstDay,
					year,
					month,
				})
			);
		}

		return monthArray;
	}

	getMonthName(monthIndex: number): string {
		const targetDate = DateTime.fromJSDate(this.date).plus({ months: monthIndex }).toJSDate();
		return this.months[targetDate.getMonth()];
	}

	getYear(monthIndex: number): number {
		const targetDate = DateTime.fromJSDate(this.date).plus({ months: monthIndex }).toJSDate();
		return targetDate.getFullYear();
	}

	writeValue(value: any): void {
		if (this.type === 'single') {
			this.selectedDate = value;
		} else if (this.type === 'range' && value) {
			this.selectedStartDate = value.start;
			this.selectedEndDate = value.end;
			this.isActive = false;
		}
		this.updateDisplayDate();
	}

	registerOnChange(fn: (value: any) => void): void {
		this._propagateChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this._propagateTouched = fn;
	}

	onOptionClick(value: Date | { start: Date; end: Date }): void {
		if (this.type === 'range' && 'start' in value) {
			this.selectedStartDate = value.start;
			this.selectedEndDate = value.end;
			this.isActive = false;
		} else if (this.type === 'single' && 'start' in value === false) {
			this.selectedDate = value;
		}
		this.updateDisplayDate();
		this.hideCalendar();
	}

	onDateHover(day: DayDetails): void {
		if (this.type === 'range' && this.selectedStartDate && !this.selectedEndDate) {
			this.hoveredDate = day.date;
		}
	}

	isHovered(day: DayDetails): boolean {
		return Boolean(
			this.type === 'range' &&
				this.selectedStartDate &&
				!this.selectedEndDate &&
				this.hoveredDate &&
				day.date > this.selectedStartDate &&
				day.date <= this.hoveredDate
		);
	}

	getYearRangeText(monthIndex: number): string {
		const targetDate = DateTime.fromJSDate(this.date).plus({ years: monthIndex * 12 });
		const startYear = Math.floor(targetDate.year / 12) * 12;
		const endYear = startYear + 11;
		return `${startYear} - ${endYear}`;
	}
}
