<form [formGroup]="filterForm" class="insights-filter-container">
	<div class="filter" *ngFor="let option of filterOptions">
		<ng-container [ngSwitch]="option.type">
			<app-apple-select
				*ngSwitchCase="'single-select'"
				class="item"
				[label]="option.label"
				[items]="option.options ?? []"
				[placeholder]="option?.placeholder ?? 'Please select filter'"
				formControlName="{{ option.name }}">
			</app-apple-select>
			<app-apple-select
				*ngSwitchCase="'multi-select'"
				class="item"
				[label]="option.label"
				[items]="option.options ?? []"
				[multiple]="true"
				[truncateCount]="3"
				[clearable]="true"
				[placeholder]="option?.placeholder ?? 'Please select filter'"
				formControlName="{{ option.name }}">
			</app-apple-select>
			<app-datepicker
				*ngSwitchCase="'date-range'"
				class="item"
				[type]="'range'"
				[options]="true"
				[label]="option.label"
				[granularity]="option.granularity ?? 'day'"
				formControlName="{{ option.name }}">
			</app-datepicker>
		</ng-container>
	</div>
</form>
