<div class="slider-container">
	<label *ngIf="label" class="label">{{ label }}</label>
	<div class="wrapper" *ngLet="getOptions() as options">
		<div class="range">{{ options.floor }}</div>
		<ngx-slider
			[(value)]="value"
			[options]="options"
			(valueChange)="onSliderChange($event)"
			[ngClass]="value === 0 ? 'grey' : 'blue'"></ngx-slider>
		<div class="range">{{ options.ceil }}</div>
		<app-apple-input
			[type]="'number'"
			[(ngModel)]="value"
			[style]="{ 'text-align': 'center' }"
			[disabled]="disabled"
			(ngModelChange)="onSliderChange($event)"></app-apple-input>
	</div>
</div>
