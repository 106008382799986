import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CampaignResponse, FilterResponse } from './../models/api/Response';
import * as CampaignActions from './campaign.actions';
import * as fromCampaign from './campaign.selectors';
import * as fromSession from 'src/app/store/session';
import * as fromRoot from 'src/app/store';
import { Store } from '@ngrx/store';
import { DateHelper } from 'src/app/helpers/date';

@Injectable()
export class CampaignEffects {
	constructor(
		private actions$: Actions,
		private http: HttpClient,
		private store: Store<fromRoot.State>
	) {}

	loadFilters$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(CampaignActions.setFilter, CampaignActions.getFilters),
			withLatestFrom(
				this.store.select(fromCampaign.selectFilters),
				this.store.select(fromCampaign.selectSelectedFilters),
				this.store.select(fromSession.getFilters)
			),
			switchMap(([action, availableFilters, selectedFilters, sessionFilters]) => {
				if (!sessionFilters || !sessionFilters.company) {
					console.error('Session filters or company is undefined', sessionFilters);
					return of(CampaignActions.loadFiltersFailure({ error: 'Session filters are not available' }));
				}

				if (action.type === CampaignActions.setFilter.type && action.filterName === 'campaign') {
					return of(
						CampaignActions.loadFiltersSuccess({
							touchpoints: availableFilters.touchpoints,
							campaigns: availableFilters.campaigns,
							companies: availableFilters.companies,
							brands: availableFilters.brands,
						})
					);
				}

				const params: string[] = [`holdingCompanyId=${sessionFilters.company.id}`];

				if (selectedFilters.touchpoints && selectedFilters.touchpoints.length > 0) {
					params.push(`touchpoints=${encodeURIComponent(selectedFilters.touchpoints.join(','))}`);
				}

				if (selectedFilters.companies && selectedFilters.companies.length > 0) {
					params.push(`companies=${encodeURIComponent(selectedFilters.companies.join(','))}`);
				}

				if (selectedFilters.period.start && selectedFilters.period.end) {
					params.push(`start=${DateHelper.formatDate(selectedFilters.period.start)}`);
					params.push(`end=${DateHelper.formatDate(selectedFilters.period.end)}`);
				}

				const url = `${environment.api}/client_portal/insights/campaigns/filters?${params.join('&')}`;

				return this.http.get<FilterResponse>(url, {}).pipe(
					map((response) => {
						return CampaignActions.loadFiltersSuccess({
							touchpoints: response.touchpoints,
							campaigns: response.campaigns,
							companies: response.companies,
							brands: response.brands,
						});
					}),
					catchError((error) => {
						console.error('Error loading filters:', error);
						return of(CampaignActions.loadFiltersFailure({ error }));
					})
				);
			})
		);
	});

	loadCampaign$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(CampaignActions.loadCampaign),
			switchMap((action) => {
				const baseUrl = `${environment.api}/client_portal/insights/campaigns`;
				const url = action.campaignId
					? `${baseUrl}?campaignId=${action.campaignId}`
					: `${baseUrl}?holdingCompanyId=${action.holdingCompanyId}`;
				return this.http.get<CampaignResponse>(url, {}).pipe(
					map((response) => {
						return CampaignActions.loadCampaignSuccess({
							details: response.details,
							stores: response.stores,
							mediaGallery: response.media,
							powerNumbers: response.metrics,
							uptime: response.uptime,
						});
					}),
					catchError((error) => {
						return of(CampaignActions.loadCampaignFailure({ error }));
					})
				);
			})
		);
	});
}
