import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { CustomValidators } from 'src/app/validators/custom-validators';
import * as fromRoot from '../../../../store';
import * as fromUser from '../../../../store/user/user.actions';

@Component({
	selector: 'app-apple-password-reset',
	templateUrl: './apple-password-reset.component.html',
	styleUrls: ['./apple-password-reset.component.scss'],
})
export class ApplePasswordResetComponent implements OnInit {
	public form!: FormGroup;
	public loading = false;
	public token!: string;

	constructor(
		private route: ActivatedRoute,
		private _fb: FormBuilder,
		private store: Store<fromRoot.State>
	) {
		this.form = this._fb.group(
			{
				password: ['', [Validators.required, CustomValidators.passwordComplexityValidator]],
				confirmPassword: ['', Validators.required],
			},
			{
				validators: [CustomValidators.passwordMatchValidator()],
			}
		);
	}

	ngOnInit(): void {
		const params = this.route.snapshot.params;
		this.token = params['reset_token'];
	}

	passwordReset() {
		this.loading = true;
		this.store.dispatch(
			fromUser.resetPassword({
				password: this.form.controls['password'].value,
				reset_token: this.token,
			})
		);
	}

	public invalid(form: AbstractControl, prop: string, validator: string) {
		return form.get(prop)?.errors?.[validator];
	}
}
