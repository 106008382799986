import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable, filter, switchMap, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { environment } from '../../environments/environment';

import * as fromRoot from '../store';
import * as fromUser from '../store/user';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private store: Store<fromRoot.State>) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!request.url.startsWith(environment.api)) {
			return next.handle(request);
		}

		return this.store
			.select((state) => state.user)
			.pipe(
				filter((state) => !!state.token),
				take(1),
				switchMap((user: fromUser.UserState) => {
					if (user.token) {
						request = request.clone({
							setHeaders: { Authorization: `Bearer ${user.token}` },
						});
					}
					return next.handle(request);
				})
			);
	}
}
