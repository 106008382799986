import { ActionReducerMap } from '@ngrx/store';
import { UserEffects } from './user/user.effects';
import { SessionEffects } from './session/session.effects';
import { TouchpointEffects } from '../features/dashboard/state';
import { ContentEffects } from '../features/content-approval/store';

import { BookingEffects } from '../features/bookings/view/state/';
import { BookingCreationEffects } from '../features/campaign/state/bookings-create.effects';
import { TouchpointDrilldownEffects } from '../features/touchpoint-drilldown/state';
import { ContentDrilldownEffects } from '../features/content-drilldown/state';
import { RevenueEffects } from '../features/insights/revenue/state';
import { CampaignEffects } from '../features/insights/campaign/state';
import { SustainabilityEffects } from '../features/insights/sustainability/state';
import * as fromUser from './user/user.reducers';
import * as fromSession from './session/session.reducers';
import * as fromTouchpoints from '../features/dashboard/state/touchpoint.reducers';
import * as fromBooking from '../features/bookings/view/state/booking-view.reducers';
import * as fromBookingCreation from '../features/campaign/state/bookings-create.reducers';
import * as fromContent from '../features/content-approval/store/content.reducers';
import * as fromTouchpointDrilldown from '../features/touchpoint-drilldown/state/touchpoint-drilldown.reducers';
import * as fromContentDrilldown from '../features/content-drilldown/state/content-drilldown.reducers';
import * as fromInsights from '../features/insights/state';

export interface State {
	user: fromUser.UserState;
	session: fromSession.SessionState;
	touchpoints: fromTouchpoints.TouchpointState;
	content: fromContent.ContentState;
	booking: fromBooking.BookingState;
	bookingCreation: fromBookingCreation.BookingCreationState;
	touchpointDrilldown: fromTouchpointDrilldown.TouchpointDrilldownState;
	contentDrilldown: fromContentDrilldown.ContentDrilldownState;
	insights: fromInsights.InsightsState;
}

export const reducers: ActionReducerMap<State> = {
	user: fromUser.reducer,
	session: fromSession.reducer,
	touchpoints: fromTouchpoints.reducer,
	content: fromContent.contentReducer,
	booking: fromBooking.reducer,
	bookingCreation: fromBookingCreation.reducer,
	touchpointDrilldown: fromTouchpointDrilldown.reducer,
	contentDrilldown: fromContentDrilldown.reducer,
	insights: fromInsights.reducer,
};

export const effects = [
	UserEffects,
	SessionEffects,
	TouchpointEffects,
	ContentEffects,
	BookingEffects,
	BookingCreationEffects,
	TouchpointDrilldownEffects,
	ContentDrilldownEffects,
	RevenueEffects,
	CampaignEffects,
	SustainabilityEffects,
];
