import { DrillDownResponse } from 'src/app/models/_old/drilldown/drilldown';

import { createAction, props } from '@ngrx/store';

import { ContentDrilldownHeader } from './models/ContentDrilldownHeader';
import { ContentDrillDownRow } from './models/ContentDrillDownRow';
import { WebSocketResponse } from 'src/app/shared/models/api/WebSocketResponse';
import { Screenshot } from 'src/app/shared/models/api/Screenshot';

// Search
export const setSearchText = createAction('[Content Drilldown] Set Search Text', props<{ searchText: string }>());

//Pagination
export const setPage = createAction('[Content Drilldown] Set Page', props<{ page: number; size: number }>());

//Sort
export const setSort = createAction(
	'[Content Drilldown] Set Sort',
	props<{ column: string | null; direction: string | null }>()
);

//Filters
export const setAppliedFilters = createAction(
	'[Content Drilldown] Set Applied Filters',
	props<{ filters: { name: string; values: string[] }[] }>()
);
export const resetAppliedFilters = createAction('[Content Drilldown] Reset Applied Filters');

// Screenshots
export const listenForBulkScreenshotUpdates = createAction('[Content Drilldown] Connect To Screenshot Websocket');
export const bulkScreenshotUpdate = createAction('[Content Drilldown] Request BulkScreenshot Update');
export const bulkScreenshotUpdateSuccess = createAction(
	'[Content Drilldown] Screenshot Received',
	props<{ data: WebSocketResponse<Screenshot> }>()
);

// Data
export const initializeContentDrilldownData = createAction(
	'[Content Drilldown] Load Touchpoint Data',
	props<{ mediaId: string; categoryId: string; touchpointId: string; variantId: string | null; isResult: boolean }>()
);
export const initializeContentDrilldownDataSuccess = createAction(
	'[Content Drilldown] Load Touchpoint Data Success',
	props<{ header: ContentDrilldownHeader }>()
);

export const refreshContentDrilldownData = createAction('[Content Drilldown] Refresh Touchpoint Data');
export const refreshContentDrilldownDataSuccess = createAction(
	'[Content Drilldown] Refresh Touchpoint Data Success',
	props<{ data: DrillDownResponse<ContentDrillDownRow> }>()
);

export const exportContentDrilldownData = createAction('[Content Drilldown] Export Touchpoint Data');
