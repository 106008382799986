import { Injectable, inject } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { Actions, ofType } from '@ngrx/effects';

import { map, switchMap, forkJoin, take, of, withLatestFrom, mergeMap, from, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HoldingCompany } from '../../shared/models/api/HoldingCompany';
import { environment } from 'src/environments/environment';
import { Action, Store } from '@ngrx/store';

import * as fromRoot from '../../store';
import * as fromSession from '../../store/session';
import * as fromUser from '../../store/user';
import { ReportNavigation } from 'src/app/shared/models/api/reports/ReportNavigation';
import { NavigationItem } from 'src/app/shared/models/api/navigation/NavigationItem';

import { WebSocketResponse } from 'src/app/shared/models/api/WebSocketResponse';
import { NotificationResponse } from 'src/app/shared/models/api/NotificationResponses';
import { WebSocketSubject } from 'rxjs/webSocket';
import { FeatureFlagService } from 'src/app/services/unleash.service';
import { ToastService } from 'src/app/services/toast.service';
import { Router } from '@angular/router';

@Injectable()
export class SessionEffects {
	constructor(
		private actions: Actions,
		private http: HttpClient,
		private store: Store<fromRoot.State>,
		private _router: Router,
		private _toast: ToastService
	) {}

	private ws!: WebSocketSubject<WebSocketResponse<NotificationResponse>>;
	private unleash: FeatureFlagService = inject(FeatureFlagService);

	initializeSession$ = createEffect(() =>
		this.actions.pipe(
			ofType(fromSession.initializeSession),
			withLatestFrom(this.store.select(fromUser.getUser)),
			switchMap(([_, user]) => {
				this.unleash.updateContext('userId', user.email);
				return this.http
					.get<HoldingCompany[]>(`${environment.api}/client_portal/holding_companies/`)
					.pipe(map((companies) => fromSession.initializeSessionSuccess({ companies })));
			})
		)
	);

	updateMenuItems$ = createEffect(() =>
		this.actions.pipe(
			ofType(fromSession.initializeSessionSuccess, fromSession.updateSessionFilters, fromSession.retrieveRoutes),
			withLatestFrom(this.store.select(fromSession.getFilters)),
			switchMap(([action, filters]) => {
				this.unleash.updateContext('holdingCompanyId', filters.company.id.toString());
				this.unleash.updateContext('holdingCompanyName', filters.company.name);
				return this.http
					.get<ReportNavigation[]>(`${environment.api}/client_portal/menu/?holdingCompanyId=${filters.company.id}`)
					.pipe(
						map((reports) => {
							if (
								action.type === fromSession.updateSessionFilters.type &&
								!this._router.url.startsWith('/network/dashboard')
							) {
								this._toast.open(
									'info',
									'Network Updated',
									`Now viewing ${filters.company.name}'s network. You've been redirected to the dashboard as some features and reports may be unavailable for this network.`,
									3000,
									'right'
								);
								this._router.navigateByUrl('/network/dashboard');
							}
							return fromSession.updateReportItemsSuccess({ reports });
						})
					);
			})
		)
	);

	listenForOutstandingNotifications$ = createEffect(() =>
		this.actions.pipe(
			ofType(fromSession.listenForOutstandingNotifications),
			withLatestFrom(this.store.select(fromUser.getUserAuthToken)),
			switchMap(([_, token]) => {
				this.ws = new WebSocketSubject<WebSocketResponse<NotificationResponse>>(
					`${environment.ws}/ws/approvals/outstanding?token=${token ?? ''}`
				);

				return this.ws.pipe(
					map((data) => {
						return fromSession.outstandingNotificationsSuccess({ data });
					})
				);
			})
		)
	);
}
