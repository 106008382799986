import { createReducer, on } from '@ngrx/store';
import * as RevenueActions from './revenue.actions';
import {
	RevenueAnalysis,
	DonutGraph,
	RevenueTable,
	MonetizedImpressions,
	InsightTrends,
	PowerNumber,
	CampaignMedia,
	TouchpointData,
	LineGraph,
} from '../../models/index';

import { Category } from './../models';

export interface RevenueState {
	initializing: boolean;
	loading: boolean;
	header: {
		currency: {
			code: string | null;
			symbol: string | null;
		};
		filters: {
			categories: Category[];
		};
	};
	filters: {
		period: {
			start: Date | null;
			end: Date | null;
		};
		categories: Category[];
	};
	data: {
		powerNumbers: PowerNumber[];
		trendNumbers: InsightTrends | null;
		monetizedImpressions: MonetizedImpressions | null;
		totalRevenue: DonutGraph | null;
		revenueAnalysis: LineGraph | null;
		touchpointRevenue: RevenueTable | null;
		clientRevenue: RevenueTable | null;
		campaignRevenue: CampaignMedia | null;
		touchpointDetails: TouchpointData[] | null;
	};
	error: any;
}

export const initialState: RevenueState = {
	initializing: true,
	loading: true,
	header: {
		currency: {
			code: null,
			symbol: null,
		},
		filters: {
			categories: [],
		},
	},
	filters: {
		period: {
			start: null,
			end: null,
		},
		categories: [],
	},
	data: {
		powerNumbers: [],
		trendNumbers: null,
		monetizedImpressions: null,
		totalRevenue: null,
		revenueAnalysis: null,
		touchpointRevenue: null,
		clientRevenue: null,
		campaignRevenue: null,
		touchpointDetails: [],
	},
	error: null,
};

export const revenueReducer = createReducer(
	initialState,
	on(RevenueActions.loadInsights, (state) => ({
		...state,
		loading: true,
		error: null,
	})),
	on(
		RevenueActions.loadInsightsSuccess,
		(
			state,
			{
				currency,
				powerNumbers,
				trendNumbers,
				monetizedImpressions,
				totalRevenue,
				revenueAnalysis,
				clientRevenue,
				touchpointRevenue,
				campaignRevenue,
				touchpointDetails,
			}
		) => {
			const addCurrencySymbol = (value: string) => `${currency.symbol}${value}`;
			return {
				...state,
				initializing: false,
				loading: false,
				header: {
					...state.header,
					currency: {
						code: currency.code,
						symbol: currency.symbol,
					},
				},
				data: {
					powerNumbers: Object.values(powerNumbers),
					trendNumbers: {
						bookedSpots: trendNumbers.bookedSpots,
						averageOrderSize: {
							...trendNumbers.averageOrderSize,
							value: addCurrencySymbol(trendNumbers.averageOrderSize.value),
						},
					},
					monetizedImpressions: {
						header: monetizedImpressions.header,
						monetized: {
							...monetizedImpressions.monetized,
						},
						notMonetized: {
							...monetizedImpressions.notMonetized,
						},
						lostOpportunity: {
							...monetizedImpressions.lostOpportunity,
							value: addCurrencySymbol(monetizedImpressions.lostOpportunity.value),
						},
					},
					totalRevenue: {
						...totalRevenue,
						value: addCurrencySymbol(totalRevenue.value),
						expected: addCurrencySymbol(totalRevenue.expected),
					},
					revenueAnalysis: revenueAnalysis,
					touchpointRevenue: {
						...touchpointRevenue,
						body: touchpointRevenue.body.map((item) => ({
							...item,
							value: addCurrencySymbol(item.value),
							image: item.image ? item.image : '/assets/unknown-touchpoint.png',
						})),
					},
					clientRevenue: {
						...clientRevenue,
						body: clientRevenue.body.map((item) => ({
							...item,
							value: addCurrencySymbol(item.value),
							image: item.image ? item.image : '/assets/unknown-client.png',
						})),
					},
					campaignRevenue: {
						...campaignRevenue,
						body: [
							...campaignRevenue.body.map((item) => ({
								...item,
								value: addCurrencySymbol(item.value),
							})),
						],
					},
					touchpointDetails: touchpointDetails.map((item) => ({
						touchpoint: {
							name: item.touchpoint.name,
							category: item.touchpoint.category.name,
							image: item.touchpoint.image,
						},
						sellThroughRate: {
							value: item.sellThroughRate.value,
							percentage: parseFloat(item.sellThroughRate.percentage.toString()), // todo: will make PR to Neptune to fix this
						},
						totalImpressions: item.totalImpressions.value,
						campaignImpressions: item.campaignImpressions.value,
						campaignValue: addCurrencySymbol(item.campaignValue.value),
						spillValue: addCurrencySymbol(item.spillValue.value),
						previousTrend: {
							data: {
								value: item.previousTrend.change.value,
								image: item.previousTrend.change.direction,
							},
						},
					})),
				},
				error: null,
			};
		}
	),
	on(RevenueActions.loadInsightsFailure, (state, { error }) => ({
		...state,
		initializing: false,
		loading: false,
		error,
	})),
	on(RevenueActions.loadCategoriesSuccess, (state, { categories }) => ({
		...state,
		header: {
			...state.header,
			filters: {
				categories: categories,
			},
		},
		error: null,
	})),
	on(RevenueActions.loadCategoriesFailure, (state, { error }) => ({
		...state,
		error,
	})),
	on(RevenueActions.setFilter, (state, { filterName, value }) => ({
		...state,
		filters: {
			...state.filters,
			[filterName]: value,
		},
	}))
);
