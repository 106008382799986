<app-apple-table
	[columns]="columns"
	[loading]="!!(loading$ | async)"
	[data]="(bookings$ | async) ?? []"
	[minimumRowHeight]="50"
	[enableHover]="true"
	(rowClicked)="clicked($event)"
	(sort)="sort($event)">
	<app-no-data
		no-data
		[title]="'No Campaigns Found'"
		[message]="'Try updating your filters and search terms'"></app-no-data>
	<div class="footer" footer>
		<app-apple-pagination
			(pageChange)="page($event)"
			[totalItems]="(total$ | async) ?? 0"
			[currentPage]="(page$ | async) ?? 1"
			position="left"
			[enablePageSizePicker]="true"></app-apple-pagination>
	</div>
</app-apple-table>
