<div class="filters">
	<app-insights-filters [filterOptions]="filterOptions" (filterChange)="onFilterChange($event)"></app-insights-filters>
</div>
<div class="dashboard" *ngLet="!!(loading$ | async) as loading">
	<ng-container *ngIf="!loading; else loadingPowerNumbers">
		<ng-container *ngIf="powerNumbers$ | async as powerNumbers">
			<div class="power-numbers" *ngIf="powerNumbers.length > 0">
				<app-insights-power-number class="power-number" *ngFor="let powerNumber of powerNumbers" [data]="powerNumber">
				</app-insights-power-number>
			</div>
		</ng-container>
	</ng-container>
	<ng-template #loadingPowerNumbers>
		<div class="power-numbers">
			<app-insights-power-number class="power-number" *ngFor="let i of [1, 2, 3, 4]" [data]="null">
			</app-insights-power-number>
		</div>
	</ng-template>
	<app-bar-graph-insight class="monetized-impressions" [loading]="loading" [data]="monetizedImpressions$ | async">
	</app-bar-graph-insight>
	<ng-container *ngIf="!loading; else loadingTrendNumbers">
		<ng-container *ngIf="trendNumbers$ | async as trendNumbers">
			<div class="booked-avg-container">
				<app-insights-power-number class="booked-spots" [data]="trendNumbers?.bookedSpots"></app-insights-power-number>
				<app-insights-power-number
					class="average-order-size"
					[data]="trendNumbers?.averageOrderSize"></app-insights-power-number>
			</div>
		</ng-container>
	</ng-container>
	<ng-template #loadingTrendNumbers>
		<div class="booked-avg-container">
			<app-insights-power-number class="booked-spots" [data]="null"></app-insights-power-number>
			<app-insights-power-number class="average-order-size" [data]="null"></app-insights-power-number>
		</div>
	</ng-template>
	<app-insights-donut-graph
		class="total-revenue"
		[loading]="loading"
		[data]="totalRevenue$ | async"></app-insights-donut-graph>
	<app-client-list
		class="client-revenue"
		[loading]="loading"
		[count]="4"
		[data]="clientRevenue$ | async"></app-client-list>
	<app-insights-line-graph
		class="revenue-graph line-graph"
		[loading]="loading"
		[data]="(revenueAnalysis$ | async) || undefined">
	</app-insights-line-graph>
	<app-page-header class="headings sources-heading" size="medium" heading="Sources"></app-page-header>
	<app-client-list
		class="touchpoint-revenue"
		[loading]="loading"
		[count]="4"
		[data]="touchpointRevenue$ | async"></app-client-list>
	<app-insights-campaign-gallery
		class="campaign-revenue"
		[loading]="loading"
		[data]="campaignRevenue$ | async"></app-insights-campaign-gallery>
	<app-page-header class="headings touchpoints-heading" size="medium" heading="Touchpoints"></app-page-header>
	<app-insights-touchpoint-details
		class="touchpoint-details"
		[loading]="loading"
		[data]="(touchpointDetails$ | async) || []"></app-insights-touchpoint-details>
</div>
