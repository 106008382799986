import { Component, Input } from '@angular/core';
import { PowerNumber } from '../../models';

@Component({
	selector: 'app-insights-power-number',
	templateUrl: './insight-power-number.component.html',
	styleUrls: ['./insight-power-number.component.scss'],
})
export class InsightPowerNumberComponent {
	@Input()
	public data?: PowerNumber | null;
}
