import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { DrillDownResponse } from 'src/app/models/_old/drilldown/drilldown';
import { ScreenshotService } from 'src/app/services/screenshot.service';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import * as fromRoot from '../../../store';
import * as fromUser from '../../../store/user';
import * as fromContentDrilldownActions from './content-drilldown.actions';
import * as fromContentDrilldownSelectors from './content-drilldown.selectors';
import { ContentDrilldownHeader } from './models/ContentDrilldownHeader';

@Injectable()
export class ContentDrilldownEffects {
	constructor(
		private actions$: Actions,
		private http: HttpClient,
		private store: Store<fromRoot.State>,
		private ss: ScreenshotService
	) {}

	private createSimpleEffect(actionType: any, newAction: any) {
		return createEffect(() =>
			this.actions$.pipe(
				ofType(actionType),
				map(() => newAction)
			)
		);
	}

	initializeContentDrilldownData$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromContentDrilldownActions.initializeContentDrilldownData),
			switchMap(({ mediaId, categoryId, touchpointId, variantId, isResult }) => {
				const url = `${environment.api}/client_portal/content_drilldown/?action_type=initialization`;
				const body = {
					mediaId,
					categoryId,
					touchpointId,
					variantId,
					isResult,
				};
				return this.http
					.post<ContentDrilldownHeader>(url, body)
					.pipe(map((header) => fromContentDrilldownActions.initializeContentDrilldownDataSuccess({ header })));
			})
		)
	);

	onSetPage$ = this.createSimpleEffect(
		fromContentDrilldownActions.setPage,
		fromContentDrilldownActions.refreshContentDrilldownData()
	);

	onSetSearchText$ = this.createSimpleEffect(
		fromContentDrilldownActions.setSearchText,
		fromContentDrilldownActions.refreshContentDrilldownData()
	);

	onSetFilter$ = this.createSimpleEffect(
		fromContentDrilldownActions.setAppliedFilters,
		fromContentDrilldownActions.refreshContentDrilldownData()
	);

	onResetFilter$ = this.createSimpleEffect(
		fromContentDrilldownActions.resetAppliedFilters,
		fromContentDrilldownActions.refreshContentDrilldownData()
	);

	onSetSort$ = this.createSimpleEffect(
		fromContentDrilldownActions.setSort,
		fromContentDrilldownActions.refreshContentDrilldownData()
	);

	refreshTouchpointData$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromContentDrilldownActions.refreshContentDrilldownData),
			withLatestFrom(
				this.store.select(fromContentDrilldownSelectors.selectMeta),
				this.store.select(fromContentDrilldownSelectors.selectFilters)
			),
			switchMap(([_, meta, filters]) => {
				const url = `${environment.api}/client_portal/content_drilldown/?action_type=table-construction`;
				const body = {
					mediaId: meta.mediaId,
					categoryId: meta.categoryId,
					touchpointId: meta.touchpointId,
					variantId: meta.variantId,
					pageSize: filters.pageSize, //we need to figure this out one day
					pageNumber: filters.page,
					searchText: filters.search,
					sortColumn: filters.column,
					sortDirection: filters.direction,
					filters: filters.applied,
					isResult: meta.isResult,
				};
				return this.http.post<DrillDownResponse<ContentDrilldownHeader>>(url, body).pipe(
					map((data) => {
						return fromContentDrilldownActions.refreshContentDrilldownDataSuccess({ data });
					})
				);
			})
		)
	);

	exportContentDrilldownData$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromContentDrilldownActions.exportContentDrilldownData),
				withLatestFrom(
					this.store.select(fromContentDrilldownSelectors.selectMeta),
					this.store.select(fromContentDrilldownSelectors.selectFilters)
				),
				switchMap(([_, meta, filters]) => {
					const url = `${environment.api}/client_portal/drilldown_export/`;
					const body = {
						filters: filters.applied,
						searchText: filters.search,
						categoryId: meta.categoryId,
						mediaId: meta.mediaId,
						touchpointId: meta.touchpointId,
						variantid: meta.variantId,
						isResult: meta.isResult,
					};
					return this.http.post(url, body, { responseType: 'blob' }).pipe(
						tap((response: Blob) => {
							const date = new Date().toLocaleDateString('en-GB').replace(/\//g, '-').slice(0, 8);
							const blob = new Blob([response]);
							const url = window.URL.createObjectURL(blob);
							const anchor = document.createElement('a');
							anchor.href = url;
							anchor.download = `${date}_DrilldownExport.xlsx`;
							anchor.click();
						})
					);
				})
			),
		{ dispatch: false }
	);

	listenForBulkScreenshotUpdates$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromContentDrilldownActions.listenForBulkScreenshotUpdates),
			withLatestFrom(this.store.select(fromUser.getUserAuthToken)),
			switchMap(([_, token]) => {
				this.ss.connect(token ?? '');
				return this.ss.screenshots.pipe(
					map((data) => fromContentDrilldownActions.bulkScreenshotUpdateSuccess({ data }))
				);
			})
		)
	);

	bulkScreenshotUpdate$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromContentDrilldownActions.bulkScreenshotUpdate),
				withLatestFrom(
					this.store.select(fromUser.getUserAuthToken),
					this.store.select(fromContentDrilldownSelectors.selectMeta)
				),
				tap(([_, token, meta]) => {
					this.ss.connect(token ?? '');
					this.ss.requestBulkScreenshots(meta.mediaId, meta.categoryId, meta.touchpointId, meta.variantId ?? '');
				})
			),
		{ dispatch: false }
	);
}
