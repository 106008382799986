<div
	class="select-container"
	[ngClass]="{ textarea: type === 'textarea' }"
	(focusout)="onBlur()"
	(click)="toggleDropdown()">
	<label *ngIf="label" class="label">{{ label }}</label>
	<ng-select
		[items]="items"
		[multiple]="multiple"
		[loading]="loading"
		[disabled]="disabled"
		bindLabel="name"
		[clearSearchOnAdd]="true"
		[closeOnSelect]="closeOnSelect"
		[clearable]="clearable"
		[(ngModel)]="selected"
		[placeholder]="placeholder"
		[maxSelectedItems]="maxSelections"
		[addTag]="allowAddNewItem ? addNewItem : false"
		addTagText="Add"
		(change)="onSelectedItemsChanged($event)"
		[isOpen]="dropdownOpen">
		<ng-template ng-label-tmp let-item="item" *ngIf="!items.length"></ng-template>
		<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
			<input *ngIf="multiple" id="item-{{ index }}" type="checkbox" class="checkbox" [ngModel]="item$.selected" />
			{{ item.name }}
		</ng-template>
		<ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
			@if (items && items.length) {
				@for (item of items; track item; let i = $index) {
					@if (truncateCount <= -1 || i < truncateCount) {
						<div class="ng-value">
							<div class="ng-value-label" [class.truncate-item]="truncateCount > -1">{{ item.name }}</div>
							<span class="ng-value-icon" *ngIf="!disabled" (click)="clear(item)" aria-hidden="true">×</span>
						</div>
					}
				}
				@if (truncateCount > -1 && items.length > truncateCount) {
					<div class="ng-value">
						<div class="ng-value-label truncate-count">+ {{ items.length - truncateCount }} more</div>
					</div>
				}
			}
		</ng-template>
		<ng-template ng-footer-tmp *ngIf="maxSelections !== 1000">
			<div class="selection-indicator">{{ selected.length }}/{{ maxSelections }}</div>
		</ng-template>
	</ng-select>
</div>
