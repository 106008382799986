import { createReducer, on } from '@ngrx/store';
import * as fromContent from './content.actions';
import { Comment, ContentApprover, ContentDetails } from './content.models';

export interface ContentState {
	comments: Comment[];
	approvals: ContentApprover[];
	details: ContentDetails | null;
	commentsLoading: boolean;
	detailsLoading: boolean;
	approvalLoading: boolean;
	error: any;
	success: boolean;
	approvalStatus: 'approved' | 'denied' | null | string;
	approvalError: any;
}

export const initialContentState: ContentState = {
	comments: [],
	approvals: [],
	details: null,
	commentsLoading: false,
	detailsLoading: false,
	approvalLoading: false,
	error: null,
	success: false,
	approvalStatus: null,
	approvalError: null,
};

export const contentReducer = createReducer(
	initialContentState,
	on(fromContent.loadComments, (state) => ({ ...state, commentsLoading: true })),
	on(fromContent.loadCommentsSuccess, (state, { comments }) => ({ ...state, comments, commentsLoading: false })),
	on(fromContent.loadCommentsFail, (state, { error }) => ({ ...state, error, commentsLoading: false })),
	on(fromContent.addComment, (state) => ({ ...state, commentsLoading: true })),
	on(fromContent.addCommentSuccess, (state, { comment }) => ({
		...state,
		commentsLoading: false,
		success: true,
	})),
	on(fromContent.addCommentFail, (state, { error }) => ({ ...state, error, commentsLoading: false })),
	on(fromContent.resetAddCommentSuccess, (state) => ({ ...state, success: false })),
	on(fromContent.loadContentDetails, (state) => ({ ...state, detailsLoading: true })),
	on(fromContent.loadContentDetailsSuccess, (state, { contentDetails }) => ({
		...state,
		details: contentDetails,
		detailsLoading: false,
	})),
	on(fromContent.loadContentDetailsFail, (state, { error }) => ({ ...state, error, detailsLoading: false })),
	on(fromContent.loadContentApproval, (state) => ({ ...state, approvalLoading: true })),
	on(fromContent.loadContentApprovalSuccess, (state, { approvers }) => ({
		...state,
		approvals: approvers,
		approvalLoading: false,
	})),
	on(fromContent.loadContentApprovalFail, (state, { error }) => ({
		...state,
		approvalError: error,
		approvalLoading: false,
	})),
	on(fromContent.approveContent, (state) => ({ ...state, approvalLoading: true })),
	on(fromContent.approveContentSuccess, (state) => ({ ...state, approvalStatus: 'approved', approvalLoading: false })),
	on(fromContent.approveContentFail, (state, { error }) => ({
		...state,
		approvalError: error,
		approvalLoading: false,
	})),
	on(fromContent.denyContent, (state) => ({ ...state, approvalLoading: true })),
	on(fromContent.denyContentSuccess, (state) => ({ ...state, approvalStatus: 'denied', approvalLoading: false })),
	on(fromContent.denyContentFail, (state, { error }) => ({ ...state, approvalError: error, approvalLoading: false }))
);
