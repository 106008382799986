<div class="media-container">
	<form [formGroup]="form">
		<div class="top">
			<div class="header">
				<app-page-header
					size="medium"
					heading="Add Media to your campaign"
					description="Select media from the library below or upload your own. A preview will be shown of MP4 files only.">
				</app-page-header>
			</div>
		</div>
		<div class="middle">
			<div class="dropzone-grid" *ngLet="variants$ | async as variants">
				<ng-container *ngFor="let variant of variants; index as i">
					<ng-container *ngIf="getDropZoneState(variant) | async as state">
						<app-media-drop-zone
							[state]="state"
							(onFileDeleted)="onFileDeleted(variant)"
							[orientation]="(orientation$ | async) ?? 'landscape'"
							(onFileDropped)="onFileReceived(variant, $event)"
							[size]="i > 0 ? 'small' : ''"
							[title]="variant == 1 ? 'Thumbnail' : variant + ' Screen'"></app-media-drop-zone>
					</ng-container>
				</ng-container>
			</div>
		</div>
		<div class="bottom">
			<div class="approvers-container section">
				<div class="header">
					<app-page-header
						size="medium"
						heading="Choose the approvers"
						description="Select who will be listed approvers for this content.">
					</app-page-header>
				</div>
				<app-form-field [control]="form.controls['approvers']">
					<app-apple-select
						label="Approvers"
						[multiple]="true"
						[closeOnSelect]="false"
						[items]="(approvers$ | async) ?? []"
						formControlName="approvers"></app-apple-select>
				</app-form-field>
			</div>
			<div class="section">
				<div class="header">
					<app-page-header
						size="medium"
						heading="Choose your Lighting Colour"
						description="Select from the range of lighting colours below to compliment your media.">
					</app-page-header>
				</div>
				<div class="picker">
					<app-color-picker [colors]="colors" formControlName="color"></app-color-picker>
				</div>
			</div>
			<div class="controls actions">
				<div class="placement">
					<app-apple-button
						[type]="'outline'"
						[size]="'large'"
						[style]="{ 'text-align': 'center', width: '90px' }"
						(click)="cancel()"
						>Cancel</app-apple-button
					>
					<app-apple-button
						[type]="'standard'"
						[size]="'large'"
						[style]="{ 'text-align': 'center', width: '150px' }"
						[disabled]="form.invalid || loading || !(variantValidation() | async)"
						(click)="submit()">
						<div class="button-content">
							<div *ngIf="!loading">Submit Media</div>
							<div *ngIf="loading">
								<i class="fa fa-solid fa-spinner fa-spin"></i>
							</div>
						</div>
					</app-apple-button>
				</div>
			</div>
		</div>
	</form>
</div>
