import { Component, OnInit, Type } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { DateTime } from 'ts-luxon';

import * as fromRoot from '../../../store';
import * as fromRevenue from './state';
import * as fromSession from '../../../store/session';

import {
	DonutGraph,
	RevenueTable,
	MonetizedImpressions,
	InsightTrends,
	PowerNumber,
	CampaignMedia,
	TouchpointData,
	Currency,
	LineGraph,
} from '.././models';

import { Category } from './models';
import { DateRange, FilterChange, FilterOption, Option } from '../components/filters/models';

@UntilDestroy()
@Component({
	selector: 'app-insights-revenue',
	templateUrl: './revenue.component.html',
	styleUrls: ['./revenue.component.scss'],
})
export class InsightsRevenueComponent implements OnInit {
	public loading$: Observable<boolean | true>;
	public currency$: Observable<Currency | null>;
	public categories$: Observable<Category[] | null>;

	public powerNumbers$: Observable<PowerNumber[] | null>;
	public trendNumbers$: Observable<InsightTrends | null>;
	public monetizedImpressions$: Observable<MonetizedImpressions | null>;
	public totalRevenue$: Observable<DonutGraph | null>;
	public revenueAnalysis$: Observable<LineGraph | null>;
	public touchpointRevenue$: Observable<RevenueTable | null>;
	public clientRevenue$: Observable<RevenueTable | null>;
	public campaignRevenue$: Observable<CampaignMedia | null>;
	public touchpointDetails$: Observable<TouchpointData[] | null>;

	private selectedCategories: number[] = [];
	public holdingCompany: number | undefined;

	public filterOptions: FilterOption[] = [
		{
			name: 'touchpoints',
			label: 'Touchpoints',
			type: 'multi-select',
			placeholder: 'All Touchpoints',
			options: [],
		},
		{
			name: 'period',
			label: 'Period',
			type: 'date-range',
		},
	];

	constructor(private store: Store<fromRoot.State>) {
		this.loading$ = this.store.select(fromRevenue.selectLoading).pipe(untilDestroyed(this));
		this.currency$ = this.store.select(fromRevenue.selectCurrency).pipe(untilDestroyed(this));
		this.categories$ = this.store.select(fromRevenue.selectCategoryFilters);

		this.powerNumbers$ = this.store.select(fromRevenue.selectPowerNumbers).pipe(untilDestroyed(this));
		this.trendNumbers$ = this.store.select(fromRevenue.selectTrendNumbers).pipe(untilDestroyed(this));
		this.monetizedImpressions$ = this.store.select(fromRevenue.selectMonetizedImpressions).pipe(untilDestroyed(this));
		this.touchpointRevenue$ = this.store.select(fromRevenue.selectTouchpointRevenue).pipe(untilDestroyed(this));
		this.clientRevenue$ = this.store.select(fromRevenue.selectClientRevenue).pipe(untilDestroyed(this));
		this.campaignRevenue$ = this.store.select(fromRevenue.selectCampaignRevenue).pipe(untilDestroyed(this));
		this.totalRevenue$ = this.store.select(fromRevenue.selectTotalRevenue).pipe(untilDestroyed(this));
		this.revenueAnalysis$ = this.store.select(fromRevenue.selectRevenueAnalysis).pipe(untilDestroyed(this));
		this.touchpointDetails$ = this.store.select(fromRevenue.selectTouchpointDetails).pipe(untilDestroyed(this));
	}

	ngOnInit(): void {
		this.store
			.select(fromSession.getFilters)
			.pipe(untilDestroyed(this))
			.subscribe((holdingCompany) => {
				this.holdingCompany = holdingCompany.company.id;
				this.resetCategories();
				this.loadInsights();
			});

		this.categories$.pipe(untilDestroyed(this)).subscribe((categories) => {
			if (categories) {
				const categoriesFilter = this.filterOptions.find((option) => option.name === 'touchpoints');

				if (categoriesFilter && categoriesFilter.options) {
					categoriesFilter.options = categories.map((category) => ({
						id: category.id,
						name: category.name,
					}));
				}
			}
		});
	}

	onFilterChange(filters: FilterChange): void {
		if (filters.field === 'touchpoints') {
			const touchpointsValue = filters.value as Option[];
			const touchpointIds = touchpointsValue.map((touchpoint) => Number(touchpoint.id));
			this.selectedCategories = touchpointIds;
			this.store.dispatch(
				fromRevenue.setFilter({
					filterName: 'categories',
					value: this.selectedCategories,
				})
			);
			this.loadInsights();
		} else if (filters.field === 'period') {
			const periodValue = filters.value as DateRange;
			if (periodValue.start && periodValue.end) {
				this.store.dispatch(
					fromRevenue.setFilter({
						filterName: 'period',
						value: {
							start: periodValue.start,
							end: periodValue.end,
						},
					})
				);
				this.loadInsights();
			}
		}
	}

	private resetCategories(): void {
		const categoriesFilter = this.filterOptions.find((option) => option.name === 'touchpoints');
		if (categoriesFilter) {
			categoriesFilter.value = [];
			this.store.dispatch(
				fromRevenue.setFilter({
					filterName: 'categories',
					value: [],
				})
			);
		}
	}

	private loadInsights(): void {
		if (this.holdingCompany !== undefined) {
			this.store.dispatch(
				fromRevenue.loadInsights({
					selectedCategories: this.selectedCategories,
					holdingCompany: this.holdingCompany,
				})
			);
		}
	}
}
