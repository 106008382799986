import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-slider',
	templateUrl: './slider.component.html',
	styleUrls: ['./slider.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SliderComponent),
			multi: true,
		},
	],
})
export class SliderComponent implements ControlValueAccessor {
	@Input()
	public label: string = '';

	@Input()
	public disabled: boolean = false;

	@Input()
	public max: number = 0;

	public value: number = 0;

	private propagateChange: any = () => {};
	private propagateTouched: any = () => {};

	constructor() {}

	writeValue(value: number): void {
		this.value = value;
	}

	registerOnChange(fn: any): void {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.propagateTouched = fn;
	}

	onSliderChange(value: any) {
		this.value = value;
		this.propagateChange(value);
		this.propagateTouched();
	}

	getOptions() {
		return {
			floor: 0,
			ceil: this.max,
			showSelectionBar: true,
			readOnly: this.disabled,
		};
	}
}
