import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';

import { Toast } from 'ngx-toastr';

@Component({
	selector: 'app-toast-notification',
	templateUrl: './toast.component.html',
	styleUrls: ['./toast.component.scss'],
})
export class ToastComponent extends Toast {}
