import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-sell-through-rate',
	templateUrl: './sell-through-rate.component.html',
	styleUrls: ['./sell-through-rate.component.scss'],
})
export class SellThroughRateComponent {
	@Input() public value: string = "0%";
	@Input() public percentage: number = 0;
}
