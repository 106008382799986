import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InsightsState } from '../../state';
import { SustainabilityState } from './sustainability.reducers';
import { TableHelpers } from 'src/app/helpers/table';

export const selectInsightState = createFeatureSelector<InsightsState>('insights');

export const selectSustainabilityState = createSelector(
	selectInsightState,
	(state: InsightsState) => state.sustainability
);

export const selectLoading = createSelector(selectSustainabilityState, (state: SustainabilityState) => state.loading);

export const selectAvailableFilters = createSelector(
	selectSustainabilityState,
	(state: SustainabilityState) => state.header.filters
);

export const selectPowerNumbers = createSelector(
	selectSustainabilityState,
	(state: SustainabilityState) => state.data.powerNumbers
);

export const selectTotalEmissions = createSelector(
	selectSustainabilityState,
	(state: SustainabilityState) => state.data.totalEmissions
);

export const selectEmissionsGraph = createSelector(
	selectSustainabilityState,
	(state: SustainabilityState) => state.data.emissionTrend
);

export const selectAllEmissionsTable = createSelector(selectSustainabilityState, (state: SustainabilityState) => {
	const emissionsTable = state.data.emissionsTable || [];
	return emissionsTable;
});

export const selectEmissionsTable = createSelector(selectSustainabilityState, (state: SustainabilityState) => {
	const totalEmissions = state.data.emissionsTable || [];
	return TableHelpers.paginate(totalEmissions, state.filters.table.page, state.filters.table.pageSize);
});

export const selectEmissionsTableTotal = createSelector(
	selectSustainabilityState,
	(state: SustainabilityState) => state.data.emissionsTable?.length
);

export const selectFilters = createSelector(selectSustainabilityState, (state: SustainabilityState) => state.filters);
