<div class="booking-container">
	<form [formGroup]="form">
		<div class="top">
			<div class="touchpoint-selection">
				<div class="touchpoint-selection-header">
					<app-page-header size="medium" heading="Touchpoint"></app-page-header>
				</div>
				<div class="touchpoint-selection-content">
					<app-form-field [control]="form.controls['touchpoint']">
						<app-apple-select
							label="Touchpoint"
							formControlName="touchpoint"
							[items]="(touchpoints$ | async) ?? []"
							[disabled]="isCampaignStatus(['active', 'post'])"></app-apple-select>
					</app-form-field>
					<app-form-field [control]="form.controls['category']">
						<app-apple-select
							label="Category"
							formControlName="category"
							[items]="form.controls['touchpoint'].value?.categories ?? []"
							[disabled]="isCampaignStatus(['active', 'post'])"></app-apple-select>
					</app-form-field>
					<app-form-field [control]="form.controls['duration']">
						<app-datepicker
							class="date-range"
							[type]="'range'"
							label="Duration"
							[monthCount]="2"
							formControlName="duration"
							[disabled]="isCampaignStatus(['active', 'post'])">
						</app-datepicker>
					</app-form-field>

					<app-form-field
						[control]="form.controls['storesRequested']"
						[customValidators]="{
							numbers: 'Stores Requested can only include numbers',
							maxStores:
								'Max number of stores you can request is ' + (form.controls['category'].value?.totalTouchpoints || 0),
						}">
						<app-apple-input
							label="Stores Requested"
							formControlName="storesRequested"
							[disabled]="isCampaignStatus(['active', 'post'])"></app-apple-input>
					</app-form-field>

					<app-form-field [control]="form.controls['type']">
						<app-apple-select
							label="Campaign Type"
							formControlName="type"
							[items]="(types$ | async) ?? []"
							[disabled]="isCampaignStatus(['active', 'post'])"></app-apple-select>
					</app-form-field>
				</div>
			</div>

			<div class="booking-details">
				<div class="booking-details-header">
					<app-page-header size="medium" heading="Campaign Details"></app-page-header>
				</div>
				<div class="booking-details-content">
					<div *ngIf="prefixField$ | async as prefixField">
						<app-dynamic-form-control
							[type]="prefixField.type"
							[label]="prefixField.name"
							[formControlName]="prefixField.name"
							[formGroup]="form"
							[options]="prefixField.options"
							[regex]="prefixField.regex"
							[prefix]="{ value: prefixField.placeholder, type: 'string' }"
							[placeholder]="''"
							[required]="prefixField.required"
							[validationWarningText]="prefixField.validation_warning_text"
							[disabled]="isCampaignStatus(['active', 'post'])">
						</app-dynamic-form-control>
					</div>

					<app-form-field [control]="form.controls['name']">
						<app-apple-input
							label="Campaign Name"
							formControlName="name"
							[disabled]="isCampaignStatus(['active', 'post'])"></app-apple-input>
					</app-form-field>

					<app-form-field
						[control]="form.controls['value']"
						[customValidators]="{ numbers: 'Campaign value can only include numbers' }">
						<app-apple-input
							label="Campaign Value"
							[prefix]="{ value: (currency$ | async) ?? '', type: 'string' }"
							formControlName="value"
							[disabled]="isCampaignStatus(['post'])"></app-apple-input>
					</app-form-field>

					<div *ngFor="let field of regularCustomFields$ | async">
						<div [ngClass]="{ 'checkbox-wrapper': field.type === 'boolean' }">
							<app-dynamic-form-control
								[type]="field.type"
								[label]="field.name"
								[formControlName]="field.name"
								[formGroup]="form"
								[options]="field.options"
								[regex]="field.regex"
								[required]="field.required"
								[validationWarningText]="field.validation_warning_text"
								[disabled]="isCampaignStatus(['active', 'post'])">
							</app-dynamic-form-control>
						</div>
					</div>
				</div>
			</div>
			<app-unit-preview
				class="unit-preview"
				[image]="form.controls['category'].value?.image || form.controls['touchpoint'].value?.image"
				[category]="form.controls['category'].value?.name"
				[touchpoint]="form.controls['touchpoint'].value?.name"></app-unit-preview>
		</div>

		<div class="middle">
			<div class="client-details">
				<div class="client-details-header">
					<app-page-header size="medium" heading="Supplier Details"></app-page-header>
				</div>
				<div class="client-details-content" formGroupName="client">
					<app-form-field
						[control]="form.get('client.email')!"
						[customValidators]="{ email: 'Client email is not a valid email' }">
						<app-apple-input
							label="Email"
							formControlName="email"
							[disabled]="isCampaignStatus(['active', 'post'])"></app-apple-input>
					</app-form-field>

					<app-form-field [control]="form.get('client.firstname')!">
						<app-apple-input
							label="First Name"
							formControlName="firstname"
							[disabled]="isCampaignStatus(['active', 'post'])"></app-apple-input>
					</app-form-field>

					<app-form-field [control]="form.get('client.lastname')!">
						<app-apple-input
							label="Last Name"
							formControlName="lastname"
							[disabled]="isCampaignStatus(['active', 'post'])"></app-apple-input>
					</app-form-field>
				</div>

				<div class="brand-selection-content">
					<app-form-field [control]="form.controls['brand']">
						<app-apple-select
							label="Holding Company"
							formControlName="brand"
							[allowAddNewItem]="true"
							[items]="(brands$ | async) ?? []"
							[disabled]="isCampaignStatus(['active', 'post'])"></app-apple-select>
					</app-form-field>

					<app-form-field [control]="form.controls['brandChildren']">
						<app-apple-select
							label="Brands"
							formControlName="brandChildren"
							[allowAddNewItem]="true"
							[closeOnSelect]="false"
							[multiple]="true"
							[items]="form.controls['brand'].value?.children ?? []"
							[disabled]="isCampaignStatus(['active', 'post'])"></app-apple-select>
					</app-form-field>
				</div>
			</div>
		</div>

		<div class="bottom">
			<div class="store-selection">
				<div class="store-selection-header">
					<app-page-header
						size="medium"
						heading="Store Selection"
						description="Please select your desired stores from the list below."></app-page-header>
				</div>
				<div class="store-selection-content">
					<app-banner *ngIf="banner" [data]="banner" class="banner"></app-banner>
					<div class="store-selection-content-map">
						<app-map
							[defaultZoom]="(defaultZoom$ | async) ?? 5"
							[locations]="(allStores$ | async) ?? []"
							[storeLocation]="storeLocation$ | async"
							[selected]="form.controls['storesSelected'].value">
						</app-map>
					</div>
					<div class="store-selection-content-table">
						<app-apple-card class="card-border" [style]="{ height: '559px', padding: '14px 14px 0px 14px' }">
							<app-apple-table
								[loading]="false"
								[selectable]="true"
								[disabled]="isCampaignStatus(['active', 'post'])"
								[selectedItems]="(selectedStores$ | async) ?? []"
								[data]="(pagedStores$ | async) ?? []"
								[columnPadding]="10"
								[textTruncateWidth]="100"
								[allRowsSelected]="
									form.controls['storesSelected'].value.length === (allStores$ | async)?.length &&
									((allStores$ | async)?.length ?? 0) > 0
								"
								(rowSelected)="onStoresSelected($event)"
								(rowDeselected)="onStoresDeselected($event)"
								(allSelected)="onSelectAllStores($event)"
								[enableHover]="true"
								[columns]="columns">
								<app-apple-dual-slot-header header>
									<div class="header" header-left>
										<div class="controls">
											<!-- <app-apple-button
												background="var(--white)"
												border="var(--grey-border)"
												[style]="{ padding: '10px 12px' }">
												<div class="button-content">
													<div class="icon">
														<img src="../../../../assets/search-icon-blue.svg" />
													</div>
												</div>
											</app-apple-button>
											<app-apple-button
												background="var(--white)"
												border="var(--grey-border)"
												[style]="{ padding: '10px 12px' }">
												<div class="button-content">
													<div class="icon">
														<img src="../../../../assets/filter-icon.svg" />
													</div>
												</div>
											</app-apple-button> -->
											<app-data-import
												label="Upload Store List"
												(dataImported)="handleDataImported($event)"
												[disabled]="isCampaignStatus(['active', 'post'])"></app-data-import>
											<app-data-export
												[data]="(exportStores$ | async) ?? []"
												label="Download Template"></app-data-export>
										</div>
									</div>
									<div class="header" header-right>
										<div class="store-count">
											<div class="store-count-total">
												<span [ngClass]="getStoreCountClass()"
													>{{ form.controls['storesSelected'].value.length }}
												</span>
												<span>/</span>
												<span>{{ form.controls['storesRequested'].value }}</span>
											</div>
											<div class="store-count-label">
												<span>Stores Selected</span>
											</div>
										</div>
									</div>
								</app-apple-dual-slot-header>
								<app-no-data
									no-data
									image="../../../../../assets/no-data-category.svg"
									title="No Stores Found"
									message="Please select a touchpoint and category to see which stores are available."></app-no-data>
								<div class="footer" footer>
									<app-apple-pagination
										[totalItems]="(allStores$ | async)?.length ?? 0"
										[currentPage]="1"
										position="center"
										(pageChange)="pageStores($event)"
										[pageSizes]="[{ id: 1, name: '9' }]"
										[enablePageSizePicker]="false"></app-apple-pagination>
								</div>
							</app-apple-table>
						</app-apple-card>
					</div>
				</div>
			</div>
		</div>

		<div class="controls actions" *ngLet="roles$ | async as roles">
			<div class="placement">
				<div class="controls">
					<app-apple-button
						*ngIf="!isBookingStatus(['Provisional', 'Confirmed'])"
						[type]="'outline'"
						[size]="'large'"
						[style]="{ 'text-align': 'center', width: '90px' }"
						(click)="cancelBooking()"
						>Cancel
					</app-apple-button>
					<app-apple-button
						*ngIf="isBookingStatus(['Provisional', 'Confirmed'])"
						[type]="'warning'"
						[size]="'large'"
						[style]="{ 'text-align': 'center', width: '90px' }"
						[disabled]="!(canDelete$ | async)"
						(click)="deleteBooking()">
						Delete
					</app-apple-button>
				</div>
				<div class="controls">
					<app-apple-button
						*ngIf="form.controls['bookingStatus'].value !== null && form.controls['bookingStatus'].value !== undefined"
						[type]="form.controls['bookingStatus'].value === 'Provisional' ? 'outline' : 'standard'"
						[size]="'large'"
						[style]="{ 'text-align': 'center', width: '90px' }"
						[disabled]="form.invalid || form.pristine || (saving$ | async)"
						(click)="submitBooking()">
						<div class="button-content">
							<div *ngIf="!(saving$ | async)">
								{{ form.controls['bookingStatus'].value ? 'Save' : 'Create' }}
							</div>
							<div *ngIf="saving$ | async">
								<i class="fa fa-solid fa-spinner fa-spin"></i>
							</div>
						</div>
					</app-apple-button>
					<app-apple-button
						*ngIf="form.controls['bookingStatus'].value === 'Provisional'"
						[type]="'standard'"
						[size]="'large'"
						[style]="{ 'text-align': 'center', width: '150px' }"
						[disabled]="form.invalid || form.controls['storesSelected'].value.length === 0"
						(click)="updateStatus()">
						Confirm
					</app-apple-button>
				</div>
			</div>
		</div>
	</form>
</div>
