import { Component, Input } from '@angular/core';
import { StoreData } from 'src/app/features/insights/campaign/models';
import { TableColumn } from 'src/app/components/apple/apple-table/models/column';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import * as fromRoot from '../../../../../store';
import * as fromCampaign from '../../state';
import { Store } from '@ngrx/store';

@UntilDestroy()
@Component({
	selector: 'app-insights-campaigns-store-list',
	templateUrl: './store-list.component.html',
	styleUrls: ['./store-list.component.scss'],
})
export class InsightsCampaignsStoreListComponent {
	@Input()
	public loading: boolean = true;

	@Input()
	public data: StoreData[] = [];

	public columns: TableColumn[] = [
		{ header: 'Store Name', field: 'storeName', sortable: false },
		{ header: 'Store Number', field: 'storeNumber', sortable: false },
		{ header: 'Plays/Period', field: 'periodPlays', sortable: false },
		{ header: 'Uptime', field: 'uptimeValue', sortable: false },
	];

	public page$: Observable<number> = this._store.select(fromCampaign.selectSelectedFilters).pipe(
		map((filters) => filters.stores.page),
		untilDestroyed(this)
	);

	public total$: Observable<number> = this._store.select(fromCampaign.selectStoreCount).pipe(untilDestroyed(this));

	constructor(private _store: Store<fromRoot.State>) {}

	page(event: { page: number; size: number }) {
		this._store.dispatch(fromCampaign.setPage({ page: event.page, size: event.size }));
	}
}
