import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DateTime } from 'ts-luxon';
import { Store } from '@ngrx/store';
import { from, Observable, tap } from 'rxjs';

import * as fromCampaign from './state';
import * as fromRoot from '../../../store';
import * as fromSession from '../../../store/session';

import { CampaignOverview, StoreData } from './models';
import { PowerNumber, LineGraph, CampaignMedia } from '../models';
import { FilterOption, FilterChange, Option, DateRange } from '../components/filters/models';
import { ToastService } from 'src/app/services/toast.service';
import { Location } from '../../campaign/models/Location';

@UntilDestroy()
@Component({
	selector: 'app-insights-campaign',
	templateUrl: './campaign.component.html',
	styleUrls: ['./campaign.component.scss'],
})
export class InsightsCampaignComponent implements OnInit {
	public holdingCompany: number | undefined;

	public loading$: Observable<boolean | true>;
	public error$: Observable<string | null>;
	public filters$: Observable<{
		touchpoints: Option[];
		campaigns: Option[];
		companies: Option[];
	} | null>;
	public powerNumbers$: Observable<PowerNumber[] | null>;
	public campaignOverview$: Observable<CampaignOverview | null>;
	public uptime$: Observable<LineGraph | null>;
	public stores$: Observable<StoreData[] | null>;
	public campaignMedia$: Observable<CampaignMedia | null>;

	public zoom: number = 5;
	public locationDefault: Location | null = null;
	public locationsArray: { id: number; longitude: number; latitude: number }[] = [];

	public filterOptions: FilterOption[] = [
		{
			name: 'touchpoints',
			label: 'Touchpoints',
			type: 'multi-select',
			placeholder: 'All Touchpoints',
			options: [],
		},
		{
			name: 'period',
			label: 'Period',
			type: 'date-range',
			granularity: 'month',
		},
		{
			name: 'companies',
			label: 'Companies',
			type: 'multi-select',
			placeholder: 'All Companies',
			options: [],
		},
		{
			name: 'campaigns',
			label: 'Campaigns',
			type: 'single-select',
			placeholder: 'All Campaigns',
			options: [],
		},
	];

	constructor(
		private store: Store<fromRoot.State>,
		private toast: ToastService
	) {
		this.loading$ = this.store.select(fromCampaign.selectLoading).pipe(untilDestroyed(this));
		this.error$ = this.store.select(fromCampaign.selectError).pipe(untilDestroyed(this));
		this.filters$ = this.store.select(fromCampaign.selectFilters).pipe(untilDestroyed(this));

		this.powerNumbers$ = this.store.select(fromCampaign.selectPowerNumbers).pipe(untilDestroyed(this));
		this.campaignOverview$ = this.store.select(fromCampaign.selectCampaignOverview).pipe(untilDestroyed(this));
		this.uptime$ = this.store.select(fromCampaign.selectUptime).pipe(untilDestroyed(this));
		this.campaignMedia$ = this.store.select(fromCampaign.selectCampaignMedia).pipe(untilDestroyed(this));
		this.stores$ = this.store.select(fromCampaign.selectStores).pipe(untilDestroyed(this));

		this.store
			.select(fromCampaign.selectAllStores)
			.pipe(untilDestroyed(this))
			.subscribe((stores) => {
				this.locationsArray = stores?.map((store) => ({ id: store.id, ...store.coordinates })) || [];
			});

		this.store
			.select(fromSession.getFilters)
			.pipe(untilDestroyed(this))
			.subscribe((location) => {
				if (location) {
					this.zoom = location.company.branding.map.zoom;
					this.locationDefault = location.company.branding.map.coordinates;
				}
			});
	}

	ngOnInit(): void {
		this.store
			.select(fromSession.getFilters)
			.pipe(untilDestroyed(this))
			.subscribe((holdingCompany) => {
				this.holdingCompany = holdingCompany.company.id;
				this.store.dispatch(fromCampaign.getFilters());
				this.store.dispatch(fromCampaign.loadCampaign({ holdingCompanyId: this.holdingCompany }));
			});

		this.filters$.pipe(untilDestroyed(this)).subscribe((filters) => {
			if (filters) {
				const touchpointsFilter = this.filterOptions.find((option) => option.name === 'touchpoints');
				const campaignsFilter = this.filterOptions.find((option) => option.name === 'campaigns');

				if (touchpointsFilter && touchpointsFilter.options) {
					touchpointsFilter.options = filters.touchpoints.map((touchpoint) => ({
						id: touchpoint.id,
						name: touchpoint.name,
					}));
				}

				if (campaignsFilter && campaignsFilter.options) {
					campaignsFilter.options = filters.campaigns.map((campaign) => ({
						id: campaign.id,
						name: campaign.name,
					}));
				}

				const companiesFilter = this.filterOptions.find((option) => option.name === 'companies');
				if (companiesFilter && companiesFilter.options) {
					companiesFilter.options = filters.companies.map((company) => ({
						id: company.id,
						name: company.name,
					}));
				}
			}
		});

		this.error$.pipe(untilDestroyed(this)).subscribe((error) => {
			if (error) {
				this.resetCampaignFilter();
				this.toast.open(
					'error',
					'Unexpected Error',
					'An unexpected error occurred while loading campaign uptime data, please try again later.',
					5000,
					'right'
				);
			}
		});
	}

	onFilterChange(filters: FilterChange): void {
		if (filters.field === 'touchpoints') {
			const touchpointsValue = filters.value as Option[];
			const touchpointIds = touchpointsValue.map((touchpoint) => Number(touchpoint.id));
			this.store.dispatch(
				fromCampaign.setFilter({
					filterName: 'touchpoints',
					filterValue: touchpointIds,
				})
			);
		} else if (filters.field === 'campaigns') {
			const campaignsValue = filters.value as Option;
			const campaignId = campaignsValue.id as string;
			this.store.dispatch(
				fromCampaign.setFilter({
					filterName: 'campaign',
					filterValue: campaignId,
				})
			);
			this.store.dispatch(
				fromCampaign.loadCampaign({
					holdingCompanyId: this.holdingCompany,
					campaignId,
				})
			);
		} else if (filters.field === 'period') {
			const periodValue = filters.value as DateRange;
			if (periodValue.start && periodValue.end) {
				this.store.dispatch(
					fromCampaign.setFilter({
						filterName: 'period',
						filterValue: {
							start: periodValue.start,
							end: periodValue.end,
						},
					})
				);
			}
		} else if (filters.field === 'companies') {
			const companiesValue = filters.value as Option[];
			const companyIds = companiesValue.map((company) => Number(company.id));
			this.store.dispatch(
				fromCampaign.setFilter({
					filterName: 'companies',
					filterValue: companyIds,
				})
			);
		}
	}

	public resetCampaignFilter(): void {
		this.store.dispatch(
			fromCampaign.setFilter({
				filterName: 'campaign',
				filterValue: '',
			})
		);

		if (this.holdingCompany) {
			this.store.dispatch(
				fromCampaign.loadCampaign({
					holdingCompanyId: this.holdingCompany,
				})
			);
		}
	}
}
