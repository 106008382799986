import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-pill',
	templateUrl: './pill.component.html',
	styleUrl: './pill.component.scss',
})
export class PillComponent {
	@Input() type!: string | 'Provisional' | 'Confirmed';

  get statusClass() {
    return this.type == "Confirmed" ? 'confirmed' : 'provisional';
  }
}
