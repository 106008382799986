<div class="toaster">
	<div class="toast-icon">
		<img [src]="'../../../assets/' + options.toastClass + '.svg'" alt="" />
	</div>
	<div class="toast-details">
		<div>
			<div [class]="options.toastClass" [attr.aria-label]="title">
				{{ title | titlecase }}
			</div>
			<div
				role="alert"
				aria-live="polite"
				class="custom-message"
				[class]="options.messageClass"
				[attr.aria-label]="message">
				{{ message }}
			</div>
		</div>
	</div>
	<div class="close-icon">
		<div (click)="remove()" class="toast-close-button">
			<img src="../../../assets/cross-grey.svg" alt="" />
		</div>
	</div>
</div>
