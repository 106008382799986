import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-media-drop-zone',
	templateUrl: './media-drop-zone.component.html',
	styleUrl: './media-drop-zone.component.scss',
})
export class MediaDropZoneComponent {
	@Input()
	public orientation!: string;

	@Input()
	public size: string = '';

	@Input()
	public title: string = '';

	@Input()
	public state: { status: string; data: number | string | null } = {
		status: 'empty',
		data: null,
	};

	@Output()
	public onFileDropped = new EventEmitter<File>();

	@Output()
	public onFileDeleted = new EventEmitter();

	onDrop(event: DragEvent): void {
		event.preventDefault();

		const files = event.dataTransfer?.files;
		if (files && files.length > 0) {
			this.onFileDropped.emit(files[0]);
		}
	}

	onFileChange(event: any): void {
		const file = event.target.files[0];
		if (file) {
			this.onFileDropped.emit(file);
		}
	}

	onDelete() {
		this.onFileDeleted.emit();
	}
}
